import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table, Label, Input } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { isEmpty } from 'lodash';
import NumberFormat from "react-number-format";
import classnames from 'classnames';
import { changeFormatOfDateString, handleErrorMessage } from "../../services/CommonService";
import BulkOrderService from '../../services/BulkOrderService';

export default class EDILinkingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ediOrders: [],
            ordNum: props.ordNum,
            accountID: props.accountID,
            selectedCheckbox: null,
            orderIndex: props.orderIndex,
            ediOrderStatuses: props.ediOrderStatuses,
            filtered_status: [],
            userRole: props.userRole,
            statusLoading: false,
            salesOrderJobStatusId: props.salesOrderJobStatusId
        };
        this.selectRecord = this.selectRecord.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.bulkOrderService = new BulkOrderService();
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.bulkOrderService.getEDIOrders().then(response => {
            let ediOrders = response.data;
            ediOrders = ediOrders.map(ediOrder => ({
                ...ediOrder,
                isSelected: false
            }));
            this.setState({ ediOrders: ediOrders, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    selectRecord(ediOrder) {
        let { ediOrders } = this.state;
        ediOrders = ediOrders.map(ediOrder => ({
            ...ediOrder,
            isSelected: false
        }));
        const elementsIndex = ediOrders.findIndex(e => e.salesOrderID === ediOrder.salesOrderID);
        ediOrders[elementsIndex].isSelected = !ediOrder.isSelected;
        this.setState({ selectedCheckbox: (ediOrder.isSelected ? null : ediOrder), ediOrders });
    }

    handleClick(value) {
        let { ediOrders } = this.state;
        ediOrders = ediOrders.find(ediOrder => ediOrder.isSelected);
        this.setState({ salesOrderJobStatusId: parseInt(value) });
    }

    handleSubmit() {
        let { ordNum, orderIndex, selectedCheckbox } = this.state;
        if (isEmpty(selectedCheckbox)) {
            toast.info("Please select an order");
            return;
        }
        let request = {
            ordNum: ordNum,
            salesOrderID: selectedCheckbox.salesOrderID,
            custOrdNum: selectedCheckbox.custOrdNum
        };
        this.setState({ statusLoading: true });
        this.bulkOrderService.linkEDIOrder(request).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("EDI Order linked successfully");
                this.props.handleCloseModal(false, false);
                this.props.takeActionOnOrder(ordNum, orderIndex, "Make");
                this.setState({ statusLoading: false });
            }
        }).catch(error => {
            this.setState({ statusLoading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    handleCancel() {
        this.props.handleCloseModal(false, false);
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { ordNum, ediOrders, selectedCheckbox, loading, statusLoading } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    <h5 className="mb-0">EDI Linking for Order No. {ordNum}</h5>
                </ModalHeader>
                <ModalBody>
                    {
                        loading ?
                            <Spinner color={"primary"} />
                            :
                            <div>
                                {isEmpty(ediOrders) ?
                                    <h5 className="text-center">No EDI Order is available.</h5>
                                    :
                                    <Table size={"sm"} striped responsive bordered>
                                        <thead>
                                            <tr className="text-center">
                                                <th>S.No.</th>
                                                <th>EDI Order ID</th>
                                                <th>Customer Order No.</th>
                                                <th>Order Date</th>
                                                <th>Order Total Ex.</th>
                                                <th>Order Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(ediOrders || []).map((ediOrder, index) =>
                                                <tr key={index} className="text-center">
                                                    <td className="text-center align-middle">
                                                        <a href={"javascript:void(0)"} onClick={() => this.selectRecord(ediOrder)}>
                                                            <i className={classnames("fa", "fa-lg", {
                                                                "fa-check-square-o": ediOrder.isSelected,
                                                                "fa-square-o": !ediOrder.isSelected,
                                                            }
                                                            )} />
                                                        </a>
                                                        {" " + (index + 1)}
                                                    </td>
                                                    <td>{ediOrder.salesOrderID}</td>
                                                    <td>{ediOrder.custOrdNum}</td>
                                                    <td>{changeFormatOfDateString(ediOrder.orderDate, "YYYY-MM-DD", "DD MMM YYYY")}</td>
                                                    <td>
                                                        <NumberFormat
                                                            value={ediOrder.orderTotalEx}
                                                            displayType={'text'}
                                                            decimalScale={4}
                                                            fixedDecimalScale={false}
                                                            thousandSeparator={true}
                                                            prefix="$"
                                                        />
                                                    </td>
                                                    <td>
                                                        <NumberFormat
                                                            value={ediOrder.orderTotal}
                                                            displayType={'text'}
                                                            decimalScale={4}
                                                            fixedDecimalScale={false}
                                                            thousandSeparator={true}
                                                            prefix="$"
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                    }
                </ModalBody>
                <ModalFooter>
                    {
                        isEmpty(ediOrders)
                            ? null
                            : <Button className="d-flex justify-content-end align-items-center" color={"primary"} size={"sm"} onClick={this.handleSubmit} disabled={statusLoading || isEmpty(selectedCheckbox)}>
                                {statusLoading ? <Spinner size="sm" className="mr-2" style={{ color: "white" }} /> : <i className="fa fa-link mr-2" />}
                                {statusLoading ? "Linking" : "Link"} EDI Order</Button>
                    }
                    <Button className="d-flex justify-content-end" color={"secondary"}
                        size={"sm"} onClick={this.handleCancel}>
                        Close
                    </Button>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}