import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Input,
    Button, Spinner, ButtonGroup
} from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import {
    toast, ToastContainer
} from "react-toastify";
import { handleErrorMessage} from "../../services/CommonService";
import StockService from '../../services/StockService'
import SearchCreditorAcccount from "../search/SearchCreditorAcccount";
import ConfirmModal from '../modal/ConfirmModal';

export default class CreditorStockFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creditorStock: {},
            formErrors: {},
            isOpenDeleteConfirmModal: false
        };
        this.stockService = new StockService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.initCreditStock = this.initCreditStock.bind(this);
        this.toggleCompleteConfirmModal = this.toggleCompleteConfirmModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        let {prodCode, accountID} = this.props;
        let {creditorStock} = this.state;
        creditorStock = this.initCreditStock();
        this.setState({creditorStock}, () => {
            this.getCreditorStock(prodCode, accountID);
        });
    }

    initCreditStock() {
        let {prodCode, accountID, stockVariable} = this.props;
        return {
            creditorStockID: null,
            accountID: accountID,
            prodCode: prodCode,
            available: true,
            discontinued: false,
            soldOut: false,
            qoh: 0,
            qoo: 0,
            eta: "",
            moq3: 0,
            packQty: 0,
            leadTime: 0,
            xRefCode: "",
            stockVariable: stockVariable,
            knownAs: "",
            moq1: 0,
            moq2: 0,
            price1: 0,
            price2: 0,
            price3: 0
        }
    }

    handleChange(change, key) {
        let {creditorStock} = this.state;

        switch (key) {
            default:
                creditorStock[key] = change;
                break;
        }
        this.setState({creditorStock});

    }

    toggleCompleteConfirmModal(change, item) {
        this.setState({ isOpenDeleteConfirmModal: change, deleteItem: item });
    }

    handleDelete() {
        this.setState({ isOpenDeleteConfirmModal: false }, () =>
            this.removeSupplier(this.state.deleteItem));
    }


    getCreditorStock(prodCode, accountID) {
        let {creditorStock} = this.state;
        if (prodCode && accountID) {
            this.setState({isLoadingCreditorStock: true, formErrors: {}});
            this.stockService.getCreditorStock(prodCode, accountID).then(response => {
                if (response.data) {
                    creditorStock.creditorStockID = response.data.creditorStockID;
                    creditorStock.accountID = response.data.accountID;
                    creditorStock.prodCode = response.data.prodCode;
                    creditorStock.available = response.data.available;
                    creditorStock.discontinued = response.data.discontinued;
                    creditorStock.soldOut = response.data.soldOut;
                    creditorStock.qoh = response.data.qoh;
                    creditorStock.qoo = response.data.qoo;
                    creditorStock.eta = response.data.eta;
                    creditorStock.moq3 = response.data.moQ3;
                    creditorStock.packQty = response.data.packQty;
                    creditorStock.leadTime = response.data.leadTime;
                    creditorStock.xRefCode = response.data.xRefCode;
                    creditorStock.knownAs = response.data.knownAs;
                    creditorStock.moq1 = response.data.moQ1;
                    creditorStock.moq2 = response.data.moQ2;
                    creditorStock.price1 = response.data.price1;
                    creditorStock.price2 = response.data.price2;
                    creditorStock.price3 = response.data.price3;
                }

                this.setState({creditorStock, isLoadingCreditorStock: false});
            }).catch(error => {
                this.setState({isLoadingCreditorStock: false});
                toast.error(handleErrorMessage(error));
                console.log(error.data)
            });
        }
    }

    removeSupplier(item) {
        let { loading } = this.props;
        let { prodCode } = this.props;
        loading.deleting = true;
        this.setState({ loading });
        this.stockService.removeSupplierForStock(item.accountID, prodCode)
            .then(response => {
                loading.deleting = false;
                this.setState({ loading });
                toast.success("Removed Supplier!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.toggle(false);
                this.props.refresh();
            })
            .catch(error => {
                loading.deleting = false;
                toast.success(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({ loading });
            });
    }

    handleSubmit() {
        let {creditorStock} = this.state;

        this.setState({isLoadingSubmit: true, formErrors: {}});
        this.stockService.updateCreditorStock(creditorStock).then(response => {
            toast.success("Saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({isLoadingSubmit: false});
            this.props.refresh();
            this.props.toggle(false);
        }).catch(error => {
            this.setState({isLoadingSubmit: false});
            toast.error(handleErrorMessage(error));
            console.log(error.data)
        });
    }

    render() {
        let {creditorStock, isLoadingSubmit, isLoadingCreditorStock} = this.state;
        let {isOpen, toggle} = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>

                </ModalHeader>
                <ModalBody>
                    {
                        isLoadingCreditorStock
                            ? <Spinner color={"primary"}/>
                            : <div>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>
                                                Supplier
                                            </Label>
                                            <SearchCreditorAcccount
                                                handleAccountChange={(selected) => this.handleChange(selected, "accountID")}
                                                defaultAccountID={""}
                                                selectedAccountID={creditorStock.accountID}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>MOQ 1</Label>
                                            <Input
                                                name="moq1"
                                                type="number"
                                                value={creditorStock.moq1}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write moq 1 here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>MOQ 2</Label>
                                            <Input
                                                name="moq2"
                                                type="number"
                                                value={creditorStock.moq2}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write moq 2 here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>MOQ 3</Label>
                                            <Input
                                                name="moq3"
                                                type="number"
                                                value={creditorStock.moq3}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write moq 3 here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Price 1 </Label>
                                            <Input
                                                name="price1"
                                                type="number"
                                                value={creditorStock.price1}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write price 1 here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Price 2</Label>
                                            <Input
                                                name="price2"
                                                type="number"
                                                value={creditorStock.price2}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write price 2 here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Price 3</Label>
                                            <Input
                                                name="price3"
                                                type="number"
                                                value={creditorStock.price3}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write price 3 here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Pack Qty</Label>
                                            <Input
                                                name="packQty"
                                                type="number"
                                                value={creditorStock.packQty}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write pack qty here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Supplier Ref.</Label>
                                            <Input
                                                name="xRefCode"
                                                type="text"
                                                value={creditorStock.xRefCode}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write xRefCode here..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Lead Time In Days</Label>
                                            <Input
                                                name="leadTime"
                                                type="number"
                                                value={creditorStock.leadTime}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                placeholder="write lead time here..."/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/* <FormGroup>
                                    <Label>QOO</Label>
                                    <Input
                                        name="qoo"
                                        type="number"
                                        value={creditorStock.qoo}
                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        placeholder="write qoo here..."/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>QOH</Label>
                                    <Input
                                        name="qoh"
                                        type="number"
                                        value={creditorStock.qoh}
                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        placeholder="write qoh here..."/>
                                </FormGroup>
                                <FormGroup>
                                    <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange(!creditorStock.available, "available")}>
                                        {creditorStock.available ?
                                            <i className="fa fa-lg fa-check-square-o mr-2" aria-hidden="true"/> :
                                            <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true"/>}
                                    </Button>
                                    <Label className="mb-0">Available</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange(!creditorStock.discontinued, "discontinued")}>
                                        {creditorStock.discontinued ?
                                            <i className="fa fa-lg fa-check-square-o mr-2"
                                               aria-hidden="true"/> :
                                            <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true"/>}
                                    </Button>
                                    <Label className="mb-0">Discontinued</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange(!creditorStock.soldOut, "soldOut")}>
                                        {creditorStock.soldOut ?
                                            <i className="fa fa-lg fa-check-square-o mr-2"
                                               aria-hidden="true"/> :
                                            <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true"/>}
                                    </Button>
                                    <Label className="mb-0">Sold Out</Label>
                                </FormGroup>*/}
                            </div>
                    }

                </ModalBody>
                <ModalFooter className={"d-flex justify-content-between"}>
                    <ButtonGroup>
                        {creditorStock.accountID && (
                            <Button color={"danger"} size={'sm'} className={""} onClick={() => this.toggleCompleteConfirmModal(true, creditorStock)}>
                                <i className="fa fa-trash mr-2" aria-hidden="true" />Remove Supplier
                            </Button>
                        )}
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button color={"primary"} size='sm' className={"mr-2"}
                            disabled={isLoadingSubmit}
                            onClick={this.handleSubmit}>
                            {isLoadingSubmit
                                ? <Spinner className={"mr-2"} size={"sm"} />
                                : <i className="fa fa-floppy-o mr-2" />
                            }
                            {isLoadingSubmit ? "Saving" : "Save"}
                        </Button>
                        <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" />
                            Cancel
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
                {
                    this.state.isOpenDeleteConfirmModal &&
                    <ConfirmModal
                        isOpen={this.state.isOpenDeleteConfirmModal}
                        toggle={this.toggleCompleteConfirmModal}
                        handleSubmit={() => this.handleDelete()}
                        handleCancel={() => this.toggleCompleteConfirmModal(false)}
                        primaryMessage={"Are you sure you want to remove this supplier?"}
                        secondaryMessage={""}
                        submitColor={"danger"}
                        cancelColor={"secondary"}
                        icon={"fa fa-trash-o fa-2x"}
                        type="Removing..."
                    />
                }
                <ToastContainer/>
            </Modal>
        )
    }


}