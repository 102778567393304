import React, { Component } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import OrderService from "../../../services/OrderService";
import { handleErrorMessage } from '../../../services/CommonService';
import { toast } from 'react-toastify';

export default class UpdateCustomerOrderNumberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ordNum: 0,
            custOrdNum: ""
        };
        this.orderService = new OrderService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ ordNum: this.props.ordNum, custOrdNum: this.props.selected });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            let custOrdNum = nextProps.selected;
            this.setState({ ordNum: nextProps.ordNum, custOrdNum });
        }
    }

    handleChange(e) {
        this.setState({ custOrdNum: e.target.value });
    }

    handleSubmit() {
        let { ordNum, custOrdNum } = this.state;
        let request = {
            ordNum: ordNum,
            custOrdNum: custOrdNum
        }
        this.setState({ loading: true });
        this.orderService.updateCustomerOrderNumber(request).then(response => {
            this.setState({ loading: false });
            this.props.refreshOrder();
            toast.success("Customer Order Number Updated");
            this.props.toggleModel();
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
            console.log(error.response);
        });
    }

    render() {
        let { custOrdNum } = this.state;
        return (
            <Modal isOpen={this.props.isOpen}
                size="md" scrollable={false}
                toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    Update Customer Order Number
                </ModalHeader>
                <ModalBody>
                    <div className="mt-1 mb-2">
                        <Label>Enter customer order number </Label>
                        <Input className="form-control"
                            onChange={this.handleChange}
                            type="textarea"
                            name="custOrdNum"
                            value={custOrdNum}
                            placeholder="Write customer order number here" />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color="primary"
                            size="sm"
                            onClick={this.handleSubmit}
                            disabled={this.state.loading}>
                            {this.state.loading ? <Spinner size="sm" className="mr-2" /> : null}
                            {this.state.loading ? "Saving" : "Save"}
                        </Button>
                        <Button color="secondary"
                            size="sm"
                            className="ml-2"
                            onClick={this.props.toggleModel}>Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}