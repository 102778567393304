import React, { Component } from 'react';
import { Badge, Button, Card, Col, ListGroup, ListGroupItem, Row, Spinner } from 'reactstrap';
import { Redirect } from "react-router-dom";
import OrderService from "../../../../services/OrderService";
import UserService from "../../../../services/UserService";
//import freightService from '../../../../services/FreightService';
//import addressService from '../../../../services/AddressService';
//import courierService from '../../../../services/shipment/CourierService';
import salesOrderProductBuilderV1Service from '../../../../services/sales/SalesOrderProductBuilderV1Service';
import UpdateRequiredDateModal from "../../../../components/Order/modals/UpdateRequiredDateModal";
import UpdateProductionReadyDateModal from "../../../../components/Order/modals/UpdateProductionReadyDateModal";
import UpdateDebtorInvoiceNoteModal from "../../../../components/Order/modals/UpdateDebtorInvoiceNoteModal";
import { changeFormatOfDateString, handleErrorMessage } from '../../../../services/CommonService';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from 'lodash';
import UploadFiles from "../../../../components/attachment/UploadFiles";
import SalesOrderNotes from "../../../../components/sales/orders/SalesOrderNotes";
import OrderEnquiryOrderItem from "./OrderEnquiryOrderItem";
import OrderEnquiryDocumentDownload from "./OrderEnquiryDocumentDownload";
import OrderEnquiryCustomerDetail from "./OrderEnquiryCustomerDetail";
import OrderEnquiryPrintMode from "./OrderEnquiryPrintMode";
import ReactToPrint from "react-to-print";
import OrderEnquiryEditModeUpdateDiscount from "../../../../components/sales/tools/OrderEnquiryEditModeUpdateDiscount";
import OrderGatewayProductBuilderBOMModal from '../../../../components/modal/OrderGatewayProductBuilderBOMModal';
import UpdateSalesOrderScheduleModal from "../../../../components/modal/UpdateSalesOrderScheduleModal";
import UpdateCustomerOrderNumberModal from '../../../../components/Order/modals/UpdateCustomerOrderNumberModal';
import DebtorAddressModal from '../../../../components/address/DebtorAddressModal';
import { toast } from 'react-toastify';
import ReworkFormPageForCustomer from "../../../Rework/ReworkFormPageForCustomer";
import queryString from 'query-string';
import { Link as Link } from "react-router-dom";
import ReworkOrderSummary from "../../../Rework/ReworkOrderSummary";
import classnames from 'classnames';
import SalesOrderCopyModal from "../../../../components/modal/SalesOrderCopyModal";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import salesOrderService from '../../../../services/WINOInventory/SalesOrderService';
import BulkOrderService from '../../../../services/BulkOrderService';

let visibleColumnsInOrderItems = ["expandIcon", "prodCode", "description", "productName", "location", "fabric", "width", "drop", "qty", "price", "qtyprice", "discVal", "orderedExtendedEx"];
const documentTypes = ["Job Sheet", "Pick Slip", "Production Label", "Invoice", "Quote", "Dispatch Label", "Packing Slip", "PowderCoat Label", "Sales Order Summary"];
const modifyDiscountPrivilege = "debtor-discount-write";
const allowedUncancelRole = ['Admin', 'Tech-Admin', 'Finance', 'Customer Service', 'SalesRep'];
const allowedCancelRole = ['Admin', 'Tech-Admin', 'Production Admin', 'Finance'];

export default class OrderEnquiryEditMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDebtorInvoiceNoteModal: false,
            openUpdateRequiredDateModal: false,
            openUpdateProductionReadyDateModal: false,
            openUpdateDiscountModal: false,
            openCustomerOrderNumberModal: false,
            workingBOM: {
                order: {},
            },
            isOpenBOMModal: false,
            isOpenSchedulerModal: false,
            isOpenWarrantyClaim: false,
            isOpenReworkOrderSummaryModal: false,
            isUncancelAllowed: false,
            isCancelAllowed: false,
            showConfirmCancel: false,
            isOpenSalesOrderCopyModal: false,
            isOpenSavedAddressModal: false,
            //freight: salesOrderProductBuilderV1Service.initFreightObj(),
            isBulkOrder: props.isBulkOrder,
            isBlankOrder: props.orderItems === 0
        };
        this.orderService = new OrderService();
        this.userService = new UserService();
        this.editOrder = this.editOrder.bind(this);
        this.toggleOpenUpdateRequiredDateModal = this.toggleOpenUpdateRequiredDateModal.bind(this);
        this.toggleOpenUpdateProductionReadyDateModal = this.toggleOpenUpdateProductionReadyDateModal.bind(this);
        this.toggleOpenDebtorInvoiceNoteModal = this.toggleOpenDebtorInvoiceNoteModal.bind(this);
        this.toggleOpenDebtorInvoiceDiscountModal = this.toggleOpenDebtorInvoiceDiscountModal.bind(this);
        this.toggleOpenCustomerOrderNumberModal = this.toggleOpenCustomerOrderNumberModal.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleScheduleModal = this.toggleScheduleModal.bind(this);
        this.toggleWarrantyClaim = this.toggleWarrantyClaim.bind(this);
        this.toggleReworkOrderSummaryModal = this.toggleReworkOrderSummaryModal.bind(this);
        this.toggleSalesOrderCopyModal = this.toggleSalesOrderCopyModal.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.handleOrderCancellation = this.handleOrderCancellation.bind(this);
        this.takeActionOnOrder = this.takeActionOnOrder.bind(this);
        //this.checkMetroPostcode = this.checkMetroPostcode.bind(this);
        //this.fetchFreightRules = this.fetchFreightRules.bind(this);
        //this.getCouriers = this.getCouriers.bind(this);
        this.toggleSavedAddressModal = this.toggleSavedAddressModal.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.bulkOrderService = new BulkOrderService();
        this.updateShippingAddress = this.updateShippingAddress.bind(this);
    }

    componentDidMount() {
        const currentUser = this.userService.getLoggedInUser();
        const userRole = currentUser?.menu?.role;
        this.setState({ isUncancelAllowed: allowedUncancelRole.includes(userRole), isCancelAllowed: allowedCancelRole.includes(userRole) });
    }

    //getCouriers() {
    //    let { freight } = this.state;
    //    courierService.getCouriers().then(response => {
    //        freight.couriers = response.data;
    //        this.setState({ freight });
    //    }).catch(error => {
    //        toast.error(handleErrorMessage(error));
    //    });
    //}

    toggleSavedAddressModal(change) {
        this.setState({ isOpenSavedAddressModal: change });
    }

    //checkMetroPostcode(postcode) {
    //    let { freight, order } = this.state;
    //    if (postcode && postcode.length > 0) {
    //        freight.isLoadingMetroPostcodeCheck = true;
    //        this.setState({ freight });
    //        addressService.getAddressStandardByPostcode(postcode).then(response => {
    //            freight.isMetroPostcode = response.data?.isMetro;
    //            freight.isLoadingMetroPostcodeCheck = false;
    //            order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
    //            order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
    //            this.setState({ order, freight });
    //        }).catch(error => {
    //            freight.isLoadingMetroPostcodeCheck = false;
    //            this.setState({ freight });
    //            toast.error(handleErrorMessage(error));
    //        })
    //    } else {
    //        order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
    //        order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
    //        this.setState({ order, freight });
    //    }
    //}

    //fetchFreightRules(accountID, isFreightResetRequired) {
    //    let { freight, order } = this.state;
    //    if (accountID) {
    //        freight.isLoadingAllRules = true;
    //        this.setState({ freight });
    //        freightService.getCustomerFreightOptions(accountID).then(response => {
    //            freight.allRules = response.data;
    //            freight.isLoadingAllRules = false;
    //            freight = salesOrderProductBuilderV1Service.updateFreightMethodOptions(freight, order.delPostCode, isFreightResetRequired);
    //            this.setState({ freight }, () => {
    //                this.checkMetroPostcode(order.delPostCode);
    //                this.getCouriers();
    //            });
    //        }).catch(error => {
    //            freight.isLoadingAllRules = false;
    //            this.setState({ freight });
    //            toast.error(handleErrorMessage(error));
    //            console.log(error);
    //        });
    //    } else {
    //        let freight = salesOrderProductBuilderV1Service.initFreightObj();
    //        this.setState({ freight });
    //    }
    //}

    updateShippingAddress() {
        let { order } = this.state;
        let request = {
            ordNum: order.ordNum,
            company: order.delCompany,
            contactName: order.contactName,
            phone: order.phone,
            email: order.email,
            fax: order.fax,
            address1: order.delAddr1,
            address2: order.delAddr2,
            address3: order.delAddr3,
            address4: order.delAddr4,
            city: order.delCity,
            state: order.delState,
            postCode: order.delPostCode
        };
        this.bulkOrderService.updateShippingAddress(request).then(response => {
            toast.success("Shipping Address Updated Successfully");
            this.props.getOrder(order.ordNum, "current");
        }).catch(error => {
            toast.error(error.message);
            console.log(error);
        });
    }

    handleOrderChange(key, change) {
        let { order } = this.props;
        let formError = {
            isValid: true,
            errors: []
        };
        switch (key) {
            case "address":
                order.contactName = change.contactName ? change.contactName : "Store Manager";
                order.phone = change.phone;
                order.email = change.email;
                order.fax = change.fax;
                order.delCompany = change.company;
                order.delAddr1 = change.address1;
                order.delAddr2 = change.address2;
                order.delAddr3 = change.address3;
                order.delAddr4 = change.address4;
                order.delCity = change.city;
                order.delState = change.state;
                order.delPostCode = change.postCode;
                formError = salesOrderProductBuilderV1Service.validateForm(formError, order, false);
                this.setState({ order, isOpenSavedAddressModal: false, formError }, () => {
                    //this.fetchFreightRules(order.accountID, false);
                    this.updateShippingAddress();
                });
                break;
            default:
                if (key) {
                    order[key] = change;
                    this.setState({ order });
                }
                else {
                    toast.error("Key Not Found!");
                }
        }
    }

    toggleBOMModal(change, context) {
        let { isOpenBOMModal, workingBOM } = this.state;
        if (change) {
            isOpenBOMModal = true;
            workingBOM.order = context;
        } else {
            isOpenBOMModal = false;
            workingBOM.order = {};
        }
        this.setState({ isOpenBOMModal, workingBOM });
    }

    toggleOpenDebtorInvoiceNoteModal() {
        this.setState((prevState) => ({ openDebtorInvoiceNoteModal: !prevState.openDebtorInvoiceNoteModal }));
    }

    toggleOpenUpdateRequiredDateModal() {
        this.setState((prevState) => ({ openUpdateRequiredDateModal: !prevState.openUpdateRequiredDateModal }));
    }

    toggleOpenUpdateProductionReadyDateModal() {
        this.setState((prevState) => ({ openUpdateProductionReadyDateModal: !prevState.openUpdateProductionReadyDateModal }));
    }

    toggleOpenDebtorInvoiceDiscountModal() {
        this.setState((prevState) => ({ openUpdateDiscountModal: !prevState.openUpdateDiscountModal }));
    }

    toggleScheduleModal() {
        this.setState((prevState) => ({ isOpenSchedulerModal: !prevState.isOpenSchedulerModal }));
    }

    toggleWarrantyClaim(change) {
        this.setState({ isOpenWarrantyClaim: change });
    }

    toggleReworkOrderSummaryModal() {
        this.setState((prevState) => ({ isOpenReworkOrderSummaryModal: !prevState.isOpenReworkOrderSummaryModal }));
    }

    toggleSalesOrderCopyModal() {
        this.setState((prevState) => ({ isOpenSalesOrderCopyModal: !prevState.isOpenSalesOrderCopyModal }));
    }

    toggleCancelModal() {
        this.setState((prevState) => ({ showConfirmCancel: !prevState.showConfirmCancel }));
    }

    toggleOpenCustomerOrderNumberModal() {
        this.setState((prevState) => ({ openCustomerOrderNumberModal: !prevState.openCustomerOrderNumberModal }));
    }

    handleOrderCancellation() {
        this.takeActionOnOrder(this.props.order.ordNum, "Rejected");
        this.toggleCancelModal();
    }

    takeActionOnOrder(ordNum, option) {
        salesOrderService.updateKeywaySalesOrder(ordNum, option).then(response => {
            toast.success("Order Cancelled!");
            this.props.getOrder(ordNum, "current");
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    editOrder(attribute) {
        switch (attribute) {
            case "requiredDate":
                this.toggleOpenUpdateRequiredDateModal();
                break;
            case "productionReadyDate":
                this.toggleOpenUpdateProductionReadyDateModal();
                break;
            case "invoiceNote":
                this.toggleOpenDebtorInvoiceNoteModal();
                break;
            case "discount":
                this.toggleOpenDebtorInvoiceDiscountModal();
                break;
            case "schedule":
                this.toggleScheduleModal();
                break;
            case "cancel":
                this.toggleCancelModal();
                break;
            case "custOrdNum":
                this.toggleOpenCustomerOrderNumberModal();
                break;
            case "address":
                this.toggleSavedAddressModal(true);
                break;
        }
    }


    render() {
        let {
            getOrder, order,
            toggleExpandRow, toggleAllRowExpanded,
            salesOrder,
            currentUser, toggleRetailCustomerModal, reworkOrder
        } = this.props;
        let {
            openDebtorInvoiceNoteModal, openUpdateRequiredDateModal, openUpdateProductionReadyDateModal, openCustomerOrderNumberModal, openUpdateDiscountModal, isUncancelAllowed, isCancelAllowed, isOpenSchedulerModal, isOpenWarrantyClaim, isOpenReworkOrderSummaryModal, isOpenSalesOrderCopyModal, showConfirmCancel, isBulkOrder, isBlankOrder, isOpenSavedAddressModal
        } = this.state;
        let canModifyDiscount = this.userService.hasPrivilege(currentUser, modifyDiscountPrivilege);

        if (order.salesOrderJobStatusId >= 95)  //  if order is invoiced then we can show the qty supplied column..
        {
            let visibleColumns = visibleColumnsInOrderItems;
            let index = visibleColumns.indexOf("qty");
            visibleColumns.splice(index + 1, 0, "qtySupplied");
            visibleColumnsInOrderItems = visibleColumns;
        }
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color="warning"
                                            className={"ml-2"}>
                                            {order.salesOrderJobStatusDescription}
                                        </Badge>
                                        {
                                            salesOrder && salesOrder.isRetail ?
                                                <Badge color="danger"
                                                    className={"ml-2"}>
                                                    Retail Order
                                                </Badge>
                                                : null
                                        }
                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Button title={"Rework"} color={"warning"}
                                                    className={"ml-2"}
                                                    size={"sm"}
                                                    onClick={this.toggleReworkOrderSummaryModal}
                                                >
                                                    <strong>R</strong>
                                                </Button>
                                                : null
                                        }


                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Link
                                                    style={{ color: "white" }}
                                                    title={"Original Order"}
                                                    className={"btn btn-sm btn-info ml-2"}
                                                    onClick={() => getOrder(reworkOrder.originalOrdNum, "current")}
                                                    to={"/sales/order/view?" + queryString.stringify({ OrdNum: reworkOrder.originalOrdNum })}>
                                                    <strong>Original order:</strong> {reworkOrder.originalOrdNum}
                                                </Link>
                                                : null
                                        }

                                        {
                                            order && order.isCustom ?
                                                <Button size={"sm"} title={"Custom Order"}
                                                    color={"warning"} className={"ml-2"} disabled style={{ opacity: 1 }}>
                                                    <i className="fa fa-wrench" />
                                                </Button>
                                                : null
                                        }
                                    </small>
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                    {(order.highPriority) ?
                                        <Badge color="danger" className={"m-1 p-1"} pill>MUSTGO</Badge>
                                        : null
                                    }
                                    {(order.excludeFuelSurcharge) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>No Fuel
                                            Surcharge</Badge>
                                        : null
                                    }
                                    {(order.isInstallation) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>Installation</Badge>
                                        : null
                                    }
                                    {(order.isRemake) ?
                                        <Badge color="info" className={"m-1 p-1"}>Remake</Badge> : null
                                    }

                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"text-right"}>
                                {
                                    !currentUser.isExternalUser
                                        ? <OrderEnquiryDocumentDownload
                                            orderCreatedFrom={order.createdFrom}
                                            documentTypes={documentTypes}
                                            ordNum={order.ordNum}
                                            isBulkOrder={isBulkOrder}
                                            isBlankOrder={isBlankOrder}
                                            refreshOrder={() => getOrder(order.ordNum, "current")}
                                            history={this.props.history}
                                        />
                                        : null
                                }
                                <div className="mt-2">
                                    {(!currentUser.isExternalUser && !isBlankOrder) ?
                                        <span>
                                            {(!currentUser.isExternalUser) && ((order.salesOrderJobStatusId === 10 || order.documentTypeID === 3) && order.salesOrderJobStatusId !== 1) ?
                                                <Button color={"primary"}
                                                    className={"mr-2 ml-2"}
                                                    size={"sm"} onClick={() => this.props.convertQuoteToOrder(order)}>
                                                    {(this.props.convertingToOrder)
                                                        ?
                                                        <Spinner size={"sm"} color={"warning"} /> :
                                                        <i className="fa fa-cart-arrow-down mr-2" aria-hidden="true" />
                                                    }
                                                    {"Convert To Order"}
                                                </Button>
                                                : null}
                                            {order.salesOrderJobStatusId === 45
                                                ? <Button size={"sm"} color={"primary"}
                                                    className={"mr-2"}
                                                    onClick={() => this.editOrder("schedule")}><i
                                                        className="fa fa-calendar mr-2" aria-hidden="true" />Schedule
                                                    Now</Button>
                                                : null}
                                            {isUncancelAllowed && !isBulkOrder && order.salesOrderJobStatusId === 1
                                                ?
                                                <Link
                                                    className={classnames("btn", "btn-sm", "mr-2", {
                                                        "btn-warning": order.createdFrom !== "KEYWAY_PRODUCT_BUILDER_V1",
                                                        "btn-primary": order.createdFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                                                    }
                                                    )}
                                                    to={"/sales/order/create/product-builder/v1?isUnCancelOrder=true&orderNum=" + order.ordNum}
                                                >
                                                    <i className="fa fa-undo mr-2" aria-hidden="true" />
                                                    Un-Cancel
                                                </Link>
                                                : null}
                                            {
                                                isCancelAllowed && order.salesOrderJobStatusId === 90 ?
                                                    <Button
                                                        size="sm"
                                                        color="danger"
                                                        className="mr-2"
                                                        onClick={() => this.editOrder("cancel")}>
                                                        Cancel Order
                                                    </Button>
                                                    : null
                                            }
                                            <Button size={"sm"} color={"info"}
                                                onClick={() => this.toggleBOMModal(true, order)}>BOM</Button>
                                            <Button size={"sm"} color={"primary"}
                                                className={"mr-2 ml-2"}
                                                onClick={() => this.editOrder("discount")}>
                                                <i className="fa fa-percent mr-2" aria-hidden="true" />
                                                Discount
                                            </Button>
                                            <ReactToPrint
                                                trigger={() => {
                                                    return <Button color={"primary"} size={"sm"}>
                                                        <i className="fa fa-print mr-2"
                                                            aria-hidden="true" />Print
                                                    </Button>;
                                                }}
                                                content={() => this.componentRef}
                                            />
                                        </span>
                                        : null}
                                    {
                                        order.salesOrderJobStatusId >= 98 ?
                                            <Button size={"sm"} color={"primary"} className={"ml-2"}
                                                onClick={() => this.toggleWarrantyClaim(true)}><i
                                                    className="fa fa-ticket mr-2" aria-hidden="true" />Create Rework</Button>
                                            : null
                                    }
                                    {/*<Button title={"Copy Order"} color={"info"}
                                            className={"ml-2"}
                                            size={"sm"}
                                            onClick={this.toggleSalesOrderCopyModal}
                                    >
                                        <i className="fa fa-clone mr-2" aria-hidden="true" /> Copy Order
                                    </Button>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <hr />
                    <div>
                        <div>
                            <OrderEnquiryCustomerDetail
                                order={order}
                                salesOrder={salesOrder}
                                editCustomer={toggleRetailCustomerModal}
                                currentUser={currentUser}
                                editOrder={this.editOrder}
                                isBulkOrder={isBulkOrder}
                            />
                        </div>
                        <hr />
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    (order.custOrdNum || order.custOrdDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 185, maxWidth: 250 }}>
                                                {
                                                    order.custOrdNum ?
                                                        <div>
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order No.</strong>
                                                                <Button
                                                                    className={"pl-0 float-right"}
                                                                    color={"link"}
                                                                    size={"sm"}
                                                                    title={"click here to edit"}
                                                                    onClick={() => this.editOrder("custOrdNum")}>
                                                                    <i className="fa fa-pencil ml-2"
                                                                        aria-hidden="true" />
                                                                </Button></p>
                                                            <div>{order.custOrdNum}</div>
                                                        </div> : null
                                                }
                                                {
                                                    order.custOrdDate ?
                                                        <div className="mt-2">
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order Date</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.custOrdDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250 }}>
                                        <div>
                                            <p className={"mb-1"}><strong>Required on</strong>
                                                <Button
                                                    className={"pl-0 float-right"}
                                                    color={"link"}
                                                    size={"sm"}
                                                    title={"click here to edit"}
                                                    onClick={() => this.editOrder("requiredDate")}>
                                                    <i className="fa fa-pencil ml-2"
                                                        aria-hidden="true" />
                                                </Button></p>
                                            <div>
                                                {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}

                                            </div>
                                        </div>
                                        {
                                            order.jobRef ?
                                                <div className="mt-2">
                                                    <p className={"mb-1"}>
                                                        <strong>Job Reference</strong>
                                                    </p>
                                                    <div>{order.jobRef}</div>
                                                </div> : null
                                        }
                                        {order.repArea ?
                                            <div className="mt-2">
                                                <p className={"mb-1"}><strong>Sales
                                                    Rep</strong>
                                                </p>
                                                <div>{order.repArea.name} ({order.repArea.code})</div>
                                            </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (order.couriers || order.consignNum || order.courierInstructions)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {order.couriers ?
                                                    <div>
                                                        <p className={"mb-1"}><strong>Shipment
                                                            by</strong></p>
                                                        <div>{order.couriers}</div>
                                                    </div> : null
                                                }
                                                {order.consignNum ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Consignment
                                                            no</strong>
                                                        </p>
                                                        <div>{order.consignNum}</div>
                                                    </div> : null
                                                }
                                                {order.courierInstructions ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Courier Instructions</strong></p>
                                                        <div>{order.courierInstructions}</div>
                                                    </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                {
                                    (order.invPrinted || order.shipDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {
                                                    order.invPrinted
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Invoiced date</strong></p>
                                                            <div>{changeFormatOfDateString(order.invPrinted, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    order.shipDate
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Shipped date</strong></p>
                                                            <div>{changeFormatOfDateString(order.shipDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }

                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"mb-1"}><strong>Notes</strong><Button
                                            color="link"
                                            className={"pl-0 float-right"}
                                            size={"sm"}
                                            title={"click here to edit"}
                                            onClick={() => this.editOrder("invoiceNote")}>

                                            <i className="fa fa-pencil ml-2"
                                                aria-hidden="true" />
                                        </Button></p>
                                        {order.notes}
                                    </div>
                                    <hr />
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"mb-1"}><strong>Prod Note</strong></p>
                                        {order.department}
                                    </div>
                                </ListGroupItem>
                                {
                                    (salesOrder && !isEmpty(salesOrder.attachmentIDs))
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                <UploadFiles
                                                    isReadOnly={true}
                                                    attachmentIDs={salesOrder ? salesOrder.attachmentIDs : ""}
                                                    multiple={false}
                                                    maxLength={1} />
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 200, maxWidth: 250 }}>
                                        <SalesOrderNotes ordNum={order.ordNum}
                                            isReadOnly={false} />
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <hr />
                        <div>
                            <Card>
                                <OrderEnquiryOrderItem
                                    order={order}
                                    currentUser={currentUser}
                                    visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                    toggleExpandRow={toggleExpandRow}
                                    toggleAllRowExpanded={toggleAllRowExpanded}

                                />
                            </Card>
                        </div>
                    </div>
                </div>

                <div style={{ display: "none" }}>
                    <OrderEnquiryPrintMode order={order}
                        currentUser={currentUser}
                        visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                        toggleExpandRow={toggleExpandRow}
                        toggleAllRowExpanded={toggleAllRowExpanded}
                        ref={el => (this.componentRef = el)}
                    />
                </div>

                {
                    openDebtorInvoiceNoteModal
                        ? <UpdateDebtorInvoiceNoteModal ordNum={order.ordNum}
                            selected={order.notes}
                            isOpen={openDebtorInvoiceNoteModal}
                            toggleModel={this.toggleOpenDebtorInvoiceNoteModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    openUpdateRequiredDateModal
                        ? <UpdateRequiredDateModal ordNum={order.ordNum}
                            selected={order.required}
                            isOpen={openUpdateRequiredDateModal}
                            toggleModel={this.toggleOpenUpdateRequiredDateModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    openUpdateProductionReadyDateModal
                        ? <UpdateProductionReadyDateModal ordNum={order.ordNum}
                            selected={order.productionReadyDate}
                            isOpen={openUpdateProductionReadyDateModal}
                            toggleModel={this.toggleOpenUpdateProductionReadyDateModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    openUpdateDiscountModal
                        ? <OrderEnquiryEditModeUpdateDiscount order={order}
                            isOpen={openUpdateDiscountModal}
                            toggleModel={this.toggleOpenDebtorInvoiceDiscountModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }

                {this.state.isOpenBOMModal
                    ? <OrderGatewayProductBuilderBOMModal isOpen={this.state.isOpenBOMModal}
                        toggle={this.toggleBOMModal}
                        ordNum={this.state.workingBOM.order.ordNum}
                    />
                    : null
                }
                {
                    isOpenSchedulerModal
                        ? <UpdateSalesOrderScheduleModal
                            isOpen={isOpenSchedulerModal}
                            toggle={this.toggleScheduleModal}
                            ordNum={order.ordNum}
                            refresh={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    isOpenWarrantyClaim ?
                        <ReworkFormPageForCustomer
                            isOpen={isOpenWarrantyClaim}
                            toggle={this.toggleWarrantyClaim}
                            toggleExpandRow={toggleExpandRow}
                            toggleAllRowExpanded={toggleAllRowExpanded}
                            order={order}
                            currentUser={currentUser}
                            visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                            salesOrder={salesOrder}
                            getOrder={getOrder}
                            history={this.props.history}
                        />
                        : null
                }
                {
                    isOpenReworkOrderSummaryModal ?
                        <ReworkOrderSummary
                            isOpen={isOpenReworkOrderSummaryModal}
                            toggle={this.toggleReworkOrderSummaryModal}
                            reworkOrder={reworkOrder}
                            getOrder={getOrder}
                            order={order}
                            currentUser={currentUser}
                        />
                        : null
                }
                {
                    isOpenSalesOrderCopyModal ?
                        <SalesOrderCopyModal
                            heading={"Copy #" + order.ordNum}
                            history={this.props.history}
                            isOpen={isOpenSalesOrderCopyModal}
                            toggle={this.toggleSalesOrderCopyModal}
                            ordNum={order.ordNum}
                            purpose={"Copy"}
                            enforceSubmit={false}
                            reworkID={null}
                        />
                        : null
                }
                {
                    showConfirmCancel ?
                        <ConfirmModal
                            isOpen={showConfirmCancel}
                            toggle={this.toggleCancelModal}
                            handleSubmit={() => this.handleOrderCancellation()}
                            handleCancel={() => this.toggleCancelModal()}
                            primaryMessage="Are you sure to cancel this order?"
                            secondaryMessage=""
                            submitColor="danger"
                            cancelColor="secondary"
                            icon="fa fa-trash-o fa-2x"
                            type="Cancelling..."
                        />
                        : null
                }
                {
                    openCustomerOrderNumberModal
                        ? <UpdateCustomerOrderNumberModal
                            ordNum={order.ordNum}
                            selected={order.custOrdNum}
                            isOpen={openCustomerOrderNumberModal}
                            toggleModel={this.toggleOpenCustomerOrderNumberModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    isOpenSavedAddressModal
                        ? <DebtorAddressModal
                            selected={{
                                company: order.delCompany,
                                contactName: order.contactName,
                                phone: order.phone,
                                email: order.email,
                                fax: order.fax,
                                address1: order.delAddr1,
                                address2: order.delAddr2,
                                address3: order.delAddr3,
                                address4: order.delAddr4,
                                city: order.delCity,
                                state: order.delState,
                                postCode: order.delPostCode,
                                country: 'AUSTRALIA',
                            }}
                            isOpen={isOpenSavedAddressModal}
                            toggleModel={this.toggleSavedAddressModal}
                            handleChange={(selectedAddress) => this.handleOrderChange("address", selectedAddress)}
                            accountID={order.accountID}
                        />
                        : null
                }
            </div>
        );

    }
}
