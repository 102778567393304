import axios from '../axios';
import {cloneDeep, isEmpty} from 'lodash';
import {calculatePercentage, findIndex, findItems, getDistinct, validateEmail} from "../CommonService";
import {PRODUCT_CUSTOM_ID, productConstant} from '../../store/ProductGroups';
import rollerBlindSingleUtil from '../../components/sales/create/productBuilderKeywayVersion/products/util/RollerBlindSingleUtil';
import rollerBlindDoubleUtil from '../../components/sales/create/productBuilderKeywayVersion/products/util/RollerBlindDoubleUtil';
import hospitalRangePartUtil from '../../components/sales/create/productBuilderKeywayVersion/products/hospitalRange/HospitalRangePartUtil';
import curtainTrackUtil from '../../components/sales/create/productBuilderKeywayVersion/products/curtainTracks/CurtainTrackUtil';
import curtainTrackPartUtil from '../../components/sales/create/productBuilderKeywayVersion/products/curtainTracks/CurtainTrackPartUtil';
import curtainTrackCustomUtil from '../../components/sales/create/productBuilderKeywayVersion/products/curtainTracks/CurtainTrackCustomUtil';
import foldingArmAwningUtil from '../../components/sales/create/productBuilderKeywayVersion/products/util/FoldingArmAwningUtil';
import verticalIndoorUtil from '../../components/sales/create/productBuilderKeywayVersion/products/util/VerticalIndoorUtil';
import ziptrakExternalUtil from '../../components/sales/create/productBuilderKeywayVersion/products/ziptrak/external/ZiptrakExternalUtil';
import ziptrakInternalUtil from '../../components/sales/create/productBuilderKeywayVersion/products/ziptrak/internal/ZiptrakInternalUtil';
import widescreenUtil from '../../components/sales/create/productBuilderKeywayVersion/products/widescreen/WidescreenUtil';
import venetianUtil from '../../components/sales/create/productBuilderKeywayVersion/products/venetian/VenetianUtil';
import verticalOutdoor from '../../components/sales/create/productBuilderKeywayVersion/products/verticalOutdoor/VerticalOutdoorUtil';
import panelTrackUtil from '../../components/sales/create/productBuilderKeywayVersion/products/panelTrack/PanelTrackUtil';
import romanBlindUtil from '../../components/sales/create/productBuilderKeywayVersion/products/romanBlind/RomanBlindUtil';
import projectionAwningUtil from '../../components/sales/create/productBuilderKeywayVersion/products/projectionAwning/ProjectionAwningUtil';
import skinOnlyUtil from '../../components/sales/create/productBuilderKeywayVersion/products/skinOnly/SkinOnlyUtil';
import customUtil from '../../components/sales/create/productBuilderKeywayVersion/products/custom/CustomUtil';
import quiklokUtil from '../../components/sales/create/productBuilderKeywayVersion/products/quiklok/QuiklokUtil';
import {
    ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1, ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2,
    ADDRESS_VALIDATION_MAX_LENGTH_COMPANY,
    ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME, ADDRESS_VALIDATION_MAX_LENGTH_EMAIL,
    ADDRESS_VALIDATION_MAX_LENGTH_PHONE, ADDRESS_VALIDATION_MAX_LENGTH_STATE, FREIGHT_RULE_FREIGHT_OVERIDE,
    PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM,
    PRODUCT_BUILDER_CURTAIN_TRACK_PART, PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS,
    PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM,
    PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES, SALES_ORDER_JOB_STATUS_ID_INVOICED,
    taxRate
} from "../../store/AppConstants";
import {v4 as uuidv4} from 'uuid';

class SalesOrderProductBuilderV1Service {

    static Instance() {
        return new SalesOrderProductBuilderV1Service();
    }

    getSalesOrder(ordNum) {
        return axios.get('api/sales/order/product-builder/v1/order/one?ordNum=' + ordNum);
    }

    getProductBuilder(productID, accountID) {
        return axios.get('api/sales/order/product-builder/v1/configuration/by-product?productID=' + productID + '&accountID=' + accountID, {
            timeout: 600000
        })
    }

    getProductGroups() {
        return axios.get('api/product/group/list');
    }

    saveSalesOrderKeyway(order) {
        if (order.id) {
            return this.updateSalesOrderKeyway(order);
        } else {
            return this.createSalesOrderKeyway(order);
        }
    }

    createSalesOrderKeyway(order) {
        return axios.post('api/sales/order/product-builder/v1/order/create', order, {
            timeout: 600000
        });
    }

    updateSalesOrderKeyway(order) {
        return axios.put('api/sales/order/product-builder/v1/order/update', order, {
            timeout: 600000
        });
    }

    getTemplateList(request) {
        return axios.post('api/sales/order/product-builder/v1/template/list', request, {
            timeout: 600000
        });
    }

    getCustomTemplateItemList(customTemplateID) {
        return axios.get('api/sales/order/product-builder/v1/template/item/list?customTemplateID=' + customTemplateID, {
            timeout: 600000
        });
    }

    saveCustomTemplates(request) {
        return axios.post('api/sales/order/product-builder/v1/template/create', request, {
            timeout: 600000
        });
    }

    updateCustomBuildTemplate(request) {
        return axios.post('api/sales/order/product-builder/v1/template/update', request, {
            timeout: 600000
        });
    }

    updateCustomBuildItemTemplate(request) {
        return axios.post('api/sales/order/product-builder/v1/template/item/update', request, {
            timeout: 600000
        });
    }

    deleteCustomBuildItemTemplate(request) {
        return axios.post('api/sales/order/product-builder/v1/template/item/delete', request, {
            timeout: 600000
        });
    }

    deleteCustomBuildTemplate(customTemplateID) {
        return axios.get('api/sales/order/product-builder/v1/template/delete?customTemplateID=' + customTemplateID, {
            timeout: 600000
        });
    }


    initFreightObj() {
        return {
            allRules: [],
            methodOptions: [],
            selectedMethodOption: "",  
            isMetroPostcode: false,
            isLoadingAllRules: false,
            isLoadingMetroPostcodeCheck: false,
            couriers: "",
            freightOverrideCourierName: "",
            freightOverrideCourierCharge: 0,
        };
    }

    updateFreightMethodOptions(freight, postcode, isFreightResetRequired) {
        let freightApplicableRules, freightMethodIndex;
        if (isEmpty(freight.allRules)) {
            return freight;
        }
        // sort rules
        freight.allRules = freight.allRules.sort((a, b) => this.freightSortComparator(a, b));

        //find applicable rules
        freightApplicableRules = freight.allRules.filter(rule => (rule.postcode ? postcode === rule.postcode : true));

        //find applicable methods
        freight.methodOptions = getDistinct(freightApplicableRules, "methodKey");

        //select a already selected method or default first one
        freight.selectedMethodOption = isFreightResetRequired ? "" : freight.selectedMethodOption;
        freightMethodIndex = findIndex(freight.methodOptions, "methodKey", freight.selectedMethodOption);
        freightMethodIndex = freightMethodIndex > -1 ? freightMethodIndex : 0;
        freight.selectedMethodOption = freight.methodOptions[freightMethodIndex].methodKey;
        return freight;
    }

    getApplicableFreightRules(freightAllRules, freightSelectedMethodKey) {
        let rules = findItems(freightAllRules, 'methodKey', freightSelectedMethodKey);
        rules = rules.sort((a, b) => this.freightSortComparator(a, b));
        return rules;
    }

    freightSortComparator(x, y) {
        let result = (x.priority === y.priority) ? 0 : (x.priority > y.priority) ? -1 : 1;
        if (result === 0) {
            result = ('' + y.postcode).localeCompare(x.postcode);
        }
        return result;
    }

    calculateFreightCharges(order, freight) {
        let selectedFreight = {
            charge: 0,
            ruleId: 0
        }, applicableRules, flag, preGSTOrderTotal, ruleIndex;

        switch (freight.selectedMethodOption) {
            case FREIGHT_RULE_FREIGHT_OVERIDE:
                selectedFreight.charge = freight.freightOverrideCourierCharge;
                ruleIndex = freight.allRules.findIndex(fr => fr.methodKey === FREIGHT_RULE_FREIGHT_OVERIDE);
                selectedFreight.ruleId = ruleIndex > -1 ? freight.allRules[ruleIndex].id : 0;
                break;
            default:
                flag = false;
                applicableRules = this.getApplicableFreightRules(freight.allRules, freight.selectedMethodOption);
                preGSTOrderTotal = order.pricing.price - order.pricing.discVal;
                for (let i in applicableRules) {
                    if (applicableRules[i].postcode && applicableRules[i].postcode !== order.delPostCode) {
                        continue;
                    }
                    switch (applicableRules[i].attributeKey) {
                        case "orderTotal":
                            if (applicableRules[i].attributeValueMin <= preGSTOrderTotal && preGSTOrderTotal <= applicableRules[i].attributeValueMax) {
                                selectedFreight.ruleId = applicableRules[i].id;
                                if (freight.isMetroPostcode) {
                                    selectedFreight.charge = applicableRules[i].metroCharge;
                                } else {
                                    selectedFreight.charge = applicableRules[i].nonMetroCharge;
                                }
                                flag = true;
                            }
                            break;
                        case "width":
                            if (applicableRules[i].attributeValueMin <= order.maxWidth && order.maxWidth <= applicableRules[i].attributeValueMax) {
                                selectedFreight.ruleId = applicableRules[i].id;
                                if (freight.isMetroPostcode) {
                                    selectedFreight.charge = applicableRules[i].metroCharge;
                                } else {
                                    selectedFreight.charge = applicableRules[i].nonMetroCharge;
                                }
                                flag = true;
                            }
                            break;
                        case "n/a":
                            selectedFreight.ruleId = applicableRules[i].id;
                            if (freight.isMetroPostcode) {
                                selectedFreight.charge = applicableRules[i].metroCharge;
                            } else {
                                selectedFreight.charge = applicableRules[i].nonMetroCharge;
                            }
                            flag = true;
                            break;
                        default:
                            break;
                    }
                    if (flag) {
                        break;
                    }
                }
                break;
        }

        if (!(selectedFreight.charge && selectedFreight.charge > 0)) {
            selectedFreight.charge = 0;
        }
        return selectedFreight;
    }

    calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling) {
        // charges per quantity
        if (packagingAndHandling && packagingAndHandling.chargeByProductCode && packagingAndHandling.chargeByProductCode[product.code]) {
            return packagingAndHandling.chargeByProductCode[product.code] * quantity;
        }
        return 0;
    }

    calculateOrderTotal(order) {
        let subTotal = (order.pricing.price + order.pricing.freight.charge + order.pricing.packagingAndHandlingCharges) - order.pricing.discVal;
        order.pricing.tax = subTotal * taxRate;
        order.pricing.total = subTotal + order.pricing.tax;

        if (order.isRetail) {
            order.pricing.retailMarkupValueDiscVal = this.calculatePercentage(order.pricing.retailMarkupValue, order.retailMarkupDiscount);
        }
        return order.pricing;
    }

    getRetailMarkupByProduct(product, productMarkupByProductCode) {
        let result = 0;
        if (productMarkupByProductCode && productMarkupByProductCode[product.code]) {
            result = productMarkupByProductCode[product.code].markup;
        }
        return result;
    }

    isVisibleOrderSummary(order) {
        return (order.pricing && order.pricing.price > 0) || order.attachmentIDs;
    }

    isVisibleOrderPricingTable(order) {
        return (order.pricing && order.pricing.price > 0);
    }

    getApplicableProducts(user, products, accountID, order) {
        let result = [], pIndex, flag, isExists;
        if (user.isTestEnviroment && user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToExternalCustomerOnTestEnvironment)
        }
        if (user.isTestEnviroment && !user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToInternalUserOnTestEnvironment)
        }
        if (!user.isTestEnviroment && user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToExternalCustomerOnProdEnvironment)
        }
        if (!user.isTestEnviroment && !user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToInternalUserOnProdEnvironment)
        }
        products.forEach(product => {
            flag = true;
            if (order && order.salesOrderItems) {
                isExists = (order.salesOrderItems || []).some((soi) => soi.productID === product.id);
            }
            if (!isExists) {
                if (!isEmpty(product.visibleForAccountIDs)) {
                    let visibleForAccountIDs = product.visibleForAccountIDs.split(/[\s,]+/);
                    isExists = visibleForAccountIDs.some(a => accountID.includes(a));
                    if (!isExists) {
                        flag = false;
                    }
                }
            }
            if (flag) {
                result.push(product);
            }
        });
        return result;
    }

    calculatePricing(product, productQuantity, stocks, pricing, discount) {
        pricing.unitPrice = 0;
        pricing.unitDiscVal = 0;
        pricing.price = 0;
        pricing.discVal = 0;
        pricing.discount = discount;
        stocks.forEach(stock => {
            stock.price = stock.price ? stock.price : 0;
            stock.finalQty = stock.swishCalculatedQty * (1.0 + stock.wastagePercent);
            stock.finalPrice = (stock.finalQty * stock.price) + stock.flatPrice;
            stock.allowDiscount = true;
            if (product.builder.stockByProdCode[stock.prodCode]) {
                stock.allowDiscount = product.builder.stockByProdCode[stock.prodCode].allowDiscountStockStockGroup;
            }
            stock.discount = stock.allowDiscount ? pricing.discount : 0;
            stock.discVal = stock.finalPrice * (stock.discount / 100);
            //--------------
            pricing.unitPrice += stock.finalPrice;
            pricing.unitDiscVal += stock.discVal;
        });

        pricing.price += pricing.unitPrice * productQuantity;
        pricing.discVal += pricing.unitDiscVal * productQuantity;
        return pricing;
    }

    calculatePercentage(number, percent) {
        return (number * (percent / 100));
        //return ((number * markUp) / 100);
    }

    calculateValueWithPercentage(number, percent) {
        let value = this.calculatePercentage(number, percent);
        return number + value;
    }


    calculateMarkupDiscount(pricing) {
        let maximumApplicableDiscount = 0;
        let costPrice = pricing.price;
        let margin = pricing.retailMarkupValue;
        let sellingPrice = costPrice + margin;
        /*
        * P = ((SP-CP) * 100)/SP
        * */
        maximumApplicableDiscount = ((sellingPrice - costPrice) * 100 / sellingPrice);
        return maximumApplicableDiscount;
    }

    getPrice(order, product, isExternalUser, price, discountByProductCode) {
        let finalPrice = price, margin = 0, discount = 0, discVal = 0, discountedPrice = 0, tax = 0;
        if (order.isRetail && isExternalUser) {
            // calculating ww cost

            //calc discount
            discount = (discountByProductCode && discountByProductCode[product.code]) ? discountByProductCode[product.code].discount : 0;
            discVal = this.calculatePercentage(price, discount);
            discountedPrice = price - discVal;

            //calculate tax
            tax = discountedPrice * taxRate;
            finalPrice = discountedPrice + tax;

            //calc retailMargin of finalPrice
            margin = this.calculatePercentage(finalPrice, product.pricing.retailMarkup);
            finalPrice = finalPrice + margin;
        }
        return finalPrice;
    }

    prepareSalesOrderItem(order, p, packagingAndHandling, discountByProductCode, request, errors, productMarkupByProductCode) {
        let errorMessagePrefix = "", salesOrderItem = {};
        switch (p.code) {
            case productConstant.ROLLER_BLINDS_SINGLE.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errorMessagePrefix = p.name + " | Row-" + (itemIndex + 1) + " | ";
                        if (!p.items[itemIndex].configuration.room.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.room.formError.message);
                        }
                        if (!p.items[itemIndex].configuration.quantity.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.quantity.formError.message);
                        }
                        if (!p.items[itemIndex].configuration.quantity.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.quantity.formError.message);
                        }
                        if (!p.items[itemIndex].configuration.totalWidth.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.totalWidth.formError.message);
                        }
                    }
                    p = rollerBlindSingleUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemRollerBlindSingle = rollerBlindSingleUtil.toSalesOrderItemRollerBlindSingleRequest(p, itemIndex, order, errors);
                    salesOrderItem.prodCodes = rollerBlindSingleUtil.getSalesOrderItemProdCodes(salesOrderItem.salesOrderItemRollerBlindSingle);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.ROLLER_BLINDS_DOUBLE.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errorMessagePrefix = p.name + " | Row-" + (itemIndex + 1) + " | ";
                        if (!p.items[itemIndex].configuration.room.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.room.formError.message);
                        }
                        if (!p.items[itemIndex].configuration.quantity.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.quantity.formError.message);
                        }
                        if (!p.items[itemIndex].configuration.quantity.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.quantity.formError.message);
                        }
                        if (!p.items[itemIndex].configuration.totalWidth.formError.isValid) {
                            errors.push(errorMessagePrefix + p.items[itemIndex].configuration.totalWidth.formError.message);
                        }
                    }
                    p = rollerBlindDoubleUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemRollerBlindDouble = rollerBlindDoubleUtil.toSalesOrderItemRollerBlindDoubleRequest(p, itemIndex, order, errors);
                    salesOrderItem.prodCodes = rollerBlindDoubleUtil.getSalesOrderItemProdCodes(salesOrderItem.salesOrderItemRollerBlindDouble);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.ZIPTRAK_EXTERNAL.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = ziptrakExternalUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = ziptrakExternalUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemZiptrak = ziptrakExternalUtil.toSalesOrderItemZiptrakRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemZiptrak.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.ZIPTRAK_INTERNAL.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = ziptrakInternalUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = ziptrakInternalUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemZiptrakInternal = ziptrakInternalUtil.toSalesOrderItemZiptrakInternalRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemZiptrakInternal.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.VERTICAL_INDOOR.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = verticalIndoorUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = verticalIndoorUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemVerticalIndoor = verticalIndoorUtil.toSalesOrderItemVerticalIndoorRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemVerticalIndoor.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.FOLDING_ARM_AWNINGS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    p = foldingArmAwningUtil.updateFormError("fabric", p, itemIndex);
                    if (!p.items[itemIndex].isValid) {
                        errors = foldingArmAwningUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = foldingArmAwningUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemFoldingArmAwning = foldingArmAwningUtil.toSalesOrderItemFoldingArmAwningRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemFoldingArmAwning.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.HOSPITAL_RANGE.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = hospitalRangePartUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = hospitalRangePartUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemHospitalRangePart = hospitalRangePartUtil.toSalesOrderItemHospitalRangePartRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemHospitalRangePart.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.WIDESCREEN.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = widescreenUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = widescreenUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemWidescreen = widescreenUtil.toSalesOrderItemWidescreenRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemWidescreen.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.CURTAIN_TRACKS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = curtainTrackUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = curtainTrackUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = {};
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    switch (p.items[itemIndex].itemType) {
                        case PRODUCT_BUILDER_CURTAIN_TRACK_PART:
                            salesOrderItem.salesOrderItemCurtainTrackPart = curtainTrackPartUtil.toSalesOrderItemCurtainTrackPartRequest(p, itemIndex, order);
                            salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemCurtainTrackPart.stocks || []).map(stock => stock.prodCode);
                            break;
                        case PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM:
                            salesOrderItem.salesOrderItemCurtainTrackCustom = curtainTrackCustomUtil.toSalesOrderItemCurtainTrackCustomRequest(p, itemIndex, order);

                            (salesOrderItem.salesOrderItemCurtainTrackCustom.subItems || []).forEach((subItem, subItemIndex) => {
                                (subItem.stocks || []).forEach(s => {
                                    salesOrderItem.prodCodes.push(s.prodCode);
                                });
                            });
                            break;
                    }
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.VENETIANS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = venetianUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = venetianUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemVenetian = venetianUtil.toSalesOrderItemVenetianRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemVenetian.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.VERTICAL_OUTDOOR.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = verticalOutdoor.validateItem(p, itemIndex, order, errors)
                    }
                    p = verticalOutdoor.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemVerticalOutdoor = verticalOutdoor.toSalesOrderItemVerticalOutdoorRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemVerticalOutdoor.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.PANEL_TRACKS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = panelTrackUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = panelTrackUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemPanelTrack = panelTrackUtil.toSalesOrderItemPanelTrackRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemPanelTrack.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.ROMAN_BLINDS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = romanBlindUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = romanBlindUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemRomanBlind = romanBlindUtil.toSalesOrderItemRomanBlindRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemRomanBlind.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.PROJECTION_AWNINGS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = projectionAwningUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = projectionAwningUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemProjectionAwning = projectionAwningUtil.toSalesOrderItemProjectionAwningRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemProjectionAwning.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.SKIN_ONLY.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = skinOnlyUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = skinOnlyUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemSkinOnly = skinOnlyUtil.toSalesOrderItemSkinOnlyRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemSkinOnly.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.CUSTOM.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = customUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = customUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemCustom = customUtil.toSalesOrderItemCustomRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemCustom.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.SHP_CTS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    if (!p.items[itemIndex].isValid) {
                        errors = quiklokUtil.validateItem(p, itemIndex, order, errors)
                    }
                    p = quiklokUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode);
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemQuiklok = quiklokUtil.toSalesOrderItemQuiklokRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemQuiklok.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
        }
    }

    getSalesOrderItemInstance(order, product, itemIndex, productMarkupByProductCode) {
        return {
            id: product.items[itemIndex].id ? product.items[itemIndex].id : null,
            productGroupID: product.productGroupID,
            productID: product.id,
            discount: product.items[itemIndex].pricing.discount,
            salesOrderStatus: null,
            attachmentIDs: "",
            salesOrderItemRollerBlindSingle: null,
            salesOrderItemRollerBlindDouble: null,
            salesOrderItemZiptrak: null,
            salesOrderItemZiptrakInternal: null,
            salesOrderItemVerticalIndoor: null,
            salesOrderItemFoldingArmAwning: null,
            salesOrderItemHospitalRangePart: null,
            salesOrderItemWidescreen: null,
            salesOrderItemCurtainTrackPart: null,
            salesOrderItemCurtainTrackCustom: null,
            salesOrderItemVerticalOutdoor: null,
            salesOrderItemPanelTrack: null,
            prodCodes: [],
            retailMarkup: order.isRetail ? this.getRetailMarkupByProduct(product, productMarkupByProductCode) : 0
        }
    }

    updateProductItemCount(products, productIndex) {
        let product = products[productIndex];

        product.itemCount = 0;
        //update associated parent product count
        products.forEach(p => {
            if (product.id === p.id || product.id === p.parentProductID) {
                product.itemCount += (p.items.length + p.savedItems.length);
            }
        });

        return product;
    }

    getItemFactoryInstance(id) {
        return {
            customID: uuidv4(),
            pricing: {
                unitPrice: 0,
                price: 0,
            }
        }
    }

    getStockFactoryInstance(id) {
        return {
            id: null,
            prodCode: "",
            price: 0,
            flatPrice: 0,
            quantityMultiplier: 1,
            qtyFormulaId: null,
            productConfigurationOptionId: null,
            productConfigurationOptionSetId: null,
            swishQuantityFormula: null,
            wastageFormula: null,
            wastagePercent: 0,
            calculatedQty: 1,
            swishCalculatedQty: 1,
            keywayCalculatedQty: 1,
            stockPickSlipQty: 0,
            keywayMeasurementUnit: "unit",
            swishMeasurementUnit: "unit",
            keywayConversionFactor: 1,
            swishConversionFactor: 1,
            isVisibleInPartList: true,
            isDeductionApplicable: false,
            description: "",
            attribute: "",
            label: "",
            attributeRowSpan: 1,
            unitPrice: 0,
            isParentItem: false,
            isCustom: false,
            discontinued: false,
            soldOut: false,
            qoh: 0
        }
    }

    convertToCustom(product, itemIndex) {
        return {
            pricing: {
                unitPrice: 0,
                price: 0,
            }
        }
    }

    getValueOrDefault(product, itemIndex, subItemIndex, valueType, defaultValue, key) {
        let result = defaultValue;
        switch (valueType) {
            case "value":
                if (itemIndex > -1) {
                    if (subItemIndex > -1) {
                        if (product.items[itemIndex].subItems[subItemIndex].configuration[key].selected.value) {
                            result = product.items[itemIndex].subItems[subItemIndex].configuration[key].selected.value;
                        }
                    } else {
                        if (product.items[itemIndex].configuration[key].selected.value) {
                            result = product.items[itemIndex].configuration[key].selected.value;
                        }
                    }
                }
                break;
            case "optionKey":
                if (itemIndex > -1) {
                    if (subItemIndex > -1) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration[key].selected.value)) {
                            result = product.items[itemIndex].subItems[subItemIndex].configuration[key].selected.value.optionKey;
                        }
                    } else {
                        if (!isEmpty(product.items[itemIndex].configuration[key].selected.value)) {
                            result = product.items[itemIndex].configuration[key].selected.value.optionKey;
                        }
                    }
                }
                break;
        }
        return result;
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }

    validateForm(formError, order, retailVisible) {
        let message = "";

        if (!order.accountID) {
            message = "Customer not selected";
            formError.accountID = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!order.contactName) {
            message = "Delivery contact name can't be empty!";
            formError.contactName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.contactName && order.contactName.length > ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME) {
            message = "Delivery contact name should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME + " characters!";
            formError.contactName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!order.phone) {
            message = "Delivery phone can't be empty!";
            formError.phone = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.phone && order.phone.length > ADDRESS_VALIDATION_MAX_LENGTH_PHONE) {
            message = "Delivery phone should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_PHONE + " characters!";
            formError.phone = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!order.email) {
            message = "Delivery email can't be empty!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.email && !validateEmail(order.email)) {
            message = "Delivery email isn't valid";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.email && order.email.length > ADDRESS_VALIDATION_MAX_LENGTH_EMAIL) {
            message = "Delivery email should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_EMAIL + " characters!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!order.delCompany) {
            message = "Delivery company name can't be empty!";
            formError.delCompany = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.delCompany && order.delCompany.length > ADDRESS_VALIDATION_MAX_LENGTH_COMPANY) {
            message = "Delivery company name should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_COMPANY + " characters!";
            formError.delCompany = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!order.delAddr1) {
            message = "Delivery address line 1 can't be empty!";
            formError.delAddr1 = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.delAddr1 && order.delAddr1.length > ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1) {
            message = "Delivery address line 1 should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1 + " characters!";
            formError.delAddr1 = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.delAddr2 && order.delAddr2.length > ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2) {
            message = "Delivery address line 2 should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2 + " characters!";
            formError.delAddr2 = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!order.delCity) {
            message = "Delivery city can't be empty!";
            formError.delCity = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order.delState && order.delState.length > ADDRESS_VALIDATION_MAX_LENGTH_STATE) {
            message = "Delivery state length can't be more than 3 characters!";
            formError.delState = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if ((order.custOrdNum && order.custOrdNum.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM)) {
            message = "Job ref: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM + " characters allowed";
            formError.custOrdNum = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if ((order.notes && order.notes.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES)) {
            message = "Notes: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES + " characters allowed";
            formError.notes = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if ((order.courierInstructions && order.courierInstructions.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS)) {
            message = "Delivery Instructions: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS + " characters allowed";
            formError.courierInstructions = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (order?.isBulkOrder) {
            if (!order?.selectedProduct) {
                message = "Please select a product";
                formError.selectedProduct = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
        } else if (!(order.salesOrderItems && order.salesOrderItems.length > 0) && !order.attachmentIDs) {
            message = "No items selected neither any file uploaded";
            formError.salesOrderItems = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (retailVisible) {
            if (!order.retailFirstName) {
                message = "Please enter first name here";
                formError.retailFirstName = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (!order.retailLastName) {
                message = "Please enter first name here";
                formError.retailLastName = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (!order.retailPhone) {
                message = "Please enter consumer contact number here";
                formError.retailPhone = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (!order.retailEmail) {
                message = "Please enter consumer email here";
                formError.retailEmail = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (!order.retailAddressLine1) {
                message = "Please enter address here";
                formError.retailAddressLine1 = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (order.retailAddressLine1 && order.retailAddressLine1.length > 50) {
                message = "Max 50 characters";
                formError.retailAddressLine1 = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (order.retailAddressLine2 && order.retailAddressLine2.length > 50) {
                message = "Max 50 characters";
                formError.retailAddressLine2 = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (!order.retailCity) {
                message = "Please enter city here";
                formError.retailCity = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (!order.retailState) {
                message = "Please enter state here";
                formError.retailState = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
            if (!order.retailPostCode) {
                message = "Please enter postCode here";
                formError.retailPostCode = message;
                formError.errors.push(message);
                formError.isValid = false;
            }
        }
        return formError;
    }

    getFabricColorLabel(o) {
        let result = o.color;
        if ((!o.isActive) || (o.stock && o.stock.discontinued)) {
            result = result + " (Discontinued)";
        } else {
            if ((o.stock && o.stock.soldOut)) {
                result = result + " (Out of Stock)";
            }
        }
        return result;
    }

    getFabricRangeLabel(o) {
        let result = o.fabricName;
        if (o.isDiscontinued) {
            result = result + " (Discontinued)";
        }
        return result;
    }

    shouldFabricColorIncluded(selectedFabricColourName, o, currentUser) {
        let isMatchingWithSelection = (o.optionKey === selectedFabricColourName);
        if (!isMatchingWithSelection) {
            if (!o.isActive) {
                return false;
            }
            else if (o.stock && o.stock.discontinued && o.stock.soldOut) {
                return false;
            }
            else if (o.stock && o.stock.discontinued && currentUser.isExternalUser) {
                return ["ESSENTIALS", "Essential"].includes(o.fabric.category) ? false : true;
            }
            else if (currentUser.isExternalUser && ["Hayman"].includes(o.optionKey)) {
                return false;
            }
        }
        return true;
    }

    shouldFabricRangeIncluded(selectedFabricRangeName, o, currentUser) {
        let isMatchingWithSelection = (o.optionKey === selectedFabricRangeName);
        if (!isMatchingWithSelection) {
            if ((!o.isActive)) {
                return false;
            }
            else if (o.isDiscontinued && o.isSoldOut) {
                return false;
            }
            else if (o.isDiscontinued && currentUser.isExternalUser) {
                return ["ESSENTIALS", "Essential"].includes(o.category) ? false : true;
            }
            else if (currentUser.isExternalUser && ["Hayman"].includes(o.optionKey)) {
                return false;
            }
        }
        return true;
    }

    shouldOrderSubmitDisabled(order, user) {
        let result = (order && order.salesOrderJobStatus && order.salesOrderJobStatus.id >= SALES_ORDER_JOB_STATUS_ID_INVOICED);
        if (!result) {
            result = (user.isExternalUser &&(((order && order.salesOrderItems) || []).some(soi => soi.productID === PRODUCT_CUSTOM_ID)));
        }
        return result;
    }

    shouldQuoteSubmitDisabled(order, user) {
        let result = (order && order.salesOrderJobStatus && order.salesOrderJobStatus.id >= SALES_ORDER_JOB_STATUS_ID_INVOICED);
        if (!result) {
            result = (order && (!order.isQuote) && order.debtorInvoiceOrdNum > 0)
        }
        if (!result) {
            result = (user.isExternalUser &&(((order && order.salesOrderItems) || []).some(soi => soi.productID === PRODUCT_CUSTOM_ID)));
        }
        return result;
    }


}

export default SalesOrderProductBuilderV1Service.Instance();