import React, { Component } from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    Badge,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    InputGroupText,
    Spinner,
    Label,
    ButtonGroup
} from "reactstrap";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { handleErrorMessage, changeFormatOfDateString } from '../services/CommonService';
import Pagination from "react-js-pagination";
import ManageHolidayModal from '../components/modal/ManageHolidayModal';
import ConfirmModal from '../components/modal/ConfirmModal';
import HolidayService from '../services/HolidayService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddressService from '../services/AddressService';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { getDateString } from '../services/CommonService';

export const maxDate = new Date();
export const minDate = moment().startOf('year').toDate();

export default class HolidayPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holidayData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: true
                    },
                    filterRequest: {
                        state: null,
                        year: getDateString(minDate, "DD/MM/YYYY")
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            isOpenModal: false,
            isOpenDeleteConfirmModal: false,
            deleteItem: null,
            workingItem: null,
            stateData: []
        };
        this.getHolidayData = this.getHolidayData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleCompleteConfirmModal = this.toggleCompleteConfirmModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getStates = this.getStates.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
        this.loadOptions = this.loadOptions.bind(this)
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.getStates();
        this.getHolidayData(this.state.holidayData);
    }

    getStates() {
        AddressService.getStates()
            .then(response => {
                const sortedStates = response.data.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
                const formattedOptions = sortedStates.map(state => ({
                    label: state.abbreviation.toUpperCase(),
                    value: state.abbreviation
                }));
                formattedOptions.unshift({ label: "ALL", value: "All" });
                this.setState({ stateData: formattedOptions });
            })
            .catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error.message));
            });
    }

    loadOptions(inputValue, callback) {
        AddressService.getStates()
            .then(response => {
                const sortedStates = response.data.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
                const formattedOptions = sortedStates.map(state => ({
                    label: state.abbreviation.toUpperCase(),
                    value: state.abbreviation
                }));
                formattedOptions.unshift({ label: "ALL", value: "All" });
                callback(formattedOptions);
            })
            .catch(error => {
                console.error('Error fetching states:', error);
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error.message));
            });
    }

    handleSubmit(holiday, flag) {
        if (flag == "Add") {
            HolidayService.addHoliday(holiday).then(response => {
                this.setState({ isOpen: false }, () => {
                    this.refresh();
                    toast.success("Holiday added successfully!");
                })
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error.message));
            });

        }
        else {
            HolidayService.editHoliday(holiday).then(response => {
                let data = response.data;
                this.setState({isOpen: false }, () => {
                    this.refresh();
                    toast.success("Holiday edited successfully!");

                });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error.message));
            });
        }
    }

    getHolidayData(holidayData) {
        this.setState({ loading: true });

        HolidayService.listAllHolidays(holidayData.request).then(response => {
            let data = response.data;
            holidayData.response = data;
            this.setState({ holidayData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error.message));
        });
    }

    handleChange(change, key) {
        let { holidayData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change, holidayData });
                break;
            case "sortKey":
                if (holidayData.request.sortRequest.key === change) {
                    holidayData.request.sortRequest.direction = !holidayData.request.sortRequest.direction;
                } else {
                    holidayData.request.sortRequest.key = change;
                    holidayData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getHolidayData(holidayData);
                }
                break;
            case "pageSize":
                holidayData.request.pageRequest[key] = change;
                holidayData.request.pageRequest.currentPage = 1;
                this.getHolidayData(holidayData);
                break;
            case "currentPage":
                holidayData.request.pageRequest[key] = change;
                this.getHolidayData(holidayData);
                break;
            case "state":
                holidayData.request.filterRequest[key] = change;
                this.getHolidayData(holidayData);
                break;
            case "year":
                holidayData.request.filterRequest[key] = change;
                this.getHolidayData(holidayData);
                break;
        }
    }


    toggleCompleteConfirmModal(change, item) {
        this.setState({ isOpenDeleteConfirmModal: change, deleteItem: item });
    }

    openModal(data) {
        this.setState({ isOpenModal: true });
    }

    toggleModal(change, data) {
        this.setState({ isOpenModal: change, workingItem: data });
    }

    handleDelete() {
        this.setState({ isOpenDeleteConfirmModal: false }, () =>
            this.delete(this.state.deleteItem));
    }

    delete(item) {
        let { loading } = this.state;
        this.setState({ loading });
        HolidayService.delete(item).then(response => {
            loading = false;
            this.setState({ loading });

            toast.success("Deleted!");
            this.refresh(true, this.state.activeTab);
        }).catch(error => {
            loading = false;
            toast.success(handleErrorMessage(error));
            this.setState({ loading });
        });
    }
        getHolidayHeading() {
            let stockHeading = [
                {
                    key: "srNo",
                    label: "Sr. No",
                    type: "text",
                    colSpan: 1,
                    minWidth: 25,
                    sorterApplicable: false,
                    valueClassName: "",
                    labelClassName: "align-middle text-left",
                    searchNode: null
                },
                {
                    key: "date",
                    label: "Date",
                    type: "text",
                    colSpan: 1,
                    minWidth: 100,
                    sorterApplicable: true,
                    valueClassName: "",
                    labelClassName: "hoverableItem align-middle text-left",
                    searchNode: null
                },
                {
                    key: "description",
                    label: "Description",
                    type: "text",
                    colSpan: 1,
                    minWidth: 150,
                    sorterApplicable: false,
                    valueClassName: "",
                    labelClassName: "hoverableItem align-middle text-left",
                    searchNode: null

                },
                {
                    key: "state",
                    label: "State",
                    type: "text",
                    colSpan: 1,
                    minWidth: 125,
                    sorterApplicable: true,
                    valueClassName: "",
                    labelClassName: "hoverableItem align-middle text-left",
                    searchNode: null
                },
                {
                    key: "action",
                    label: "Action",
                    type: "action",
                    colSpan: 1,
                    minWidth: 90,
                    sorterApplicable: false,
                    valueClassName: "text-center",
                    labelClassName: "align-middle text-center",
                    searchNode: <div className="text-center align-middle">
                        <Button color='primary'
                            size='sm'
                            onClick={() => this.toggleModal(true, null)}>
                            <i className="fa fa-plus mr-2"
                                aria-hidden="true" />
                            Add</Button>
                    </div>

                }
            ];

            return stockHeading;
        }

        render() {
            let {
                holidayData,
                loading,
                isOpenModal,
                isOpenDeleteConfirmModal,
                stateData
            } = this.state;
            let { pageRequest, sortRequest, filterRequest } = holidayData.request;
            let holidayHeading = this.getHolidayHeading();

            console.log("filterRequest.state:", filterRequest.state);
            console.log("stateData", stateData);

            return (
                <div>

                    <Breadcrumb tag="nav" listTag="div">
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">Holidays</BreadcrumbItem>
                    </Breadcrumb>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        <h5>Holidays</h5>
                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className={"text-right"}>
                                        <Link color='primary' style={{ textDecoration: "none" }}
                                            className="btn btn-sm btn btn-primary"
                                            to={'/holidays/import'}>
                                            <i className="fa fa-upload mr-2"
                                                aria-hidden="true" />
                                            Import
                                        </Link>

                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Row className={"mb-2 align-items-center"}>
                                    <Col xl={2} lg={6} md={6} sm={12} xs={12}>
                                        <Label><i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date</Label>
                                        <DatePicker
                                            className={"form-control"}
                                            selected={filterRequest.year ? moment(filterRequest.year, 'DD/MM/YYYY').toDate() : null}
                                            onChange={(date) =>
                                                this.handleChange(
                                                    date ? getDateString(date, 'DD/MM/YYYY') : null,
                                                    "year"
                                                )
                                            }
                                            selectsStart
                                            startDate={filterRequest.year}
                                            isClearable
                                            placeholderText="Start Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dropdownMode="select"
                                            withPortal
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                                        <Label>State</Label>
                                        <div style={{ position: 'relative' }}>
                              
                                            <AsyncSelect
                                                key={filterRequest.state}
                                                cacheOptions
                                                defaultOptions={this.state.stateData}
                                                isClearable={!!filterRequest.state}
                                                loadOptions={this.loadOptions}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.value}
                                                value={{ label: filterRequest.state, value: filterRequest.state }}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        this.handleChange(selectedOption.value, "state");
                                                    } else {
                                                        this.handleChange(null, "state");
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        {loading ? <p>Loading...</p> :
                                            <p>Showing
                                                {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {holidayData.response.totalRecords}
                                                {' '}entries&nbsp;&nbsp;
                                            </p>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <Table striped bordered responsive hover size='sm'>
                                    <thead>
                                        <tr>
                                            {(holidayHeading || []).map((item, index) => {
                                                return (
                                                    <th key={index}
                                                        onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                                        colSpan={item.colSpan}
                                                        className={item.labelClassName}
                                                        style={{ minWidth: item.minWidth }}>
                                                        {item.label}
                                                        {
                                                            item.sorterApplicable ?
                                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                                )} aria-hidden="true" /> : null
                                                        }
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            {(holidayHeading || []).map((item, index) => {
                                                return (
                                                    <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                        {item.searchNode}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(holidayData.response.records || []).map((holiday, index) => {
                                            return (
                                                <tr key={index} className="text-center align-middle">
                                                    <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                                    <td className="text-center align-middle">{changeFormatOfDateString(holiday.date, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY')}</td>
                                                    <td className="text-left align-middle">{holiday.description}</td>
                                                    <td className="text-center align-middle">{holiday.state}</td>
                                                    <td className="text-center align-middle">
                                                        <ButtonGroup>
                                                            <>
                                                                <Button color='primary'
                                                                    size='sm'
                                                                    onClick={() => this.toggleModal(true, holiday)}>
                                                                    <i className="fa fa-pencil mr-2"
                                                                        aria-hidden="true" />
                                                                    Edit
                                                                </Button> &nbsp;

                                                                <Button color='danger'
                                                                    size='sm'
                                                                    onClick={() => this.toggleCompleteConfirmModal(true, holiday)}>
                                                                    <i className="fa fa-trash mr-2"
                                                                        aria-hidden="true" />
                                                                    Delete
                                                                </Button>
                                                            </>

                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>

                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <Row>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"} style={{ maxWidth: 200 }}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Show</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"select"}
                                                name={"pageSize"}
                                                value={pageRequest.pageSize}
                                                disabled={loading}
                                                onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                                <option value={50}>50 Rows</option>
                                                <option value={100}>100 Rows</option>
                                                <option value={500}>500 Rows</option>
                                            </Input>
                                        </InputGroup>

                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"float-right"}>
                                        <Pagination
                                            activePage={pageRequest.currentPage}
                                            itemsCountPerPage={pageRequest.pageSize}
                                            totalItemsCount={holidayData.response.totalRecords}
                                            pageRangeDisplayed={3}
                                            onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                            innerClass='pagination'
                                            activeLinkClass='active'
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Card>
                    {this.state.isOpenModal
                        ? <ManageHolidayModal
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            item={this.state.workingItem}
                            stateData={this.state.stateData} 
                            handleSubmit={this.handleSubmit}
                            refreshAgain={() => this.getHolidayData(holidayData)} />
                        : null
                    }
                    {
                        isOpenDeleteConfirmModal &&
                        <ConfirmModal
                            isOpen={isOpenDeleteConfirmModal}
                            toggle={this.toggleCompleteConfirmModal}
                            handleSubmit={() => this.handleDelete()}
                            handleCancel={() => this.toggleCompleteConfirmModal(false)}
                            primaryMessage={"Are you sure you want to delete this holiday?"}
                            secondaryMessage={""}
                            submitColor={"danger"}
                            cancelColor={"secondary"}
                            icon={"fa fa-trash-o fa-2x"}
                            type="Deleting..."
                        />
                    }
                    <ToastContainer />
                </div>
            );
        }
    }
