export const MOBILE_SCREEN_WIDTH_IN_PIXEL = 800;
export const SALES_ORDER_CREATED_FROM_KEYWAY_PRODUCT_BUILDER_V1 = "KEYWAY_PRODUCT_BUILDER_V1";
export const PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM = "Custom";
export const PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING = "Fitting";
export const PRODUCT_BUILDER_HOSPITAL_RANGE_PART = "Part";
export const STOCK_GROUP_HOSPITAL_RANGE_CODES = ["B-HOS", "C-HOS", "HOS"];
export const PRODUCT_BUILDER_CURTAIN_TRACK_PART = "Part";
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM = "Custom";
export const PRODUCT_BUILDER_CUSTOM_PART = "Part";
export const PRODUCT_BUILDER_CUSTOM_BUILD = "Build";
export const BUNNINGS_ACCOUNT_ID = "01500";
export const ANDERSENS_ACCOUNT_ID = "01604";
export const HAYMAN_AND_NOOSA_ACCOUNT_ID = "03225";
export const HAYMAN_AND_NOOSA_FABRIC = ["Essentials"];
export const HAYMAN_AND_NOOSA_FABRIC_COLOR = ["Hayman Cloud", "Hayman Flint", "White", "Peppercorn"];
export const HAYMAN_AND_NOOSA_ROLLER_BLIND_LAYOUT = ["Single"];
export const HAYMAN_AND_NOOSA_ROLLER_BLIND_BOTTOM_RAIL_TYPE = ["Round"];
export const HAYMAN_AND_NOOSA_ROLLER_BLIND_BOTTOM_RAIL_COLOUR = ["Silver"];
export const PRODUCT_BUILDER_PANEL_TRACK_PRODCODE = "KW-PANELTR";
export const PRODUCT_BUILDER_ROMAN_BLIND_PRODCODE = "KW-ROMAN";
export const PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE = "ROLSINGLE";
export const PRODUCT_BUILDER_ROLLER_BLIND_DOUBLE_PRODCODE = "KW-ROLDOUBLE";
export const PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE = "KW-PROJAWN";
export const PRODUCT_BUILDER_CURTAIN_TRACK_PRODCODE = "KW-CURTAINTR";
export const PRODUCT_BUILDER_FOLDING_ARM_AWNING_PRODCODE = "KW-FOLDARM";
export const PRODUCT_BUILDER_HOSPITAL_RANGE_PRODCODE = "KW-HOSPITAL";
export const PRODUCT_BUILDER_VENETIAN_PRODCODE = "KW-VENETIAN";
export const PRODUCT_BUILDER_VERTICAL_INDOOR_PRODCODE = "KW-VERTIN";
export const PRODUCT_BUILDER_VERTICAL_OUTDOOR_PRODCODE = "KW-VERTOUT";
export const PRODUCT_BUILDER_WIDESCREEN_PRODCODE = "KW-WIDESCREEN";
export const PRODUCT_BUILDER_ZIPTRAK_EXTERNAL_PRODCODE = "KW-ZIPTRAK";
export const PRODUCT_BUILDER_ZIPTRAK_INTERNAL_PRODCODE = "KW-ZIPTRAK-INT";
export const PRODUCT_BUILDER_QUIKLOK_PRODCODE = "KW-QUIKLOK";
export const PRODUCT_BUILDER_CAFE_CRANK_PRODCODE = "KW-CAFECRANK";
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_INTERNAL_LIVE = true;
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_EXTERNAL_LIVE = true;
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_INTERNAL_LIVE = true;
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_EXTERNAL_LIVE = true;
export const PRODUCT_BUILDER_ORDER_GUIDELINE_DOCUMENT_PUBLIC_URL = "https://winonline.blob.core.windows.net/winonline/static/Windoware-Product-Order-Guidelines.pdf";
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents = [
    "runner",
    "runnerColour",
    "control",
    "cordType",
    "cordSizeType",
    "cordCustomEnteredLength",
    "stacking",
    "overlapArms",
    "underlapArms",
    "operation",
    "projection",
    "bracket",
    "returnOption"
];
export const PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE = "Coolum - Semi Cassette";
export const WINDOWARE_DEFAULT_CONTACTNUMBER = "61 7 3299 3788";
export const WINDOWARE_DEFAULT_CONTACTEMAIL = "sales@windoware.com.au";
export const WINDOWARE_DEFAULT_NAME = "Windoware Team";
export const taxRate = 0.10;
//statuses includes Pending, Awaiting Furth Info, Awaiting production
export const ORDER_GATEWAY_PENDING_TAB_STATUS = [30, 40];
//statuses includes Scheduled, Waiting on Stock, Waiting on Fabric,Waiting on Pelmet
export const PRODUCTION_SCHEDULER_ORDER_STATUS = [50, 51, 52, 70];
//statuses includes PC Awaiting Picking, PC Awaiting Drop Off, PC Awaiting Pickup, PC Complete
export const PRODUCTION_SCHEDULER_POWDERCOATING_ORDER_STATUS = [57, 58, 59, 60];

export const requestMethodOptions = ["GET", "POST", "PUT", "DELETE"];

export const OrderEnquiryProductionModeOrderItemsProductPartListItems = [
    "1AI", "ROLHDKWUPG", "1AL", "1AN", "1AO", "1AJ", "1AM", "KW-SKINONLY-FAA", "KW-SKINONLY-VIN", "KW-SKINONLY-VOD", "KW-SKINONLY-ZIPE", "KW-SKINONLY-ROL", "KW-SKINONLY-PTR", PRODUCT_BUILDER_PANEL_TRACK_PRODCODE, PRODUCT_BUILDER_ROMAN_BLIND_PRODCODE, PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE, PRODUCT_BUILDER_ROLLER_BLIND_DOUBLE_PRODCODE, PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE, PRODUCT_BUILDER_CURTAIN_TRACK_PRODCODE, PRODUCT_BUILDER_FOLDING_ARM_AWNING_PRODCODE, PRODUCT_BUILDER_HOSPITAL_RANGE_PRODCODE, PRODUCT_BUILDER_VENETIAN_PRODCODE, PRODUCT_BUILDER_VERTICAL_INDOOR_PRODCODE, PRODUCT_BUILDER_VERTICAL_OUTDOOR_PRODCODE, PRODUCT_BUILDER_WIDESCREEN_PRODCODE, PRODUCT_BUILDER_ZIPTRAK_EXTERNAL_PRODCODE, PRODUCT_BUILDER_ZIPTRAK_INTERNAL_PRODCODE, PRODUCT_BUILDER_QUIKLOK_PRODCODE, PRODUCT_BUILDER_CAFE_CRANK_PRODCODE
];

export const SHIPMENT_SENDER_DEFAULT_ID = 46;//Windoware Pty Ltd-QLD Dispatch

export const SHIPMENT_COURIER_BORDER_EXPRESS_NAME = "Border Express";
export const SHIPMENT_COURIER_BORDER_EXPRESS_SERVICE_CODE = 'PAR';

export const SHIPMENT_COURIER_TFM_EXPRESS_NAME = "TFM Express";

export const SHIPMENT_METHOD_CUSTOMER_COLLECT = "Customer Collect";
export const SHIPMENT_METHOD_WINDOWARE_DELIVERY = "Windoware Delivery";
export const SHIPMENT_METHOD_OWN_TRUCK = "Own Truck";
export const SHIPMENT_METHOD_OWN_COURIER = "Own Courier";

export const SHIPMENT_METHOD_ConsignNumNotMandatory = [SHIPMENT_METHOD_CUSTOMER_COLLECT, SHIPMENT_METHOD_WINDOWARE_DELIVERY, SHIPMENT_METHOD_OWN_COURIER, SHIPMENT_METHOD_OWN_TRUCK];
export const ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_COMPANY = 50;
export const ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1 = 50;
export const ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2 = 50;
export const ADDRESS_VALIDATION_MAX_LENGTH_CITY = 25;
export const ADDRESS_VALIDATION_MAX_LENGTH_STATE = 3;
export const ADDRESS_VALIDATION_MAX_LENGTH_POSTCODE = 6;
export const ADDRESS_VALIDATION_MAX_LENGTH_FAX = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_COUNTRY = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_COUNTRY_CODE = 2;
export const ADDRESS_VALIDATION_MAX_LENGTH_PHONE = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_EMAIL = 50;
export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS = 25;
export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES = 500;
export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM = 30;
export const DISABLED_SALES_ORDER_ENQUIRY_OPTIONS = ["Packing", "Invoiced", "Shipped", "Paid"];
export const ENABLED_SALES_ORDER_ENQUIRY_OPTIONS = ["Cancelled", "Submitted", "Review", "Quote", "Pending", "Credit Hold", "Awaiting Further Info", "Awaiting Production"];
export const WAREHOUSE_LOCATION_TYPES = [
    { locationTypeID: 1, name: "Pick" },
    { locationTypeID: 2, name: "Bulk" },
    { locationTypeID: 3, name: "GI" }
];
export const WARRANTY_PERIOD_FOR_INDOOR_PRODUCT = 3;
export const WARRANTY_PERIOD_FOR_OUTDOOR_PRODUCT = 5;
export const WARRANTY_PERIOD_FOR_MOTOR = 3;
export const WARRANTY_PRODUCT_TYPE_CODE = ["COM", "PWD", "CTS", "PPE", "CUST"];
export const OUT_OF_WARRANTY_REASONS = [5];
export const REWORK_EXTRA_DISCOUNT_ORDER_ITEM_PRODCODE = "4";
export const FREIGHT_RULE_FREIGHT_OVERIDE = "Freight Override";
export const CORE_FREIGHT_RULE_ROLE = ["Admin", "Tech Admin"];
export const QUARTZ_SCHEDULED_JOB_DEBTOR_MONTHLY_STATEMENT = "StatementEmailQuartzService";
export const QUARTZ_SCHEDULED_JOB_DEBTOR_QUOTES_FOLLOW_UP = "DebtorQuotes_AutoReminderQuartzService";
export const PACKAGING_AND_HANDLING_FEE_PRODCODE = "2F";
export const LABOUR_CHARGE_PRODCODE = "00001";
export const SALES_ORDER_JOB_STATUS_ID_INVOICED = 95;
export const ALL_PRODCODES = [
    "KW-SKINONLY-FAA", "KW-SKINONLY-VIN", "KW-SKINONLY-VOD", "KW-SKINONLY-ZIPE", "KW-SKINONLY-ROL", "KW-SKINONLY-PTR", PRODUCT_BUILDER_PANEL_TRACK_PRODCODE, PRODUCT_BUILDER_ROMAN_BLIND_PRODCODE, PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE, PRODUCT_BUILDER_ROLLER_BLIND_DOUBLE_PRODCODE, PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE, PRODUCT_BUILDER_CURTAIN_TRACK_PRODCODE, PRODUCT_BUILDER_FOLDING_ARM_AWNING_PRODCODE, PRODUCT_BUILDER_HOSPITAL_RANGE_PRODCODE, PRODUCT_BUILDER_VENETIAN_PRODCODE, PRODUCT_BUILDER_VERTICAL_INDOOR_PRODCODE, PRODUCT_BUILDER_VERTICAL_OUTDOOR_PRODCODE, PRODUCT_BUILDER_WIDESCREEN_PRODCODE, PRODUCT_BUILDER_ZIPTRAK_EXTERNAL_PRODCODE, PRODUCT_BUILDER_ZIPTRAK_INTERNAL_PRODCODE, PRODUCT_BUILDER_QUIKLOK_PRODCODE, PRODUCT_BUILDER_CAFE_CRANK_PRODCODE
];
export const DEBTOR_CATEGORY = {
    GOLD: {debtorCategoryID: 1, name: "Gold"},
    SILVER: {debtorCategoryID: 2, name: "Silver"},
    BLUE: {debtorCategoryID: 3, name: "Blue"}
};


export const STOCK_QTY_TYPE = {
    each: "Each",
    warehouse: "WH",
};
export const STOCK_QTY_FORMAT = {
    each: "Each",
    packQty_of_PackSize: "PackQty of PackSize",
};

