import React, { Component } from 'react';
import { Badge, Button, Input, Table, Spinner, Label } from 'reactstrap';
import NumberFormat from "react-number-format";
import '../../../../../../assets/css/app/Print.css';
import SearchStock from "../../../../../search/SearchStockKeyway";
import classnames from 'classnames';
import ConfirmModal from '../../../../../../components/modal/ConfirmModal';
import { cloneDeep } from 'lodash';
import { isEmpty } from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import { toast } from 'react-toastify';

export default class CustomBOMModalPrint extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: {},
            itemIndex: -1,
            stocksByProdCode: {},
            isLoading: false,
            cloneProduct: {},
            isOpenSaveToBuildModal: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.cloneProduct = this.cloneProduct.bind(this);
        this.saveStockChangesToMainBuild = this.saveStockChangesToMainBuild.bind(this);
        this.toggleSaveToBuildModal = this.toggleSaveToBuildModal.bind(this);
        this.toggleSearchCondition = this.toggleSearchCondition.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        let { product } = this.props;
        if (!isEmpty(product)) {
            this.cloneProduct(product);
        }
    }

    cloneProduct(product) {
        let { cloneProduct } = this.state;
        cloneProduct = cloneDeep(product);
        this.setState({ cloneProduct });
    }

    saveStockChangesToMainBuild(isSavingTemplate, isNewTemplate) {
        let { cloneProduct } = this.state;
        let { itemIndex, toggleTemplateModal, saveTemplate } = this.props;
        let change = cloneProduct.items[itemIndex].stocks;
        this.props.handleChange(change, "save-stock", itemIndex, true);
        this.setState({ isOpenSaveToBuildModal: false });
        toast.info("Changes saved to Build!", {
            position: toast.POSITION.BOTTOM_CENTER
        });
        if (isSavingTemplate) {
            if (isNewTemplate) {
                toggleTemplateModal(true);
            }
            else {
                saveTemplate();
            }
        }
        this.refresh();
    }

    getGrossMargin(item, stocksByProdCode) {
        let landedCost = 0;
        let sellingPrice = item.pricing.unitPrice;
        (item.stocks || []).forEach((stock, index) => {
            if (stocksByProdCode[stock.prodCode]) {
                landedCost += (stocksByProdCode[stock.prodCode].landedCost);
            }
        });
        return sellingPrice ? (((sellingPrice - landedCost) * 100) / sellingPrice) : 0;
    }

    handleChange(change, key, stockIndex) {
        let { itemIndex } = this.props;
        let { cloneProduct } = this.state;
        switch (key) {
            case "new-stock":
                cloneProduct.items[itemIndex].stocks.push(salesOrderProductBuilderV1Service.getStockFactoryInstance());
                stockIndex = cloneProduct.items[itemIndex].stocks.length - 1;
                break;
            case "clone-stock":
                cloneProduct.items[itemIndex].stocks.push(cloneDeep(cloneProduct.items[itemIndex].stocks[stockIndex]));
                stockIndex = cloneProduct.items[itemIndex].stocks.length - 1;
                break;
            case "delete-stock":
                cloneProduct.items[itemIndex].stocks.splice(stockIndex, 1);
                stockIndex = -1;
                break;
            case "prodCode":
                if (change && change.prodCode) {
                    cloneProduct.items[itemIndex].stocks[stockIndex].prodCode = change.prodCode;
                    cloneProduct.items[itemIndex].stocks[stockIndex].unitPrice = change.keywayStock ? change.keywayStock.eachUnitPrice : 0;
                    cloneProduct.items[itemIndex].stocks[stockIndex].price = change.keywayStock ? change.keywayStock.eachUnitPrice : 0;
                    cloneProduct.items[itemIndex].stocks[stockIndex].whUnitQty = change.keywayStock && (["", "0", 0, 1, null, undefined].includes(change.keywayStock.whUnitQty) ? 0 : change.keywayStock.whUnitQty);
                    cloneProduct.items[itemIndex].stocks[stockIndex].keywayCalculatedQty = cloneProduct.items[itemIndex].stocks[stockIndex].whUnitQty ? (1 / cloneProduct.items[itemIndex].stocks[stockIndex].whUnitQty) : 0;
                    cloneProduct.items[itemIndex].stocks[stockIndex].whUnitName = cloneProduct.items[itemIndex].stocks[stockIndex].keywayMeasurementUnit = change.keywayStock && (["", "0", 0, null, undefined].includes(change.keywayStock.whUnitName) ? "N/A" : change.keywayStock.whUnitName);
                    cloneProduct.items[itemIndex].stocks[stockIndex].swishCalculatedQty = 1;
                    cloneProduct.items[itemIndex].stocks[stockIndex].swishMeasurementUnit = change.keywayStock && (["", "0", 0, null, undefined].includes(change.keywayStock.eachUnitName) ? "N/A" : change.keywayStock.eachUnitName);
                    cloneProduct.items[itemIndex].stocks[stockIndex].discontinued = change.discontinued;
                    cloneProduct.items[itemIndex].stocks[stockIndex].soldOut = change.soldOut;
                    cloneProduct.items[itemIndex].stocks[stockIndex].qoh = change.qoh;
                    cloneProduct.items[itemIndex].stocks[stockIndex].price1 = change.keywayStock ? change.keywayStock.eachUnitPrice : 0;
                    cloneProduct.items[itemIndex].stocks[stockIndex].isNonStock = change.keywayStock ? change.keywayStock.isNonStock : 0;
                    this.props.addStockInStocksByProdCode(change);
                } else {
                    cloneProduct.items[itemIndex].stocks[stockIndex].prodCode = "";
                    cloneProduct.items[itemIndex].stocks[stockIndex].unitPrice = 0;
                    cloneProduct.items[itemIndex].stocks[stockIndex].price = 0;
                    cloneProduct.items[itemIndex].stocks[stockIndex].discontinued = false;
                    cloneProduct.items[itemIndex].stocks[stockIndex].soldOut = false;
                    cloneProduct.items[itemIndex].stocks[stockIndex].qoh = 0;
                }
                break;
            case "swishCalculatedQty":
                cloneProduct.items[itemIndex].stocks[stockIndex].swishCalculatedQty = parseFloat(change.toFixed(4));
                if (["", "0", 0, null, undefined].includes(cloneProduct.items[itemIndex].stocks[stockIndex].whUnitName)) {
                    cloneProduct.items[itemIndex].stocks[stockIndex].keywayMeasurementUnit = "N/A";
                } else {
                    cloneProduct.items[itemIndex].stocks[stockIndex].keywayMeasurementUnit = cloneProduct.items[itemIndex].stocks[stockIndex].whUnitName;
                }
                if (["", "0", 0, 1, null, undefined].includes(cloneProduct.items[itemIndex].stocks[stockIndex].whUnitQty)) {
                    cloneProduct.items[itemIndex].stocks[stockIndex].keywayCalculatedQty = 0;
                } else {
                    cloneProduct.items[itemIndex].stocks[stockIndex].keywayCalculatedQty = parseFloat((cloneProduct.items[itemIndex].stocks[stockIndex].swishCalculatedQty / cloneProduct.items[itemIndex].stocks[stockIndex].whUnitQty).toFixed(8));
                }
                break;
            default:
                cloneProduct.items[itemIndex].stocks[stockIndex][key] = change;
                break;
        }
        if (stockIndex > -1) {
            cloneProduct.items[itemIndex].stocks[stockIndex].isCustom = true;
        }
        this.setState({ cloneProduct });
    }


    toggleSaveToBuildModal(isOpen) {
        this.setState({ isOpenSaveToBuildModal: isOpen });
    }

    toggleSearchCondition(stockIndex, searchKey) {
        let { itemIndex } = this.props;
        let { cloneProduct } = this.state;
        const item = cloneProduct.items[itemIndex];
        const stock = item.stocks[stockIndex];
        if (stock.searchCondition === searchKey) {
            stock.searchCondition = "";
        } else {
            stock.searchCondition = searchKey;
        }
        this.setState({
            cloneProduct: {
                ...cloneProduct,
                items: [
                    ...cloneProduct.items.slice(0, itemIndex),
                    {
                        ...item,
                        stocks: [
                            ...item.stocks.slice(0, stockIndex),
                            stock,
                            ...item.stocks.slice(stockIndex + 1)
                        ]
                    },
                    ...cloneProduct.items.slice(itemIndex + 1)
                ]
            }
        });
    }

    render() {
        let { cloneProduct, isOpenSaveToBuildModal } = this.state;
        let { product, itemIndex, stocksByProdCode, order, toggle,
            isSavingTemplate, toggleTemplateModal, saveTemplate, templateData, hasProductBuilderTemplateWritePrivilege } = this.props;
        return (
            <div>
                <h5>{order.debtorInvoiceOrdNum ? <span className="mr-2">#{order.debtorInvoiceOrdNum}</span> : null}Custom
                    Build</h5>
                {Object.keys(cloneProduct).length > 0 ?
                    <div>
                        <Table responsive={true} bordered={true} striped={true} size={"sm"}>
                            <thead>
                                <tr>
                                    <th className="text-center"
                                        style={{ minWidth: 50 }}>{cloneProduct.items[itemIndex].configuration.width.selected.value + " x " + cloneProduct.items[itemIndex].configuration.drop.selected.value}</th>
                                    <th className="text-center" style={{ minWidth: 475 }}>Item code</th>
                                    <th className="text-center" style={{ minWidth: 100 }}>Each Qty</th>
                                    <th className="text-center" style={{ minWidth: 100 }}>WH Pack Qty</th>
                                    <th className="text-center" style={{ minWidth: 100 }}>Each Price</th>
                                    <th className="text-center" style={{ minWidth: 80 }}>Total Price</th>
                                    <th className="text-center" style={{ minWidth: 110 }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (cloneProduct.items[itemIndex].stocks || []).map((stock, stockIndex) => {
                                        if (stock.isVisibleInPartList) {
                                            return <tr
                                                key={"" + cloneProduct.items[itemIndex] + stockIndex}
                                                className={classnames({ "bg-warning": stock.isCustom })}
                                            >
                                                <td className="align-middle">
                                                    <div>
                                                        <strong>{stock.attribute}</strong>
                                                    </div>
                                                    <div>
                                                        {stock.label}
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className={(cloneProduct.items[itemIndex].configuration.prodCode.selected.value && cloneProduct.items[itemIndex].configuration.prodCode.selected.value === stock.prodCode) ? "d-none" : ""}>
                                                        <Label>
                                                            <Button color={"link"}
                                                                size={"sm"}
                                                                onClick={() => this.toggleSearchCondition(stockIndex, "allStocks")}>
                                                                <i className={classnames("fa", "fa-lg", "fa-fw", stock.searchCondition === "allStocks" ? "fa-check-square-o" : "fa-square-o")} aria-hidden="true" />
                                                            </Button>
                                                            Search All
                                                        </Label>
                                                        <Label>
                                                            <Button color={"link"}
                                                                size={"sm"}
                                                                onClick={() => this.toggleSearchCondition(stockIndex, "services")}>
                                                                <i className={classnames("fa", "fa-lg", "fa-fw", stock.searchCondition === "services" ? "fa-check-square-o" : "fa-square-o")} aria-hidden="true" />
                                                            </Button>
                                                            Search Services
                                                        </Label>
                                                    </div>
                                                    <div>
                                                        {stock.searchCondition === "services" ?
                                                            <SearchStock
                                                                disabled={cloneProduct.items[itemIndex].configuration.prodCode.selected.value && cloneProduct.items[itemIndex].configuration.prodCode.selected.value === stock.prodCode}
                                                                handleSelection={(selection) => this.handleChange(selection, "prodCode", stockIndex)}
                                                                selected={stock.prodCode}
                                                                defaultProdCode={""}
                                                                filter={{
                                                                    bmFlag: null,
                                                                    excludeDiscontinued: true,
                                                                    excludeSoldOut: true,
                                                                    stockGroupCodes: null,
                                                                    stockMasterGroupCodes: null,
                                                                    colour: null,
                                                                    excludeOffRange: null,
                                                                    includeFabric: null,
                                                                    excludeArchived: null,
                                                                    excludeOutOfStock: null,
                                                                    isNonStock: 1,
                                                                }}
                                                            />
                                                            :
                                                            <SearchStock
                                                                disabled={cloneProduct.items[itemIndex].configuration.prodCode.selected.value && cloneProduct.items[itemIndex].configuration.prodCode.selected.value === stock.prodCode}
                                                                handleSelection={(selection) => this.handleChange(selection, "prodCode", stockIndex)}
                                                                selected={stock.prodCode}
                                                                defaultProdCode={""}
                                                                filter={{
                                                                    bmFlag: 0,
                                                                    excludeDiscontinued: true,
                                                                    excludeSoldOut: true,
                                                                    stockGroupCodes: stock.searchCondition === "allStocks" ? [] : [product.items[itemIndex].configuration.productCode.selected.value],
                                                                    stockMasterGroupCodes: [],
                                                                    colour: "",
                                                                    excludeOffRange: null,
                                                                    includeFabric: true,
                                                                    excludeArchived: null,
                                                                    excludeOutOfStock: null,
                                                                    isNonStock: 0,
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    {stock.discontinued ? <Badge color={"danger"} className={"mr-2"}>Discontinued</Badge> : null}
                                                    {stock.soldOut ? <Badge color={"danger"} className={"mr-2"}>Sold Out</Badge> : null}
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="swishCalculatedQty"
                                                        disabled={cloneProduct.items[itemIndex].configuration.prodCode.selected.value && cloneProduct.items[itemIndex].configuration.prodCode.selected.value === stock.prodCode}
                                                        value={stock.swishCalculatedQty}
                                                        onChange={(e) => this.handleChange(parseFloat(e.target.value), e.target.name, stockIndex)}
                                                        onFocus={(event) => event.target.select()}
                                                        placeholder="Each Qty" />
                                                    {
                                                        (stock.swishMeasurementUnit && stock.swishMeasurementUnit != "" && stock.swishMeasurementUnit != "0") ?
                                                            <div className="text-center">
                                                                {stock.swishMeasurementUnit + "(s)"}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (!stock.isNonStock && stock.qoh && stock.qoh !== null && (stock.qoh <= stock.swishCalculatedQty))
                                                            ? <Badge color={"danger"}>QOH&nbsp;{stock.qoh}</Badge>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {(stock.whUnitQty && stock.whUnitName !== "N/A") ?
                                                        stock.keywayCalculatedQty + " " + stock.whUnitName + "(s) of " + stock.whUnitQty + " " + stock.swishMeasurementUnit + "(s)"
                                                        : "N/A"}
                                                </td>
                                                <td className="align-middle text-right">
                                                    <Input type="number" name="price"
                                                        value={stock.price}
                                                        onChange={(e) => this.handleChange(parseFloat(e.target.value ? e.target.value : 0), e.target.name, stockIndex)}
                                                        onFocus={(event) => event.target.select()}
                                                        placeholder="Unit Price" />
                                                    <div className="text-right">
                                                        <small>
                                                            <NumberFormat
                                                                prefix={'OSP. @ $'}
                                                                value={stocksByProdCode[stock.prodCode] ? stocksByProdCode[stock.prodCode].price1 : 0}
                                                                displayType={'text'}
                                                                decimalScale={4} />
                                                        </small>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-right">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={stock.finalPrice}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={false} />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Button color={"link"}
                                                        title={"click here to copy item"}
                                                        onClick={() => this.handleChange(null, "clone-stock", stockIndex)}>
                                                        <i className="fa fa-clone fa-lg"
                                                            aria-hidden="true" />
                                                    </Button>
                                                    <span className={"text-muted"}>|</span>
                                                    <Button color={"link"}
                                                        title={"click here to delete"}
                                                        onClick={() => this.handleChange(null, "delete-stock", stockIndex)}>
                                                        <i className="fa fa-trash-o fa-lg text-danger"
                                                            aria-hidden="true" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        } else {
                                            return null;
                                        }

                                    })
                                }
                                <tr>
                                    <td>
                                        <strong>Price(ex.GST)</strong>
                                    </td>
                                    <td className="text-right" colSpan={5}>
                                        <strong>
                                            <NumberFormat
                                                prefix={'$'}
                                                value={cloneProduct.items[itemIndex].pricing.unitPrice}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true} />
                                        </strong>
                                    </td>
                                    <td colSpan={2} />
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Gross Margin</strong>
                                    </td>
                                    <td className="text-right" colSpan={5}>
                                        <strong>
                                            <NumberFormat
                                                value={this.getGrossMargin(cloneProduct.items[itemIndex], stocksByProdCode)}
                                                suffix={'%'}
                                                displayType={'text'}
                                                decimalScale={2} />
                                        </strong>
                                    </td>
                                    <td colSpan={2} />
                                </tr>
                            </tbody>
                        </Table>
                        <div className={"d-flex justify-content-between mb-2"}>
                            <div>
                                <Button
                                    color={"primary"}
                                    className={"ml-1"}
                                    onClick={() => this.handleChange(null, "new-stock", -1)}>
                                    <i className="fa fa-cart-plus mr-2" aria-hidden="true" />
                                    Add new item
                                </Button>
                            </div>
                            <div>
                                <Button color={"primary"}
                                    onClick={() => this.toggleSaveToBuildModal(true)}>
                                    <i className="fa fa-floppy-o mr-2" />
                                    Save to Build</Button>
                                {
                                    templateData && templateData.customTemplateID > 0 ?
                                        <Button
                                            color={hasProductBuilderTemplateWritePrivilege ? "primary" : "secondary"}
                                            disabled={!hasProductBuilderTemplateWritePrivilege}
                                            className={"ml-2 mr-2"}
                                            onClick={() => this.saveStockChangesToMainBuild(true, false)}>
                                            {isSavingTemplate ? <Spinner className={"mr-2"} size={"sm"} /> : <i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>}
                                            Update template
                                        </Button>
                                        :
                                        <Button
                                            color={hasProductBuilderTemplateWritePrivilege ? "primary" : "secondary"}
                                            disabled={!hasProductBuilderTemplateWritePrivilege}
                                            className={"ml-2 mr-2"}
                                            onClick={() => this.saveStockChangesToMainBuild(true, true)}>
                                            {isSavingTemplate ? <Spinner className={"mr-2"} size={"sm"} /> : <i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>}
                                            Save to template
                                        </Button>
                                }
                                <Button color={"secondary"}
                                    onClick={() => toggle(false)}>
                                    <i className="fa fa-times mr-2" />
                                    Cancel</Button>
                            </div>
                            {isOpenSaveToBuildModal ?

                            <ConfirmModal
                                isOpen={isOpenSaveToBuildModal}
                                toggle={this.toggleSaveToBuildModal}
                                handleSubmit={() => this.saveStockChangesToMainBuild(false, false)}
                                handleCancel={() => this.toggleSaveToBuildModal(false)}
                                primaryMessage={"Confirm Save"}
                                secondaryMessage={"Changes done once cannot be rolled back. Are you sure you want to save your current changes?"}
                                submitColor={"primary"}
                                cancelColor={"secondary"}
                                icon={"fa fa-exclamation-triangle fa-2x"}
                                loading={false}
                                type="Saving..."
                                    />

                            : null
                        }
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }
}