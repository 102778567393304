import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Spinner,
    Table,
    UncontrolledTooltip
} from 'reactstrap';
import OrderService from '../../services/OrderService';
import classnames from 'classnames';
import {
    changeFormatOfDateString,
    displayECD,
    findIndex,
    getDateString,
    getOrderColorName,
    handleErrorMessage,
    scrollTo
} from './../../services/CommonService';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import OrderProductGrpDetails from './OrderProductGrpDetails';
import DebtorCategoryBucket from './DebtorCategoryBucket';
import queryString from 'query-string';
import { Link as Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { DebtorInvoiceStatusByID } from './../../store/DebtorInvoiceStatus';
import OrderGatewayOrderItemsModal from '../../components/modal/OrderGatewayOrderItemsModal'
import SearchBannerGatewayOrder from './SearchBannerGatewayOrder';
import { toast, ToastContainer } from 'react-toastify';
import * as FileSaver from 'file-saver';
import { cloneDeep, isEmpty } from 'lodash';
import OrderInformationModal from './OrderInformationModal';
import OrderStatusChangeModal from './OrderStatusChangeModal';
import MultipleOrderStatusChangeModal from './MultipleOrderStatusChangeModal';
import SalesOrderItemProductDetailModal from '../modal/SalesOrderItemProductDetailModal';
import SalesOrderService from '../../services/WINOInventory/SalesOrderService';
import ViewDebtorNoteModal from '../modal/ViewDebtorNoteModal';
import CheckWarrantyForReworkModal from '../modal/CheckWarrantyForReworkModal';
import ConfirmModal from "../../components/modal/ConfirmModal";
import ImportNotesModal from '../modal/ImportNotesModal';
import EDILinkingModal from './EDILinkingModal';

export default class GatewayOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orders: {
                request: props.request,
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            loading: false,
            showConfirmCancel: false,
            searchText: "",
            ordernumbers: "",
            isOpenOrderItemsModal: false,
            isOpenOrderStatusChangeModal: false,
            isOpenMultipleOrderStatusChangeModal: false,
            isOpenEDILinkingModal: false,
            selectedOrder: null,
            selectedOrderIndex: null,
            highlightedOrder: {},
            selectedOrderNumber: props.selectedOrderNumber,
            allRecordsSelected: false,
            selectedCheckboxes_count: 0,
            hasOrderGatewayReadonlyPrivilege: props.hasOrderGatewayReadonlyPrivilege,
            hasDebtorInvoiceCreditHoldReleasePrivilege: props.hasDebtorInvoiceCreditHoldReleasePrivilege,
            selectedOrderForProdGroup: {},
            isOpenProductGroupModal: false,
            clickType: "",
            checkWarrantyModalData: {},
            isCheckWarrantyModalOpen: false,
            userRole: props.userRole,
            canApproveRework: false,
            isHiddenExtendedColumn: true,
            isUncancelAllowed: false,
        };

        this.orderService = new OrderService();
        this.refresh = this.refresh.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleButtonChange = this.handleButtonChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.clearProductSummaryfilter = this.clearProductSummaryfilter.bind(this);
        this.selectRecord = this.selectRecord.bind(this);
        this.selectAllRecords = this.selectAllRecords.bind(this);
        this.toggleOrderItemsModal = this.toggleOrderItemsModal.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.toggleJobInfoModal = this.toggleJobInfoModal.bind(this);
        this.toggleEDILinkingModal = this.toggleEDILinkingModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseEDILinkingModal = this.handleCloseEDILinkingModal.bind(this);
        this.toggleOrderStatusChangeModal = this.toggleOrderStatusChangeModal.bind(this);
        this.toggleMultipleOrderStatusChangeModal = this.toggleMultipleOrderStatusChangeModal.bind(this);
        this.handleCloseOrderStatusChangeModal = this.handleCloseOrderStatusChangeModal.bind(this);
        this.handleCloseMultipleOrderStatusChangeModal = this.handleCloseMultipleOrderStatusChangeModal.bind(this);
        this.toggleProductGroupModal = this.toggleProductGroupModal.bind(this);
        this.getQuoteActiveCSSClassName = this.getQuoteActiveCSSClassName.bind(this);
        this.takeActionOnOrder = this.takeActionOnOrder.bind(this);
        this.updateSelectedOrderStatusToAWPR = this.updateSelectedOrderStatusToAWPR.bind(this);
        this.updateOrderOverrideHold = this.updateOrderOverrideHold.bind(this);
        this.toggleInternalNoteInfoModel = this.toggleInternalNoteInfoModel.bind(this);
        this.openApproveWarrantyModal = this.openApproveWarrantyModal.bind(this);
        this.toggleApproveWarrantyModal = this.toggleApproveWarrantyModal.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.handleOrderCancellation = this.handleOrderCancellation.bind(this);
        this.handleClearAndReImport = this.handleClearAndReImport.bind(this);
        this.handleClearImportedItems = this.handleClearImportedItems.bind(this);
        this.toggleImportNotesModal = this.toggleImportNotesModal.bind(this);
        this.toggleExtendedColumn = this.toggleExtendedColumn.bind(this);
        this.checkBulkOrder = this.checkBulkOrder.bind(this);
    }

    componentDidMount() {
        let { userRole } = this.props;
        if (['Admin', 'Production Admin', 'Finance'].includes(userRole)) {
            this.setState({ canApproveRework: true });
        }
        if (['Admin', 'Tech-Admin', 'Finance', 'Customer Service', 'SalesRep'].includes(userRole)) {
            this.setState({ isUncancelAllowed: true });
        }
        this.refresh();
        this.getSalesOrderJobStatuses();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedOrderNumber !== this.state.selectedOrderNumber) {
            this.setState({ selectedOrderNumber: nextProps.selectedOrderNumber });
        }

        if (nextProps.hasOrderGatewayReadonlyPrivilege !== this.state.hasOrderGatewayReadonlyPrivilege) {
            this.setState({ hasOrderGatewayReadonlyPrivilege: nextProps.hasOrderGatewayReadonlyPrivilege });
        }

        if (nextProps.userRole !== this.state.userRole) {
            this.setState({ userRole: nextProps.userRole });
        }

    }

    refresh() {
        this.getOrders(this.state.orders);
    }

    handleClearAndReImport(ordNum) {
        this.setState({ loading: true });
        this.orderService.clearAndReImportSalesOrder(ordNum).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success('Re-Imported Order #' + ordNum, {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.refresh();
            }
            this.setState({ loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleClearImportedItems(ordNum) {
        this.setState({ loading: true });
        this.orderService.clearImportedItems(ordNum).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success('Cleared Imported Items for Order #' + ordNum, {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.refresh();
            }
            this.setState({ loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleOrderItemsModal(value) {
        this.setState({ isOpenOrderItemsModal: value });
    }

    toggleOrderStatusChangeModal(value) {
        this.setState({ isOpenOrderStatusChangeModal: value });
    }

    toggleMultipleOrderStatusChangeModal(value) {
        this.setState({ isOpenMultipleOrderStatusChangeModal: value });
    }

    LoadingorderQuery() {
        let { orders } = this.state;
        let query = "";
        if (orders.request.filterRequest.isProductSummarySearched) {
            if (orders.request.filterRequest.debtorCategoryId > 0) {
                query = "Fetching Orders with Category: " + orders.request.filterRequest.debtorCategoryLabel
            }
            if (!isEmpty(orders.request.filterRequest.productName)) {
                query = "Fetching Orders with " + orders.request.filterRequest.productName.toLowerCase();
            }
            if (orders.request.filterRequest.debtorCategoryId > 0 && orders.request.filterRequest.productName) {
                query = "Fetching Orders with Category: " + orders.request.filterRequest.debtorCategoryLabel + " and Product " + orders.request.filterRequest.productName.toLowerCase();
            }
        }
        return query;
    }


    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let { salesOrderJobStatuses } = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({ salesOrderJobStatuses })
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getOrders(orders) {
        let { highlightedOrder, ordernumbers } = this.state;
        let { selectedOrderNumber } = this.props;

        this.setState({ loading: true, selectedCheckboxes_count: 0 });

        let request = cloneDeep(orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.orderService.getAllGatewayOrders(request, this.props.type).then(response => {
            orders.response = response.data;
            ordernumbers = "";
            orders.response.records.forEach(function (item, index) {
                ordernumbers += item.ordNum + ","
            });

            this.props.handleOrderCountFromParent(this.props.type, response.data.totalRecords);

            if (selectedOrderNumber) {
                let orderIndex = findIndex(orders.response.records, 'ordNum', parseInt(selectedOrderNumber));
                if (orderIndex > -1) {
                    // highlight order
                    highlightedOrder = orders.response.records[orderIndex];
                }

                this.setState({
                    highlightedOrder,
                    selectedOrderNumber
                }, () => {
                    setTimeout(() => {
                        scrollTo("Order" + highlightedOrder.ordNum)
                    }, 1000);

                });
            }
            this.setState({ orders, loading: false, ordernumbers: ordernumbers, type: this.props.type });
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    onOrderClick(orderNum) {
        this.setState({ selectedOrderNumber: orderNum });
        this.props.handleOrderNumberFromParent(orderNum);
    }

    selectRecord(val, OrdNum) {
        let { orders } = this.state;
        const elementsIndex = orders.response.records.findIndex(x => x.ordNum === OrdNum);
        orders.response.records[elementsIndex].selected = !val;
        let counter = 0;
        for (let i in orders.response.records) {
            if (orders.response.records[i].selected) {
                counter++;
            }
        }
        if (counter === orders.response.records.length) {
            this.setState({ allRecordsSelected: true, selectedCheckboxes_count: counter, orders });
        }
        else {
            this.setState({ allRecordsSelected: false, selectedCheckboxes_count: counter, orders });
        }
    }

    selectAllRecords() {
        let self = this;
        let { orders } = this.state;
        orders.response.records.forEach(function (el) {
            el.selected = !self.state.allRecordsSelected;
        });
        let counter = 0;
        for (let i in orders.response.records) {
            if (orders.response.records[i].selected) {
                counter++;
            }
        }
        self.setState({ orders, allRecordsSelected: !self.state.allRecordsSelected, selectedCheckboxes_count: counter });
    }

    toggle = targetName => {
        if (!this.state[targetName]) {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    };

    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };

    getQuoteActiveCSSClassName(value) {
        let cssClassName = "";
        if (value >= 20 && value <= 30)
            cssClassName = "text-warning";
        else if (value > 30)
            cssClassName = "text-danger";
        else
            cssClassName = "text-success";

        return cssClassName;
    }

    getColValue(value, storeItem, order, index) {
        let { hasOrderGatewayReadonlyPrivilege, hasDebtorInvoiceCreditHoldReleasePrivilege, userRole, isUncancelAllowed } = this.state;
        switch (storeItem.type) {
            case "consignNum":
                let url = order.consignmentTrackingUrl;
                if (url) {
                    return (<a
                        href={url}
                        target={"_blank"}>
                    <Button color={"link"} size={"sm"}>
                        <i className="fa fa-truck mr-2" aria-hidden="true" /><small>{value}</small>
                    </Button>
                    </a>)
                }
                return <span><small>{value}</small></span>;
                break;
            case "link":
                return <ButtonGroup>
                    <Link
                        onClick={() => this.onOrderClick(value)}
                        className={"btn btn-sm btn-" + getOrderColorName(order, this.state.highlightedOrder.ordNum) + ""}
                        style={{ color: "white" }}
                        title={"Click here to view order in detail"}
                        to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}>
                        {value}
                    </Link>
                    {
                        order.isRework ?
                            <Button size={"sm"} title={"Rework"}
                                style={{ backgroundColor: '#ffa500', color: 'white', borderColor: '#ffa500' }}>
                                R
                            </Button>
                            : null
                    }
                    {
                        order.isCustom ?
                            <Button size={"sm"} title={"Custom Order"}
                                color={"warning"} disabled style={{ opacity: 1 }}>
                                <i className="fa fa-wrench" />
                            </Button>
                            : null
                    }
                </ButtonGroup>;
            case "date":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return <span><NumberFormat value={value}
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'} /></span>;
            case "number":
                return <span><NumberFormat value={value}
                    displayType={'text'}
                    thousandSeparator={true} /></span>;
            case "delCompany":
                return <small>{value}</small>;
            case "statusByID":
                let result = "";
                switch (value) {
                    case 0:
                        result = <Badge color="primary">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 1:
                        result = <Badge color="warning">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 2:
                        result = <Badge color="warning">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 3:
                        result = <Badge color="info">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 4:
                        result = <Badge color="success">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case -1:
                        result = <Badge color="danger">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                }
                return <div className={"text-center"}>
                    <h5>{result}</h5>
                </div>;
            case "days_Since_Last_Activity":
                let cssClassName = this.getQuoteActiveCSSClassName(value);

                return <p className={cssClassName}>{value}</p>;
            case "salesOrderJobStatusId":
                let color = "info";
                if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId]) {
                    if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].isOnHold) {
                        color = "warning";
                    }
                    if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].isCompleted) {
                        color = "success";
                    }
                }
                if (value === "Cancelled") {
                    color = "danger";
                }

                let tooltipTitle = "";
                if (((this.props.type === "CAN" && order.cancelledDate !== null) || (this.props.type === "DISP" && order.statusUpdateDate !== null))) {
                    switch (this.props.type) {
                        case "CAN":
                            tooltipTitle = "Cancelled at " + changeFormatOfDateString(order.cancelledDate, "DD/MM/YYYY HH:mm:ss", 'DD MMM YYYY hh:mm:ss a');
                            break;
                        case "DISP":
                            tooltipTitle = "Status updated at " + changeFormatOfDateString(order.statusUpdateDate, "DD/MM/YYYY HH:mm:ss", 'DD MMM YYYY hh:mm:ss a');
                            break;
                    }
                }

                if (!hasOrderGatewayReadonlyPrivilege && (this.props.type === "PEN" || this.props.type === "PROD")) {
                    tooltipTitle = "Click here to update status";
                }


                return <div className={"text-center"}>
                    <Badge color={color} id={`bdg-${index}`}
                        className={classnames({
                            "hand-cursor": ["DISP", "PROD", "PEN"].includes(this.props.type)
                        })}
                        onClick={this.openJobInfoModal.bind(this, order, this.props.type, hasOrderGatewayReadonlyPrivilege, "status")}>{value}
                        {(((order.salesOrderJobStatusId <= 45) || (userRole === "Admin" || userRole === "Finance")) && (this.props.type === "PEN" || this.props.type === "PROD")) ? <i className="fa fa-pencil ml-1" aria-hidden="true" /> : null}</Badge>
                    {
                        tooltipTitle ?
                            <UncontrolledTooltip placement="right" target={`bdg-${index}`}>
                                {tooltipTitle}
                            </UncontrolledTooltip>
                            : null
                    }
                </div>;

            case "categoryByID":
                let className = "";
                switch (order.debtorCategoryID) {
                    case 1:
                        className = "btn btn-sm btn-warning";
                        break;
                    case 2:
                        className = "btn btn-sm btn-secondary";
                        break;
                    case 3:
                    default:
                        className = "btn btn-sm btn-primary";
                        break;
                }

                let result1 = "";
                if (value) {
                    if (hasOrderGatewayReadonlyPrivilege) {
                        result1 = <Button className={className} disabled>{value}</Button>;
                    }
                    else {
                        result1 = <Link
                            className={className}
                            style={{ color: "white" }}
                            title={"Click here to see account details"}
                            to={"/customer/#" + value}
                        >{value}</Link>;
                    }
                } else { result1 = '' }
                return <span>{result1}</span>;
            case "ECD":
                return displayECD(value, "DD MMM YYYY");
            case "checkbox":
                return <div className={"text-center align-middle"}>
                    <a href={"javascript:void(0)"} onClick={() => this.selectRecord(value, order.ordNum)}>
                        <i className={classnames("fa", "fa-lg", {
                            "fa-check-square-o": value,
                            "fa-square-o": !value,
                        }
                        )} />

                    </a>
                </div>;
            case "productDetails":
                return <a href='javascript:void(0)'
                    onClick={this.openJobInfoModal.bind(this, order, this.props.type, hasOrderGatewayReadonlyPrivilege, "productLink")}>{this.getProductString(order.orderDetails)}
                </a>;
            case "action":
                return <div className={"d-flex justify-content-between"}>
                    {
                        this.props.type !== 'IMP' ?
                            (!order.reworkStatus && order.isRework && order.salesOrderJobStatusId >= 5 && order.salesOrderJobStatusId < 45) ?
                                <Button color={"info"} outline={true} size={"sm"} disabled={!this.state.canApproveRework} onClick={() => this.openApproveWarrantyModal(order, index)}>
                                    <i className="fa fa-refresh" aria-hidden="true"></i> Approve Rework
                                </Button>
                                :
                                <ButtonGroup>
                                    {!order.overrideHold && hasDebtorInvoiceCreditHoldReleasePrivilege && order.exceedsCreditLimit ?
                                        <Button color={"success"}
                                            outline={true}
                                            disabled={order.isLoadingStatusUpdate === "Override" || hasOrderGatewayReadonlyPrivilege}
                                            size={"sm"}
                                            onClick={() => this.updateOrderOverrideHold(order.ordNum, index, true, "Override")}>
                                            {order.isLoadingStatusUpdate && order.isLoadingStatusUpdate === "Override" ?
                                                <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                                : <i className="fa fa-life-ring mr-2" aria-hidden="true" />
                                            }
                                            {order.isLoadingStatusUpdate === "Override" ? "Overriding" : "Override"}
                                        </Button> : null}
                                    {!order.exceedsCreditLimit || order.overrideHold ?

                                        <Button color={"success"}
                                            outline={true}
                                            disabled={order.isLoadingStatusUpdate === "Make"}
                                            size={"sm"} onClick={() => this.checkBulkOrder(order, index)}>
                                            {order.isLoadingStatusUpdate && order.isLoadingStatusUpdate === "Make" ?
                                                <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                                : <i className="fa fa-industry mr-2" aria-hidden="true" />
                                            }
                                            {order.isLoadingStatusUpdate === "Make" ? "Processing Request" : "Make"}
                                        </Button>
                                        : null
                                    }
                                    {order.overrideHold && hasDebtorInvoiceCreditHoldReleasePrivilege && order.exceedsCreditLimit ?
                                        <Button color={"danger"}
                                            outline={true}
                                            disabled={order.isLoadingStatusUpdate === "Remove Override" || hasOrderGatewayReadonlyPrivilege}
                                            size={"sm"}
                                            onClick={() => this.updateOrderOverrideHold(order.ordNum, index, false, "Remove Override")}>
                                            {order.isLoadingStatusUpdate && order.isLoadingStatusUpdate === "Remove Override" ?
                                                <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                                : <i className="fa fa-life-ring mr-2" aria-hidden="true" />
                                            }
                                            {order.isLoadingStatusUpdate === "Remove Override" ? "Removing" : "Remove Override"}
                                        </Button>
                                        : null}
                                </ButtonGroup>
                            :
                            <>
                                <Link
                                    className={classnames("btn", "btn-sm", "btn-primary", {
                                        "disabled": this.state.loading || hasOrderGatewayReadonlyPrivilege || order.salesOrderJobStatusId > 30
                                    })}
                                    to={"/sales/order/create/product-builder/v1?orderNum=" + order.ordNum}>
                                    <i className="fa fa-external-link mr-2" aria-hidden="true" />Open in Product Builder
                                </Link>
                                <ButtonGroup>
                                    <Button color={"info"}
                                        outline={true}
                                        disabled={this.state.loading || hasOrderGatewayReadonlyPrivilege || order.salesOrderJobStatusId > 30}
                                        size={"sm"}
                                        onClick={() => this.handleClearImportedItems(order.ordNum)}>
                                        Clear Imported Items
                                    </Button>
                                    <Button color={"info"}
                                        outline={true}
                                        disabled={this.state.loading || hasOrderGatewayReadonlyPrivilege || order.salesOrderJobStatusId > 30}
                                        size={"sm"}
                                        onClick={() => this.handleClearAndReImport(order.ordNum)}>
                                        Clear & Re-Import
                                    </Button>
                                </ButtonGroup>
                            </>
                    }
                    <ButtonGroup>
                        {
                            (isUncancelAllowed && this.props.type === 'IMP' && order.salesOrderJobStatusId === 1) ?
                                <Link
                                    className={classnames("btn", "btn-sm", "btn-primary", {
                                        "disabled": this.state.loading || hasOrderGatewayReadonlyPrivilege
                                    })}
                                    to={"/sales/order/create/product-builder/v1?isUnCancelOrder=true&orderNum=" + order.ordNum}
                                >
                                    <i className="fa fa-undo mr-2" aria-hidden="true" />Un-Cancel
                                </Link>
                                :
                                <Button color={"danger"}
                                    outline={true}
                                    disabled={this.state.loading || (this.props.type === 'IMP' && order.salesOrderJobStatusId > 30) || order.isLoadingStatusUpdate === "Rejected" || hasOrderGatewayReadonlyPrivilege}
                                    size={"sm"} onClick={() => this.toggleCancelModal(true, index, order)}>
                                    {order.isLoadingStatusUpdate && order.isLoadingStatusUpdate === "Rejected" ?
                                        <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                        : <i className="fa fa-ban mr-2" aria-hidden="true" />
                                    }
                                    {order.isLoadingStatusUpdate === "Rejected" ? "Cancelling" : "Cancel"}
                                </Button>
                        }
                    </ButtonGroup>
                </div>;
                break;
            case "notes":
                if (value) {
                    return <div><a href='javascript:void(0)' id={`notes-${index}`}
                        onClick={this.toggleInternalNoteInfoModel.bind(this, value, true)}>{value.substring(0, 50)}</a>
                        <UncontrolledTooltip placement="right" target={`notes-${index}`}>
                            {"Click here to view note"}
                        </UncontrolledTooltip>
                    </div>;
                }
                break;
            case "importStatusAndNotes":
                let badgeColor = "";
                switch (order.importStatusTypeID) {
                    case 1:
                        badgeColor = "primary";
                        break;
                    case 2:
                        badgeColor = "warning";
                        break;
                    case 3:
                        badgeColor = "danger";
                        break;
                    case 8:
                        badgeColor = "info";
                        break;
                    case 10:
                        badgeColor = "success";
                        break;
                    default:
                        badgeColor = "primary";
                        break;
                }
                return (<div>
                    <Badge className="cursor-pointer" color={badgeColor} title={order.importSalesOrderStatus + ' | ' + order.importStatusType}>{order.importSalesOrderStatus}</Badge>
                    {value ?
                        <a href='javascript:void(0)' className='ml-2 text-decoration-none'
                            onClick={() => this.toggleImportNotesModal(value, true)}>
                            Notes
                        </a>
                        : null}
                </div>);
                break;
            case "importSourceOrderID":
                return <small>{value}</small>;
            default:
                return <span>{value}</span>
        }
    }

    openProductGroupModal(order) {
        this.setState({ selectedOrderForProdGroup: order, isOpenProductGroupModal: true });
    }

    toggleProductGroupModal(change) {
        this.setState({ isOpenProductGroupModal: change });
    }

    toggleInternalNoteInfoModel(value, change) {
        this.setState({ isOpenInternalNoteInfoModal: change, selectedInternalNote: value });
    }

    toggleImportNotesModal(value, change) {
        this.setState({ isOpenImportNotesModal: change, selectedImportNotes: value });
    }

    getProductString(orderDetails) {
        let productsString = [];
        orderDetails.map((item) => {
            //productsString.push(item.product);
            productsString.push(item.product + " (" + item.qty + ") ");
        });

        return productsString.toString();
    }

    selectRecord(val, OrdNum) {
        let { orders } = this.state;
        const elementsIndex = orders.response.records.findIndex(x => x.ordNum === OrdNum);
        orders.response.records[elementsIndex].selected = !val;
        let counter = 0;
        for (let i in orders.response.records) {
            if (orders.response.records[i].selected) {
                counter++;
            }
        }
        if (counter === orders.response.records.length) {
            this.setState({ allRecordsSelected: true, selectedCheckboxes_count: counter, orders });
        }
        else {
            this.setState({ allRecordsSelected: false, selectedCheckboxes_count: counter, orders });
        }
    }

    selectAllRecords() {
        let self = this;
        let { orders } = this.state;
        orders.response.records.forEach(function (el) {
            el.selected = !self.state.allRecordsSelected;
        });
        let counter = 0;
        for (let i in orders.response.records) {
            if (orders.response.records[i].selected) {
                counter++;
            }
        }
        self.setState({ orders, allRecordsSelected: !self.state.allRecordsSelected, selectedCheckboxes_count: counter });
    }

    openJobInfoModal(order, type, hasOrderGatewayReadonlyPrivilege, clickType) {
        let { orders } = this.state;
        this.setState({ clickType: clickType });
        const elementsIndex = orders.response.records.findIndex(x => x.ordNum === order.ordNum);

        if (!hasOrderGatewayReadonlyPrivilege && type === "PEN" && clickType === "status") {
            orders.response.records[elementsIndex].selected = true;
            this.setState({ orders, isOpenMultipleOrderStatusChangeModal: true });
        }
        else if (['IMP', 'PEN', 'PROD', 'DISP'].includes(type)) {
            this.setState({ selectedOrder: order, isOpenJobInfoModal: true });
        }
    }

    toggleJobInfoModal(change) {
        this.setState({ isOpenJobInfoModal: change });
    }

    toggleEDILinkingModal(change) {
        if (change) {
            this.setState({ isOpenEDILinkingModal: change });
        } else {
            this.setState({ selectedOrderIndex: null, selectedOrder: null, isOpenEDILinkingModal: change });
        }
    }

    checkBulkOrder(order, orderIndex) {
        if (order.isBulkOrder) {
            this.setState({ selectedOrderIndex: orderIndex, selectedOrder: order }, () => this.toggleEDILinkingModal(true));
        } else {
            this.takeActionOnOrder(order.ordNum, orderIndex, "Make");
        }
    }

    handleCloseModal(data, isRefresh) {
        this.setState({ isOpenJobInfoModal: data });
        if (isRefresh) {
            this.getOrders(this.state.orders);
        }
    }

    handleCloseEDILinkingModal(change, isRefresh) {
        this.toggleEDILinkingModal(change);
        if (isRefresh) {
            this.getOrders(this.state.orders);
        }
    }

    handleCloseOrderStatusChangeModal(data, isRefresh) {
        this.setState({ isOpenOrderStatusChangeModal: data });
        if (isRefresh) {
            this.getOrders(this.state.orders);
        }
    }

    handleCloseMultipleOrderStatusChangeModal(data, isRefresh) {
        this.setState({ isOpenMultipleOrderStatusChangeModal: data });
        if (isRefresh) {
            this.getOrders(this.state.orders);
        }
    }

    toggleCancelModal(change, index, order) {
        this.setState({ showConfirmCancel: change, selectedOrderIndex: index, selectedOrder: order });
    }

    handleOrderCancellation() {
        let { selectedOrder, selectedOrderIndex } = this.state;
        this.takeActionOnOrder(selectedOrder.ordNum, selectedOrderIndex, "Rejected");
        this.toggleCancelModal(false, null, {});
    }

    takeActionOnOrder(ordNum, orderIndex, option) {
        let { orders, salesOrderJobStatuses } = this.state;
        orders.response.records[orderIndex].isLoadingStatusUpdate = option;
        this.setState({ orders });

        SalesOrderService.updateKeywaySalesOrder(ordNum, option).then(response => {
            orders.response.records[orderIndex].isLoadingStatusUpdate = "";
            this.setState({ orders });
            this.refresh();
            if (option === "Rejected") {
                toast.success("Order Cancelled!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.success(salesOrderJobStatuses.statusesById[response.data.salesOrderJobStatusId].statusDescription === "Credit Hold" ? "Order " + ordNum + " has been placed on credit hold due to exceeding credit limit."
                    : "Order " + ordNum + " has been sent to the production team.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }).catch(error => {
            orders.response.records[orderIndex].isLoadingStatusUpdate = "";
            this.setState({ orders });
            console.log(error);
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    updateOrderOverrideHold(ordNum, orderIndex, value, option) {
        let { orders } = this.state;
        orders.response.records[orderIndex].isLoadingStatusUpdate = option;
        this.setState({ orders });
        this.orderService.updateOrderOverrideHold(ordNum, value).then(response => {
            orders.response.records[orderIndex].isLoadingStatusUpdate = "";
            this.setState({ orders });
            toast.success(value ? "Order has been overridden" : "Order has been removed from override hold");
            this.refresh();
        }).catch(error => {
            orders.response.records[orderIndex].isLoadingStatusUpdate = "";
            this.setState({ orders });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }

    handleChange(change, key) {
        let { orders } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (orders.request.sortRequest.key === change) {
                    orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                } else {
                    orders.request.sortRequest.key = change;
                    orders.request.sortRequest.direction = false;
                }
                searchParams.set("sortRequestKey", change);
                searchParams.set("sortRequestDirection", orders.request.sortRequest.direction);

                this.getOrders(orders);
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                searchParams.set("pageSize", change);
                this.getOrders(orders);
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                searchParams.set("currentPage", change);
                this.getOrders(orders);
                break;
            case "consignNum":
                orders.request.filterRequest[key] = change;
                orders.request.pageRequest.currentPage = 1;
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                this.getOrders(orders);
                break;
            case "salesOrderJobStatusId":
                orders.request.filterRequest[key] = parseInt(change);
                orders.request.pageRequest.currentPage = 1;
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                if (change) {
                    searchParams.set("salesOrderJobStatusId", parseInt(change));
                }
                else {
                    searchParams.delete('salesOrderJobStatusId');
                }
                this.getOrders(orders);
                break;
            case "branch":
                if (change === 'All') {
                    change = null;
                }
                orders.request.filterRequest[key] = parseInt(change);
                orders.request.pageRequest.currentPage = 1;
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                searchParams.set("branch", parseInt(change));
                this.getOrders(orders);
                break;
            default:
                orders.request.filterRequest[key] = change;
                orders.request.pageRequest.currentPage = 1;
                if (key === "repCode") {
                    if (change === 'ALL') {
                        orders.request.filterRequest[key] = 0;
                        change = 0;
                    }
                }
                if (key === "courier") {
                    if (change === 'All') {
                        orders.request.filterRequest[key] = "";
                        change = "";
                    }
                }
                searchParams.set(key, change);
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                this.setState({ orders });
                this.getOrders(orders);
        }

        if (key === 'startDate') {
            searchParams.set('startDate', getDateString(orders.request.filterRequest.startDate, "DD/MM/YYYY"))
        }

        if (key === 'endDate') {
            searchParams.set('endDate', getDateString(orders.request.filterRequest.endDate, "DD/MM/YYYY"))
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);

        this.props.handleFilterFromParent(orders.request.filterRequest);
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.ordNum.toString().includes(searchText);

            if (!flag && item.ordTotal) {
                flag = item.ordTotal.toString().includes(searchText)
            }
            if (!flag && item.custOrdNum) {
                flag = item.custOrdNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.consignNum) {
                flag = item.consignNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.couriers) {
                flag = item.couriers.toLowerCase().includes(searchText)
            }
            if (!flag && item.ordDate) {
                flag = getDateString(item.ordDate, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.shipDate) {
                flag = getDateString(item.shipDate, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    getStore({ filterRequest }) {
        let type = this.props.type;
        let { hasOrderGatewayReadonlyPrivilege } = this.state;
        return [
            {
                key: "selected",
                label: <div className={"text-center"}><a href="javascript:void(0)" onClick={this.selectAllRecords}>
                    {this.state.allRecordsSelected ?
                        <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                        <i className="fa fa-lg fa-square-o" aria-hidden="true" />
                    }
                </a></div>,
                type: "checkbox",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: type === 'PEN' && !hasOrderGatewayReadonlyPrivilege,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "ordNum",
                label: "Order No",
                type: "link",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "ordDate",
                label: "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "required",
                label: "Required Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: type !== 'IMP',
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "productionReadyDate",
                label: "ECD Date",
                type: "ECD",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: type === 'PROD',
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "invPrinted",
                label: "Invoiced Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: type === "DISP",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "shipDate",
                label: "Shipping Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: type === "DISP",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            /*{
                key: "statusID",
                label: "Status",
                type: "statusByID",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"select"}
                           name={"statusID"}
                           value={filterRequest.statusID}
                           onChange={(e) => this.handleChange(e.target.value, "statusID")}>
                    </Input>
                </div>
            },*/
            {
                key: "salesOrderJobStatusDescription",
                label: "Status",
                type: "salesOrderJobStatusId",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: type !== 'IMP',
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "accountID",
                label: "Account",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "delCompany",
                label: "Company Name",
                type: "delCompany",
                colSpan: 1,
                minWidth: 170,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "jobRef",
                label: "Job Ref.",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: type !== "PEN" && type !== "PROD",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "cancelReason",
                label: "Cancel Reason",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: type === "CAN",
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "custOrdNum",
                label: "Cust. Ord. Ref.",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: (type !== "CAN" && type !== "DISP"),
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "orderDetails",
                label: "Stock Group(s)",
                type: "productDetails",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                showColumn: ['IMP', 'PEN', 'PROD', 'DISP'].includes(type),
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "importSalesOrderSource",
                label: "Source",
                type: "default",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                showColumn: type === 'IMP',
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left bg-secondary text-white",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "importSourceOrderID",
                label: "Order ID",
                type: "importSourceOrderID",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: type === 'IMP',
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left bg-secondary text-white",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "createdDate",
                label: "Import Date",
                type: "date",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                showColumn: type === 'IMP',
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left bg-secondary text-white",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "importNotes",
                label: "Import Status / Warnings",
                type: "importStatusAndNotes",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: type === 'IMP',
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left bg-secondary text-white",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "notes",
                label: "Notes",
                type: "notes",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                showColumn: type === "PEN",
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
            },
            {
                key: "rep",
                label: "Rep",
                type: "delCompany",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: type === "PROD" || type === "DISP",
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "couriers",
                label: "Delivery Method",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: type === "DISP",
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "consignNum",
                label: "Connote#",
                type: "consignNum",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: type === "DISP",
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "days_Since_Last_Activity",
                label: <Label><span>Days Since</span><br /> <span>Last Active</span></Label>,
                type: "days_Since_Last_Activity",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: type === "QUOTE",
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "ordTotal",
                label: "Order Value",
                type: "currency",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                showColumn: type !== 'IMP',
                valueClassName: "align-middle text-right",
                labelClassName: "text-left align-middle",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: type === 'IMP' ? 600 : 350,
                sorterApplicable: false,
                showColumn: ['IMP', 'PEN'].includes(type),
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                extendedColumn: false,
                searchNode: null
            },
        ];
    }

    toggleExtendedColumn(isHiddenExtendedColumn) {
        this.setState({ isHiddenExtendedColumn });
    }

    clearProductSummaryfilter() {
        let { orders } = this.state;
        orders.request.filterRequest.isProductSummarySearched = false;
        orders.request.filterRequest.productName = "";
        orders.request.filterRequest.productId = null;
        orders.request.filterRequest.debtorCategoryId = 0;
        orders.request.filterRequest.debtorCategoryLabel = "";

        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("isProductSummarySearched");
        searchParams.delete("productName");
        searchParams.delete("debtorCategoryId");
        searchParams.delete("debtorCategoryLabel");
        if (orders.request.filterRequest.accountID === "01500") {
            orders.request.filterRequest.accountID = "";
            searchParams.delete("accountID");
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);

        this.setState({ orders }, () => this.getOrders(orders));
    }

    handleProductChange(val) {
        let { orders } = this.state;
        orders.request.filterRequest.isProductSummarySearched = true;
        orders.request.filterRequest["productName"] = val.name;
        orders.request.filterRequest["productId"] = val.productId;
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("isProductSummarySearched", true);
        searchParams.set("productName", val.name);

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        this.setState({ orders }, () => this.getOrders(orders));
    }

    handleCategoryChange(val, Label) {
        let { orders } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        if (Label !== "Bunnings") {
            if (orders.request.filterRequest.accountID !== '' && orders.request.filterRequest.accountID === '01500') {
                orders.request.filterRequest.accountID = '';
                searchParams.delete("accountID");
            }
            orders.request.filterRequest.isProductSummarySearched = true;
            orders.request.filterRequest.debtorCategoryId = val;
            orders.request.filterRequest.debtorCategoryLabel = Label;

            searchParams.set("isProductSummarySearched", true);
            searchParams.set("debtorCategoryId", val);
            searchParams.set("debtorCategoryLabel", Label);

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        } else {
            orders.request.filterRequest.accountID = val;
            orders.request.pageRequest.currentPage = 1;
            searchParams.set('accountID', val);
            searchParams.set("currentPage", orders.request.pageRequest.currentPage);
        }
        this.setState({ orders }, () => this.getOrders(orders));
    }

    handleButtonChange(val) {
        let counter = 0;
        let { orders } = this.state;
        let OrdersArr = orders.response.records;
        for (let i in OrdersArr) {
            if (OrdersArr[i].selected) {
                counter++;
                break;
            }
        }
        if (counter === 0) {
            toast.warn("No record selected !", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            let ordernumbers = "";
            let toastValue = "";
            switch (val) {
                case "Approve":
                    toastValue = "Approved";
                    break;
                case "Reject":
                    toastValue = "Rejected";
                    break;
                case "Cancel":
                    toastValue = "Canceled";
                    break;
            }
            OrdersArr.forEach(function (item, index) {
                if (item.selected) {
                    ordernumbers += item.ordNum + ","
                }
            });

            this.orderService.updateGatewayOrders(ordernumbers, val).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Order's " + toastValue + " successfully !", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.refresh();
                }
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    printOrderSummaryInExcel = () => {
        let { orders } = this.state;

        let request = cloneDeep(orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }

        this.setState({ downloading: true });
        this.orderService.exportAllGatewayOrders(request, this.props.type).then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "ORDER-SUMMARY" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            console.log(error.data)
        });
    };

    updateSelectedOrderStatusToAWPR() {
        let { orders, salesOrderJobStatuses } = this.state;
        let ordersClone = cloneDeep(orders.response.records);
        this.setState({ isLoadingMakeButton: true });
        let creditHoldOrdNums = '';
        let selectedOrderRecord = (ordersClone || []).filter(x => x.selected);
        //set salesOrderStatus to "Awaiting Production"
        let statusIndex = salesOrderJobStatuses.statuses.findIndex(x => x.statusCode === 'AWPR');
        (selectedOrderRecord || []).forEach(item => {
            item.salesOrderJobStatusId = salesOrderJobStatuses.statuses[statusIndex].id;
        });

        //for check if selected order array conatin all credit hold orders
        if (selectedOrderRecord.findIndex(x => !x.exceedsCreditLimit || x.overrideHold) === -1) {
            toast.error("Order status couldn't be updated due to order is on credit hold.");
            this.setState({ isLoadingMakeButton: false });
            return;
        }

        this.orderService.changeOrderStatusForMultipleOrders(selectedOrderRecord).then(response => {
            creditHoldOrdNums = (response.data || []).filter(x => x.exceedsCreditLimit && !x.overrideHold).map(x => x.ordNum).join();
            toast.success(creditHoldOrdNums !== "" ? "Order status changed successfully!, but " + creditHoldOrdNums + " order has exceeded the credit limit and placed on credit hold." : "Order status changed successfully!");
            this.refresh();
            this.setState({ isLoadingMakeButton: false });
        }).catch(error => {
            this.setState({ isLoadingMakeButton: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }

    openApproveWarrantyModal(data) {
        this.setState({ checkWarrantyModalData: data, isCheckWarrantyModalOpen: true })
    }

    toggleApproveWarrantyModal(data) {
        this.setState({ isCheckWarrantyModalOpen: data });
    }

    render() {
        let { orders, loading, isLoadingMakeButton, searchText, isOpenOrderItemsModal, isOpenOrderStatusChangeModal, selectedOrderForProdGroup, isOpenMultipleOrderStatusChangeModal, salesOrderJobStatuses, highlightedOrder, downloading, isOpenJobInfoModal, isOpenEDILinkingModal, selectedOrder, selectedOrderIndex, selectedCheckboxes_count, checkWarrantyModalData, isCheckWarrantyModalOpen, showConfirmCancel, userRole, isHiddenExtendedColumn } = this.state;
        let { pageRequest, sortRequest, filterRequest } = orders.request;
        let store = this.getStore(orders.request);
        let type = this.props.type;
        let totalOrdervalue = 0;
        return (
            <div>
                <div className="mb-2">
                    <SearchBannerGatewayOrder
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                        salesOrderJobStatuses={salesOrderJobStatuses}
                        type={type}
                        branches={this.props.branches}
                        reps={this.props.reps}
                    />
                </div>

                {['IMP', 'PEN', 'PROD', 'DISP'].includes(type) ?
                    <div>
                        <Alert color="secondary">
                            <div className="pb-2">
                                <OrderProductGrpDetails
                                    handleChange={this.handleProductChange}
                                    clearChange={this.clearProductSummaryfilter}
                                    type={type}
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                />
                            </div>

                            <div className="border-top border-light pt-2">
                                <DebtorCategoryBucket
                                    handleChange={this.handleCategoryChange}
                                    clearChange={this.clearProductSummaryfilter}
                                    type={type}
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                />
                            </div>

                        </Alert>
                    </div>
                    : null
                }
                {loading ?
                    <span className="text-right ml-3">
                        <Spinner size={"sm"} color={"primary"} />
                        {orders.request.filterRequest.isProductSummarySearched ? this.LoadingorderQuery() : null}
                    </span>
                    :
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div className={"text-left ml-3"}>
                            <p>Showing
                                {' '} {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                {' '}of {orders.response.totalRecords}
                                {' '}entries</p>
                        </div>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className={"text-right"}>
                                <Button color={"success"} size={"sm"} className={'mr-2'} outline={true}
                                    title={'Click here to export orders'}
                                    onClick={this.printOrderSummaryInExcel} disabled={downloading}>Export
                                    {downloading
                                        ? <Spinner size="sm"
                                            className={"ml-2"}
                                            color={"success"} />
                                        : <i className="fa fa-file-excel-o ml-2" aria-hidden="true" />}
                                </Button>
                                {selectedCheckboxes_count > 0 ?
                                    <span>
                                        <Button color="primary" size={"sm"} className={'mr-2'}
                                            onClick={this.toggleMultipleOrderStatusChangeModal}>
                                            Update Status
                                        </Button>
                                        <Button color={"success"}
                                            outline={true} size={"sm"} disabled={isLoadingMakeButton}
                                            onClick={this.updateSelectedOrderStatusToAWPR}>
                                            {isLoadingMakeButton ?
                                                <Spinner size={"sm"} color="primary" className={"mr-2"} /> :
                                                <i className="fa fa-industry mr-2" aria-hidden="true" />}
                                            {isLoadingMakeButton ? "Processing Request" : "Make Selected"}
                                        </Button>
                                    </span>
                                    : null
                                }
                                {type === "IMP" ?
                                    <Button color={"primary"}
                                        size={"sm"}
                                        outline={isHiddenExtendedColumn}
                                        title={isHiddenExtendedColumn ? 'Show Extended Columns' : 'Hide Extended Columns'}
                                        onClick={() => this.toggleExtendedColumn(!isHiddenExtendedColumn)}>
                                        <i className={classnames("fa", "mr-2", {
                                            "fa-eye": isHiddenExtendedColumn,
                                            "fa-eye-slash": !isHiddenExtendedColumn,
                                        })} aria-hidden="true" />
                                        {isHiddenExtendedColumn ? "Show Extended Columns" : "Hide Extended Columns"}
                                    </Button>
                                    : null}
                            </div>
                        </Col>
                    </div>
                }
                {/*<Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <InputGroup className={"mb-2"}>
                                <InputGroupAddon addonType="prepend">
                                    <Button color={"primary"}
                                        size={"sm"}
                                        onClick={() => this.toggleOrderItemsModal(true)}
                                        style={{ zIndex: '1', position: 'relative' }}>
                                        <i className="fa fa-search mr-1 ml-1" aria-hidden="true" />
                                    </Button>
                                    {*//*<InputGroupText>
                                        <i className="fa fa-search"
                                            aria-hidden="true" />
                                    </InputGroupText>*//*}
                                </InputGroupAddon>
                                <Input
                                    type={"text"}
                                    name={"searchText"}
                                    placeholder={"Search..."}
                                    value={searchText}
                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                />
                            </InputGroup>
                        </div>
                    </Col>*/}
                <div>
                    <Table hover bordered size={"sm"} striped responsive>
                        <thead>
                            <tr>
                                {(store || []).filter(item => (item.showColumn ? true : false) && (isHiddenExtendedColumn ? !item.extendedColumn : true)).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>

                            {(orders.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, i) => {
                                totalOrdervalue = totalOrdervalue + order.ordTotal;
                                return (
                                    <tr key={i}
                                        id={highlightedOrder.ordNum === order.ordNum ? "Order" + highlightedOrder.ordNum : null}>

                                        {(store || []).filter(item => (item.showColumn ? true : false) && (isHiddenExtendedColumn ? (!item.extendedColumn) : true)).map((storeItem, index) => {
                                            return (
                                                <td key={index} className={storeItem.valueClassName}>
                                                    {this.getColValue(order[storeItem.key], storeItem, order, i)}
                                                </td>
                                            );
                                        })}

                                    </tr>
                                );
                            })}
                            {this.props.type === "DISP" ?
                                <tr>
                                    <td colSpan={13} className={"text-right"}><b>
                                        Total Order value</b></td>
                                    <td className={"text-right"}>
                                        <b>
                                            <NumberFormat value={totalOrdervalue}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}
                                                prefix={'$'} />
                                        </b>
                                    </td>
                                </tr> : null}
                        </tbody>
                    </Table>

                    {
                        /*// remove approve For now
                        { type === "PEN" ?
                            <Row>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        <AcceptOrRejectOrder handleChange={this.handleButtonChange} />
                                    </div>
                                </Col>
                            </Row>
                            : null
                        }*/
                    }

                    {!loading ?
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={orders.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                        :
                        null
                    }

                    {
                        isOpenOrderItemsModal
                            ? <OrderGatewayOrderItemsModal
                                isOpen={isOpenOrderItemsModal}
                                toggle={this.toggleOrderItemsModal}
                                orderNums={this.state.ordernumbers}
                            />
                            : null
                    }

                    {selectedOrder ?

                        <OrderInformationModal ordNum={selectedOrder.ordNum}
                            isOpen={isOpenJobInfoModal}
                            toggle={this.toggleJobInfoModal}
                            handlerCloseModal={this.handleCloseModal}
                            clickType={this.state.clickType}
                            type={this.state.type}
                            order={selectedOrder}
                            salesOrderJobStatuses={salesOrderJobStatuses}
                            userRole={userRole}
                        />

                        : null}

                    {
                        selectedOrder ?
                            <EDILinkingModal
                                ordNum={selectedOrder.ordNum}
                                accountID={selectedOrder.accountID}
                                takeActionOnOrder={this.takeActionOnOrder}
                                orderIndex={selectedOrderIndex}
                                isOpen={isOpenEDILinkingModal}
                                toggle={this.toggleEDILinkingModal}
                                handleCloseModal={this.handleCloseEDILinkingModal}
                                userRole={userRole}
                            />
                            : null
                    }

                    {
                        selectedOrder
                            ? <OrderStatusChangeModal
                                isOpen={isOpenOrderStatusChangeModal}
                                toggle={this.toggleOrderStatusChangeModal}
                                order={selectedOrder}
                                salesOrderJobStatuses={salesOrderJobStatuses}
                                handlerCloseModal={this.handleCloseOrderStatusChangeModal}
                            />
                            : null
                    }

                    {isOpenMultipleOrderStatusChangeModal ?
                        <MultipleOrderStatusChangeModal
                            isOpen={isOpenMultipleOrderStatusChangeModal}
                            toggle={this.toggleMultipleOrderStatusChangeModal}
                            orders={orders.response.records}
                            salesOrderJobStatuses={salesOrderJobStatuses}
                            handlerCloseModal={this.handleCloseMultipleOrderStatusChangeModal}
                            selectedCheckboxesCount={selectedCheckboxes_count}
                        /> : null}

                    <SalesOrderItemProductDetailModal
                        order={selectedOrderForProdGroup}
                        isOpen={this.state.isOpenProductGroupModal}
                        toggle={this.toggleProductGroupModal} />

                    {this.state.isOpenInternalNoteInfoModal ?
                        <ViewDebtorNoteModal
                            isOpen={this.state.isOpenInternalNoteInfoModal}
                            toggle={this.toggleInternalNoteInfoModel}
                            selected={this.state.selectedInternalNote} />
                        : null
                    }
                    {this.state.isOpenImportNotesModal ?
                        <ImportNotesModal
                            isOpen={this.state.isOpenImportNotesModal}
                            toggle={this.toggleImportNotesModal}
                            selected={this.state.selectedImportNotes} />
                        : null
                    }
                    {
                        isCheckWarrantyModalOpen ?
                            <CheckWarrantyForReworkModal
                                selectedData={checkWarrantyModalData}
                                isOpen={isCheckWarrantyModalOpen}
                                toggle={this.toggleApproveWarrantyModal}
                                refreshAgain={this.refresh}
                            /> : null
                    }

                </div>

                <ConfirmModal
                    isOpen={showConfirmCancel}
                    toggle={this.toggleCancelModal}
                    handleSubmit={() => this.handleOrderCancellation()}
                    handleCancel={() => this.toggleCancelModal(false, null, {})}
                    primaryMessage={"Are you sure you wish to cancel?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    type="Cancelling..."
                />

                <ToastContainer />
            </div>
        );
    }
}