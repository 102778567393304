import React, { Component } from 'react';
import FileSaver from 'file-saver';
import {
    getLocalDateStringFromUtcDateString, findIndex, getDateString,
    handleErrorMessage
} from '../../services/CommonService';
import {
    Table,
    Card,
    CardBody,
    CardHeader,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Spinner,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import catalogueService from "../../services/CatalogueService";
import classnames from 'classnames';
import ProductCatalogueUploadModal from '../../components/modal/ProductCatalogueUploadModal';
import cloneDeep from 'lodash/cloneDeep';
import ServiceToPostFiles from '../../services/ServiceToPostFiles/ServiceToPostFiles';
import queryString from 'query-string';
import ConfirmModal from '../../components/modal/ConfirmModal';

const allTabs = [
    {
        label: "Product Brochure",
        key: "productBrochure"
    },
    {
        label: "Order Forms",
        key: "productOrderForms"
    }
];

export default class ManageCatalogues extends Component {
    constructor(props) {
        super(props);

        this.state = {
            catalogues: [],
            workingItem: {},
            isOpenWorkingItemModal: false,
            loading: {
                isUploadingAttachment: false,
                isUploadingBasicInfo: false,
                deleting: false,
                list: false

            },
            activeTab: 'productBrochure',
            activeModalTab: 'basicInfo',
            deleteItem: null,
            isOpenDeleteConfirmModal: false
        };
        this.serviceToPostFiles = new ServiceToPostFiles();
        this.onDocumentClickSuccess = this.onDocumentClickSuccess.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleWorkingItemModal = this.toggleWorkingItemModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitBasicInfo = this.handleSubmitBasicInfo.bind(this);
        this.toggleModalTab = this.toggleModalTab.bind(this);
        this.toggleCompleteConfirmModal = this.toggleCompleteConfirmModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    onDocumentClickSuccess(item) {
        item.downloading = true;
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    item.requestProgressPercentage = percent;
                    this.setState({ item });
                }
            }
        };
        item.requestProgressPercentage = 0;
        this.setState({ item });
        catalogueService.downloadAttachment(item.fileName, options, this.state.activeTab).then(response => {
            item.downloading = false;
            item.requestProgressPercentage = 0;
            this.setState({ item });
            FileSaver.saveAs(response.data, item.description);
        }).catch(error => {
            item.downloading = false;
            item.requestProgressPercentage = 0;
            this.setState({ item });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    componentDidMount() {
        let { activeTab } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            activeTab = searchParams.activeTab ? searchParams.activeTab : activeTab;
        }
        this.setState({ activeTab });
        this.refresh(false, activeTab);
    }


    delete(item) {
        let { loading } = this.state;
        loading.deleting = item.id;
        this.setState({ loading });
        catalogueService.delete(item.id).then(response => {
            loading.deleting = false;
            this.setState({ loading });

            toast.success("Deleted!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.refresh(true, this.state.activeTab);
        }).catch(error => {
            loading.deleting = false;
            toast.success(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ loading });
        });
    }

    refresh(silentLoading) {
        let { loading, catalogues, activeTab } = this.state;
        if (!silentLoading) {
            loading.list = true;
            this.setState({ loading });
        }
        catalogueService.list(activeTab).then(response => {
            loading.list = false;
            if (response.data) {
                catalogues = response.data;
            }
            this.setState({ catalogues, loading });
        }).catch(error => {
            loading.list = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    selectItem(item) {
        this.setState({ workingItem: cloneDeep(item), isOpenWorkingItemModal: true });
    }

    toggleWorkingItemModal(change) {
        this.setState({ isOpenWorkingItemModal: change, activeModalTab: 'basicInfo' });
    }

    handleChange(change, key) {
        let { workingItem } = this.state;
        switch (key) {
            default:
                workingItem[key] = change;
                this.setState({ workingItem });
                break;
        }
    }

    validateRequest(workingItem, activeTab) {
        if (!(workingItem.file)) {
            toast.error("File is missing!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        /*if (activeTab === 'productBrochure' && !(workingItem.snapshotFile)) {
            toast.error("Snapshot file is missing", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }*/

        if (!workingItem.id && !(workingItem.title)) {
            toast.error("Name/description is missing", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        return true;
    }

    handleSubmit() {
        let { workingItem, loading, catalogues, activeTab } = this.state;

        let isValid = this.validateRequest(workingItem, activeTab);

        if (!isValid) {
            return;
        }
        loading.isUploadingAttachment = true;
        this.setState({ loading });


        let data = new FormData();
        data.append('id', workingItem.id ? workingItem.id : 0);
        data.append('file', workingItem.file);
        //data.append('ScreenShotFile', workingItem.snapshotFile);

        if (workingItem.id) {
            let index = findIndex(catalogues, 'id', workingItem.id);
            data.append('description', catalogues[index] ? catalogues[index].title : '');
        }
        else {
            data.append('description', workingItem.title);
        }

        this.serviceToPostFiles.saveCatalogue(data, this.state.activeTab).then(response => {
            loading.isUploadingAttachment = false;
            this.setState({ loading, isOpenWorkingItemModal: false, activeModalTab: 'basicInfo' });
            toast.success("Success", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.refresh(true, this.state.activeTab);
        }).catch(error => {
            loading.isUploadingAttachment = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleSubmitBasicInfo() {
        let { workingItem, loading } = this.state;

        if (!(workingItem.title)) {
            toast.error("Name/description is missing", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }

        loading.isUploadingBasicInfo = true;
        this.setState({ loading });

        let req = {
            id: workingItem.id ? workingItem.id : 0,
            title: workingItem.title,
            type: this.state.activeTab,
        }

        catalogueService.saveCatalogueBasicInfo(req).then(response => {
            loading.isUploadingBasicInfo = false;
            this.setState({ loading, isOpenWorkingItemModal: false, activeModalTab: 'basicInfo' });
            toast.success("Success", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.refresh(true, this.state.activeTab);
        }).catch(error => {
            loading.isUploadingBasicInfo = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                this.refresh(false, this.state.activeTab)
            });
        }
    }

    toggleModalTab(tab) {
        if (this.state.activeModalTab !== tab) {
            this.setState({
                activeModalTab: tab
            });
        }
    }

    toggleCompleteConfirmModal(change, item) {
        this.setState({ isOpenDeleteConfirmModal: change, deleteItem: item });
    }
    handleDelete() {
        this.setState({ isOpenDeleteConfirmModal: false }, () =>
            this.delete(this.state.deleteItem));
    }

    render() {

        let { catalogues, isOpenWorkingItemModal, workingItem, loading, activeTab, activeModalTab, isOpenDeleteConfirmModal } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Product Uploads</BreadcrumbItem>
                </Breadcrumb>

                <div>
                    <Card>
                        <CardHeader>
                            <Nav tabs card>
                                {allTabs.map((tab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink
                                            className={classnames({ active: activeTab === tab.key })}
                                            onClick={() => {
                                                this.toggle(tab.key);
                                            }}>
                                            <Button style={{ textDecoration: "none" }}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;</Button>

                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <TabContent activeTab={activeTab}>
                                {
                                    allTabs.map((tab, index) => (
                                        <TabPane tabId={tab.key} key={index}>
                                            <div>
                                                {loading.list ? <Spinner color={"primary"} /> :
                                                    <Table responsive={true}
                                                        striped={true}
                                                        bordered={true}
                                                        hover={true}
                                                        size={"sm"}>
                                                        <thead>
                                                            <tr>
                                                                <th className={"text-left align-middle"}>S.No</th>
                                                                <th className={"text-left align-middle"}>Updated on</th>
                                                                <th className={"text-left align-middle"}>Updated by</th>
                                                                <th className={"text-left align-middle"}>Name</th>
                                                                <th className={"text-center align-middle"} >Edit</th>
                                                                <th className={"text-center align-middle"} >Download</th>
                                                                <th className={"text-center align-middle"}>
                                                                    <Button onClick={() => this.selectItem({})}
                                                                        color="primary"
                                                                        size={"sm"}>
                                                                        <i className={"fa fa-plus mr-2"} />New
                                                                    </Button></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (catalogues || []).map((item, index) => {
                                                                    return <tr>
                                                                        <td className={"text-left align-middle"}>{index + 1}</td>
                                                                        <td className={"text-left align-middle"}>
                                                                            {getDateString(item.lastModifiedOn && item.lastModifiedOn != '' ? item.lastModifiedOn : item.createdOn, 'DD MMM YYYY')}
                                                                        </td>
                                                                        <td className={"text-left align-middle"}>
                                                                            {item.lastModifiedByName && item.lastModifiedByName != '' ? item.lastModifiedByName : item.createdByName}
                                                                        </td>
                                                                        <td className={"text-left align-middle"}>{item.title}</td>
                                                                        <td className="text-center align-middle">
                                                                            <Button
                                                                                onClick={() => this.selectItem(item)}
                                                                                color="primary"
                                                                                size={"sm"}>
                                                                                <i className={"fa fa-pencil mr-2"} />Edit
                                                                            </Button>
                                                                        </td>
                                                                        <td className="text-center align-middle">
                                                                            <Button color="primary"
                                                                                size={"sm"}
                                                                                title="Click here to download"
                                                                                disabled={this.state.downloading}
                                                                                onClick={() => this.onDocumentClickSuccess(item)}>
                                                                                {item.downloading
                                                                                    ? <Spinner size="sm"
                                                                                        className={"mr-2"}
                                                                                        color={"primary"} />
                                                                                    : <i className="fa fa-download mr-2" />
                                                                                } Download

                                                                            </Button>
                                                                        </td>
                                                                        <td className="text-center align-middle">
                                                                            <Button onClick={() => this.toggleCompleteConfirmModal(true, item)}
                                                                                color="danger"
                                                                                size={"sm"}>
                                                                                <i className={classnames("mr-2", "fa", {
                                                                                    "fa fa-trash-o mr-2": (loading.deleting !== item.id) && item.isActive,
                                                                                    "fa-square-o": (loading.deleting !== item.id) && !item.isActive,
                                                                                    "fa-circle-o-notch fa-spin": (loading.deleting === item.id)
                                                                                }
                                                                                )} />
                                                                                Delete
                                                                            </Button>
                                                                        </td>

                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>
                                        </TabPane>
                                    ))}
                            </TabContent>
                        </CardBody>
                    </Card>


                    <ProductCatalogueUploadModal
                        type={this.state.activeTab}
                        isOpen={isOpenWorkingItemModal}
                        selected={workingItem}
                        toggle={this.toggleWorkingItemModal}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        handleSubmitBasicInfo={this.handleSubmitBasicInfo}
                        loading={loading}
                        activeTab={activeModalTab}
                        toggleModalTab={this.toggleModalTab}
                        onDocumentClickSuccess={this.onDocumentClickSuccess}
                    />
                    {
                        isOpenDeleteConfirmModal &&
                        <ConfirmModal
                            isOpen={isOpenDeleteConfirmModal}
                            toggle={this.toggleCompleteConfirmModal}
                            handleSubmit={() => this.handleDelete()}
                            handleCancel={() => this.toggleCompleteConfirmModal(false)}
                            primaryMessage={"Are you sure you want to delete this document?"}
                            secondaryMessage={""}
                            submitColor={"danger"}
                            cancelColor={"secondary"}
                            icon={"fa fa-trash-o fa-2x"}
                            type="Deleting..."
                        />
                    }

                </div>


                <ToastContainer />
            </div>
        );
    }
}