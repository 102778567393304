import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
//Pages
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/auth/LoginPage';
import RolePage from '../pages/auth/RolePage';
import UserPage from '../pages/UserPage';
import UserStyledInbox from '../pages/user/UserInbox';
import ManageUserInboxTemplate from '../pages/user/ManageUserInboxTemplate';
import UserManagePage from "../pages/UserManagePage";
import PurchaseHistoryPage from '../pages/PurchaseHistoryPage';
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import ProductionOutstandingOrdersPage from '../pages/production/ProductionOutstandingOrdersPage';
import ProductionScheduleDeschedulePage from "../pages/production/ProductionScheduleDeschedulePage";
import CRMLeadManagePage from '../pages/crm/CRMLeadManagePage';
import TaskManagePage from '../pages/crm/TaskManagePage';
import TasksPage from '../pages/crm/TasksPage';
import SalesQuotesPage from '../pages/sales/SalesQuotesPage';
import SalesQuotesCreatePage from '../deprecated/SalesQuotesCreatePage';
import SalesOrdersPage from '../pages/sales/SalesOrdersPage';
import SalesQuotesOverviewPage from '../pages/sales/Quotes/SalesQuotesOverviewPage';
import SalesCustomerOrders from '../pages/sales/SalesCustomerOrders';
import SalesOrderProductBuilder from '../pages/sales/order/create/productBuilderKeywayVersion/SalesOrderProductBuilder';
import SalesOrderSubmittedPage from '../components/sales/create/SalesOrderSubmittedPage';

import ContactsMasterPage from '../pages/Customer/ContactsMasterPage';
import AddressMasterPage from '../pages/Customer/AddressMasterPage';
import OutstandingPaymentPage from '../pages/debtor/OutstandingPaymentPage';
import RetailPageLinks from "../pages/CustomerRetail/RetailPageLinks";

import ComingSoonPage from '../pages/ComingSoonPage';

import UnAuthorized from '../pages/UnAuthorized';
import StockEnquiryPage from '../pages/stock/stockEnquiry/StockEnquiryPage';
import StockGroupsPage from '../pages/stock/StockGroupsPage';
import ProductGroupPage from '../pages/product/ProductGroupPage';
import ProductsPage from '../pages/product/ProductsPage';
import ProductConfigurationPage from '../pages/product/ProductConfiguration';
import ProductConfigurationOptionPage from '../pages/product/ProductConfigurationOptionPage';
import ProductConfigurationOptionSetPage from '../pages/product/ProductConfigurationOptionSetPage';
import ProductConfigurationFabricNewPage from '../pages/product/ProductConfigurationFabricNewPage';
import ProductConfigurationPricingMatrixPage from "../pages/product/ProductConfigurationPricingMatrixPage";
import StockRecommendation from '../pages/stock/StockRecommendation';
import AddressStandardPage from '../pages/address/AddressStandardPage';
import FreightRuleManagePage from '../pages/address/FreightRuleManagePage';
import PackagingHandlingChargesRuleManagePage from '../pages/shipment/PackagingHandlingChargesRuleManagePage';

import NewsLetterItems from '../pages/NewsLetter/NewsLetterItems';
import ManageCatalogues from '../pages/catalogues/ManageCatalogues';
import ProductUploads from '../pages/catalogues/ProductUploads';

import ProductionDashboard from '../pages/Dashboard/ProductionDashboard';

// --- Production ---
//import ExportMachineData from "../pages/production/machine-data/export/";


//---------Order -----------------
import OrderEnquiry from '../pages/sales/order/enquiry/OrderEnquiry';
import OrderGatewayPage from '../pages/sales/OrderGateway/OrderGatewayPage';
//Components
import ConvertLead from '../components/crm/ConvertLead';
import ReviewOrders from '../components/sales/review/ReviewOrders';
import DiscountTable from '../components/DiscountTable';
import Lead from '../components/crm/Leads';
import DebtorEnquiryPage from '../pages/debtor/DebtorEnquiryPage';
import MyAccountsPage from '../pages/Customer/MyAccountsPage';
import FAQPageList from '../pages/faq/FAQPageList';
import NewsLetterPageList from '../pages/NewsLetter/NewsLetterPageList';
import AddFAQ from '../pages/faq/AddFAQ';
import ManageFabrics from '../pages/fabric/Fabric';
import FabricsTab from '../pages/fabric/FabricsTabPage';
import FabricInfo from '../pages/fabric/FabricForm';
import ProductDiscountPage from '../components/product/ProductDiscountPage';
import CustomerProfile from '../components/user/UserProfile';
import MyOrders from '../components/dashboard/MyOrders';
import UploadFabricColors from '../pages/fabric/FabricColor';
import FabricBrochure from '../pages/fabric/FabricBrochure';
import FabricDetailedView from '../pages/fabric/FabricBrochureDetail';
import ViewFaqSuggestions from '../pages/faq/ViewFaqSuggestions';
import ImportFabricDataView from '../pages/fabric/FabricImport';
import StockPage from '../pages/stock/StockPage';
import CustomerAccountDetailsPage from '../pages/debtor/CustomerAccountsDetailsPage';
import SalesRepPage from '../pages/salesrep/SalesRepPage';
import ManageRep from '../pages/salesrep/ManageRep';
import ManageCustomerPage from '../pages/debtor/ManageCustomerPage';
import ManageDebtorContacts from '../pages/common/ManageContactsPage';
import ViewDetailedStatemnet from '../pages/debtor/ViewDetailedStatement';
import ViewDebtorReceipts from '../pages/debtor/ViewDebtorReceipts';
import DebtorsOutstandingQuotes from '../components/DebtorsQuotes/DebtorsOutstandingQuotes';
import ProductionDashboardSummaryCard from '../components/dashboard/production/ProductionDashboardSummaryCard';
//-----------------StockUnitType
import StockUnitTypePage from '../pages/StockUnitType/StockUnitTypePage';
import ManageStockUnitType from '../pages/StockUnitType/ManageStockUnitType';
import StockUnitPage from '../pages/StockUnit/StockUnitPage';
import ManageStockUnit from '../pages/StockUnit/ManageStockUnit';
//-----------------Creditors
import CreditorPage from '../pages/Creditor/CreditorPage';
import ManageCreditor from '../pages/Creditor/ManageCreditor';
//-----------------StockInventory
//-----------------WINO StockInventory
import StockInventoryPage from '../pages/StockInventory/StockInventoryPage';
import ManageStockInventoryPage from '../pages/StockInventory/ManageStockInventoryPage';
import ManageStockInventoryCost from '../pages/StockInventory/ManageStockInventoryCost';
import ManageStockInventoryPrice from '../pages/StockInventory/ManageStockInventoryPrice';
import ManageStockInventoryStatus from '../pages/StockInventory/ManageStockInventoryStatus';
import StockInventoryPriceHistoryPage from "../pages/StockInventory/StockInventoryPriceHistoryPage";
import ManageStockInventoryCostProduction from '../pages/StockInventory/ManageStockInventoryCostProduction';
import ManageStockInventoryCostLanded from '../pages/StockInventory/ManageStockInventoryCostLanded';
import StockInventoryCostProductionHistoryPage from '../pages/StockInventoryCostProductionHistoryPage';
import StockLandedCostHistoryPage from "../pages/StockInventory/StockLandedCostHistoryPage";
import StockInventoryTransactionPage from "../pages/StockInventoryTransaction/StockInventoryTransactionPage";
import StockSupplierPage from "../pages/StockSupplier/StockSupplierPage";
import ManageStockSupplierPage from "../pages/StockSupplier/ManageStockSupplierPage";

import ImportExportPage from "../pages/ImportExportPage";
//----------------Stock
import StocksPage from "../pages/stock/StocksPage";
import NonStocksPage from "../pages/nonStock/NonStocksPage";
import StockGroupPage from '../deprecated/StockGroupPage';
import StockKeepingUnits from '../pages/stock/StockKeepingUnits';
import StockAttribute from '../pages/stock/ManageStockAttributePage';
//--------------Stock-Take
import StockTakePage from '../pages/StockTakePage/StockTakePage';

import StockInventoryTransactionTypePage from '../pages/StockInventoryTransactionType/StockInventoryTransactionTypePage';
import ManageStockInventoryTransactionType from '../pages/StockInventoryTransactionType/ManageStockInventoryTransactionType';
import ManageStockGroups from '../deprecated/ManageStockGroups';
import WINOWarehousePage from '../pages/WINOInventory/Warehouse/WINOWarehousePage';
import WINOManageWarehouse from '../pages/WINOInventory/Warehouse/WINOManageWarehouse';
import WarehouseLocationTypes from '../pages/WarehouseLocationType/WarehouseLocationTypes'
import WarehouseLocationTypeManagePage from '../pages/WarehouseLocationType/WarehouseLocationTypeManagePage'
import WINOWarehouseLocationPage from '../pages/WINOInventory/WarehouseLocation/WINOWarehouseLocationPage';
import WINOManageWarehouseLocation from '../pages/WINOInventory/WarehouseLocation/WINOManageWarehouseLocation';
import WINOStockWarehouseLocation from '../pages/WINOInventory/StockWarehouseLocation/WINOStockWarehouseLocation';
import WINOManageStockWarehouseLocation from '../pages/WINOInventory/StockWarehouseLocation/WINOManageStockWarehouseLocation';
import CurrencyPage from "../pages/Currency/CurrencyPage";
import ManagePurchaseOrder from '../pages/WINOInventory/PurchaseOrder/ManagePurchaseOrder';
import PurchaseOrderTab from '../pages/WINOInventory/PurchaseOrder/PurchaseOrderTab';
import StockCostingMethodPage from "../pages/StockCostingMethod/StockCostingMethodPage";
//-----Customer dashboard
import CustomerDashboardStats from '../components/dashboard/CustomerDashboard/CustomerDashboardStats';
import ManagePurchaseInvoice from '../pages/WINOInventory/PurchaseInvoice/ManagePurchaseInvoice';

import AdminLayout from "../components/dashboard/AdminLayout";
import WarehouseQtyTransfer from "../pages/WINOInventory/Warehouse/WarehouseQtyTransfer";
import StockTransferHistoryPage from "../pages/WINOInventory/StockWarehouseLocation/StockTransferHistoryPage";
import PurchaseInvoiceTab from '../pages/WINOInventory/PurchaseInvoice/PurchaseInvoiceTab';
import CustomerInvite from '../pages/auth/SignupPage';
import ManageStockStockWarehouseLocation from '../components/WINOInventory/ManageStockStockWarehouseLocation';
import CreditorEnquiryPage from '../pages/Creditor/CreditorEnquiryPage';
import SwishCreditorPage from "../pages/Creditor/SwishCreditorPage";
/*import SwishCreditorEnquiryPage from "../pages/Creditor/SwishCreditorEnquiryPage";*/
import ManageSwishCreditor from "../pages/Creditor/ManageSwishCreditor";
import ProductionCapacityTabs from '../pages/production/ProductionCapacityTabs';
import ProductConfigurationStockFormula from '../pages/product/ProductConfigurationStockFormula';
import ProductConfigurationStockDeductionPage from '../pages/product/ProductConfigurationStockDeductionPage'

import ManageProductConfigurationStockFormula from '../pages/product/ManageProductConfigurationStockFormula';
import DispatchPage from "../pages/dispatch/DispatchPage";
import CouriersPage from '../pages/dispatch/CouriersPage';
import PrinterServerPage from "../pages/printerServer/PrinterServerPage";
import FreightRuleImportPage from '../pages/address/FreightRuleImportPage';
import ProductPage from '../pages/product/ProductPage';
import ProductConfigurationMatrixConverterImport from '../pages/product/ProductConfigurationMatrixConverterImport';
//import DebtorPreferencesPage from '../pages/debtor/DebtorPreferencesPage';
import EmailLogs from '../../src/pages/EmailLog/EmailLogsPage'
import SchedulingJobsPage from '../pages/SchedulingJobs/SchedulingJobsPage';
import ImportKeywayStockPage from '../pages/stock/ImportKeywayStockPage';
import ImportKeywayNonStockPage from '../pages/nonStock/ImportKeywayNonStockPage';
import CustomerInvitePage from '../pages/debtor/CustomerInvitationDetails';

import StockUnitsPage from '../pages/stock/StockUnitsPage';
import FabricColorImport from '../pages/fabric/FabricColorImport';
import PurchaseOrderEnquiryPage from '../pages/purchase/PurchaseOrderEnquiryPage';
import PrivilegePage from '../pages/PrivilegePage';
import UserRolesPrivilegeTabs from '../pages/UserRolesPrivilegeTabs';
import ApiLogPage from '../pages/ApiLogPage';
import PowdercoatingOrderTabPage from '../pages/PowderCoating/PowdercoatingOrderTabPage';
import PowdercoatingPurchaseOrderForm from '../pages/PowderCoating/PowdercoatingPurchaseOrderForm';
import ManageStockAttributePage from '../pages/stock/ManageStockAttributePage';
import ManageInventoryStockBulkProdCodes from '../pages/StockInventory/ManageInventoryStockBulkProdCodes';
import ProductOrderForms from "../pages/catalogues/ProductOrderForms";
import ProductBrochures from "../pages/catalogues/ProductBrochures";
import GLCurrencyPage from '../pages/stock/GLCurrencyPage';
import PurchaseOrdersPage from '../pages/purchase/PurchaseOrdersPage';
import ManagePurchaseOrderFormpage from '../pages/purchase/ManagePurchaseOrderFormPage';
//import ProductionOOSManager from '../pages/production/ProductionOOSManager';
import ReworkFormPageCustomer from '../pages/Rework/ReworkFormPageForCustomer';
import ReworkFormPageForCustomer from '../pages/Rework/ReworkFormPageForCustomer';
import ReworkUnitPage from '../pages/Rework/ReworkUnitPage';
import ReworkPage from '../pages/Rework/ReworkPage';
//--------------WarehouseLocType

//-------------PickerApps
import StockReceiptEnquiryPage from '../pages/stock/stockReceipt/StockReceiptEnquiryPage';
import StockReceiveDashboard from '../pages/stock/stockReceive/StockReceiveDashboard';
import WarehouseTransfer from '../pages/pickerApp/warehouse/WarehouseTransfer';
import WarehouseLocation from '../pages/WarehouseLocationType/WarehouseLocation';
import ScheduledJobWrapperPage from "../pages/SchedulingJobs/ScheduledJobWrapperPage";
import StockReceiptsPage from '../pages/WarehouseLocationType/StockReceiptsPage';
import MaintenancePage from '../pages/MaintenancePage';
import HolidayPage from '../pages/HolidayPage';
import ImportHolidayPage from '../pages/ImportHolidayPage';
import BulkOrderPage from '../pages/bulkImport/BulkOrderPage';
import ImportBulkOrderPage from '../pages/bulkImport/ImportBulkOrderPage';


const RoutesConfig = props => {

    return (
        <div>
            <Route exact path="/login" component={LoginPage}/>
            <Route exact path="/forgot" component={ForgotPasswordPage}/>
            <Route exact path="/coming-soon" component={ComingSoonPage}/>
            <Route exact path="/not-allowed" component={UnAuthorized}/>
            <Route exact path="/sign-up" component={CustomerInvite} />
            <Route exact path="/maintenance" component={MaintenancePage} />

            <PrivateRoute exact path="/" component={HomePage}/>
            <PrivateRoute exact path='/users' component={UserPage}/>
            <PrivateRoute exact path='/users/manage' component={UserManagePage}/>
            <PrivateRoute exact path='/roles' component={RolePage}/>
            <PrivateRoute path='/purchaseHistory' component={PurchaseHistoryPage}/>
            <PrivateRoute path='/profile' component={CustomerProfile}/>

            <PrivateRoute exact path='/sales/orders/outstanding' component={ProductionOutstandingOrdersPage}/>
            <PrivateRoute path='/productionCapacity' component={ProductionCapacityTabs}/>
            <PrivateRoute path='/production/schedule/auto' component={ProductionScheduleDeschedulePage}/>


            <PrivateRoute path='/discount/stock' component={DiscountTable}/>
            <PrivateRoute path='/discount/product' component={ProductDiscountPage}/>
            <PrivateRoute path="/crm/leads" component={Lead}/>
            <PrivateRoute path='/crm/manageLead/:id?' component={CRMLeadManagePage}/>
            <PrivateRoute path='/crm/tasks' component={TasksPage}/>
            <PrivateRoute path='/crm/manageTask/:id?' component={TaskManagePage}/>
            <PrivateRoute path='/crm/convertlead' component={ConvertLead}/>

            <PrivateRoute exact path='/customer' component={DebtorEnquiryPage}/>
            <PrivateRoute exact path='/customer/statements' component={ViewDetailedStatemnet}/>
            <PrivateRoute exact path='/customer/receipts' component={ViewDebtorReceipts}/>
            <PrivateRoute exact path='/customers/accounts' component={CustomerAccountDetailsPage}/>
            <PrivateRoute exact path='/customers/manage/account/one' component={ManageCustomerPage}/>
            <PrivateRoute exact path='/customers/manage/account/one/contacts' component={ManageDebtorContacts}/>
            <PrivateRoute exact path='/customer/contacts' component={ContactsMasterPage} />
            <PrivateRoute exact path='/customer/address' component={AddressMasterPage} />
            <PrivateRoute exact path='/customer/outstanding-payment' component={OutstandingPaymentPage} />
            {/*<PrivateRoute exact path='/customer/preferences' component={DebtorPreferencesPage}/>*/}

            <PrivateRoute exact path='/sales/quotes' component={SalesQuotesPage}/>
            <PrivateRoute exact path='/sales/quotes/Overview' component={SalesQuotesOverviewPage}/>
            <PrivateRoute exact path='/sales/manage/quotes' component={DebtorsOutstandingQuotes}/>
            <PrivateRoute exact path='/reps' component={SalesRepPage}/>
            <PrivateRoute exact path='/rep/one' component={ManageRep}/>
            <PrivateRoute exact path='/sales/orders' component={SalesOrdersPage}/>
            <PrivateRoute exact path='/sales/orders/review' component={ReviewOrders}/>
            <PrivateRoute exact path='/sales/orders/customer-view' component={SalesCustomerOrders}/>
            <PrivateRoute exact path='/sales/order/create/product-builder/v1'
                          component={SalesOrderProductBuilder}/>
            <PrivateRoute exact path='/sales/order/submitted' component={SalesOrderSubmittedPage}/>
            <PrivateRoute exact path='/sales/order/enquiry' component={OrderEnquiry}/>
            <PrivateRoute exact path='/sales/order/view' component={OrderEnquiry}/>
            <PrivateRoute exact path='/sales/order/edit' component={OrderEnquiry}/>

            <PrivateRoute exact path='/sales/ordergateway' component={OrderGatewayPage}/>
            <PrivateRoute exact path='/inventory/stock/enquiry' component={StockEnquiryPage} />
            <PrivateRoute exact path='/inventory/stock/groups/list' component={StockGroupsPage} />
            <PrivateRoute exact path='/inventory/stock/list' component={StockPage}/>
            <PrivateRoute exact path='/inventory/stock/recommendation' component={StockRecommendation}/>
            <PrivateRoute exact path='/inventory/stock-unit' component={StockUnitPage}/>
            <PrivateRoute exact path='/inventory/stock-unit/manage/one' component={ManageStockUnit}/>
            <PrivateRoute exact path='/inventory/stock-unit-type' component={StockUnitTypePage}/>
            <PrivateRoute exact path='/inventory/stock-unit-type/manage/one' component={ManageStockUnitType}/>
            <PrivateRoute exact path='/inventory/stock-inventory' component={StockInventoryPage}/>
            <PrivateRoute exact path='/inventory/stock-inventory/manage/one' component={ManageStockInventoryPage}/>
            <PrivateRoute exact path='/inventory/stock-inventory-cost/manage/one' component={ManageStockInventoryCost}/>
            <PrivateRoute exact path='/inventory/stock-inventory-price/manage/one'
                          component={ManageStockInventoryPrice}/>
            <PrivateRoute exact path='/inventory/stock-inventory-status/manage/one'
                          component={ManageStockInventoryStatus}/>
            <PrivateRoute exact path='/inventory/stock/inventory/production-cost/manage/one'
                          component={ManageStockInventoryCostProduction}/>
            <PrivateRoute exact path='/inventory/stock/inventory/landed-cost/manage/one'
                          component={ManageStockInventoryCostLanded}/>
            <PrivateRoute exact path='/inventory/stocks' component={StocksPage}/>
            <PrivateRoute exact path='/inventory/nonStocks' component={NonStocksPage} />
            <PrivateRoute exact path='/keyway-inventory/warehouse/stock/transfer' component={WarehouseQtyTransfer}/>
            <PrivateRoute exact path='/keyway-inventory/warehouse/stock/transfer/history'
                          component={StockTransferHistoryPage}/>

            <PrivateRoute exact path='/creditor/list' component={SwishCreditorPage}/>
            <PrivateRoute exact path='/inventory/keyway/creditors' component={CreditorPage}/>
            <PrivateRoute exact path='/inventory/keyway/creditors/manage/one' component={ManageCreditor}/>
            <PrivateRoute exact path='/keyway-inventory/stock/warehouse-location/one'
                          component={ManageStockStockWarehouseLocation}/>
            <PrivateRoute exact path='/keyway-inventory/warehouses' component={WINOWarehousePage}/>
            <PrivateRoute exact path='/keyway-inventory/warehouses/manage/one' component={WINOManageWarehouse}/>

            <PrivateRoute exact path='/keyway-inventory/warehouse-location-types' component={WarehouseLocationTypes}/>

            <PrivateRoute exact path='/keyway-inventory/stock-inventory-cost-production-history'
                          component={StockInventoryCostProductionHistoryPage}/>
            <PrivateRoute exact path='/keyway-inventory/warehouse-location' component={WINOWarehouseLocationPage}/>
            <PrivateRoute exact path='/keyway-inventory/warehouse-location/manage/one'
                          component={WINOManageWarehouseLocation}/>

            <PrivateRoute exact path='/keyway-inventory/warehouse-location-types/manage'
                          component={WarehouseLocationTypeManagePage}/>
            <PrivateRoute exact path='/keyway-inventory/stock-price-history'
                          component={StockInventoryPriceHistoryPage}/>
            <PrivateRoute exact path='/keyway-inventory/stock-landed-cost-history'
                          component={StockLandedCostHistoryPage}/>

            <PrivateRoute exact path='/keyway-inventory/stock-warehouse-location'
                          component={WINOStockWarehouseLocation}/>
            <PrivateRoute exact path='/keyway-inventory/stock-warehouse-location/manage/one'
                          component={WINOManageStockWarehouseLocation}/>
            <PrivateRoute exact path='/keyway-inventory/stock-inventory-transaction'
                          component={StockInventoryTransactionPage}/>
            <PrivateRoute exact path='/keyway-inventory/import-export' component={ImportExportPage}/>
            <PrivateRoute exact path='/keyway-inventory/stock-supplier' component={StockSupplierPage}/>
            <PrivateRoute exact path='/keyway-inventory/stock-supplier/supplier' component={StockSupplierPage}/>
            <PrivateRoute exact path='/keyway-inventory/stock-supplier/manage' component={ManageStockSupplierPage}/>

            <PrivateRoute exact path='/keyway-inventory/purchase-order' component={PurchaseOrderTab}/>
            <PrivateRoute exact path='/keyway-inventory/purchase-order/manage/one' component={ManagePurchaseOrder}/>
            <PrivateRoute exact path='/keyway-inventory/stock-costing-method' component={StockCostingMethodPage}/>

            <PrivateRoute exact path='/keyway-inventory/purchase-invoice' component={PurchaseInvoiceTab}/>
            <PrivateRoute exact path='/keyway-inventory/purchase-invoice/manage/one' component={ManagePurchaseInvoice}/>

            <PrivateRoute exact path='/GL-currency' component={CurrencyPage}/>

            <PrivateRoute exact path='/inventory/product/group/list'
                          component={ProductGroupPage}/>
            <PrivateRoute exact path='/inventory/product/list'
                component={ProductPage} />
            <PrivateRoute exact path='/inventory/products/all/list' component={ProductsPage} />
            <PrivateRoute exact path='/inventory/product/configuration'
                          component={ProductConfigurationPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/stock/formula'
                          component={ProductConfigurationStockFormula}/>
            <PrivateRoute exact path='/inventory/product/configuration/stock/formula/one'
                          component={ManageProductConfigurationStockFormula}/>
            <PrivateRoute exact path='/inventory/product/configuration/stock/deduction'
                          component={ProductConfigurationStockDeductionPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/fabric'
                          component={ProductConfigurationFabricNewPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/option'
                          component={ProductConfigurationOptionPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/option/set'
                          component={ProductConfigurationOptionSetPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/price/matrix'
                          component={ProductConfigurationPricingMatrixPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/matrix/convertor'
                          component={ProductConfigurationMatrixConverterImport}/>

            <PrivateRoute exact path='/company/myorders' component={MyOrders}/>
            <PrivateRoute exact path='/company/accounts' component={MyAccountsPage}/>
            <PrivateRoute exact path='/company/stats' component={CustomerDashboardStats}/>
            <PrivateRoute exact path='/company/admin' component={AdminLayout}/>


            <PrivateRoute exact path='/manage/product/catalogues' component={ManageCatalogues}/>
            <PrivateRoute exact path='/product/catalogues' component={ProductUploads}/>

            <PrivateRoute exact path='/company/Faq' component={FAQPageList}/>
            <PrivateRoute exact path='/tools/faq/manage' component={AddFAQ}/>
            <PrivateRoute exact path='/tools/faq/manage/suggestions' component={ViewFaqSuggestions}/>
            <PrivateRoute exact path='/tools/printer/management' component={PrinterServerPage}/>

            <PrivateRoute exact path='/fabrics/info' component={FabricBrochure}/>
            <PrivateRoute exact path='/fabrics/one/color/info' component={FabricDetailedView}/>

            <PrivateRoute exact path='/tools/manage/fabrics/list' component={ManageFabrics} />
            <PrivateRoute exact path='/tools/manage/fabrics/tab' component={FabricsTab} />
            <PrivateRoute exact path='/tools/manage/fabrics/import' component={ImportFabricDataView}/>
            <PrivateRoute exact path='/tools/manage/fabrics/one' component={FabricInfo}/>
            <PrivateRoute exact path='/tools/manage/fabrics/one/colors/list' component={UploadFabricColors}/>

            <PrivateRoute path='/tools/email-logs' component={EmailLogs}/>
            <PrivateRoute path='/tools/scheduler/jobs' component={SchedulingJobsPage}/>
            <PrivateRoute path='/tools/scheduler/job/list' component={ScheduledJobWrapperPage}/>

            <PrivateRoute exact path='/tools/manage/shipment/packaging-handling/charges/rule/list'
                          component={PackagingHandlingChargesRuleManagePage}/>

            <PrivateRoute exact path='/tools/manage/stock-take' component={StockTakePage}/>

            <PrivateRoute exact path='/manage/newsletter/' component={NewsLetterItems}/>
            <PrivateRoute exact path='/newsletters' component={NewsLetterPageList}/>
            <PrivateRoute exact path='/user/inbox' component={UserStyledInbox}/>


            <PrivateRoute exact path='/address/standards' component={AddressStandardPage}/>
            <PrivateRoute exact path='/manage/freight/rule' component={FreightRuleManagePage}/>
            <PrivateRoute exact path='/freight/rule/import' component={FreightRuleImportPage}/>
            <PrivateRoute exact path='/manage/user-inbox/template' component={ManageUserInboxTemplate}/>

            <PrivateRoute exact path='/inventory/stockGroup' component={StockGroupPage}/>
            <PrivateRoute exact path='/inventory/stockGroup/manage' component={ManageStockGroups}/>


            <PrivateRoute exact path='/inventory/stockInventoryTransactionType'
                          component={StockInventoryTransactionTypePage}/>
            <PrivateRoute exact path='/inventory/stockInventoryTransactionType/manage'
                          component={ManageStockInventoryTransactionType}/>

            <PrivateRoute exact path={'/inventory/dispatch'} component={DispatchPage} />
            <PrivateRoute exact path={'/inventory/dispatch/couriers'} component={CouriersPage} />

            <PrivateRoute exact path='/inventory/keyway/stock/import' component={ImportKeywayStockPage} />
            <PrivateRoute exact path='/inventory/keyway/nonStock/import' component={ImportKeywayNonStockPage} />
            <PrivateRoute exact path='/tools/manage/fabrics/one/colors/import' component={FabricColorImport} />
            <PrivateRoute exact path='/customer/invitation' component={CustomerInvitePage} />
            <PrivateRoute exact path='/inventory/units' component={StockUnitsPage} />
            <PrivateRoute exact path='/inventory/stocks/overview' component={StockKeepingUnits} />
            <PrivateRoute exact path='/inventory/stocks/attribute' component={ManageStockAttributePage} />
            <PrivateRoute exact path='/purchase/order/enquiry' component={PurchaseOrderEnquiryPage}/>


            <PrivateRoute exact path='/production/productionDashboard' component={ProductionDashboard} />
            <PrivateRoute exact path='/components/dashboard/ProductionDashboardSummaryCard'
                component={ProductionDashboardSummaryCard} />
            <PrivateRoute exact path='/privilege' component={PrivilegePage} />
            <PrivateRoute exact path='/user/role/list' component={UserRolesPrivilegeTabs}/>


            <PrivateRoute exact path='/company/retail' component={RetailPageLinks} />
            <PrivateRoute exact path='/tools/api/log' component={ApiLogPage} />
            <PrivateRoute exact path='/tools/powdercoating/order' component={PowdercoatingOrderTabPage} />
            <PrivateRoute exact path='/tools/powdercoating/purchase/order/manage' component={PowdercoatingPurchaseOrderForm} />
            <PrivateRoute exact path='/customer/new-account/' component={ManageCustomerPage} />
            <PrivateRoute exact path='/tools/manage/stock/bulk/update' component={ManageInventoryStockBulkProdCodes}/>
            <PrivateRoute exact path='/product/productOrderForms' component={ProductOrderForms}/>
            <PrivateRoute exact path='/product/productBrochures' component={ProductBrochures} />
            <PrivateRoute exact path='/finance/currency/list' component={GLCurrencyPage} />
            <PrivateRoute exact path='/product/productBrochures' component={ProductBrochures} />
            <PrivateRoute exact path='/creditor/enquiry' component={CreditorEnquiryPage} />
            <PrivateRoute exact path='/creditors/manage/one' component={ManageSwishCreditor} />
            <PrivateRoute exact path='/purchase-orders' component={PurchaseOrdersPage} />
            <PrivateRoute exact path='/purchase-orders/manage' component={ManagePurchaseOrderFormpage} />

            <PrivateRoute exact path='/warehouse/transfer' component={WarehouseTransfer} />
            <PrivateRoute exact path='/stock/receipt/enquiry' component={StockReceiptEnquiryPage} />
            <PrivateRoute exact path='/stock/receipt/list' component={StockReceiptsPage} />
            <PrivateRoute exact path='/stock/receive' component={StockReceiveDashboard} />
            <PrivateRoute exact path='/warehouse/location' component={WarehouseLocation} />

            {/*<PrivateRoute exact path='/production/schedule/oos/manage' component={ProductionOOSManager}/>*/}
            <PrivateRoute exact path='/rework/form/customer' component={ReworkFormPageForCustomer} />
            <PrivateRoute exact path='/rework/order/units/table' component={ReworkUnitPage} />
            <PrivateRoute exact path='/rework' component={ReworkPage} />
            <PrivateRoute exact path='/production/order/enquiry' component={OrderEnquiry} />
            <PrivateRoute exact path='/holidays' component={HolidayPage} />
            <PrivateRoute exact path='/holidays/import' component={ImportHolidayPage} />
            <PrivateRoute exact path='/bulk-order' component={BulkOrderPage} />
            <PrivateRoute exact path='/bulk-order/import' component={ImportBulkOrderPage} />
        </div>
    );
};

export default RoutesConfig;