import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardFooter, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner, Table } from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import CustomerService from '../../services/CustomerService';
import { findIndex, handleErrorMessage, validateEmail, getDistinctValues } from '../../services/CommonService';
import UserService from '../../services/UserService';
import CustomerAccountsDetailsFilter from '../../components/debtor/CustomerAccounts/CustomerAccountsDetailsFilter';
import RepService from '../../services/RepService';
import AddressService from '../../services/AddressService';
import OutstandingPaymentSendEmailModal from '../../components/modal/OutstandingPaymentSendEmailModal';
import ConfirmModal from '../../components/modal/ConfirmModal';
import NumberFormat from "react-number-format";

export default class OutstandingPaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerAccountsData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        company: '',
                        accountId: '',
                        debtorCategoryID: 0,
                        paymentTermsID: 0,
                        accountStatus: 'Active',
                        repCode: 0,
                        isIncludeChildren: false,
                        excludeClosedOrInactive: true
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            debtorPaymentTerms: [],
            debtorAccountStatus: [],
            debtorCategory: [],
            reps: [],
            hasDebtorWritePrivilege: false,
            stateOption: [],
            selectedAccounts: [],
            showConfirmSendEmail: false,
            emailModal: {
                isOpen: false,
                isLoadingEmailSend: false,
                mail: {
                    id: "",
                    from: [{
                        label: "Windoware Finance(accountsreceivable@windoware.com.au)",
                        value: "accountsreceivable@windoware.com.au"
                    }],
                    fromOptions: [{
                        label: "Windoware Finance(accountsreceivable@windoware.com.au)",
                        value: "accountsreceivable@windoware.com.au"
                    }],
                    to: [],
                    toOptions: [],
                    cc: [],
                    ccOptions: [],
                    bcc: [],
                    bccOptions: [],
                    subject: "",
                    body: "",
                },
                template1Header: `This is just a friendly reminder that the amount outstanding in 30-Days period will be due for payment before the end of month, see below.<br />`,
                template1Footer: `Would be grateful if you could confirm that everything is on track for payment, and also please include your company code above as the reference for the payment.<br /><br />If you are needing copies of invoices these can be found on Keyway.<br />`,
                template2Header: `Your account is credit on hold because of the overdue amount in 60-Days (and/or 90-Days), see below.<br />`,
                template2Footer: `Can you please confirm when the payment can be made?<br />Thank you.<br />`,
                outstandingPaymentData: "",
            }
        };
        this.customerService = new CustomerService();
        this.userService = new UserService();
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getDebtorAccountStatus = this.getDebtorAccountStatus.bind(this);
        this.getDebtorCategory = this.getDebtorCategory.bind(this);
        this.getDebtorPaymentTerms = this.getDebtorPaymentTerms.bind(this);
        this.getReps = this.getReps.bind(this);
        this.getCustomerAccountsHeading = this.getCustomerAccountsHeading.bind(this);
        this.toggleEmailModal = this.toggleEmailModal.bind(this);
        this.sendEmailModal = this.sendEmailModal.bind(this);
        this.handleClickEmail = this.handleClickEmail.bind(this);
        this.getFormattedNumber = this.getFormattedNumber.bind(this);
        this.handleSelectedAccounts = this.handleSelectedAccounts.bind(this);
        this.sendEmailToMultipleAccounts = this.sendEmailToMultipleAccounts.bind(this);
        this.setEmailData = this.setEmailData.bind(this);
        this.getRecipientEmailIDs = this.getRecipientEmailIDs.bind(this);
    }

    componentDidMount() {
        let { customerAccountsData } = this.state;
        this.getCustomerAccounts(customerAccountsData);
        this.getStates();
    }

    refresh() {
        this.getDebtorAccountStatus();
        this.getDebtorCategory();
        this.getDebtorPaymentTerms();
        this.getReps();
        let currentUser = this.userService.getLoggedInUser();
        let hasDebtorWritePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-write');
        this.setState({ hasDebtorWritePrivilege });
    }

    async getRecipientEmailIDs(selectedCustomer, sendEmail) {
        selectedCustomer.toEmails = [];
        await this.customerService.getRecipientEmailIDs(selectedCustomer.accountID).then(response => {
            if (response.data) {
                selectedCustomer.toEmails = response.data;
                this.setState({ selectedCustomer }, async () => {
                    await this.setEmailData(this.state.selectedCustomer, sendEmail);
                });
            }
        }).catch(error => {
            console.log(error.data);
        });
    }

    async setEmailData(selectedCustomer, sendEmail) {
        let { emailModal } = this.state;
        let useTemplate2 = selectedCustomer.ninetyDays !== 0 || selectedCustomer.sixtyDays !== 0;
        if (selectedCustomer.toEmails.length > 0) {
            emailModal.mail.to = emailModal.mail.toOptions = selectedCustomer.toEmails.map(email => ({
                label: email,
                value: email
            }));
        } else {
            emailModal.mail.to[0] = emailModal.mail.toOptions[0] = {
                label: selectedCustomer.email,
                value: selectedCustomer.email
            };
        }
        emailModal.mail.subject = "Outstanding Payment Reminder";
        emailModal.outstandingPaymentData = `${[null, 0].includes(selectedCustomer.balance) ? '' : `<br /><strong>Outstanding Balance</strong>`}${[null, 0].includes(selectedCustomer.current) ? '' : `<br />Current: ${this.getFormattedNumber(selectedCustomer.current)}`}${[null, 0].includes(selectedCustomer.thirtyDays) ? '' : `<br />30 Days: ${this.getFormattedNumber(selectedCustomer.thirtyDays)}`}${[null, 0].includes(selectedCustomer.sixtyDays) ? '' : `<br />60 Days: ${this.getFormattedNumber(selectedCustomer.sixtyDays)}`}${[null, 0].includes(selectedCustomer.ninetyDays) ? '' : `<br />90 Days: ${this.getFormattedNumber(selectedCustomer.ninetyDays)}`}${[null, 0].includes(selectedCustomer.balance) ? '' : `<br /><strong>Total: ${this.getFormattedNumber(selectedCustomer.balance)}</strong>`}<br /><br />`;
        emailModal.mail.body = `Dear ${selectedCustomer.company},<br /><br />Hope you are doing well.<br /><br />${useTemplate2 ? emailModal.template2Header.concat(emailModal.outstandingPaymentData, emailModal.template2Footer) : emailModal.template1Header.concat(emailModal.outstandingPaymentData, emailModal.template1Footer)}<br />Any issues please contact us at Accounts Receivable &lt;<a href='mailto:accountsreceivable@windoware.com.au'>accountsreceivable@windoware.com.au</a>&gt;.<br /><br />Kind Regards,<br />Windoware Team<br /><img src='https://winonline.blob.core.windows.net/winonline/static/img/logo/win_logo.png' />`;
        this.setState({ emailModal }, async () => {
            if (sendEmail) {
                await this.sendEmailModal(this.state.emailModal.mail);
            }
        });
    }

    async sendEmailModal(mail) {
        let { emailModal, selectedCustomer } = this.state;
        emailModal.isLoadingEmailSend = true;
        emailModal.mail = mail;
        this.setState({ emailModal });
        let request = {
            tos: getDistinctValues(emailModal.mail.to ? emailModal.mail.to : [], 'value'),
            ccs: getDistinctValues(emailModal.mail.cc ? emailModal.mail.cc : [], 'value'),
            bccs: getDistinctValues(emailModal.mail.bcc ? emailModal.mail.bcc : [], 'value'),
            subject: emailModal.mail.subject,
            body: emailModal.mail.body,
            accountID: selectedCustomer.accountID,
            company: selectedCustomer.company
        };
        await this.customerService.sendOutstandingPaymentEmail(request).then(response => {
            if (response.data) {
                toast.dismiss();
                toast.success("Email Sent", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
            this.toggleEmailModal(false);
            emailModal.isLoadingEmailSend = false;
            this.setState({ emailModal });
        }).catch(error => {
            console.log(error.data);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            emailModal.isLoadingEmailSend = false;
            this.setState({ emailModal });
        });
    }

    async handleClickEmail(change, selectedCustomer) {
        this.toggleEmailModal(change);
        await this.getRecipientEmailIDs(selectedCustomer, false);
    }

    toggleEmailModal(change) {
        let { emailModal } = this.state;
        emailModal.isOpen = change;
        this.setState({ emailModal });
    }

    handleSelectedAccounts(selectedCustomer) {
        let { selectedAccounts } = this.state;
        const index = selectedAccounts.findIndex(customer => customer.accountID === selectedCustomer.accountID);
        if (index !== -1) {
            selectedAccounts.splice(index, 1);
        } else {
            selectedAccounts.push(selectedCustomer);
        }
        this.setState({ selectedAccounts });
    }

    async sendEmailToMultipleAccounts() {
        this.setState({ showConfirmSendEmail: false });
        let { selectedAccounts } = this.state;
        for (const selectedCustomer of selectedAccounts) {
            await this.getRecipientEmailIDs(selectedCustomer, true);
        }
        this.setState({ selectedAccounts: [] });
    }

    getFormattedNumber(value) {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }

    getReps() {
        RepService.fetchReps().then(res => {
            this.setState({ reps: res.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorAccountStatus() {
        this.customerService.getDebtorAccountStatus().then(response => {
            this.setState({ debtorAccountStatus: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorCategory() {
        this.customerService.getDebtorCategory().then(response => {
            this.setState({ debtorCategory: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStates() {
        AddressService.getStates().then(response => {
            response.data.map(state => {
                this.setState(previousState => ({
                    stateOption: [...previousState.stateOption, state.abbreviation]
                }));
            })
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorPaymentTerms() {
        this.customerService.getDebtorPaymentTerms().then(response => {
            this.setState({ debtorPaymentTerms: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCustomerAccounts(customerAccountsData) {
        this.setState({ loading: true });
        this.customerService.getCustomerAccounts(customerAccountsData.request).then(response => {
            customerAccountsData.response = response.data;
            this.setState({ customerAccountsData, loading: false }, () => this.refresh());
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.company.toLowerCase().includes(searchText);

            if (!flag && item.accountID) {
                flag = item.accountID.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.accountStatusDescription) {
                flag = item.accountStatusDescription.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.debtorCategoryName) {
                flag = item.debtorCategoryName.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.repName) {
                flag = item.repName.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.paymentTerm) {
                flag = item.paymentTerm.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.debtorCategoryName) {
                flag = item.debtorCategoryName.toString().toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleAccountChange(accountId) {
        let { customerAccountsData } = this.state;
        customerAccountsData.request.filterRequest.accountId = accountId;
        customerAccountsData.request.pageRequest.currentPage = 1;
        this.getCustomerAccounts(customerAccountsData);
        this.setState({ customerAccountsData });
    }

    handleChange(change, key) {
        let { customerAccountsData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (customerAccountsData.request.sortRequest.key === change) {
                    customerAccountsData.request.sortRequest.direction = !customerAccountsData.request.sortRequest.direction;
                } else {
                    customerAccountsData.request.sortRequest.key = change;
                    customerAccountsData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getCustomerAccounts(customerAccountsData);
                }
                break;
            case "pageSize":
                customerAccountsData.request.pageRequest[key] = change;
                customerAccountsData.request.pageRequest.currentPage = 1;
                this.getCustomerAccounts(customerAccountsData);
                break;
            case "currentPage":
                customerAccountsData.request.pageRequest[key] = change;
                this.getCustomerAccounts(customerAccountsData);
                break;
            case "nothing":
                //just for skip
                break;
            default:
                customerAccountsData.request.filterRequest[key] = change;
                if (key === "accountStatus") {
                    customerAccountsData.request.filterRequest.excludeClosedOrInactive = false;
                }
                if (key !== "accountStatus") {
                    if (change === 'ALL') {
                        customerAccountsData.request.filterRequest[key] = 0
                    }
                    else {
                        customerAccountsData.request.filterRequest[key] = change;
                    }
                }
                customerAccountsData.request.pageRequest.currentPage = 1;
                this.getCustomerAccounts(customerAccountsData);
                this.setState({ customerAccountsData });
        }

    }

    handleCheckBoxChange(value, name) {
        let { customerAccountsData } = this.state;
        customerAccountsData.request.filterRequest[name] = value;
        customerAccountsData.request.pageRequest.currentPage = 1;
        this.getCustomerAccounts(customerAccountsData);
        this.setState({ customerAccountsData });
    }

    getCustomerAccountsHeading({ filterRequest }) {
        let customerAccountsHeading = [
            {
                key: "accountID",
                label: "Account",
                type: "link",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "text-left",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: false,
                searchNode: null
            },
            {
                key: "company",
                label: "Name",
                type: "text",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: false,
                searchNode: null
            },
            {
                key: "debtorCategoryName",
                label: "Category",
                type: "category",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: false,
                searchNode: null
            },
            {
                key: "paymentTerm",
                label: "Payment Terms",
                type: "text",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: false,
                searchNode: null
            },
            {
                key: "accountStatusDescription",
                label: "Status",
                type: "status",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: false,
                searchNode: null
            },
            {
                key: "repName",
                label: "Rep",
                type: "text",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: false,
                searchNode: null
            },
            {
                key: "total",
                label: "Balance",
                type: "text",
                colSpan: 5,
                rowSpan: 1,
                minWidth: 450,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                isSubHeading: false,
                searchNode: null
            },
            {
                key: "current",
                label: "Current",
                type: "number",
                colSpan: 1,
                rowSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: true,
                searchNode: null
            },
            {
                key: "thirtyDays",
                label: "30 Days",
                type: "number",
                colSpan: 1,
                rowSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: true,
                searchNode: null
            },
            {
                key: "sixtyDays",
                label: "60 Days",
                type: "number",
                colSpan: 1,
                rowSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: true,
                searchNode: null
            },
            {
                key: "ninetyDays",
                label: "90 Days",
                type: "number",
                colSpan: 1,
                rowSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: true,
                searchNode: null
            },
            {
                key: "balance",
                label: "Total",
                type: "number",
                colSpan: 1,
                rowSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                isSubHeading: true,
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                isSubHeading: false,
                searchNode: null
            }
        ];
        return customerAccountsHeading;
    }

    getAccountStatusDescription(accountStatusDescription) {
        let color = '';
        switch (accountStatusDescription) {
            case 'Active':
                color = 'success';
                break;
            case 'Inactive':
                color = 'warning';
                break;
            case 'Closed':
                color = 'danger';
                break;
            default:
                color = 'primary';
                break;
        }
        return color;
    }

    getCategoryBadgeColor(category) {
        let color = '';
        switch (category) {
            case 'Blue':
                color = 'primary';
                break;
            case 'Gold':
                color = 'warning';
                break;
            case 'Silver':
                color = 'secondary';
                break;
        }
        return color;
    }

    getColValue(customerHeading, customer) {
        let { hasDebtorWritePrivilege, emailModal } = this.state;
        let value = customer[customerHeading.key];
        switch (customerHeading.type) {
            case "link":
                return <Link
                    className="btn btn-sm btn-primary"
                    style={{ color: "white" }}
                    title={"Click here to see account details"}
                    to={"/customer/#" + value}>
                    {value}
                </Link>;
                break;
            case "status":
                return <Badge
                    color={this.getAccountStatusDescription(value)}>{value}</Badge>;
                break;
            case "category":
                return <Badge color={this.getCategoryBadgeColor(value)}>{value}</Badge>;
                break;
            case "action":
                return <Button size="sm" color="primary" onClick={() => this.handleClickEmail(!emailModal.isOpen, customer)}>
                    <i className="fa fa-envelope mr-2" aria-hidden="true" />Email
                </Button>;
                break;
            case "number":
                return <NumberFormat value={value}
                    displayType={'text'}
                    decimalScale={2}
                    thousandSeparator={true}
                    fixedDecimalScale={false}
                    prefix={"$"} />;
                break;
            default:
                return <span>{value}</span>;
        }
    }

    render() {
        let { customerAccountsData, loading, searchText, hasDebtorWritePrivilege,
            reps, debtorCategory, debtorPaymentTerms, debtorAccountStatus, stateOption, emailModal, selectedAccounts, showConfirmSendEmail } = this.state;
        let { pageRequest, sortRequest, filterRequest } = customerAccountsData.request;
        let customerAccountsHeading = this.getCustomerAccountsHeading(customerAccountsData.request);
        return (
            <div>
                <div>{
                    <CustomerAccountsDetailsFilter
                        handleChange={this.handleChange}
                        handleAccountChange={this.handleAccountChange}
                        filterRequest={filterRequest}
                        reps={reps}
                        debtorPaymentTerms={debtorPaymentTerms}
                        debtorAccountStatus={debtorAccountStatus}
                        debtorCategory={debtorCategory}
                        stateOption={stateOption}
                        isIncludeChildren={filterRequest.isIncludeChildren}
                        excludeClosedOrInactive={filterRequest.excludeClosedOrInactive}
                    />
                }
                </div>
                <br />
                <Card>
                    <CardBody>
                        <Row className={"align-items-center"}>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                {
                                    loading
                                        ? <Spinner size={"sm"} className="mr-2" color="primary" />
                                        : <div className={"text-left"}>
                                            <span className="mr-2">
                                                <a href={"javascript:void(0)"}
                                                    onClick={() => this.handleCheckBoxChange(!filterRequest.excludeClosedOrInactive, "excludeClosedOrInactive")}>
                                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                                        "fa-check-square-o": filterRequest.excludeClosedOrInactive,
                                                        "fa-square-o": !filterRequest.excludeClosedOrInactive,
                                                    }
                                                    )} />
                                                </a>&nbsp;Exclude Closed and Inactive
                                            </span>
                                            <span>
                                                <a href={"javascript:void(0)"}
                                                    onClick={() => this.handleCheckBoxChange(!filterRequest.isIncludeChildren, "isIncludeChildren")}>
                                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                                        "fa-check-square-o": filterRequest.isIncludeChildren,
                                                        "fa-square-o": !filterRequest.isIncludeChildren,
                                                    }
                                                    )} />
                                                </a>&nbsp;Include Children
                                            </span>
                                            {
                                                selectedAccounts.length > 0 ?
                                                    <Button
                                                        title="Send email to all selected accounts"
                                                        className="float-right"
                                                        size="sm"
                                                        color="primary"
                                                        disabled={emailModal.isLoadingEmailSend}
                                                        onClick={() => this.setState({ showConfirmSendEmail: true })}>
                                                        {
                                                            emailModal.isLoadingEmailSend ?
                                                                <>
                                                                    <i className="fa fa-fw fa-circle-o-notch fa-spin mr-1" aria-hidden="true" /><span>Sending Email</span>
                                                                </>
                                                                : <span>Email Reminder</span>
                                                        }
                                                    </Button>
                                                    : null
                                            }
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        <th className="align-middle" rowSpan={2} style={{ minWidth: 75 }}>S.No</th>
                                        {(customerAccountsHeading || []).filter(item => !item.isSubHeading).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, item.sorterApplicable ? "sortKey" : "nothing")}
                                                    colSpan={item.colSpan}
                                                    rowSpan={item.rowSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(customerAccountsHeading || []).filter(item => item.isSubHeading).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, item.sorterApplicable ? "sortKey" : "nothing")}
                                                    colSpan={item.colSpan}
                                                    rowSpan={item.rowSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(customerAccountsData.response.records || []).filter((customer) => this.searchFunction(customer, searchText)).map((customer, index) =>
                                        <tr key={index}>
                                            <td className="align-middle">{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                                <Button color="link"
                                                    size="sm"
                                                    className="p-0 ml-2"
                                                    onClick={() => this.handleSelectedAccounts(customer)}>
                                                    {
                                                        selectedAccounts.find(x => x.accountID === customer.accountID)
                                                            ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                                aria-hidden="true" />
                                                            : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                                    }
                                                </Button>
                                            </td>
                                            {(customerAccountsHeading || []).filter(item => item.key !== "total").map((item, headingIndex) => {
                                                return (
                                                    <td key={headingIndex}
                                                        className={item.valueClassName}>
                                                        {this.getColValue(item, customer)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 440 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                Showing
                                                {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {customerAccountsData.response.totalRecords}
                                                {' '}entries
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={customerAccountsData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <OutstandingPaymentSendEmailModal isOpen={emailModal.isOpen}
                    isSendingEmail={emailModal.isLoadingEmailSend}
                    toggle={this.toggleEmailModal}
                    message={emailModal.mail}
                    handleSubmit={this.sendEmailModal}
                />
                <ConfirmModal
                    isOpen={showConfirmSendEmail}
                    toggle={() => this.setState({ showConfirmSendEmail: !showConfirmSendEmail })}
                    handleSubmit={this.sendEmailToMultipleAccounts}
                    handleCancel={() => this.setState({ showConfirmSendEmail: false })}
                    primaryMessage={"Are you sure to send email reminder to selected customers?"}
                    secondaryMessage={""}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-envelope-o fa-2x"}
                    loading={emailModal.isLoadingEmailSend}
                    type="Sending..."
                />
                <ToastContainer />
            </div>
        );
    }
}
