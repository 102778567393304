import React, { Component } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner, Table } from 'reactstrap';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { changeFormatOfDateString, findIndex, handleErrorMessage } from '../../../services/CommonService';
import { cloneDeep } from 'lodash';
import PurchaseOrderService from '../../../services/purchase/PurchaseOrderService';
import UserService from '../../../services/UserService';
import { STOCK_QTY_FORMAT, STOCK_QTY_TYPE, WAREHOUSE_LOCATION_TYPES } from '../../../store/AppConstants';
import SearchWarehouseLocation from "../../../components/search/SearchWarehouseLocation";
import warehouseLocationTypeService from '../../../services/WarehouseLocationTypeService';
import StockQtyFormat from '../../../components/stock/StockQtyFormat';
import ConfirmModal from '../../../components/modal/ConfirmModal';

export default class StockReceivePurchaseOrderItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordNum: null,
            order: null,
            isLoadingStockReceive: false,
            filter: {},
            isOpenQtyConfirmationModal: false,
            isQtyZero: false,
            selectedItem: [],
            hasProcurementRole: false
        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.userService = new UserService();
        this.getPurchaseOrder = this.getPurchaseOrder.bind(this);
        this.getWarehouseLocation = this.getWarehouseLocation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitStockReceive = this.handleSubmitStockReceive.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.toggleQtyConfirmationModal = this.toggleQtyConfirmationModal.bind(this);
        this.checkQty = this.checkQty.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        if (this.userService.hasRole(currentUser, "Procurement")) {
            this.setState({ hasProcurementRole: true });
        }
        this.handleChange(this.props.ordNum, "ordNum", "default");
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            this.handleChange(nextProps.ordNum, "ordNum", "default");
        }
    }

    handleChange(change, key, index) {
        let { order, ordNum, filter } = this.state;
        switch (index) {
            case "filter":
                switch (key) {
                    default:
                        filter[key] = change;
                        this.setState({ order });
                        break;
                }
                break;
            default:
                switch (key) {
                    case "ordNum":
                        ordNum = change;
                        if (change) {
                            this.setState({ ordNum }, () => {
                                this.getPurchaseOrder(ordNum);
                            });
                        } else {
                            this.setState({ ordNum, order: null });
                        }
                        break;
                    case "isSelected":
                        order.orderItems[index][key] = change;
                        this.setState({ order });
                        break;
                    case "locationID":
                        order.orderItems[index][key] = change;
                        this.setState({ order }, () => {
                            this.getWarehouseLocation(change, index);
                        });
                        break;
                    case "locationTypeID":
                        order.orderItems[index].locationTypeID = change;
                        order.orderItems[index].locationID = null;
                        this.setState({ order });
                        break;
                    case "qtyToBeReceived":
                        order.orderItems[index][key] = change;
                        order.orderItems[index].isNotAllowed = false;
                        this.setState({ order });
                        break;
                }
        }

    }

    getPurchaseOrder(ordNum) {
        this.setState({ ordNum, isLoadingOrder: true });
        this.purchaseOrderService.getOrder(ordNum).then(response => {
            let order = response.data;
            (order.orderItems || []).forEach(item => {
                item.qtyToBeReceived = 0;
                item.eachUnitName = ((item.stock && item.stock.keywayStock && item.stock.keywayStock.eachUnitName && item.stock.keywayStock.eachUnitName !== "0") ? item.stock.keywayStock.eachUnitName : "NA");
                item.whUnitName = ((item.stock && item.stock.keywayStock && item.stock.keywayStock.whUnitName && item.stock.keywayStock.whUnitName !== "0") ? item.stock.keywayStock.whUnitName : "NA");
                item.whUnitQty = ((item.stock && item.stock.keywayStock && item.stock.keywayStock.whUnitQty && item.stock.keywayStock.whUnitQty !== 0) ? item.stock.keywayStock.whUnitQty : 1);

                if (item && item.lastStockInsertedWarehouseLocation) {
                    item.locationID = item.lastStockInsertedWarehouseLocation.locationID;
                    item.locationTypeID = item.lastStockInsertedWarehouseLocation.locationTypeID;
                }
            });
            this.setState({ order, isLoadingOrder: false });
        }).catch(error => {
            this.setState({ isLoadingOrder: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER })
        })
    }

    getWarehouseLocation(id, itemIndex) {
        let { order } = this.state;
        if (itemIndex > -1) {
            order.orderItems[itemIndex].isLoadingLocationType = true;
        }
        this.setState({ order });
        warehouseLocationTypeService.getWarehouseLocation(id).then(response => {
            if (itemIndex > -1) {
                order.orderItems[itemIndex].locationTypeID = response.data.locationTypeID;
                order.orderItems[itemIndex].isLoadingLocationType = false;
            }
            this.setState({ order });
        }).catch(error => {
            if (itemIndex > -1) {
                order.orderItems[itemIndex].isLoadingLocationType = false;
            }
            this.setState({ order });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER })
        })
    }

    searchFunction(item, filter) {
        let flag = true;
        if (flag && filter.prodCode) {
            flag = item.prodCode.toLowerCase().includes(filter.prodCode.toLowerCase())
        }
        if (flag && filter.description) {
            flag = item.description.toLowerCase().includes(filter.description.toLowerCase())
        }
        if (flag && filter.supplierRef) {
            flag = item.supplierRef.toLowerCase().includes(filter.supplierRef.toLowerCase())
        }
        return flag;
    }

    getStore() {
        let { filter } = this.state;
        return [
            {
                key: "prodCode",
                label: <div>Prod
                    <div>Code</div>
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 80,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: " align-middle text-center",
                searchNode: <Input type="search" id="prodCode" name="prodCode"
                    value={filter.prodCode}
                    onChange={(e) => this.handleChange(e.target.value, "prodCode", "filter")}
                    placeholder="Search" />
            },
            {
                key: "supplierRef",
                label: <div>Supplier
                    <div>Ref</div>
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 80,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: " align-middle text-center",
                searchNode: <Input type="search" id="supplierRef" name="supplierRef"
                    value={filter.supplierRef}
                    onChange={(e) => this.handleChange(e.target.value, "supplierRef", "filter")}
                    placeholder="Search" />
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <Input type="search" id="description" name="description"
                    value={filter.description}
                    onChange={(e) => this.handleChange(e.target.value, "description", "filter")}
                    placeholder="Search" />
            },
            {
                key: "ordered",
                label: <div>
                    <div>Ordered</div>
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 110,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "ordered",
                label: <div>
                    <div>Ordered</div>
                    <div>WH pack Qty</div>
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 110,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "received",
                label: <div>
                    Received
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 110,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            }, {
                key: "outStanding",
                label: <div>
                    Outstanding
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 110,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "toBeReceived",
                label: <div>
                    To Be Received
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 125,
                maxWidth: 150,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "lastUpdatedDate",
                label: <div>Updated
                    <div> Date</div>
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 75,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "locationTypeID",
                label: <div>Location
                    <div>Type</div>
                </div>,
                type: "text",
                colSpan: 1,
                minWidth: 90,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "locationID",
                label: "Location",
                type: "text",
                colSpan: 1,
                minWidth: 155,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                maxWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",
                searchNode: null
            }
        ];
    }

    checkQty(item) {
        if (item.qtyToBeReceived == 0 || item.outstanding < item.qtyToBeReceived) {
            let { isQtyZero } = this.state;
            isQtyZero = item.qtyToBeReceived == 0;
            this.setState({ isQtyZero, selectedItem: item });
            this.toggleQtyConfirmationModal(true);
        }
        else {
            this.handleSubmitStockReceive(item);
        }
    }

    toggleQtyConfirmationModal(isOpen) {
        this.setState({ isOpenQtyConfirmationModal: isOpen });
    }

    handleCancel(item) {
        let { order } = this.state;
        let itemIndex = (order.orderItems || []).findIndex(oi => oi.itemNum === item.itemNum);
        order.orderItems[itemIndex].isNotAllowed = true;
        this.setState({ order }, () =>
            this.toggleQtyConfirmationModal(false)
        );
    }

    handleSubmitStockReceive(item) {
        if (!item.locationTypeID) {
            toast.info("Please select Location Type");
            return false;
        }
        if (!item.locationID) {
            toast.info("Please select Location");
            return false;
        }
        let request = {
            ordNum: item.ordNum,
            itemNum: item.itemNum,
            qtyToBeReceived: item.qtyToBeReceived,
            locationTypeID: item.locationTypeID,
            locationID: item.locationID,
            stockReceiptID: this.props.stockReceiptID,
        };
        let { order } = this.state;
        let itemIndex = (order.orderItems || []).findIndex(oi => oi.itemNum === item.itemNum);
        if (itemIndex > -1) {
            order.orderItems[itemIndex].isLoadingStockReceive = true;
        }
        this.setState({ order, isOpenQtyConfirmationModal: false });
        this.purchaseOrderService.stockReceive(request).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("Stock received!", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }
            itemIndex = (order.orderItems || []).findIndex(oi => oi.itemNum === response.data.purchaseItemNum);
            if (itemIndex > -1) {
                order.statusID = response.data.statusID;
                order.orderStatus = response.data.orderStatus;
                order.orderItems[itemIndex].received = response.data.received;
                order.orderItems[itemIndex].qtyToBeReceived = 0;
                order.orderItems[itemIndex].lastReceivedOn = response.data.lastReceivedOn;
                order.orderItems[itemIndex].isLoadingStockReceive = false;
                order.orderItems[itemIndex].isNotAllowed = false;
            }
            this.setState({ order }, () => {
                this.props.handleChange(order, "orderStatus");
                this.props.handleChange(response.data.stockReceiptID, "stockReceiptID");
            });
        }).catch(error => {
            if (itemIndex > -1) {
                order.orderItems[itemIndex].isLoadingStockReceive = false;
                this.setState({ order });
            }
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {
            order, filter, isLoadingOrder, selectedItem,
            isOpenQtyConfirmationModal, isQtyZero, hasProcurementRole
        } = this.state;
        let store = this.getStore();


        return (
            <div>
                {
                    isLoadingOrder
                        ? <Spinner color={"primary"} />
                        : null
                }
                {
                    (order && order.orderItems)
                        ?
                        <Table striped bordered responsive hover size='sm' className={"mb-0"} style={{ minHeight: 225 }}>
                            <thead>
                                <tr>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{ minWidth: item.minWidth, maxWidth: item.maxWidth }}>
                                                {item.label}
                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{ minWidth: item.minWidth, maxWidth: item.maxWidth }}>
                                                {item.searchNode}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {(order.orderItems || []).filter(item => this.searchFunction(item, filter)).map((item, index) => {
                                    item.outstanding = item.qty - item.received;

                                    return <tr key={index} className={item.qty === item.received ? "table-success" : ""}>
                                        <td className="text-center align-middle">
                                            <Link className="btn btn-sm btn-primary"
                                                to={"/inventory/stock/enquiry?prodCode=" + item.prodCode}>
                                                {item.prodCode}
                                            </Link>
                                        </td>
                                        <td className="align-middle">
                                            <small>{item.supplierRef}</small>
                                        </td>
                                        <td className="align-middle">
                                            <div>{item.description}</div>
                                            {(item.stock && item.stock.keywayStock)
                                                ? <div>
                                                    {(item.stock.keywayStock.colour)
                                                        ? <div>{"Colour: " + item.stock.keywayStock.colour}</div> : null}
                                                    {
                                                        ((item.stock.keywayStock.length) || (item.stock.keywayStock.width) || (item.stock.keywayStock.height))
                                                            ? <div>
                                                                {(item.stock.keywayStock.length)
                                                                    ? <span
                                                                        className="mr-1">{("Length: " + item.stock.keywayStock.length + item.stock.keywayStock.dimensionUnitName)}</span>
                                                                    : null}
                                                                {(item.stock.keywayStock.width)
                                                                    ? <span
                                                                        className="ml-1 mr-1">{("Width: " + item.stock.keywayStock.width + item.stock.keywayStock.dimensionUnitName)}</span>
                                                                    : null}
                                                                {(item.stock.keywayStock.height)
                                                                    ? <span
                                                                        className="ml-1 mr-1">{("Height: " + item.stock.keywayStock.height + item.stock.keywayStock.dimensionUnitName)}</span>
                                                                    : null}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                : null}
                                        </td>
                                        <td className="text-center align-middle">
                                            <StockQtyFormat
                                                eachUnitName={item.eachUnitName}
                                                whUnitName={item.whUnitName}
                                                whUnitQty={item.whUnitQty}
                                                qty={item.qty}
                                                qtyType={STOCK_QTY_TYPE.each}
                                                format={STOCK_QTY_FORMAT.each}
                                                emptyPlaceholderText={"-"}
                                                isPoManage={false}
                                            />
                                        </td>
                                        <td className="text-center align-middle">
                                            {
                                                (item.whUnitQty && item.whUnitQty !== 1) ?
                                                    <StockQtyFormat
                                                        eachUnitName={item.eachUnitName}
                                                        whUnitName={item.whUnitName}
                                                        whUnitQty={item.whUnitQty}
                                                        qty={item.qty}
                                                        qtyType={STOCK_QTY_TYPE.each}
                                                        format={STOCK_QTY_FORMAT.packQty_of_PackSize}
                                                        emptyPlaceholderText={"-"}
                                                        isPoManage={false}
                                                    />
                                                    : "NA"
                                            }
                                        </td>
                                        <td className="text-center align-middle">
                                            <StockQtyFormat
                                                eachUnitName={item.eachUnitName}
                                                whUnitName={item.whUnitName}
                                                whUnitQty={item.whUnitQty}
                                                qty={item.received}
                                                qtyType={STOCK_QTY_TYPE.each}
                                                format={STOCK_QTY_FORMAT.each}
                                                emptyPlaceholderText={"-"}
                                                isPoManage={false}
                                            />
                                        </td>
                                        <td className="text-center align-middle">
                                            <StockQtyFormat
                                                eachUnitName={item.eachUnitName}
                                                whUnitName={item.whUnitName}
                                                whUnitQty={item.whUnitQty}
                                                qty={item.outstanding}
                                                qtyType={STOCK_QTY_TYPE.each}
                                                format={STOCK_QTY_FORMAT.each}
                                                emptyPlaceholderText={"-"}
                                                isPoManage={false}
                                            />
                                        </td>
                                        <td className="text-center align-middle">
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>All &nbsp;
                                                        <Input addon type="checkbox"
                                                            checked={item.qtyToBeReceived === item.outstanding}
                                                            onClick={() => this.handleChange((item.qtyToBeReceived === item.outstanding ? 0 : item.outstanding), "qtyToBeReceived", findIndex(order.orderItems, "itemNum", item.itemNum))} />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type={"number"} name={"qtyToBeReceived"}
                                                    placeholder={"qty"}
                                                    onFocus={(event) => event.target.select()}
                                                    value={item.qtyToBeReceived}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, findIndex(order.orderItems, "itemNum", item.itemNum))} />
                                            </InputGroup>
                                            <StockQtyFormat
                                                eachUnitName={item.eachUnitName}
                                                whUnitName={item.whUnitName}
                                                whUnitQty={item.whUnitQty}
                                                qty={item.qtyToBeReceived}
                                                qtyType={STOCK_QTY_TYPE.each}
                                                format={STOCK_QTY_FORMAT.each}
                                                emptyPlaceholderText={""}
                                                isPoManage={false}
                                            />
                                            <br />
                                            {item.isNotAllowed ?
                                                <span className={"text-danger"}>
                                                    {"Please check the Qty!"}
                                                </span>
                                                : null}
                                        </td>
                                        <td className="text-center align-middle">{changeFormatOfDateString(item.lastReceivedOn, 'DD/MM/yyyy', 'DD MMM yyyy')}</td>
                                        <td className="align-middle">
                                            {
                                                item.isLoadingLocationType
                                                    ? <Spinner color={"primary"} size={"sm"} />
                                                    : <Input type={"select"}
                                                        placeholder={"Select"}
                                                        name={"locationTypeID"}
                                                        value={item.locationTypeID}
                                                        className={"mb-1"}
                                                        onChange={(e) => this.handleChange(e.target.value, "locationTypeID", findIndex(order.orderItems, "itemNum", item.itemNum))}>
                                                        <option value={0}>Select</option>
                                                        {
                                                            WAREHOUSE_LOCATION_TYPES.map((locationType, locationIndex) =>
                                                                <option
                                                                    key={locationIndex}
                                                                    value={locationType.locationTypeID}>{locationType.name}</option>
                                                            )}
                                                    </Input>
                                            }
                                        </td>
                                        <td className="align-middle">
                                            <SearchWarehouseLocation
                                                handleSelection={(selected) => this.handleChange(selected ? selected.value : null, "locationID", findIndex(order.orderItems, "itemNum", item.itemNum))}
                                                locationID={item.locationID}
                                                locationTypeID={item.locationTypeID}
                                            />
                                            {
                                                ((item.stockWarehouseLocations || []).length > 0)
                                                    ? <Table size={"sm"} bordered className={"mb-0"}>
                                                        <tbody>
                                                            {
                                                                (item.stockWarehouseLocations || []).map((swl, swlIndex) => {
                                                                    if (swl.warehouseLocation) {
                                                                        return <tr key={swlIndex}>
                                                                            <td>{swl.warehouseLocation.warehouseLocationType ? swl.warehouseLocation.warehouseLocationType.name : ""}</td>
                                                                            <td><a href="javascript:void(0)"
                                                                                onClick={() => this.handleChange(swl.warehouseLocation.locationID, "locationID", findIndex(order.orderItems, "itemNum", item.itemNum))}>{swl.warehouseLocation.name}</a>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    return null
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    : null
                                            }
                                        </td>
                                        <td className="text-center align-middle">
                                            <Button className={"btn"} size={"sm"} color={"primary"} disabled={hasProcurementRole}
                                                onClick={() => this.checkQty(item)}>
                                                {item.isLoadingStockReceive
                                                    ? <Spinner color={"light"} size="sm" className={"mr-2"} />
                                                    : <i className="fa fa-floppy-o mr-2"
                                                        aria-hidden="true" />
                                                }
                                                Receive
                                            </Button>
                                        </td>
                                    </tr>
                                }
                                )}
                            </tbody>
                        </Table>
                        : null
                }
                <ToastContainer />
                {
                    isOpenQtyConfirmationModal ?
                        <ConfirmModal
                            isOpen={isOpenQtyConfirmationModal}
                            toggle={this.toggleQtyConfirmationModal}
                            handleSubmit={() => this.handleSubmitStockReceive(selectedItem)}
                            handleCancel={() => this.handleCancel(selectedItem)}
                            primaryMessage={"Warning!"}
                            secondaryMessage={isQtyZero ?
                                'Entered Qty is zero. Are you sure you want to receive this?' :
                                'Entered Qty is greater than the outstanding qty. Are you sure you want to receive this?'}
                            submitColor={"primary"}
                            cancelColor={"secondary"}
                            icon={"fa fa-exclamation-triangle fa-2x"}
                            loading={false}
                            type="Receiving..."
                        />
                        : null
                }
            </div>
        );
    }
}