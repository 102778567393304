import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    Table,
    TabPane
} from 'reactstrap';
import queryString from 'query-string';
import classnames from 'classnames';
import salesCustomerOrderService from './../../services/sales/SalesCustomerOrderService'
import OrderService from './../../services/OrderService'
import UserService from './../../services/UserService';
import SalesQuoteService from './../../services/sales/SalesQuoteService';
import {Link as Link} from "react-router-dom";
import {
    changeFormatOfDateString,
    displayECD,
    getDateString,
    getDistinctValues,
    handleErrorMessage
} from '../../services/CommonService';
import {convertQuoteToOrder, downloadInvoice, getquotesBody} from '../../services/CommonFunctions';
import NumberFormat from "react-number-format";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import {DebtorInvoiceStatusByID} from '../../store/DebtorInvoiceStatus';
import FileSaver from 'file-saver';
import {toast, ToastContainer} from 'react-toastify';
import OrdersTabFilter from '../../components/sales/OrdersTabFilter';
import ComposeEmail from "../../components/modal/ComposeEmail";
import ConfirmModal from "../../components/modal/ConfirmModal";
import SalesOrderItemProductDetailModal from '../../components/modal/SalesOrderItemProductDetailModal';


const tabs = [
    {
        key: "submitted",
        label: "Current Retail Orders",
        statusOptions: [
            {label: "All", value: ""},
            {label: "Active", value: "0"},
            {label: "Ready To Pick", value: "1"},
            {label: "Being Picked", value: "2"},
            {label: "Being Despatched", value: "3"},
        ]
    },
    {
        key: "completed",
        label: "Completed Retail Orders",
        statusOptions: [
            {label: "All", value: ""},
            {label: "Invoiced", value: "4"},
        ]
    },
    {
        key: "quote",
        label: "Quotes",
        statusOptions: [
            {label: "All", value: ""},
            {label: "Active", value: "0"},
            {label: "Cancelled", value: "-1"},
            {label: "Completed", value: "5"}
        ]
    },
    {
        key: "cancelled-ord/quot",
        label: "Cancelled Retail Orders/Quotes",
        statusOptions: []
    }
];
const maxDate = moment().add(2, 'years').toDate();
const minDate = moment().subtract(20, 'years').toDate();

export default class RetailOrders extends Component {

    constructor(props) {
        super(props);


        this.state = {
            orders: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ordNum",
                        direction: false
                    },
                    filterRequest: {
                        ordNum: null,
                        custOrdNum: "",
                        accountID: "",
                        startDate: undefined, endDate: undefined,
                        statusID: null,
                        salesOrderJobStatusId: null,
                        consignNum: "",
                        isCancelledQuote: true,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            loading: false,
            cancelReasonOption: [],
            showConfirmDelete: false,
            downloading: false,
            convertingToOrder: false,
            downloadQuote: false,
            loadingCancelledQuote: false,
            searchText: "",
            activeTab: "submitted",
            isOpenProductGroupModal: false,
            selectedOrder: {},
            user: {},
            emailModal: {
                isOpen: false,
                isLoadingEmailSend: false,
                mail: {
                    id: "",
                    from: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    fromOptions: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    to: [],
                    toOptions: [],
                    cc: [],
                    ccOptions: [],
                    bcc: [],
                    bccOptions: [],
                    subject: "",
                    body: "",
                    OrderNo: ""
                }
            }
        };

        this.refresh = this.refresh.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.printOrderSummaryInExcel = this.printOrderSummaryInExcel.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.emailQuote = this.emailQuote.bind(this);
        this.sendEmailModal = this.sendEmailModal.bind(this);
        this.downloadQuote = this.downloadQuote.bind(this);
        this.handleQuotesCancelation = this.handleQuotesCancelation.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.convertSelfQuoteToOrder = this.convertSelfQuoteToOrder.bind(this);
        this.getCancelReasonsOptions = this.getCancelReasonsOptions.bind(this);
        this.salesQuoteService = new SalesQuoteService();
        this.orderService = new OrderService();
        this.userService = new UserService();
    }

    componentDidMount() {
        let user = this.userService.getLoggedInUser();
        this.setState({user});
        this.getCancelReasonsOptions();
        this.refresh();
    }

    refresh() {
        let {orders} = this.state;
        this.getOrders(orders);
        this.getSalesOrderJobStatuses();
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let {salesOrderJobStatuses} = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({salesOrderJobStatuses})
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCancelReasonsOptions() {
        this.salesQuoteService.fetchSalesOrderCancelReasons().then(response => {
            // its a customer screen ,so quote cancel reason will always be = Customer Request
            let reason = response.data.filter(x => x.description === "Customer Request");
            this.setState({cancelReasonOption: reason});
        }).catch(error => {
            console.log(error.response);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    getOrders(orders) {
        let {activeTab} = this.state;
        let tab = activeTab.toUpperCase();
        this.setState({loading: true});
        let request = cloneDeep(orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        salesCustomerOrderService.getAllCustomerOrders(request, tab, true).then(response => {
            orders.response = response.data;
            this.setState({orders, loading: false});
        }).catch(error => {
            orders.response = [];
            this.setState({loading: false, orders});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }


    toggle(tab) {
        let {activeTab, orders} = this.state;
        if (activeTab !== tab) {
            activeTab = tab;
            orders.request.pageRequest.currentPage = 1;
            this.setState({activeTab, orders}, () => {
                this.getOrders(this.state.orders);
            });
        }
    }

    openProductGroupModal(order) {
        this.setState({selectedOrder: order, isOpenProductGroupModal: true});
    }

    toggleModal(change) {
        this.setState({isOpenProductGroupModal: change});
    }

    getProductGroupString(order) {
        let productGroupString = [];
        let productGroups = order.orderDetails;

        productGroups.map((data) => {
            productGroupString.push(data.product + "(" + data.qty + ")");
        });

        return productGroupString.toString();
    }

    toggleDeleteModal(change, Ord) {
        this.setState({showConfirmDelete: change, selectedOrder: Ord})
    }

    handleQuotesCancelation() {
        let CancelReasonId = 0;
        let {selectedOrder, cancelReasonOption} = this.state;
        if (cancelReasonOption.length > 0) {
            CancelReasonId = cancelReasonOption[0].reasonID;
        }
        if (CancelReasonId > 0) {
            this.setState({loadingCancelledQuote: true});
            this.salesQuoteService.cancelSelfOutstandingsQuote(CancelReasonId, selectedOrder.ordNum).then(response => {
                if (response.status === 200 || response.status === '200') {
                    this.setState({loadingCancelledQuote: false});
                    this.toggleDeleteModal(false, selectedOrder);
                    this.refresh();
                    toast.success("Quote cancelled successfully!")
                }
            }).catch(error => {
                this.setState({loadingCancelledQuote: false});
                toast.error(handleErrorMessage(error));
            });
        }
    }

    convertSelfQuoteToOrder(Order) {
        this.setState({selectedOrder: Order, convertingToOrder: true});
        convertQuoteToOrder(Order.ordNum, result => {     // here result is the callback value returned from common funct.
            if (result.status !== 200) {
                toast.error(handleErrorMessage(result));
                this.setState({selectedOrder: Order, convertingToOrder: false});
            } else {
                toast.success("Quote Converted successfully.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.refresh();
                this.setState({selectedOrder: Order, convertingToOrder: false});
            }
        });
    }

    downloadQuote(Order) {
        this.setState({selectedOrder: Order, downloadQuote: true});
        downloadInvoice(Order.ordNum, "quote", result => {     // here result is the callback value returned from common funct.
            if (result.status !== 200) {
                toast.error(handleErrorMessage(result));
                this.setState({selectedOrder: Order, downloadQuote: false});
            } else {
                this.setState({selectedOrder: Order, downloadQuote: false});
            }
        });
    }

    emailQuote(change, OrderNo) {
        let {emailModal} = this.state;
        emailModal.isOpen = change;
        emailModal.mail.subject = "Widoware | Quote # " + OrderNo;
        emailModal.mail.body = getquotesBody();
        emailModal.mail.OrderNo = OrderNo;
        this.setState({emailModal});
    }

    sendEmailModal(mail) {
        let {emailModal} = this.state;
        emailModal.isLoadingEmailSend = true;
        emailModal.mail = mail;
        this.setState({emailModal});

        let request = {
            tos: getDistinctValues(emailModal.mail.to ? emailModal.mail.to : [], 'value'),
            ccs: getDistinctValues(emailModal.mail.cc ? emailModal.mail.cc : [], 'value'),
            bccs: getDistinctValues(emailModal.mail.bcc ? emailModal.mail.bcc : [], 'value'),
            subject: emailModal.mail.subject,
            body: emailModal.mail.body,
            OrderNo: emailModal.mail.OrderNo
        };

        this.orderService.SendInvoiceInEmail(request).then(response => {
            if (response.data) {
                toast.success("Email sent!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
            this.emailQuote(false);
            emailModal.isLoadingEmailSend = false;
            this.setState({emailModal})
        }).catch(error => {
            console.log(error.data);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            emailModal.isLoadingEmailSend = false;
            this.setState({emailModal})
        });
    }

    getColValue(value, storeItem, order) {
        const {emailModal, downloadQuote, selectedOrder, user} = this.state;
        switch (storeItem.type) {
            case "product":
                return <Button className="btn btn-sm" color="link"
                               onClick={this.openProductGroupModal.bind(this, order)}>{this.getProductGroupString(order)}</Button>;

            case "link":
                return <Link
                    className="btn btn-sm btn-primary" style={{color: "white"}}
                    title={"Click here to view order in detail"}
                    to={"/sales/order/view?" + queryString.stringify({OrdNum: value})}>
                    {value}
                </Link>;

            case "date":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            case "ECD":
                return displayECD(value, 'DD MMM YYYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return <NumberFormat value={Math.floor(value)}
                                     displayType={'text'}
                                     decimalScale={2}
                                     thousandSeparator={true}
                                     fixedDecimalScale={true}
                                     prefix={'$'}/>;
            case "number":
                return <NumberFormat value={value}
                                     displayType={'text'}
                                     thousandSeparator={true}/>;
            case "statusByID":
                let result = "";
                switch (value) {
                    case 0:
                        result = <Badge color="primary">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 1:
                        result = <Badge color="warning">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 2:
                        result = <Badge color="warning">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 3:
                        result = <Badge color="info">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 4:
                        result = <Badge color="success">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case -1:
                        result = <Badge color="danger">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                }
                return <div className={"text-center"}>
                    <h5>{result}</h5>
                </div>;
            case "salesOrderJobStatus":
                let color = "light";
                if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId]) {
                    switch (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].statusCode) {
                        case "SUB":
                            color = "light";
                            break;
                        case "REV":
                            color = "info";
                            break;
                        case "CAN":
                            color = "danger";
                            break;
                        case "PEN":
                            color = "warning";
                            break;
                        default:
                            color = "info";
                    }
                    return <div className={"text-center"}><Badge
                        color={color}>{this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].statusDescription}</Badge>
                    </div>;
                }

                return "";
                return <div className={"text-center"}><Badge
                    color={color}>{this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].statusDescription}</Badge>
                </div>;
            case "downloadQuote":
                return <div className={"text-center"}><Button title="Download Quote" color='primary' size="sm"
                                                              id={`btn-${value}`}
                                                              onClick={() => this.downloadQuote(order)}>
                    {(downloadQuote && (order.ordNum === selectedOrder.ordNum))
                        ? <Spinner size={"sm"} className={"mr-2"}/>
                        : <i className="fa fa-file-pdf-o" aria-hidden="true"/>
                    }
                </Button></div>;
            case "emailQuote":
                return <div className={"text-center"}><Button title="Email Quote" color='primary' size="sm"
                                                              id={`btn-${value}`}
                                                              onClick={() => this.emailQuote(!emailModal.isOpen, order.ordNum)}>
                    <i className="fa fa-envelope" aria-hidden="true"/>
                </Button></div>;
            case "actionForQuotes":
                return <div className={"text-center"}>
                    <Link
                        className="btn btn-sm btn-secondary" style={{color: "white"}}
                        title={"Click here to view Quote in detail"}
                        to={"/sales/order/view?" + queryString.stringify({OrdNum: order.ordNum})}>
                        {"Review"}
                    </Link>
                    {' '}
                    {/*disabled={(!(order.isValidQuote) && user.isExternalUser)}*/}
                    <Button color={"primary"}
                            size={"sm"} onClick={() => this.convertSelfQuoteToOrder(order)}>
                        {(this.state.convertingToOrder && (order.ordNum === selectedOrder.ordNum))
                            ?
                            <Spinner size={"sm"} color={"warning"}/> :
                            <i className="fa fa-cart-arrow-down mr-2" aria-hidden="true"/>
                        }
                        {"Convert To Order"}
                    </Button>
                    {' '}
                    {
                        // customers cannot convert quote after 30 days*/
                    }
                    {order.createdFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                        ? <Link className="btn btn-sm btn-info"
                                to={"/sales/order/create/product-builder/v1?orderNum=" + order.ordNum}>
                            <i className="fa fa-external-link mr-2" aria-hidden="true"/>
                            Edit
                        </Link>
                        : null
                    }
                    {
                        /*style={(!(order.isValidQuote) && user.isExternalUser) ? { pointerEvents: "none", opacity: '0.6' } : null}
                        // customers can edit quote even after 30 days*/
                    }
                    {' '}
                    <Button color={"danger"}
                            disabled={(!order.isValidQuote)}
                            size={"sm"} onClick={() => this.toggleDeleteModal(true, order)}>
                        {(this.state.loadingCancelledQuote && (order.ordNum === selectedOrder.ordNum))
                            ?
                            <Spinner size={"sm"} color={"primary"}/> :
                            <i className="fa fa-ban mr-2 mr-2" aria-hidden="true"/>
                        }
                        {"Cancel"}
                    </Button>
                </div>;
            case "consumerName":
                if (value) {
                    return <span>{value + " " + order.retailLastName}</span>
                }
                return "";
                break;
            default:
                return <span>{value}</span>
        }
    }

    handleChange(change, key) {
        let {orders} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (orders.request.sortRequest.key === change) {
                    orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                } else {
                    orders.request.sortRequest.key = change;
                    orders.request.sortRequest.direction = false;
                }
                this.getOrders(orders);
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
            case "consignNum":
                orders.request.filterRequest[key] = change;
                this.getOrders(orders);
                break;

            case "salesOrderJobStatusId":
                orders.request.filterRequest[key] = parseInt(change);
                this.getOrders(orders);
                break;

            default:
                orders.request.filterRequest[key] = change;
                this.setState({orders});
                this.getOrders(orders);
        }

    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.ordNum.toString().includes(searchText);

            if (!flag && item.ordTotal) {
                flag = item.ordTotal.toString().includes(searchText)
            }
            if (!flag && item.custOrdNum) {
                flag = item.custOrdNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.consignNum) {
                flag = item.consignNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.couriers) {
                flag = item.couriers.toLowerCase().includes(searchText)
            }
            if (!flag && item.ordDate) {
                flag = changeFormatOfDateString(item.ordDate, "DD/MM/YYYY", 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.shipDate) {
                flag = changeFormatOfDateString(item.shipDate, 'DD/MM/YYYY', 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    getStore(orderRequest, salesOrderJobStatuses) {
        let {pageRequest, sortRequest, filterRequest} = orderRequest;
        let {activeTab} = this.state;
        let store = [
            {
                key: "ordDate",
                label: "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "submitted,quote,completed,cancelled-ord/quot",
                searchNode: <div>
                    <Row>
                        <Col xl={5} lg={12} md={12} sm={12} xs={12}>
                            <div className={"text-left"}>

                                <small>Start Date</small>
                            </div>
                        </Col>
                        <Col xl={7} lg={12} md={12} sm={12} xs={12}>
                            <div className={"text-right"}>
                                <DatePicker
                                    className="form-control form-control-sm"
                                    selected={filterRequest.startDate}
                                    onChange={date => this.handleChange(date, "startDate")}
                                    selectsStart
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/MM/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={5} lg={12} md={12} sm={12} xs={12}>
                            <div className={"text-left"}>
                                <small>End Date</small>
                            </div>
                        </Col>
                        <Col xl={7} lg={12} md={12} sm={12} xs={12}>
                            <div className={"text-right"}>
                                <DatePicker
                                    className="form-control form-control-sm"
                                    selected={filterRequest.endDate}
                                    onChange={date => this.handleChange(date, "endDate")}
                                    selectsEnd
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/MM/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                        </Col>

                    </Row>

                </div>
            },
            {
                key: "ordNum",
                label: "Order No.",
                type: "link",
                colSpan: 1,
                minWidth: "80px",
                sorterApplicable: true,
                valueClassName: "",
                show: "submitted,quote,completed,cancelled-ord/quot",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"number"}
                           placeholder={"Search..."}
                           name={"ordNum"}
                           value={filterRequest.ordNum}
                           onChange={(e) => this.handleChange(e.target.value, "ordNum")}/>
                </div>
            },
            {
                key: "custOrdNum",
                label: "Order Reference",
                type: "default",
                colSpan: 1,
                minWidth: "90px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "submitted,quote,completed,cancelled-ord/quot",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"custOrdNum"}
                           value={filterRequest.custOrdNum}
                           onChange={(e) => this.handleChange(e.target.value, "custOrdNum")}/>
                </div>
            },
            {
                key: "retailFirstName",
                label: "Consumer Name",
                type: "consumerName",
                colSpan: 1,
                minWidth: "90px",
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "submitted,quote,completed,cancelled-ord/quot",
                searchNode: null
            },
            {
                key: "retailPhone",
                label: "Consumer Phone",
                type: "text",
                colSpan: 1,
                minWidth: "90px",
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "submitted,quote,completed,cancelled-ord/quot",
                searchNode: null
            },
            {
                key: "retailEmail",
                label: "Consumer Email",
                type: "text",
                colSpan: 1,
                minWidth: "90px",
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "submitted,quote,completed,cancelled-ord/quot",
                searchNode: null
            },
            {
                key: "product",
                label: "Product",
                type: "product",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                show: "submitted,quote,completed,cancelled-ord/quot",
                searchNode: null
            },


            /*{
                key: "statusID",
                label: "Status",
                type: "statusByID",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"select"}
                           name={"statusID"}
                           value={filterRequest.statusID}
                           onChange={(e) => this.handleChange(e.target.value, "statusID")}>
                        {activeTab.statusOptions.map((option, index) =>
                            <option key={index} value={option.value}>{option.label}</option>
                        )}
                    </Input>
                </div>
            },*/ {
                key: "salesOrderJobStatusId",
                label: "Order Status",
                type: "salesOrderJobStatus",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "submitted,quote,completed,cancelled-ord/quot",
                searchNode: <div>
                    <Input type={"select"}
                           name={"salesOrderJobStatusId"}
                           value={filterRequest.salesOrderJobStatusId ? filterRequest.salesOrderJobStatusId : ""}
                           onChange={(e) => this.handleChange(e.target.value, "salesOrderJobStatusId")}>
                        <option value={""}>All</option>
                        {(salesOrderJobStatuses.statuses || []).map((option, index) =>
                            <option key={index} value={option.id}>{option.statusDescription}</option>
                        )}
                    </Input>
                </div>
            },
            {
                key: "cancelReason",
                label: "Cancellation Reason",
                type: "default",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                show: "cancelled-ord/quot",
                searchNode: null
            },
            {
                key: "ordTotal",
                label: "Order Total",
                type: "currency",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                show: "submitted,completed,quote,cancelled-ord/quot",
                searchNode: null
            },
            {
                key: "shipDate",
                label: "Shipment Date",
                type: "date",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "completed",
                searchNode: null
            },
            {
                key: "couriers",
                label: "Ship By",
                type: "text",
                colSpan: 1,
                minWidth: "160px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "completed",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"couriers"}
                           value={filterRequest.couriers}
                           onChange={(e) => this.handleChange(e.target.value, "couriers")}/>
                </div>
            },
            {
                key: "productionReadyDate",
                label: "ECD Date",
                type: "ECD",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "submitted",
                searchNode: null
            },
            {
                key: "consignNum",
                label: "ConsignNum",
                type: "default",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "completed",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"consignNum"}
                           value={filterRequest.consignNum}
                           onChange={(e) => this.handleChange(e.target.value, "consignNum")}/>
                </div>
            },
            {
                key: "",
                label: "Download",
                type: "downloadQuote",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "quote",
                searchNode: null
            },
            {
                key: "",
                label: "Email",
                type: "emailQuote",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "quote",
                searchNode: null
            },
            {
                key: "",
                label: "Action",
                type: "actionForQuotes",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                show: "quote",
                searchNode: null
            }
        ];

        return store;
    }

    printOrderSummaryInExcel = () => {
        let activeTab = this.state.activeTab;
        let request = cloneDeep(this.state.orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }

        this.setState({downloading: true});
        salesCustomerOrderService.GenerateOrderSummaryInExcelReport(request, activeTab.toUpperCase()).then(response => {
            this.setState({downloading: false});
            FileSaver.saveAs(response.data, "ORDER-SUMMARY " + ".xlsx");
        }).catch(error => {
            this.setState({downloading: false});
            console.log(error.data)
        });
    };

    showHeading(item, activeTab) {
        let flag = true;
        flag = item.show.includes(activeTab);

        return flag;
    }


    render() {
        let {orders, activeTab, loading, searchText, downloading, salesOrderJobStatuses, selectedOrder, emailModal, loadingCancelledQuote, showConfirmDelete} = this.state;
        let {pageRequest, sortRequest, filterRequest} = orders.request;
        let store = this.getStore(orders.request, salesOrderJobStatuses);

        return (
            <div>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;</Button>
                                        {activeTab === tab.key && loading
                                            ? <Spinner size={"sm"} color={"primary"}/>
                                            : null}

                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            {tabs.map((tab, index) => (
                                <TabPane tabId={tab.key} key={index}>
                                    {tab.key === activeTab ?
                                        <div>
                                            {activeTab !== 'quote' && activeTab !== 'cancelled-ord/quot' ?
                                                <OrdersTabFilter orders={orders} handleChange={this.handleChange}/>
                                                : null}
                                            {activeTab === 'cancelled-ord/quot' ?
                                                <Row className='ml-2' style={{alignItems: 'center'}}>
                                                    <div>
                                                        Show Cancelled &nbsp;&nbsp;
                                                    </div>
                                                    <div style={{float: 'left'}}>
                                                        <Input type='select' name='isCancelledQuote'
                                                               placeholder='select'
                                                               value={filterRequest.isCancelledQuote}
                                                               onChange={(e) => this.handleChange(e.target.value, "isCancelledQuote")}>
                                                            <option value='false'>Orders</option>
                                                            <option value='true'> Quotes</option>
                                                        </Input>
                                                    </div>

                                                </Row>
                                                : null}
                                            <br/>
                                            <div>

                                                <Table hover bordered size={"sm"} responsive>
                                                    <thead>
                                                    <tr>
                                                        <th className="align-middle">S.No</th>
                                                        {(store || []).filter((item) => this.showHeading(item, activeTab)).map((item, index) => {
                                                            return (
                                                                <th key={index}
                                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                                    colSpan={item.colSpan}
                                                                    className={item.labelClassName}
                                                                    style={{minWidth: item.minWidth}}>
                                                                    {item.label}
                                                                    {
                                                                        item.sorterApplicable ?
                                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                                }
                                                                            )} aria-hidden="true"/> : null
                                                                    }

                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {(orders.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td key={index}>
                                                                    {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                                                </td>
                                                                {(store || []).filter((item) => this.showHeading(item, activeTab)).map((storeItem, index) => {
                                                                    return (
                                                                        <td key={index}
                                                                            className={storeItem.valueClassName}>
                                                                            {this.getColValue(order[storeItem.key], storeItem, order)}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>

                                                </Table>
                                                <Row>
                                                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                                        <div className={"text-left"} style={{maxWidth: 440}}>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>Showing
                                                                        {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                                        {' '}of {orders.response.totalRecords}
                                                                        {' '}entries</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    type={"select"}
                                                                    name={"pageSize"}
                                                                    value={pageRequest.pageSize}
                                                                    disabled={loading}
                                                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                                    <option value={10}>10 Rows</option>
                                                                    <option value={25}>25 Rows</option>
                                                                    <option value={50}>50 Rows</option>
                                                                    <option value={100}>100 Rows</option>
                                                                    <option value={500}>500 Rows</option>
                                                                </Input>

                                                                <Button
                                                                    className={'btn btn-outline-success btn-sm ml-2'}
                                                                    outline={true}
                                                                    onClick={this.printOrderSummaryInExcel}
                                                                    disabled={downloading}>Export &nbsp;
                                                                    {downloading
                                                                        ? <Spinner size="sm"
                                                                                   className={"mr-2"}
                                                                                   style={{color: "green"}}/>
                                                                        : <i className="fa fa-file-excel-o"
                                                                             aria-hidden="true"/>}
                                                                </Button>
                                                            </InputGroup>


                                                        </div>
                                                    </Col>

                                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                        <div className={"float-right"}>
                                                            <Pagination
                                                                activePage={pageRequest.currentPage}
                                                                itemsCountPerPage={pageRequest.pageSize}
                                                                totalItemsCount={orders.response.totalRecords}
                                                                pageRangeDisplayed={3}
                                                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                                                itemClass='page-item'
                                                                linkClass='page-link'
                                                                activeClass='active'
                                                                innerClass='pagination'
                                                                activeLinkClass='active'
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        : null}
                                </TabPane>
                            ))}
                        </TabContent>

                    </CardBody>

                </Card>

                {this.state.isOpenProductGroupModal ?
                    <SalesOrderItemProductDetailModal
                        order={selectedOrder}
                        isOpen={this.state.isOpenProductGroupModal}
                        toggle={this.toggleModal}/>
                    : null
                }

                {
                    emailModal.isOpen ?
                        <ComposeEmail isOpen={emailModal.isOpen}
                                      isSendingEmail={emailModal.isLoadingEmailSend}
                                      toggle={this.emailQuote}
                                      message={emailModal.mail}
                                      handleSubmit={this.sendEmailModal}
                                      parentType={"Quotes"}
                        />
                        : null
                }

                <ConfirmModal
                    isOpen={showConfirmDelete}
                    toggle={this.toggleDeleteModal}
                    handleSubmit={() => this.handleQuotesCancelation()}
                    handleCancel={() => this.toggleDeleteModal(false, {})}
                    primaryMessage={"Are you sure you want to cancel this quote?"}
                    secondaryMessage={""}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    loading={loadingCancelledQuote}
                    type="Cancelling..."
                />
                <ToastContainer/>
            </div>
        );
    }
}