import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import {isHaymanAndNoosaCustomer, round, roundUp} from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    ANDERSENS_ACCOUNT_ID, BUNNINGS_ACCOUNT_ID,
    PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE
} from "../../../../../../store/AppConstants";
import {findItems} from "../../../../../../../../ClientApp/src/services/CommonService";

class RollerBlindSingleUtil {

    static Instance() {
        return new RollerBlindSingleUtil();
    }

    updateFormError(key, product, itemIndex, subItemIndex) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "room":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.room.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }
                if (!isEmpty(product.items[itemIndex].configuration.room.selected.value) &&
                    product.items[itemIndex].configuration.room.selected.value.length > 50) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than 50 characters",
                    };
                }
                product.items[itemIndex].configuration.room.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.room.selected.value === i.configuration.room.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.room.formError.isValid = true;
                        i.configuration.room.formError.isWarning = true;
                        i.configuration.room.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.room.formError.message === "Duplicate location!") {
                            i.configuration.room.formError.isValid = true;
                            i.configuration.room.formError.isWarning = false;
                            i.configuration.room.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value) || (product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value < product.items[itemIndex].subItems[subItemIndex].configuration.width.min) || (product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value > product.items[itemIndex].subItems[subItemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].subItems[subItemIndex].configuration.width.min + " and " + product.items[itemIndex].subItems[subItemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].subItems[subItemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value) || (product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value < product.items[itemIndex].subItems[subItemIndex].configuration.drop.min) || (product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value > product.items[itemIndex].subItems[subItemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].subItems[subItemIndex].configuration.drop.min + " and " + product.items[itemIndex].subItems[subItemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].subItems[subItemIndex].configuration.drop.formError = formError;
                break;
            case "totalWidth":
                switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                    case "Single":
                        if ((!product.items[itemIndex].configuration.totalWidth.selected.value) || (product.items[itemIndex].configuration.totalWidth.selected.value > product.items[itemIndex].configuration.totalWidth.max)) {
                            formError = {
                                isValid: false,
                                message: "Expected width below " + product.items[itemIndex].configuration.totalWidth.max,
                            };
                        }
                        break;
                    case "Single 2Link Non Driven":
                    case "Single 2Link Driven":
                        if ((!product.items[itemIndex].configuration.totalWidth.selected.value) || (product.items[itemIndex].configuration.totalWidth.selected.value > product.items[itemIndex].configuration.totalWidth.max)) {
                            formError = {
                                isValid: false,
                                message: "Expected width below " + product.items[itemIndex].configuration.totalWidth.max,
                            };
                        }
                        break;
                    case "Single 3Link":
                        let combinedLinkedAndDrivedBlindsWidth = 0;
                        let singleDrivedBlindWidth = 0;
                        if (product.items[itemIndex].subItems[0] && product.items[itemIndex].subItems[1] && product.items[itemIndex].subItems[2]) {
                            switch (product.items[itemIndex].configuration.diagram.selected.value.optionKey) {
                                case "Single 3Link Left Linked":
                                    combinedLinkedAndDrivedBlindsWidth = product.items[itemIndex].subItems[0].configuration.width.selected.value + product.items[itemIndex].subItems[1].configuration.width.selected.value;
                                    singleDrivedBlindWidth = product.items[itemIndex].subItems[2].configuration.width.selected.value;
                                    break;
                                case "Single 3Link Right Linked":
                                    combinedLinkedAndDrivedBlindsWidth = product.items[itemIndex].subItems[1].configuration.width.selected.value + product.items[itemIndex].subItems[2].configuration.width.selected.value;
                                    singleDrivedBlindWidth = product.items[itemIndex].subItems[0].configuration.width.selected.value;
                                    break;
                                case "Single 3Link All Linked LHC":
                                    combinedLinkedAndDrivedBlindsWidth = product.items[itemIndex].subItems[1].configuration.width.selected.value + product.items[itemIndex].subItems[2].configuration.width.selected.value;
                                    singleDrivedBlindWidth = product.items[itemIndex].subItems[0].configuration.width.selected.value;
                                    break;
                                case "Single 3Link All Linked RHC":
                                    combinedLinkedAndDrivedBlindsWidth = product.items[itemIndex].subItems[0].configuration.width.selected.value + product.items[itemIndex].subItems[1].configuration.width.selected.value;
                                    singleDrivedBlindWidth = product.items[itemIndex].subItems[2].configuration.width.selected.value;
                                    break;
                            }

                            if ((!product.items[itemIndex].configuration.totalWidth.selected.value)) {
                                formError = {
                                    isValid: false,
                                    message: "Expected width upto " + product.items[itemIndex].configuration.totalWidth.max + "mm",
                                };
                                break;
                            }
                            if (combinedLinkedAndDrivedBlindsWidth > product.items[itemIndex].configuration.totalWidth.maxForCombinedLinkedAndDrivedBlinds) {
                                switch (product.items[itemIndex].configuration.diagram.selected.value.optionKey) {
                                    case "Single 3Link Left Linked":
                                        formError = {
                                            isValid: false,
                                            message: "Expected sum of 1st(Left) and 2nd(Center) Blind's width below than " + product.items[itemIndex].configuration.totalWidth.maxForCombinedLinkedAndDrivedBlinds + "mm",
                                        };
                                        break;
                                    case "Single 3Link Right Linked":
                                        formError = {
                                            isValid: false,
                                            message: "Expected sum of 2nd(Center) and 3rd(Right) Blind's width below than " + product.items[itemIndex].configuration.totalWidth.maxForCombinedLinkedAndDrivedBlinds + "mm",
                                        };
                                        break;
                                }
                                break;
                            }
                            if (singleDrivedBlindWidth > product.items[itemIndex].configuration.totalWidth.maxForSingleDrivedBlindWidth) {
                                switch (product.items[itemIndex].configuration.diagram.selected.value.optionKey) {
                                    case "Single 3Link Left Linked":
                                        formError = {
                                            isValid: false,
                                            message: "Expected 3rd(Right) Blind's width below than " + product.items[itemIndex].configuration.totalWidth.maxForSingleDrivedBlindWidth + "mm",
                                        };
                                        break;
                                    case "Single 3Link Right Linked":
                                        formError = {
                                            isValid: false,
                                            message: "Expected 1st(Left) Blind's width below than " + product.items[itemIndex].configuration.totalWidth.maxForSingleDrivedBlindWidth + "mm",
                                        };
                                        break;
                                }
                                break;
                            }
                            if ((combinedLinkedAndDrivedBlindsWidth + singleDrivedBlindWidth) > product.items[itemIndex].configuration.totalWidth.max) {
                                formError = {
                                    isValid: false,
                                    message: "Expected total width below than " + product.items[itemIndex].configuration.totalWidth.max + "mm",
                                };
                                break;
                            }
                            break;
                        }
                }
                product.items[itemIndex].configuration.totalWidth.formError = formError;
                break;
            default:
                break;
        }

        if (subItemIndex > -1) {
            product.items[itemIndex].subItems[subItemIndex].isValid =
                product.items[itemIndex].subItems[subItemIndex].configuration.width.formError.isValid
                && product.items[itemIndex].subItems[subItemIndex].configuration.drop.formError.isValid;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return (product.items[itemIndex].subItems || []).every((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].isValid)
            && product.items[itemIndex].configuration.room.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.totalWidth.formError.isValid;
    }


    updateRules(key, product, itemIndex, subItemIndex, order) {
        let optionIndex, drivenByBlindSequence, condition1, condition2, condition3;
        let isHaymanAndNoosa = isHaymanAndNoosaCustomer(order.accountID);
        switch (key) {
            case "width":
                drivenByBlindSequence = product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value;
                if (!isEmpty(product.items[itemIndex].subItems[drivenByBlindSequence - 1])) {
                    switch (product.items[itemIndex].subItems[drivenByBlindSequence - 1].configuration.operation.selected.value.optionKey) {
                        case "DC Battery Motor":
                            if (product.items[itemIndex].configuration.heavyDuty.selected.value) {
                                product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 885;
                            } else {
                                product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 575;
                            }
                            product.items[itemIndex].subItems[subItemIndex].configuration.width.max = 3010;
                            break;
                        case "RTS AC Motor":
                            product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 690;
                            product.items[itemIndex].subItems[subItemIndex].configuration.width.max = 3010;
                            break;
                        case "4-Core Motor":
                            product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 590;
                            product.items[itemIndex].subItems[subItemIndex].configuration.width.max = 3010;
                            break;
                        case "Chain":
                            product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 200;
                            product.items[itemIndex].subItems[subItemIndex].configuration.width.max = 3010;
                            break;
                        default:
                            break;
                    }
                }
                break;
            case "drop":
                product.items[itemIndex].subItems[subItemIndex].configuration.drop.min = 400;
                product.items[itemIndex].subItems[subItemIndex].configuration.drop.max = 3000;
                break;
            case "totalWidth":
                switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                    case "Single":
                        product.items[itemIndex].configuration.totalWidth.max = 3010;
                        break;
                    case "Single 2Link Non Driven":
                        product.items[itemIndex].configuration.totalWidth.max = 6020;
                        break;
                    case "Single 2Link Driven":
                        product.items[itemIndex].configuration.totalWidth.max = 5200;
                        break;
                    case "Single 3Link":
                        product.items[itemIndex].configuration.totalWidth.max = 8200;
                        product.items[itemIndex].configuration.totalWidth.maxForCombinedLinkedAndDrivedBlinds = 5200;
                        product.items[itemIndex].configuration.totalWidth.maxForSingleDrivedBlindWidth = 3000;
                        break;
                }
                break;
            case "heavyDuty":
                drivenByBlindSequence = product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value;

                switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                    case "Single":
                    case "Single 2Link Non Driven":
                        if (!isEmpty(product.items[itemIndex].subItems[drivenByBlindSequence - 1])) {
                            switch (product.items[itemIndex].subItems[drivenByBlindSequence - 1].configuration.operation.selected.value.optionKey) {
                                case "Chain":
                                case "DC Battery Motor":
                                    if (product.items[itemIndex].subItems.find(subItem => subItem.configuration.width.selected.value <= 2110)) {
                                        product.items[itemIndex].configuration.heavyDuty.selected.isSelectable = true;

                                        //selected optional
                                        product.items[itemIndex].configuration.heavyDuty.selected.value = product.items[itemIndex].configuration.heavyDuty.selected.isManuallySelected;
                                    }
                                    if (product.items[itemIndex].subItems.find(subItem => subItem.configuration.width.selected.value > 2110)) {
                                        product.items[itemIndex].configuration.heavyDuty.selected.isSelectable = false;

                                        //selected mandatory
                                        product.items[itemIndex].configuration.heavyDuty.selected.value = true;
                                    }
                                    break;
                                case "RTS AC Motor":
                                case "4-Core Motor":
                                    product.items[itemIndex].configuration.heavyDuty.selected.isSelectable = false;
                                    product.items[itemIndex].configuration.heavyDuty.selected.value = true;
                                    break;
                                default:
                                    break;
                            }
                        }

                        break;
                    case "Single 2Link Driven":
                    case "Single 3Link":
                        product.items[itemIndex].configuration.heavyDuty.selected.isSelectable = false;
                        product.items[itemIndex].configuration.heavyDuty.selected.value = true;
                        break;
                }
                break;
            case "motor":
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey) {
                        case "DC Battery Motor":
                            if (product.items[itemIndex].configuration.heavyDuty.selected.value) {
                                product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.selectedSet = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.sets.find(s => s.condition1 === "Heavy Duty");
                            } else {
                                product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.selectedSet = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.sets.find(s => s.condition1 === "Standard Duty");
                            }
                            break;
                        case "RTS AC Motor":
                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.selectedSet = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.sets[0];
                            break;
                        case "4-Core Motor":
                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.selectedSet = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.sets[0];
                            break;
                        case "Chain":
                            break;
                        default:
                    }
                }
                break;
            case "chargingCable":
            case "remote15Channels":
            case "wifiBridge":
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey) {
                        case "DC Battery Motor":
                            product.items[itemIndex].configuration.remote15Channels.selected.isSelectable = true;
                            product.items[itemIndex].configuration.wifiBridge.selected.isSelectable = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = true;
                            break;
                        case "RTS AC Motor":
                            product.items[itemIndex].configuration.remote15Channels.selected.isSelectable = true;

                            product.items[itemIndex].configuration.wifiBridge.selected.isSelectable = false;
                            product.items[itemIndex].configuration.wifiBridge.selected.value = false;

                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                            product.items[itemIndex].configuration.chargingCable.selected.value = false;
                            break;
                        case "4-Core Motor":
                        case "Chain":
                        default:
                            product.items[itemIndex].configuration.remote15Channels.selected.isSelectable = false;
                            product.items[itemIndex].configuration.remote15Channels.selected.value = false;

                            product.items[itemIndex].configuration.wifiBridge.selected.isSelectable = false;
                            product.items[itemIndex].configuration.wifiBridge.selected.value = false;

                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                            product.items[itemIndex].configuration.chargingCable.selected.value = false;


                    }
                }
                break;
            case "chainColor":
            case "chainLength":
                if (product.items[itemIndex].subItems && product.items[itemIndex].subItems.length > 0) {
                    let startCursor = (subItemIndex !== null && subItemIndex >= 0) ? subItemIndex : 0;
                    let endCursor = (subItemIndex !== null && subItemIndex >= 0) ? subItemIndex : product.items[itemIndex].subItems.length - 1;

                    for (startCursor; startCursor <= endCursor; startCursor++) {
                        subItemIndex = startCursor;
                        if (isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value)) {
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable = false;
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value = {};
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.dropdownValue = "";

                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable = false;
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value = {};
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.dropdownValue = "";
                        } else {
                            switch (product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey) {
                                case "RTS AC Motor":
                                case "4-Core Motor":
                                case "DC Battery Motor":
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable = false;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value = {};
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.dropdownValue = "";

                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable = false;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value = {};
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.dropdownValue = "";
                                    break;
                                case "Chain":
                                default:
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable = !isHaymanAndNoosa;

                                    if (product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable) {
                                        if (isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value)) {
                                            optionIndex = 0;
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.finalOptions[optionIndex];
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value.optionKey;
                                        }
                                    }
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable = !isHaymanAndNoosa;

                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.options = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value) ? [] : product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value.sets;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.options;

                                    if (product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable) {
                                        if (isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value)) {
                                            optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions.findIndex((o, oIndex) => {
                                                return product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value >= o.min && product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value <= o.max;
                                            });
                                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions[optionIndex];
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions[optionIndex].optionKey;
                                        }
                                    }
                            }
                        }
                    }
                }
                break;
            case "operation":
                (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                    switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                        case "Single":
                            switch (subItemIndex) {
                                case 0:
                                    product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = true;
                                    break;
                                case 1:
                                case 2:
                                default:
                                    break;
                            }
                            break;
                        case "Single 2Link Driven":
                            switch (subItemIndex) {
                                case 0:
                                case 1:
                                    let toBeEnabledSubItemIndex = null;
                                    let toBeDisabledSubItemIndex = null;
                                    let controlSide = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide");
                                    switch (controlSide) {
                                        case "Right":
                                            toBeEnabledSubItemIndex = 1;
                                            toBeDisabledSubItemIndex = 0;
                                            break;
                                        case "Left":
                                        default:
                                            toBeEnabledSubItemIndex = 0;
                                            toBeDisabledSubItemIndex = 1;
                                            break;
                                    }

                                    if (toBeEnabledSubItemIndex !== null && toBeDisabledSubItemIndex !== null && !isEmpty(product.items[itemIndex].subItems[toBeEnabledSubItemIndex]) && !isEmpty(product.items[itemIndex].subItems[toBeDisabledSubItemIndex])) {
                                        product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.operation.selected.isSelectable = true;

                                        //operation
                                        if (!isEmpty(product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.operation.selected.value) && isEmpty(product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.operation.selected.value)) {
                                            // before making empty copy value to 1st blind
                                            product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.operation.selected.value = product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.operation.selected.value;
                                            product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.operation.selected.dropdownValue;
                                        }
                                        //now empty it
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.operation.selected.isSelectable = false;
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.operation.selected.value = {};
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.operation.selected.dropdownValue = "";

                                        //chain color
                                        if (!isEmpty(product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainColor.selected.value) && isEmpty(product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.chainColor.selected.value)) {
                                            // before making empty copy value to 1st blind
                                            product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.chainColor.selected.value = product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainColor.selected.value;
                                            product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.chainColor.selected.dropdownValue = product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainColor.selected.dropdownValue;
                                        }
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainColor.selected.isSelectable = false;
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainColor.selected.value = {};
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainColor.selected.dropdownValue = "";

                                        //chain length
                                        if (!isEmpty(product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainLength.selected.value) && isEmpty(product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.chainLength.selected.value)) {
                                            // before making empty copy value to 1st blind
                                            product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.chainLength.selected.value = product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainLength.selected.value;
                                            product.items[itemIndex].subItems[toBeEnabledSubItemIndex].configuration.chainLength.selected.dropdownValue = product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainLength.selected.dropdownValue;
                                        }
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainLength.selected.isSelectable = false;
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainLength.selected.value = {};
                                        product.items[itemIndex].subItems[toBeDisabledSubItemIndex].configuration.chainLength.selected.dropdownValue = "";
                                    }
                                    break;
                                case 2:
                                default:
                                    break;
                            }
                            break;
                        case "Single 2Link Non Driven":
                            switch (subItemIndex) {
                                case 0:
                                    product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = true;
                                    break;
                                case 1:
                                    product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = true;
                                    break;
                                case 2:
                                default:
                                    break;
                            }
                            break;
                        case "Single 3Link":

                            switch (product.items[itemIndex].configuration.diagram.selected.value.optionKey) {
                                case "Single 3Link Left Linked":
                                case "Single 3Link Right Linked":
                                    switch (subItemIndex) {
                                        case 0:
                                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = true;
                                            break;
                                        case 1:
                                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = false;
                                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value = {};
                                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.dropdownValue = "";

                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable = false;
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value = {};
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.dropdownValue = "";

                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable = false;
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value = {};
                                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.dropdownValue = "";

                                            break;
                                        case 2:
                                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = true;
                                            break;
                                        default:
                                            break;
                                    }
                                    break;
                                case "Single 3Link All Linked LHC":
                                case "Single 3Link All Linked RHC":
                                    product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = subItem.configuration.drivenByBlindSequence.selected.value === subItem.configuration.blindSequence.selected.value;

                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable = false;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value = {};
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.dropdownValue = "";

                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable = false;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value = {};
                                    product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.dropdownValue = "";

                                    if (!product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable) {
                                        product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable = false;
                                        product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value = {};
                                        product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.dropdownValue = "";
                                    }
                                    break;
                            }
                            break;
                    }
                });
                break;
            case "operationGroupCombination":
                drivenByBlindSequence = product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value;

                if (!isEmpty(product.items[itemIndex].subItems[drivenByBlindSequence - 1])) {
                    condition1 = product.items[itemIndex].configuration.heavyDuty.selected.value ? "Heavy Duty" : "Standard Duty";
                    condition2 = "";
                    switch (product.items[itemIndex].subItems[drivenByBlindSequence - 1].configuration.operation.selected.value.optionKey) {
                        case "RTS AC Motor":
                        case "4-Core Motor":
                        case "DC Battery Motor":
                            condition2 = "Operation!=Chain";
                            break;
                        case "Chain":
                            condition2 = "Operation==Chain";
                            break;
                        default:
                            break;
                    }
                    //--------------
                    condition3 = product.items[itemIndex].configuration.diagram.selected.value.optionKey;
                    product.items[itemIndex].configuration.operationGroupCombination.selected.value = product.items[itemIndex].configuration.operationGroupCombination.finalOptions.find(x => {
                        return x.condition1 === condition1 && x.condition2 === condition2 && (x.condition3 ? x.condition3.includes(condition3) : true);
                    });
                }

                break;
            case "bracketCovers":
                if (product.items[itemIndex].configuration.mount.selected.value.optionKey === "Outside/Face") {
                    product.items[itemIndex].configuration.bracketCovers.selected.isSelectable = true;
                    product.items[itemIndex].configuration.bracketCovers.selected.value = true;
                } else {
                    product.items[itemIndex].configuration.bracketCovers.selected.isSelectable = false;
                    product.items[itemIndex].configuration.bracketCovers.selected.value = false;
                }
                break;
            case "bottomRailColor":
                product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.isSelectable = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value.optionKey !== "Sewn In Lathe";
                break;
        }
        return product;
    }


    updateStocks(key, product, itemIndex, subItemIndex, order) {
        let option, set, stocks, condition1, condition2, condition3, condition4, label, attribute, tempStocks,
            bracketColor, isHaymanAndNoosa, sets;
        isHaymanAndNoosa = isHaymanAndNoosaCustomer(order.accountID);
        switch (key) {
            case "set":
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE,
                    price: product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];

                attribute = "SET";
                label = "";
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "mount":
                stocks = [];
                stocks = isEmpty(product.items[itemIndex].configuration.mount.selected.value) ? [] : product.items[itemIndex].configuration.mount.selected.value.stocks;
                attribute = "Mount";
                label = "";
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                    product.items[itemIndex].subItems[subItemIndex].configuration.mount.selected.stocks = stocks;
                });
                break;
            case "heavyDuty":
                stocks = [];
                attribute = "Heavy Duty";
                label = "";
                (product.items[itemIndex].configuration.heavyDuty.finalOptions || []).forEach(o => {
                    stocks = [...stocks, ...o.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                    product.items[itemIndex].subItems[subItemIndex].configuration.heavyDuty.selected.stocks = stocks;
                });
                break;
            case "operationGroupCombination":
                attribute = "Operation group";
                if (product.items[itemIndex].configuration.operationGroupCombination && product.items[itemIndex].configuration.operationGroupCombination.selected.value && product.items[itemIndex].configuration.operationGroupCombination.selected.value.description) {
                    switch (product.items[itemIndex].configuration.operationGroupCombination.selected.value.description) {
                        case "38C STD":
                        case "38M STD":
                        case "50C STD":
                        case "50M STD":
                            //single
                            subItemIndex = 0;
                            label = product.items[itemIndex].configuration.operationGroupCombination.selected.value.description;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }

                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                              
                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }
                            break;
                        case "38C STD IDLER + 38C F-LINK":
                            // single 2link non driven
                            // 1st blind---------------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "38C F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;

                            }


                            // 2nd blind---------------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "38C STD IDLER";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }
                            break;
                        case "38M STD IDLER + 38M F-LINK":
                            // single 2link non driven
                            //1st blind -------------------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "38M F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })
                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            //2nd blind------------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "38M STD IDLER";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }
                            break;
                        case "50C STD IDLER + 50C F-LINK":
                            // single 2link non driven
                            //1st blind-----------------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50C F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //2nd blind---------------------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50C STD IDLER";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }
                            break;
                        case "50M STD IDLER + 50M F-LINK":
                            // single 2link non driven
                            //1st blind---------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50M F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //2nd blind--------------------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50M STD IDLER";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            break;
                        case "50C M-LINK + 50C F-LINK":
                            // single 2link driven
                            //1st blind---------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? "50C F-LINK" : "50C M-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];

                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //2nd blind---------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? "50C M-LINK" : "50C F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            break;
                        case "50M M-LINK + 50M F-LINK":
                            // single 2link driven
                            //1st blind---------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? "50M F-LINK" : "50M M-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];

                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //2nd blind---------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? "50M M-LINK" : "50M F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            break;
                        case "50C M-LINK IDLER + 2 x 50C F-LINK":
                            //1st blind---------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50C F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //2nd blind---------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50C M-LINK IDLER";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //3rd blind---------------
                            subItemIndex = 2;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50C F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            break;
                        case "50M M-LINK IDLER + 2 x 50M F-LINK":
                            //1st blind---------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50M F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //2nd blind---------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50M M-LINK IDLER";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //3rd blind
                            subItemIndex = 2;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "50M F-LINK";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                                    stocks.forEach((st, stIndex) => {
                                        if (order.accountID.includes("01500")) {
                                            st.price = parseFloat(String(st.price).replace("34", "32.2"), 10);
                                        }
                                    })

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            break;
                        case "Motor 3Link DERIVED + 2 x Motor 3Link LINKED":
                            //1st blind---------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "Motor 3Link DERIVED";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            //2nd blind---------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "Motor 3Link LINKED";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //3rd blind
                            subItemIndex = 2;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "Motor 3Link LINKED";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            break;
                        case "2 x Motor 3Link LINKED + Motor 3Link DERIVED":
                            //1st blind---------------
                            subItemIndex = 0;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "Motor 3Link LINKED";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            //2nd blind---------------
                            subItemIndex = 1;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "Motor 3Link LINKED";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }


                            //3rd blind
                            subItemIndex = 2;
                            if (product.items[itemIndex].subItems[subItemIndex]) {
                                label = "Motor 3Link DERIVED";
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                stocks = [];
                                option = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions.find(x => x.optionKey === label);
                                if (!isEmpty(option)) {
                                    bracketColor = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "bracketColor");
                                    set = option.sets.find(x => x.condition1 === bracketColor);
                                    if (!isEmpty(set)) {
                                        stocks = [...stocks, ...set.stocks];
                                    }
                                }
                                stocks.forEach((stockItem, stockIndex) => {
                                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                                });
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value = label;
                                product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks = stocks;
                            }

                            break;
                    }
                }

                break;
            case "operation":
                attribute = "Operation";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey) {
                        case "RTS AC Motor":
                            condition1 = order.accountID.includes(BUNNINGS_ACCOUNT_ID) ? "parentAccountID==01500" : "default";
                            set = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.sets.find(x => x.condition1 === condition1);
                            if (!isEmpty(set)) {
                                stocks = set.stocks;
                            }
                            label = "Motorised";
                            break;
                        case "4-Core Motor":
                            set = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.sets[0];
                            if (!isEmpty(set)) {
                                stocks = set.stocks;
                            }
                            label = "Motorised";
                            break;
                        case "DC Battery Motor":
                            condition1 = product.items[itemIndex].configuration.heavyDuty.selected.value ? "Heavy Duty" : "Standard Duty";
                            condition2 = order.accountID.includes(BUNNINGS_ACCOUNT_ID) ? "parentAccountID==01500" : "default";
                            set = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.sets.find(x => x.condition1 === condition1 && x.condition2 === condition2);
                            if (!isEmpty(set)) {
                                stocks = set.stocks;
                            }
                            label = "Motorised";
                            break;
                        case "Chain":
                            label = "Chain";
                            break;
                        default:
                            break;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);

                });
                product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.stocks = stocks;

                break;
            case "fabricColor":
                attribute = "Skin";
                stocks = [{
                    id: null,
                    description: "",
                    prodCode: product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.productConfigurationStock.swishQuantityFormula,
                    keywayQuantityFormula: product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.productConfigurationStock.keywayQuantityFormula,
                    wastageFormula: product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.productConfigurationStock.wastageFormula,
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1000,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.stocks = stocks;
                break;
            case "rollDirection":
                attribute = "Roll Direction";
                stocks = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.value) ? [] : product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.value.stocks;
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.stocks = stocks;
                break;
            case "controlSide":
                attribute = "Control Side";
                stocks = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value) ? [] : product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.stocks;
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.stocks = stocks;
                break;
            case "chainLength":
                attribute = "Chain";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value)) {
                    stocks = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    if (product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value.optionKey === "Stainless Steel") {
                        if (order.accountID.includes(ANDERSENS_ACCOUNT_ID)) {
                            stockItem.price = 3; // suggested by @Kali
                        }
                        if (order.accountID.includes(BUNNINGS_ACCOUNT_ID)) {
                            stockItem.price = 12; // suggested by @Kali
                            switch (true) {
                                case product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value.setNum <= 125:
                                    stockItem.price = 12.24;
                                    break;
                                case product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value.setNum <= 150:
                                    stockItem.price = 14.58;
                                    break;
                                case product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value.setNum <= 200:
                                    stockItem.price = 17.47;
                                    break;
                                case product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value.setNum <= 100000:
                                    stockItem.price = 19.5;
                                    break;
                            }
                        }

                    }
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.stocks = stocks;
                break;
            case "bracketColor":
                if (product.items[itemIndex].configuration.bracketColor && !isEmpty(product.items[itemIndex].configuration.bracketColor.selected)) {
                    attribute = "Brackets";
                    stocks = [];
                    tempStocks = [];
                    if (!isEmpty(product.items[itemIndex].configuration.bracketColor.selected.value)) {

                        condition1 = product.items[itemIndex].configuration.layout.selected.value.optionKey;
                        condition2 = product.items[itemIndex].configuration.heavyDuty.selected.value ? "Heavy Duty" : "Standard Duty";
                        condition3 = product.items[itemIndex].configuration.bracketCovers.selected.value ? "bracketCovers==true" : "bracketCovers==false";
                        condition4 = product.items[itemIndex].configuration.layout.selected.value.optionKey;

                        switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                            case "Single":
                                set = product.items[itemIndex].configuration.bracketColor.selected.value.sets.find(x => x.condition1 === condition1 && x.condition2 === condition2 && x.description === condition3);
                                break;
                            case "Single 2Link Non Driven":
                            case "Single 2Link Driven":
                                set = product.items[itemIndex].configuration.bracketColor.selected.value.sets.find(x => x.condition1 === condition1 && x.description === condition3);
                                break;
                            case "Single 3Link":
                                set = product.items[itemIndex].configuration.bracketColor.selected.value.sets.find(x => x.condition1 === condition1 && x.description === condition3 && x.condition3.includes(condition4));
                                break;
                        }
                        if (!isEmpty(set)) {
                            tempStocks = set.stocks;
                        }
                    }

                    //Only needs to be supplied once per set of linked blinds @ below quantities
                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        if (subItemIndex === 0) {
                            stocks = [...tempStocks]; // to deep clone
                        } else {
                            switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                                case "Single":
                                case "Single 2Link Driven":
                                case "Single 3Link":
                                    stocks = [];
                                    break;
                                case "Single 2Link Non Driven":
                                    stocks = (tempStocks || []).filter(x => ["08812", "08813", "08814", "08815"].includes(x.prodCode));
                                    break;
                            }
                        }
                        stocks = stocks.map(s => cloneDeep(s));

                        (stocks || []).forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                        });

                        product.items[itemIndex].subItems[subItemIndex].configuration.bracketColor.selected.stocks = stocks;
                    });
                }
                break;
            case "bottomRailColor":
                attribute = "Bottom Rail";
                label = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value.optionKey;
                stocks = [];
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value)) {
                    stocks = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });

                product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.stocks = stocks;
                break;
            case "chargingCable":
                attribute = "Accessories";
                label = "Charging Cable";
                stocks = [];
                if (product.items[itemIndex].configuration.chargingCable.selected.value) {
                    condition1 = product.items[itemIndex].configuration.heavyDuty.selected.value ? "Heavy Duty" : "Standard Duty";
                    condition2 = order.accountID.includes(BUNNINGS_ACCOUNT_ID) ? "parentAccountID==01500" : "default";
                    option = product.items[itemIndex].configuration.chargingCable.finalOptions.find(x => x.condition1.includes(condition1) && x.condition2.includes(condition2));
                    if (!isEmpty(option)) {
                        stocks = option.stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });

                product.items[itemIndex].configuration.chargingCable.selected.stocks = stocks;
                break;
            case "remote15Channels":
                attribute = "Accessories";
                label = "Remote";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value)) {
                    if (product.items[itemIndex].configuration.remote15Channels.selected.value) {
                        condition1 = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey;
                        condition2 = order.accountID.includes(BUNNINGS_ACCOUNT_ID) ? "parentAccountID==01500" : "default";
                        condition3 = product.items[itemIndex].configuration.heavyDuty.selected.value ? "Heavy Duty" : "Standard Duty";
                        option = product.items[itemIndex].configuration.remote15Channels.finalOptions.find(x => {
                            switch (product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey) {
                                case "DC Battery Motor":
                                    return x.condition1.includes(condition1) && x.condition2.includes(condition2) && x.description.includes(condition3);
                                case "RTS AC Motor":
                                case "4-Core Motor":
                                case "Chain":
                                default:
                                    return x.condition1.includes(condition1) && x.condition2.includes(condition2);
                            }

                        });
                        if (option) {
                            stocks = option.stocks;
                        }
                    }
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                    });

                    product.items[itemIndex].configuration.remote15Channels.selected.stocks = stocks;
                }
                break;
            case "wifiBridge":
                attribute = "Accessories";
                label = "Wifi Bridge";
                stocks = [];
                if (product.items[itemIndex].configuration.wifiBridge.selected.value) {
                    option = product.items[itemIndex].configuration.wifiBridge.finalOptions[0];
                    stocks = option.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });

                product.items[itemIndex].configuration.wifiBridge.selected.stocks = stocks;
                break;
            case "consumables":
                attribute = "Consumables";
                label = "";
                stocks = [];
                (product.items[itemIndex].subItems[subItemIndex].configuration.consumables.finalOptions || []).forEach((option, optionIndex) => {
                    sets = [];
                    if ((isHaymanAndNoosa ? option.condition1 === "Hayman & Noosa" : true)) {
                        switch (option.optionKey) {
                            case "SPLINE FLAT 13mm FOR 38/50mm KEYWAY":
                            case "DOUBLE SIDED TAPE 24MMx30MTRS":
                            case "LAY FLAT PLASTIC TUBE 150mm":
                            case "Cord Safe Clip Clear":
                            case "Plastic Bags 10cmx15cm":
                            case "Cord Safe Warning Tag":
                                sets = option.sets;
                                break;
                            case "12MM DOUBLE SIDED TAPE 30 Metres":
                                condition1 = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value.optionKey === "Sewn In Lathe" ? "bottomRailType==Sewn In Lathe" : "bottomRailType!=Sewn In Lathe";
                                sets = findItems(option.sets, "condition1", condition1);
                                break;
                            case "SPLINE FLAT 15mm FOR ROUND B/RAIL":
                            case "SPLINE FLAT 10mm  FOR OVAL B/RAIL":
                                sets = option.sets.filter(set => set.condition1.includes(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value.optionKey));
                                break;
                            case "SCREW 25MM 6Ga Zinc BUGLE HEAD":
                                condition1 = order.accountID === "01660" ? "AccountID==01660" : "N/A";
                                sets = findItems(option.sets, "condition1", condition1);
                                break;
                        }
                    }
                    sets.forEach(set => {
                        if (!isEmpty(set)) {
                            stocks = [...stocks, ...set.stocks];
                        }
                    });
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.consumables.selected.stocks = stocks;
                break;
            case "accessoryBoxColour":
                attribute = "Accessory Box";
                stocks = [];
                if (isHaymanAndNoosa) {
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.value)) {
                        label = product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.value.optionKey;

                        product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.value.sets.forEach(s => {
                            if ((product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value >= s.min) && (product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value <= s.max)) {
                                stocks = s.stocks;
                            }
                        });
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });

                product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.stocks = stocks;
                break;
            case "keyway":
                attribute = "Keyway";
                stocks = [];
                if (isHaymanAndNoosa) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.keyway.finalOptions.forEach(o => {
                        if ((product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value >= o.primaryAttributeMin) && (product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value <= o.primaryAttributeMax)) {
                            label = o.optionKey;
                            stocks = o.stocks;
                        }
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode);
                });

                product.items[itemIndex].subItems[subItemIndex].configuration.keyway.selected.stocks = stocks;
                break;
        }
        return product;
    }


    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };


    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }


    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, stockByProdCode) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1 && subItemIndex !== null && subItemIndex > -1) ? product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1 && subItemIndex !== null && subItemIndex > -1) ? product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;

        let deduction = null;
        let params = {
            width: width,
            drop: drop,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor
        };

        stockItem.swishCalculatedQty = this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params);
        stockItem.keywayCalculatedQty = this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params);

        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                break;
            case "Skin":
                deduction = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.deduction;
                if (deduction && width > params.stockLinearWidth) {
                    width = deduction.cutWidth;//cutWidth
                }
                let fabricWidth = params.stockLinearWidth / 1000;
                let panel = 0, fabricDrop = 0, usage = 0;
                try {
                    panel = round(((width / 1000) / fabricWidth), 0);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }
                fabricDrop = (drop + 300) / 1000;
                if (width > (fabricWidth / 2 * 1000)) {
                    if ((width / 1000) > 3) {
                        usage = 0;
                    } else {
                        usage = panel * fabricDrop;
                    }
                } else {
                    usage = fabricDrop / round(((fabricWidth * 1000) / width), 0)
                }
                usage = roundUp(usage, 1);

                stockItem.swishCalculatedQty = usage;
                stockItem.keywayCalculatedQty = usage;
                break;
            default:
                deduction = product.items[itemIndex].subItems[subItemIndex].configuration.deduction.selected.value;
        }
        if (stockItem.isDeductionApplicable && !deduction) {
            deduction = salesOrderProductBuilderV1Service.initDeduction();
        }

        //finally all calculation tweaks done, now simple calculation goes on
        stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * stockItem.swishCalculatedQty) * stockItem.swishConversionFactor;
        stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * stockItem.keywayCalculatedQty) * stockItem.keywayConversionFactor;
        stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
        stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
        stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
        stockItem.deductionDrop = 0;
        stockItem.cutWidth = stockItem.keywayCalculatedQty + stockItem.deductionWidth;
        stockItem.cutDrop = 0;
        stockItem.attribute = attribute;
        stockItem.label = label;
        stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
    }


    getStocks(product, itemIndex) {

        let stocks = [];
        if (product.items[itemIndex]) {
            product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                stocks = [];
                product.items[itemIndex].subItems[subItemIndex].stocks = [];

                stocks = this.getSubItemStocks(product, itemIndex, subItemIndex);
                product.items[itemIndex].subItems[subItemIndex].stocks = [...product.items[itemIndex].subItems[subItemIndex].stocks, ...stocks];

                if (subItemIndex === 0) {
                    //item specific stocks
                    stocks = this.getItemStocks(product, itemIndex);
                    product.items[itemIndex].subItems[subItemIndex].stocks = [...product.items[itemIndex].subItems[subItemIndex].stocks, ...stocks];
                }
            });
        }


        return product;
    }


    getItemStocks(product, itemIndex) {
        let stocks = [];
        if (!isEmpty(product.items[itemIndex].configuration.chargingCable.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].configuration.chargingCable.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].configuration.remote15Channels.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].configuration.remote15Channels.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].configuration.wifiBridge.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].configuration.wifiBridge.selected.stocks];
        }
        return stocks;
    }


    getSubItemStocks(product, itemIndex, subItemIndex) {
        let stocks = [];

        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.heavyDuty.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.heavyDuty.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.bracketColor.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.bracketColor.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.mount.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.mount.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.consumables.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.consumables.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.stocks];
        }
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.keyway.selected.stocks)) {
            stocks = [...stocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.keyway.selected.stocks];
        }
        return stocks;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;

            product.items[itemIndex].pricing.unitPrice = 0;
            product.items[itemIndex].pricing.unitDiscVal = 0;
            product.items[itemIndex].pricing.price = 0;
            product.items[itemIndex].pricing.discVal = 0;
            product.items[itemIndex].pricing.discount = 0;

            // per sub-item
            product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                subItem.pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, subItem.stocks, subItem.pricing, discount);

                product.items[itemIndex].pricing.unitPrice += subItem.pricing.unitPrice;
                product.items[itemIndex].pricing.unitDiscVal += subItem.pricing.unitDiscVal;
                product.items[itemIndex].pricing.price += subItem.pricing.price;
                product.items[itemIndex].pricing.discVal += subItem.pricing.discVal;
                product.items[itemIndex].pricing.discount = subItem.pricing.discount;
            });
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                if (product.maxWidth < product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value) {
                    product.maxWidth = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                }
            });
        });
        return product;
    }

    updateDeduction(product, itemIndex, subItemIndex, order) {
        let isHaymanAndNoosa = isHaymanAndNoosaCustomer(order.accountID);
        let deduction, controlSide;
        controlSide = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide");
        let width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value;
        let fabricDeductionWidth = 0;
        let fabricDeductionDrop = 300;
        let defaultDeduction = 0;
        if (isHaymanAndNoosa) {
            if (width <= 2100) {
                defaultDeduction = -33;
            } else {
                defaultDeduction = -35;
            }
            fabricDeductionWidth = (defaultDeduction) + (-2);
        } else {
            switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                case "Single":
                    product.items[itemIndex].subItems[subItemIndex].configuration.deduction.deductionRules.forEach(r => {
                        if (r.layout === product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                            if (r.operationGroup === product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value) {
                                defaultDeduction = r.deduction;
                                fabricDeductionWidth = r.deduction;
                            }
                        }
                    });
                    break;
                case "Single 2Link Non Driven":
                case "Single 2Link Driven":
                case "Single 3Link":
                    product.items[itemIndex].subItems[subItemIndex].configuration.deduction.deductionRules.forEach(r => {
                        if (r.layout === product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                            if (r.operationGroup === product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected.value) {
                                if (r.blindPosition === product.items[itemIndex].subItems[subItemIndex].configuration.blindPosition.selected.value) {
                                    if (r.controlSide === controlSide) {
                                        defaultDeduction = r.deduction;
                                        fabricDeductionWidth = r.deduction;
                                    }
                                }
                            }
                        }
                    });
                    break;
            }
        }
        deduction = salesOrderProductBuilderV1Service.initDeduction();
        deduction.deductionWidth = fabricDeductionWidth;
        deduction.deductionDrop = fabricDeductionDrop;
        deduction.cutWidth = width + (deduction.deductionWidth);
        deduction.cutDrop = drop + (deduction.deductionDrop);
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.deduction = deduction;


        deduction = salesOrderProductBuilderV1Service.initDeduction();
        deduction.deductionWidth = defaultDeduction;
        deduction.deductionDrop = 0;
        deduction.cutWidth = width + (deduction.deductionWidth);
        deduction.cutDrop = drop + (deduction.deductionDrop);
        product.items[itemIndex].subItems[subItemIndex].configuration.deduction.selected.value = deduction;

        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                product = this.updateSubItemStocks(product, itemIndex, subItemIndex, order)
            });
        }
        return product;
    }


    updateSubItemStocks(product, itemIndex, subItemIndex, order) {
        if (subItemIndex > -1) {
            product = this.updateStocks("set", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("mount", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("heavyDuty", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("operationGroupCombination", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("fabricColor", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("controlSide", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("bracketColor", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("bottomRailColor", product, itemIndex, subItemIndex, order);

            product = this.updateDeduction(product, itemIndex, subItemIndex, order);

            product = this.updateStocks("set", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("mount", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("heavyDuty", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("operationGroupCombination", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("fabricColor", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("rollDirection", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("controlSide", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("chainLength", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("bracketColor", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("bottomRailColor", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("chargingCable", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("remote15Channels", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("wifiBridge", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("consumables", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("accessoryBoxColour", product, itemIndex, subItemIndex, order);
            product = this.updateStocks("keyway", product, itemIndex, subItemIndex, order);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    findDrivenByBindSequence(product, itemIndex, subItemIndex) {
        let drivenByBindSequence = 0;
        switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
            case "Single":
            case "Single 2Link Non Driven":
                drivenByBindSequence = product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value;
                break;
            case "Single 2Link Driven":
                switch (product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value) {
                    case 1:
                        drivenByBindSequence = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? 1 : 2;
                        break;
                    case 2:
                        drivenByBindSequence = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? 1 : 2;
                        break;
                }
                break;
            case "Single 3Link":
                switch (product.items[itemIndex].configuration.diagram.selected.value.optionKey) {
                    case "Single 3Link Left Linked":
                    case "Single 3Link Right Linked":
                        switch (product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value) {
                            case 1:
                                drivenByBindSequence = 1;
                                break;
                            case 2:
                                drivenByBindSequence = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? 1 : 3;
                                break;
                            case 3:
                                drivenByBindSequence = 3;
                                break;
                        }
                        break;
                    case "Single 3Link All Linked LHC":
                    case "Single 3Link All Linked RHC":
                        switch (product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value) {
                            case 1:
                            case 2:
                            case 3:
                                drivenByBindSequence = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide") === "Left" ? 1 : 3;
                                break;
                        }
                        break;
                }
                break;
        }
        return drivenByBindSequence;
    }

    isVisibleExtraOptions(product, itemIndex, subItemIndex, key) {
        let isVisible = false;
        let drivenByBlindSequence = product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value;
        let controlSide = "";
        switch (key) {
            case "motor-accessories":
                if (!isEmpty(product.items[itemIndex].subItems[drivenByBlindSequence - 1])) {
                    if (product.items[itemIndex].subItems[drivenByBlindSequence - 1].configuration.operation.selected.value.optionKey !== "Chain") {
                        switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                            case "Single":
                            case "Single 2Link Non Driven":
                            case "Single 3Link":
                                isVisible = product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value === 1;
                                break;
                            case "Single 2Link Driven":
                                controlSide = salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, subItemIndex, "optionKey", "Left", "controlSide");
                                switch (controlSide) {
                                    case "Right":
                                        isVisible = product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value === 2;
                                        break;
                                    case "Left":
                                    default:
                                        isVisible = product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value === 1;
                                        break;
                                }
                                break;
                        }
                    }
                }

        }
        return isVisible;
    }

    toSalesOrderItemRollerBlindSingleRequest(pg, itemIndex, order, errors) {
        let errorMessagePrefix = "";
        let temp = {};
        let salesOrderItemRollerBlindSingle = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            room: pg.items[itemIndex].configuration.room.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            mount: isEmpty(pg.items[itemIndex].configuration.mount.selected.value) ? "" : pg.items[itemIndex].configuration.mount.selected.value.optionKey,
            bracketCovers: !!pg.items[itemIndex].configuration.bracketCovers.selected.value,
            layout: pg.items[itemIndex].configuration.layout.selected.value.optionKey,
            diagram: pg.items[itemIndex].configuration.diagram.selected.value.optionKey,
            heavyDuty: pg.items[itemIndex].configuration.heavyDuty.selected.value,
            operationGroupCombination: pg.items[itemIndex].configuration.operationGroupCombination.selected.value.description,
            bracketColor: salesOrderProductBuilderV1Service.getValueOrDefault(pg, itemIndex, -1, "optionKey", "", "bracketColor"),
            chargingCable: !!pg.items[itemIndex].configuration.chargingCable.selected.value,
            remote15Channels: !!pg.items[itemIndex].configuration.remote15Channels.selected.value,
            wifiBridge: !!pg.items[itemIndex].configuration.wifiBridge.selected.value,
            subItems: [],
            stocks: pg.items[itemIndex].stocks
        };
        (pg.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
            if (!subItem.isValid) {
                if (!pg.items[itemIndex].subItems[subItemIndex].configuration.width.formError.isValid) {
                    errors.push(errorMessagePrefix + pg.items[itemIndex].subItems[subItemIndex].configuration.width.formError.message);
                }
                if (!pg.items[itemIndex].subItems[subItemIndex].configuration.drop.formError.isValid) {
                    errors.push(errorMessagePrefix + pg.items[itemIndex].subItems[subItemIndex].configuration.drop.formError.message);
                }
            }
            (pg.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                if (!subItem.isValid) {
                    if (!pg.items[itemIndex].subItems[subItemIndex].configuration.width.formError.isValid) {
                        errors.push(errorMessagePrefix + pg.items[itemIndex].subItems[subItemIndex].configuration.width.formError.message);
                    }
                    if (!pg.items[itemIndex].subItems[subItemIndex].configuration.drop.formError.isValid) {
                        errors.push(errorMessagePrefix + pg.items[itemIndex].subItems[subItemIndex].configuration.drop.formError.message);
                    }
                }

                salesOrderItemRollerBlindSingle.subItems[subItemIndex] = {
                    id: null,
                    salesOrderItemRollerBlindSingleID: null,
                    blindSequence: subItem.configuration.blindSequence.selected.value,
                    blindSequenceMax: subItem.configuration.blindSequenceMax.selected.value,
                    drivenByBlindSequence: subItem.configuration.drivenByBlindSequence.selected.value,
                    blindPositionType: subItem.configuration.blindPositionType.selected.value,
                    blindPosition: subItem.configuration.blindPosition.selected.value,
                    operation: subItem.configuration.operation.selected.value.optionKey,
                    operationGroup: subItem.configuration.operationGroup.selected.value,
                    width: subItem.configuration.width.selected.value,
                    drop: subItem.configuration.drop.selected.value,
                    fabricType: subItem.configuration.fabricType.selected.value.optionKey,
                    fabricColor: subItem.configuration.fabricColor.selected.value.optionKey,
                    rollDirection: salesOrderProductBuilderV1Service.getValueOrDefault(pg, itemIndex, subItemIndex, "optionKey", "", "rollDirection"),
                    controlSide: salesOrderProductBuilderV1Service.getValueOrDefault(pg, itemIndex, subItemIndex, "optionKey", "Left", "controlSide"),
                    chainColor: salesOrderProductBuilderV1Service.getValueOrDefault(pg, itemIndex, subItemIndex, "optionKey", "", "chainColor"),
                    chainLength: salesOrderProductBuilderV1Service.getValueOrDefault(pg, itemIndex, subItemIndex, "optionKey", "", "chainLength"),
                    bottomRailType: subItem.configuration.bottomRailType.selected.value.optionKey,
                    bottomRailColor: subItem.configuration.bottomRailColor.selected.value.optionKey,
                    accessoryBoxColour: subItem.configuration.accessoryBoxColour.selected.value.optionKey,
                    bmCode: subItem.configuration.set.selected.value.priceMatrix.bmCode,
                    widthSet: subItem.configuration.set.selected.value.widthSet.width,
                    dropSet: subItem.configuration.set.selected.value.dropSet.drop,
                    deduction: subItem.configuration.deduction.selected.value.deductionWidth,
                    fabricCutWidth: subItem.configuration.fabricColor.selected.deduction.cutWidth,
                    fabricCutDrop: subItem.configuration.fabricColor.selected.deduction.cutDrop,
                    stocks: subItem.stocks
                };
            });
        });

        return salesOrderItemRollerBlindSingle;
    }

    getSalesOrderItemProdCodes(salesOrderItemRollerBlindSingle) {
        let prodCodes = [];
        prodCodes = [...((salesOrderItemRollerBlindSingle.stocks || []).map(stock => stock.prodCode))];

        (salesOrderItemRollerBlindSingle.subItems || []).forEach(subItem => {
            prodCodes = [...prodCodes, ...((subItem.stocks || []).map(stock => stock.prodCode))]
        });
        return prodCodes;
    }
}

export default RollerBlindSingleUtil.Instance();