import React, { Component } from 'react';
import { Button, Card, CardHeader, Col, Collapse, Label, Row, Table } from "reactstrap";
import NumberFormat from "react-number-format";
import { OrderEnquiryProductionModeOrderItemsProductPartListItems } from "../../../../../store/AppConstants";
import OrderEnquiryProductionModeOrderWarehousePickItems from "./WarehousePick/OrderEnquiryProductionModeOrderWarehousePickItems";
import { getDistinct, getSorted, groupBy, handleErrorMessage, updateQtyUnitFormat, changeFormatOfDateString } from '../../../../../../src/services/CommonService';
import { toast } from 'react-toastify';
import OrderService from '../../../../../services/OrderService';
import classnames from 'classnames';
import ConfirmModal from "../../../../../components/modal/ConfirmModal";
import productionScheduleService from '../../../../../services/production/ProductionScheduleService';
import UserService from '../../../../../services/UserService';
import WarehouseLocationDetailsModal from '../../../../../components/modal/WarehouseLocationDetailsModal';
import warehouseService from '../../../../../services/WINOInventory/WarehouseService';
import OrderEnquiryProductionModeOrderItemsCustomProductPartList from './OrderEnquiryProductionModeOrderItemsCustomProductPartList';

export default class OrderEnquiryProductionModeOrderItemsProductPartList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows,
            order: this.props.order,
            selectedItem: {},
            activeTab: this.props.order && this.props.order.products.length !== 0 ? this.props.order.products[0].name : null,
            isOpen: this.props.order && this.props.order.products.length !== 0 ? this.props.order.products[0].name : null,
            sortRequest: {
                key: "location",
                direction: false
            },
            pickedList: [],
            showConfirmPick: false,
            isOpenConfirmCompleteStatusModal: false,
            hasProductionScheduleReadonlyPrivilege: false,
            salesOrderJobData: {},
            isOpenWarehouseLocationDetailsModal: false,
            locationDetails: []
        };
        this.handleSort = this.handleSort.bind(this);
        this.userService = new UserService();
        this.orderService = new OrderService();
        this.handlePickList = this.handlePickList.bind(this);
        this.checkPartialSupply = this.checkPartialSupply.bind(this);
        this.handlePickClick = this.handlePickClick.bind(this);
        this.completeSalesOrderJob = this.completeSalesOrderJob.bind(this);
        this.handleClickedItem = this.handleClickedItem.bind(this);
    }

    componentDidMount() {
        this.buildProductTabs();
        this.rowsData();
        let currentUser = this.userService.getLoggedInUser();
        let hasProductionScheduleReadonlyPrivilege = this.userService.hasPrivilege(currentUser, 'production-schedule-readonly');
        this.setState({ currentUser, hasProductionScheduleReadonlyPrivilege });
        this.orderService.getSalesOrderJobDetails(this.props.order.ordNum, 0).then(response => {
            this.setState({ salesOrderJobData: response.data });
        }).catch(error => {
            console.log(error);
        });
    }

    handleClickedItem(item) {
        if (item.prodCode) {
            warehouseService.getWarehouseLocationDetailsList(item.prodCode)
                .then(response => {
                    this.setState({ locationDetails: response.data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
        this.setState({ selectedItem: item, isOpenWarehouseLocationDetailsModal: true });
    }

    completeSalesOrderJob() {
        let { order, salesOrderJobData } = this.state;
        const isPickNeeded = order.items.find(item => (item.identifier === "W/H Pick" && item.qtyAudited === null));
        if (isPickNeeded) {
            toast.error("Not all items have a Pick Qty. Please enter a Qty to pick on relevant items.");
        } else if (salesOrderJobData?.id) {
            let request = {
                salesOrderJobID: salesOrderJobData.id
            };
            productionScheduleService.updateProductionSalesOrderJobComplete(request).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Job Completed!");
                    this.props.getOrder(order.ordNum, "current");
                }
            }).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        } else {
            toast.error("Something went wrong!");
        }
        this.setState({ isOpenConfirmCompleteStatusModal: false });
    }

    checkPartialSupply() {
        let { pickedList } = this.state;
        let partialSupply = false;
        for (let item of pickedList) {
            if (item.qtyAudited < item.qty) {
                partialSupply = true;
                break;
            }
        }
        if (partialSupply) {
            this.setState({ showConfirmPick: true });
        } else {
            this.handlePickClick();
        }
    }

    handlePickClick() {
        let { pickedList, order } = this.state;
        const emptyValueExists = pickedList.find(item => item.qtyAudited === "");
        if (emptyValueExists) {
            toast.error("Pick Qty can't be empty.");
        } else {
            this.orderService.updatePickedItemsDetails(pickedList).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Details Updated Successfully.");
                    this.props.getOrder(order.ordNum, "current");
                }
            }).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        }
        this.setState({ showConfirmPick: false });
    }

    handlePickList(data, action) {
        let { pickedList } = this.state;
        switch (action) {
            case "add":
                this.setState({
                    pickedList: [...pickedList, { ordNum: data.ordNum, itemNum: data.itemNum, qtyAudited: data.qtyAudited ?? data.qty, qty: data.qty }]
                });
                break;
            case "remove":
                this.setState({
                    pickedList: pickedList.filter(item => item.itemNum !== data.itemNum)
                });
                break;
            case "addAll":
                this.setState({
                    pickedList: data.map(item => ({ ordNum: item.ordNum, itemNum: item.itemNum, qtyAudited: item.qtyAudited ?? item.qty, qty: item.qty }))
                });
                break;
            case "removeAll":
                this.setState({
                    pickedList: []
                });
                break;
            case "updatePickQty":
                this.setState({
                    pickedList: pickedList.map(item => item.itemNum === data.itemNum ? { ...item, qtyAudited: data.qtyAudited } : item)
                });
                break;
            default:
                break;
        }
    }

    buildProductTabs() {
        let { order } = this.state;
        for (let product of order.products) {
            product.parts = [];
            product.customParts = [];
            product.items.forEach((item) => {
                if (item.parts && item.parts.length > 0) {
                    if (item.isCustom) {
                        product.customParts = [...product.customParts, ...item.parts];
                    } else {
                        product.parts = [...product.parts, ...item.parts];
                    }
                }
            });
            let partsGroupByProdCode = groupBy(product.parts, "prodCode");
            product.parts = getDistinct(product.parts, "prodCode");
            (product.parts || []).forEach((item, index) => {
                let keywayQty = 0;
                (partsGroupByProdCode[item.prodCode] || []).forEach((partItem, index) => {
                    keywayQty += (partItem.parentItemQty * partItem.keywayQtyWithDeduction);
                });
                item.totalkeywayQtyWithDeduction = Number.isInteger(keywayQty) ? keywayQty : keywayQty.toFixed(2);
            });
            let customPartsGroupByProdCode = groupBy(product.customParts, "prodCode");
            product.customParts = getDistinct(product.customParts, "prodCode");
            (product.customParts || []).forEach((item, index) => {
                let keywayQty = 0;
                (customPartsGroupByProdCode[item.prodCode] || []).forEach((customPartItem, index) => {
                    keywayQty += (customPartItem.parentItemQty * customPartItem.swishQtyWithWastage);
                });
                item.totalSwishQtyWithWastage = Number.isInteger(keywayQty) ? keywayQty : keywayQty.toFixed(2);
            });
        }
        for (let i in order.products) {
            if (i === 0 || i === '0') {
                order.products[0].isOpen = true;
            } else {
                order.products[i].isOpen = false;
            }
        }
        this.setState({ order });
    }

    componentWillReceiveProps(nextprops) {
        if (this.state.rows !== nextprops.rows) {
            this.buildProductTabs();
            this.rowsData();
        }
    }

    handleChange(change, value) {
        let { order } = this.state;
        for (let productIndex in order.products) {
            for (let partsIndex in order.products[productIndex].parts) {
                if (order.products[productIndex].parts[partsIndex].prodCode === change) {
                    order.products[productIndex].parts[partsIndex].selected = !order.products[productIndex].parts[partsIndex].selected;
                }
            }
        }
        this.setState({ order });
    }

    getPartListHeading() {
        let { activeParts } = this.props;
        let partListHeading = [
            {
                key: "attribute",
                label: "Attribute",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "prodCode",
                label: "Item Code",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "totalkeywayQtyWithDeduction",
                label: "Qty",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "location",
                label: "Location",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "qoh",
                label: "QOH",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "etaDate",
                label: "Next PO ETA",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            }
        ];
        return partListHeading;
    }

    toggleProductPartListCollapse(tabName, tabStatus) {
        let { activeTab, order } = this.state;
        activeTab = tabName;
        for (let productIndex in order.products) {
            if (order.products[productIndex].name === tabName) {
                order.products[productIndex].isOpen = tabStatus;
                this.handleSort("location", productIndex, "collapse")
            } else {
                order.products[productIndex].isOpen = !tabStatus;
                this.handleSort("location", productIndex, "collapse")
            }
        }
        this.setState({ activeTab, order });
    }

    handleSort(change, i, key) {
        let { sortRequest, order } = this.state;
        switch (key) {
            case "collapse":
                sortRequest.key = change;
                sortRequest.direction = true;
                break;
            default:
                if (sortRequest.key === change) {
                    sortRequest.direction = !sortRequest.direction;
                }
                else {
                    sortRequest.key = change;
                    sortRequest.direction = false;
                }
        }
        if (order.products.length > 0) {
            order.products[i].parts = getSorted(order.products[i].parts, change, sortRequest.direction);
        }
        this.setState({ order, sortRequest });
    }

    rowsData() {
        let { order } = this.state;
        for (let productIndex in order.products) {
            for (let partsIndex in order.products[productIndex].parts) {
                if (order.products[productIndex].parts[partsIndex].location !== null && Array.from(order.products[productIndex].parts[partsIndex].location)[0] === ',') {
                    order.products[productIndex].parts[partsIndex].location = order.products[productIndex].parts[partsIndex].location.substring(1);
                }
            }
        }
        this.setState({ order }, () => {
            if (!this.props.bom) {
                this.handleSort("location", 0, "location")
            }
        }
        );
    }

    render() {
        let { activeParts, bom, tabId } = this.props;
        let { sortRequest, activeTab, order, pickedList, showConfirmPick, isOpenConfirmCompleteStatusModal, hasProductionScheduleReadonlyPrivilege, salesOrderJobData, selectedItem, locationDetails, isOpenWarehouseLocationDetailsModal } = this.state;
        let salesOrderJobIsCompleted = salesOrderJobData?.statusId === 90;
        let partListHeading = this.getPartListHeading();
        let prodCodes = [];
        let warehousePicksItems = order.items.filter((item) => {
            if (item.identifier === "W/H Pick") {
                prodCodes.push(item.prodCode);
                return true;
            } else {
                return false;
            }
        });
        if (prodCodes.length > 0) {
            this.orderService.getStockInfoByProdcodes({ prodCodes }).then((response) => {
                warehousePicksItems = warehousePicksItems.forEach((item) => {
                    var res = response.data.filter(r => r.prodCode === item.prodCode);
                    item.location = (res && res[0]) ? res[0].location : '';
                    item.qoh = (res && res[0]) ? res[0].qoh : 0;
                    item.picked = false;
                });
            }).catch(error => {
                console.log(error);
            });
        }

        return (
            <div>
                {(order.products || []).map((item, itemIndex) => {
                    return (item?.parts?.length > 0) &&
                        <Card key={itemIndex} className={"mt-1 mb-2"}>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <Button
                                            size={"sm"}
                                            color={"link"}
                                            onClick={() => this.toggleProductPartListCollapse(item.name, !item.isOpen)}
                                        >{item.name}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            {
                                item.name === activeTab && item.isOpen ?
                                    <Collapse isOpen={item.isOpen}>
                                        <Table bordered responsive size={"sm"} striped={activeParts ? false : true} className={"mb-0"}>
                                            <thead>
                                                <tr>
                                                    {
                                                        !bom && activeParts ?
                                                            <th rowSpan={2} className="align-middle text-center">Pick</th>
                                                            : null
                                                    }
                                                    {(partListHeading || []).map((item, index) => {
                                                        return (
                                                            <th key={index}
                                                                onClick={(item.sorterApplicable && !bom) ? (() => this.handleSort(item.key, itemIndex, "sortKey")) : null}
                                                                rowSpan={item.rowSpan}
                                                                colSpan={item.colSpan}
                                                                className={item.labelClassName}
                                                                style={{ minWidth: item.minWidth }}>
                                                                {item.label}
                                                                {
                                                                    item.sorterApplicable && !bom && activeParts ?
                                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                            "fa-sort": (sortRequest.key !== item.key),
                                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                        }
                                                                        )} aria-hidden="true" /> : null
                                                                }
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (item.parts || []).filter(item => !OrderEnquiryProductionModeOrderItemsProductPartListItems.includes(item.prodCode)).map((row, rowIndex) => (
                                                        <tr key={rowIndex} bgcolor={(!row.selected && !row.isFromKeyway) ? "lightpink" : (row.selected ? "#C8C8C8" : "white")}>
                                                            {
                                                                !bom && activeParts ?
                                                                    <td>
                                                                        <div className='ml-2'>
                                                                            <Label>
                                                                                <Button color={"link"}
                                                                                    size={"sm"}
                                                                                    onClick={() => this.handleChange(row.prodCode, rowIndex)}>
                                                                                    {
                                                                                        row.selected
                                                                                            ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                                                                aria-hidden="true" />
                                                                                            : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                                                                    }
                                                                                </Button>
                                                                            </Label>
                                                                        </div>
                                                                    </td> : null
                                                            }
                                                            <td className="align-middle">
                                                                <div>
                                                                    <strong>{row.attribute}</strong>
                                                                </div>
                                                                <div>
                                                                    {row.label}
                                                                </div>
                                                            </td>
                                                            <td className="align-middle text-center text-nowrap">
                                                                {row.prodCode ?
                                                                    <a href={"/inventory/stock/enquiry?prodCode=" + row.prodCode} target="_blank" className="btn btn-sm btn-primary" title="Click here to open its details">
                                                                        {row.prodCode}
                                                                    </a>
                                                                    : null}
                                                            </td>
                                                            <td>{row.description}</td>
                                                            <td className="text-center">
                                                                <NumberFormat
                                                                    value={row.totalkeywayQtyWithDeduction}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={false}
                                                                    thousandSeparator={true}
                                                                    suffix={updateQtyUnitFormat(row.totalkeywayQtyWithDeduction, row.keywayMeasurementUnit)}
                                                                />
                                                            </td>
                                                            <td>
                                                                {row.location ?
                                                                    <Button color={"link"} style={{ textDecoration: "none" }} onClick={() => this.handleClickedItem(row)}>{row.location}</Button>
                                                                    : null}
                                                            </td>
                                                            <td className={"text-center"}>
                                                                <NumberFormat
                                                                    value={row.qoh}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={false}
                                                                    thousandSeparator={true}
                                                                    suffix={updateQtyUnitFormat(row.qoh, row.eachUnitName)}
                                                                />
                                                            </td>
                                                            <td className="align-middle text-center text-nowrap">
                                                                <>
                                                                    {(!row.hasPO && row.free < 0) ?
                                                                        <NumberFormat
                                                                            prefix="Free: -"
                                                                            value={Math.abs(row.free)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={false}
                                                                            thousandSeparator={true}
                                                                            suffix=" | No PO ETA"
                                                                            className="text-danger"
                                                                        />
                                                                        :
                                                                        <>
                                                                            {row.poNum ?
                                                                                <a href={"/purchase/order/enquiry?ordNum=" + row.poNum} target="_blank" className="btn btn-sm btn-primary" title="Click here to open this PO">
                                                                                    {row.poNum}
                                                                                </a>
                                                                                : null}
                                                                            {row.etaDate ?
                                                                                <span className="ml-1">{changeFormatOfDateString(row.etaDate, "YYYY-MM-DD hh:mm:ss", "DD MMM YYYY")}</span>
                                                                                : null}
                                                                        </>}
                                                                </>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Collapse>
                                    : null
                            }
                        </Card>
                })}
                <OrderEnquiryProductionModeOrderItemsCustomProductPartList
                    rows={this.state.rows}
                    order={this.state.order}
                    activeParts={this.props.activeParts}
                    bom={this.props.bom}
                    partListHeading={partListHeading}
                />
                {
                    (warehousePicksItems.length > 0) &&
                    <Card key={22} className={"mt-1 mb-2"}>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <Button size={"sm"} color={"link"}>
                                        {"W/H Picks"}
                                    </Button>
                                    {
                                        pickedList.length ?
                                            <Button className={"mr-2"} size={"sm"} color={"success"} onClick={this.checkPartialSupply}>
                                                {"Pick Selected Qty"}
                                            </Button>
                                            : null
                                    }
                                    {salesOrderJobData?.statusId >= 45 ?
                                        <Button
                                            size={"sm"}
                                            disabled={hasProductionScheduleReadonlyPrivilege || salesOrderJobIsCompleted}
                                            color={salesOrderJobIsCompleted ? "success" : "primary"}
                                            onClick={() => this.setState({ isOpenConfirmCompleteStatusModal: true })}>
                                            <i className={classnames("fa ", "mr-2", {
                                                "fa-check-circle": salesOrderJobIsCompleted,
                                                "fa-check-circle-o": !salesOrderJobIsCompleted,
                                            })}
                                                aria-hidden="true" />
                                            {salesOrderJobIsCompleted ? "Completed" : "Complete"}
                                        </Button>
                                        : null}
                                </Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={true}>
                            <OrderEnquiryProductionModeOrderWarehousePickItems
                                items={warehousePicksItems}
                                tabId={tabId}
                                pickedList={pickedList}
                                handlePickList={this.handlePickList}
                                salesOrderJobStatus={salesOrderJobData?.statusId}
                                handleClickedItem={this.handleClickedItem}
                            />
                        </Collapse>
                    </Card>
                }
                <ConfirmModal
                    isOpen={showConfirmPick}
                    toggle={() => this.setState({ showConfirmPick: !showConfirmPick })}
                    handleSubmit={() => this.handlePickClick()}
                    handleCancel={() => this.setState({ showConfirmPick: false })}
                    primaryMessage={"Are you sure you wish to short supply these items?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-warning fa-2x"}
                    type="Updating..."
                />
                <ConfirmModal
                    isOpen={isOpenConfirmCompleteStatusModal}
                    toggle={() => this.setState({ isOpenConfirmCompleteStatusModal: !isOpenConfirmCompleteStatusModal })}
                    handleCancel={() => this.setState({ isOpenConfirmCompleteStatusModal: false })}
                    handleSubmit={this.completeSalesOrderJob}
                    primaryMessage={"<h5>Confirm Status Complete</h5>"}
                    secondaryMessage={"<p className={'m-2'}>Are you sure you want to mark the status of Order No." + order.ordNum + " as Completed?</p>"}
                    submitColor={"success"}
                    cancelColor={"secondary"}
                    icon={"fa fa-check-circle fa-2x"}
                    type="Updating..."
                />
                <WarehouseLocationDetailsModal
                    isOpen={isOpenWarehouseLocationDetailsModal}
                    toggle={() => this.setState({ isOpenWarehouseLocationDetailsModal: !isOpenWarehouseLocationDetailsModal })}
                    handleCancel={() => this.setState({ isOpenWarehouseLocationDetailsModal: false })}
                    selectedItem={selectedItem}
                    locationDetails={locationDetails}
                />
            </div>
        )
    }
}