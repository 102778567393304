import React, { Component } from 'react';
import {
    Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Input,
    InputGroup, InputGroupAddon, InputGroupText, Label, Pagination, Row, Spinner, Table
} from 'reactstrap';
import { handleErrorMessage, scrollTo, changeFormatOfDateString, getDateString } from '../../../../../../services/CommonService';
import classnames from 'classnames';
import customUtil from './CustomUtil';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { cloneDeep } from 'lodash';
import ConfirmModal from '../../../../../../components/modal/ConfirmModal';

export const maxDate = new Date();
export const minDate = moment().subtract(20, 'years').toDate();

export default class CustomBuildTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            templateData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        createdBy: '',
                        startDate: '',
                        endDate: '',
                        templateName: '',
                        description: '',
                        id: '',
                        prodCode: '',
                        excludeInactive: true
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            templateItemData: [],
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false,
            isOpenBOMModal: false,
            isLoadingData: false,
            isOpenDeleteConfirmationModal: false,
            isDeletingTemplate: false,
            selectedCustomTemplateID: '',
            excludeInactive: true,
            openedEditDocumentationRecords: []
        };
        this.getTemplates = this.getTemplates.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleSelectedTemplateDataChange = this.handleSelectedTemplateDataChange.bind(this);
        this.updateTemplate = this.updateTemplate.bind(this);
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.toCustomProductBuilder = this.toCustomProductBuilder.bind(this);
        this.getTemplateItems = this.getTemplateItems.bind(this);
        this.handleEditDocumentation = this.handleEditDocumentation.bind(this);
    }

    componentDidMount() {
        this.getTemplates();
    }

    getTemplates() {
        let { templateData } = this.state;
        this.setState({ isLoadingData: true });
        let request = cloneDeep(templateData.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        salesOrderProductBuilderV1Service.getTemplateList(request).then(response => {
            templateData.response = response.data;
            this.setState({ templateData, isLoadingData: false });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ isLoadingData: false });
        });
    }

    getTemplateItems(customTemplateID, index) {
        let { templateItemData } = this.state;
        salesOrderProductBuilderV1Service.getCustomTemplateItemList(customTemplateID).then(response => {
            templateItemData = response.data;
            this.setState({ templateItemData }, () => this.toCustomProductBuilder(index));
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    toggleBOMModal(isOpen) {
        this.setState({ isOpenBOMModal: isOpen });
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({ isOpenConsolidatedBOMModal: change });
    }

    handleChange(change, key) {
        let { templateData } = this.state;
        switch (key) {
            case "sortKey":
                if (templateData.request.sortRequest.key === change) {
                    templateData.request.sortRequest.direction = !templateData.request.sortRequest.direction;
                } else {
                    templateData.request.sortRequest.key = change;
                    templateData.request.sortRequest.direction = false;
                }
                this.setState({ templateData }, () => this.getTemplates());
                break;
            case "pageSize":
                templateData.request.pageRequest[key] = change;
                templateData.request.pageRequest.currentPage = 1;
                this.setState({ templateData }, () => this.getTemplates());
                break;
            case "currentPage":
                templateData.request.pageRequest[key] = change;
                this.setState({ templateData }, () => this.getTemplates());
                break;
            case "startDate":
                templateData.request.filterRequest.startDate = change;
                this.setState({ templateData }, () => this.getTemplates());
                break;
            case "endDate":
                templateData.request.filterRequest.endDate = change;
                this.setState({ templateData }, () => this.getTemplates());
                break;
            case "excludeInactive":
                templateData.request.filterRequest.excludeInactive = change;
                this.setState({ templateData, excludeInactive: change }, () => this.getTemplates());
                break;
            default:
                templateData.request.filterRequest[key] = change;
                this.setState({ templateData }, () => this.getTemplates());
                break;
        }
    }

    updateTemplate(recordIndex) {
        let { templateData } = this.state;
        let request = this.getProductTemplateCustomRequestInstance(templateData.response.records[recordIndex]);
        salesOrderProductBuilderV1Service.updateCustomBuildTemplate(request).then(response => {
            toast.success("Template updated successfully!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.getTemplates();
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    deleteTemplate(customTemplateID) {
        this.setState({ isDeletingTemplate: true });
        salesOrderProductBuilderV1Service.deleteCustomBuildTemplate(customTemplateID).then(response => {
            toast.success("Template deleted!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isOpenDeleteConfirmationModal: false, isDeletingTemplate: false }, () => this.getTemplates());
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isOpenDeleteConfirmationModal: false, isDeletingTemplate: false });
        });
    }

    toggleDeleteConfirmModal(isOpen, selectedCustomTemplateID) {
        this.setState({ isOpenDeleteConfirmationModal: isOpen, selectedCustomTemplateID: selectedCustomTemplateID });
    }

    handleSelectedTemplateDataChange(index, key, change) {
        let { templateData } = this.state;
        if (key) {
            templateData.response.records[index][key] = change;
            this.setState({ templateData });
        }
    }

    getProductTemplateCustomRequestInstance(templateData) {
        let customTemplateRequest = {
            accountID: templateData.accountID,
            id: templateData.id,
            isActive: templateData.isActive,
            templateName: templateData.templateName,
            description: templateData.description,
            prodCode: templateData.prodCode,
            model: templateData.model,
            location: templateData.location,
            quantity: templateData.quantity,
            width: templateData.width,
            drop: templateData.drop,
            productionInstructions: templateData.productionInstructions,
            productionNotes: templateData.productionNotes,
            documentation: templateData.documentation
        };
        return customTemplateRequest;
    }

    handleEditDocumentation(recordIndex) {
        console.log('recordIndex', recordIndex);
        let { openedEditDocumentationRecords } = this.state;
        if (openedEditDocumentationRecords.includes(recordIndex)) {
            openedEditDocumentationRecords.splice(openedEditDocumentationRecords.indexOf(recordIndex), 1);
        } else {
            openedEditDocumentationRecords.push(recordIndex);
        }
        this.setState({ openedEditDocumentationRecords });
    }

    getStore(templateData) {
        let { hasProductBuilderTemplateWritePrivilege } = this.props;
        let { openedEditDocumentationRecords } = this.state;
        let handleEditDocumentation = this.handleEditDocumentation;
        let handleSelectedTemplateDataChange = this.handleSelectedTemplateDataChange;
        let toggleDelete = this.toggleDeleteConfirmModal;
        let updateTemplate = this.updateTemplate;
        let getTemplateItems = this.getTemplateItems;
        return [
            {
                key: "id",
                label: "ID",
                colSpan: 1,
                minWidth: 15,
                sortApplicable: true,
                valueClassName: "text-right align-middle",
                labelClassName: "hoverableItem text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "createdOn",
                label: "Created On",
                colSpan: 1,
                minWidth: 20,
                sortApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "hoverableItem text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{changeFormatOfDateString(value, 'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY')}</span>
                }
            },
            {
                key: "createdBy",
                label: "Created By",
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "hoverableItem text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "templateName",
                label: "Name",
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "hoverableItem text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <Input type="text" name="templateName"
                        disabled={!hasProductBuilderTemplateWritePrivilege}
                        value={value}
                        onChange={(e) => handleSelectedTemplateDataChange(recordIndex, e.target.name, e.target.value)}
                        placeholder="Template Name" />
                }
            },
            {
                key: "description",
                label: "Description",
                type: 'text',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-left align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <Input type="text" name="description"
                        disabled={!hasProductBuilderTemplateWritePrivilege}
                        value={value}
                        onChange={(e) => handleSelectedTemplateDataChange(recordIndex, e.target.name, e.target.value)}
                        placeholder="Description" />
                }
            },
            {
                key: "prodCode",
                label: "ProdCode",
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-left align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "documentation",
                label: "Documentation",
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    const editDocumentation = openedEditDocumentationRecords.includes(recordIndex);
                    return <>
                        {editDocumentation ?
                            <Input type="text" name="documentation" value={value}
                        onChange={(e) => handleSelectedTemplateDataChange(recordIndex, e.target.name, e.target.value)}
                        placeholder="Enter Documentation Link" />
                            : (value ? <a href={value} target="_blank">Build Info</a> : "N/A")
                        }
                        {hasProductBuilderTemplateWritePrivilege &&
                            <Button size={"md"} color={"link"} onClick={() => handleEditDocumentation(recordIndex)}>
                                {editDocumentation ? "Done" : <i className="fa fa-pencil" aria-hidden="true" />}
                            </Button>
                        }
                    </>;
                }
            },
            {
                key: "isActive",
                label: "Is Active",
                colSpan: 1,
                minWidth: 18,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <Button size={"sm"} color={"link"}
                        disabled={!hasProductBuilderTemplateWritePrivilege}
                        onClick={() => handleSelectedTemplateDataChange(recordIndex, "isActive", !value)}>
                        <i className={classnames("mt-2", "fa", "fa-lg", {
                            "fa-check-square-o": value,
                            "fa-square-o": !value,
                        }
                        )} />
                    </Button>
                }
            },
            {
                key: "action",
                label: "Action",
                colSpan: 1,
                minWidth: 40,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className="text-center">
                        <Button color={"link"}
                            disabled={!hasProductBuilderTemplateWritePrivilege}
                            title={"Click here to update the current template"}
                            onClick={() => updateTemplate(recordIndex)}>
                            <i className="fa fa-floppy-o"
                                aria-hidden="true" />
                        </Button>
                        <span className={"text-muted"}>|</span>
                        <Button color={"link"}
                            disabled={!hasProductBuilderTemplateWritePrivilege}
                            title={"Click here to delete"}
                            onClick={() => toggleDelete(true, record.id)}>
                            <i className="fa fa-trash-o fa-lg text-danger"
                                aria-hidden="true" />
                        </Button>
                        <span className={"text-muted"}>|</span>
                        <Button color={"link"}
                            title={"Click here to add to the Build."}
                            onClick={() => getTemplateItems(record.id, recordIndex)}>
                            <i className="fa fa-plus" aria-hidden="true" />
                        </Button>
                    </div>
                }
            }
        ];
    }

    toCustomProductBuilder(index) {
        let { templateData, templateItemData } = this.state;
        let { order, product, packagingAndHandling, discountByProductCode, products } = this.props;
        let item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
        product.items.push(item); //insert item
        let itemIndex = product.items.length - 1; // get item index
        let productIndex = products.findIndex(p => p.name === "CUSTOM");
        let salesOrderItemCustom = customUtil.getSalesOrderItemCustomInstanceFromTemplate(templateData.response.records[index]);
        templateItemData.forEach(itemData => {
            let stock = customUtil.getStockFactoryInstanceFromTempate(itemData);
            salesOrderItemCustom.stocks.push(stock);
        });

        //adding templateData to product
        product.items[itemIndex].templateData = {
            templateName: templateData.response.records[index].templateName,
            description: templateData.response.records[index].description,
            isActive: templateData.response.records[index].isActive,
            customTemplateID: templateData.response.records[index].id,
            documentation: templateData.response.records[index].documentation
        };

        //prepare context
        let context = {
            salesOrderItemCustom: salesOrderItemCustom
        };
        products[productIndex] = customUtil.initItem(product, itemIndex, context, order, packagingAndHandling, discountByProductCode);
        this.props.handleChange("product", products[productIndex]);
        toast.success("Template added to the Build tab.", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }

    render() {
        let { isOpenBOMModal, templateData, isLoadingData, excludeInactive,
            isOpenDeleteConfirmationModal, isDeletingTemplate, selectedCustomTemplateID } = this.state;
        let { request, response } = templateData;
        let store = this.getStore(templateData);
        return (<div>
            <h5 className="mb-2">Saved Templates</h5>
            <div>
                <Row className={"mb-2 align-items-center"}>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label>Created By:</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"createdBy"}
                                value={request.filterRequest.createdBy}
                                onChange={(e) => this.handleChange(e.target.value, "createdBy")} />
                        </div>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;From Create Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control"
                                selected={request.filterRequest.startDate}
                                onChange={date => this.handleChange(date, "startDate")}
                                selectsStart
                                startDate={request.filterRequest.startDate}
                                endDate={request.filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;To Create Date </Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={request.filterRequest.endDate}
                                onChange={date => this.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={request.filterRequest.startDate}
                                endDate={request.filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={"mb-2 align-items-center"}>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label>Name: </Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"templateName"}
                                value={request.filterRequest.templateName}
                                onChange={(e) => this.handleChange(e.target.value, "templateName")} />
                        </div>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label>Description:</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"description"}
                                value={request.filterRequest.description}
                                onChange={(e) => this.handleChange(e.target.value, "description")} />
                        </div>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label>ID:</Label>
                        <div className={"text-center"}>
                            <Input type={"number"}
                                placeholder={"Search..."}
                                name={"id"}
                                value={request.filterRequest.id}
                                onChange={(e) => this.handleChange(e.target.value, "id")} />
                        </div>
                    </Col>
                </Row>
                <Row className={"mb-2 align-items-center"}>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label>ProdCode: </Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"prodcode"}
                                value={request.filterRequest.prodcode}
                                onChange={(e) => this.handleChange(e.target.value, "prodcode")} />
                        </div>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Label className={"mt-1"}>
                            <Button color={"link"}
                                size={"sm"}
                                onClick={() => this.handleChange(!excludeInactive, "excludeInactive")}>
                                <i className={classnames("fa", "fa-lg", "fa-fw", {
                                    "fa-square-o": !excludeInactive,
                                    "fa-check-square-o": excludeInactive,
                                }
                                )} aria-hidden="true" />
                            </Button>
                            Show only Active
                        </Label>
                    </Col>
                </Row>
            </div>
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={"text-left ml-3"}>
                        {isLoadingData ? <p>Loading...</p> :
                            <p>Showing
                                {' '}{((templateData.request.pageRequest.currentPage - 1) * templateData.request.pageRequest.pageSize) + 1}
                                {' '}to {((templateData.request.pageRequest.currentPage) * templateData.request.pageRequest.pageSize)}
                                {' '}of {templateData.response.totalRecords}
                                {' '}entries</p>
                        }
                    </div>
                </div>
                <Table striped bordered responsive hover size='sm'>
                    <thead>
                        <tr>
                            {(store || []).map((column, index) => {
                                return (
                                    <th key={index}
                                        onClick={(e) => column.sortApplicable && this.handleChange(column.key, "sortKey")}
                                        colSpan={column.colSpan}
                                        className={column.labelClassName}
                                        style={{ minWidth: column.minWidth, width: column.width }}>
                                        {column.label}
                                        {
                                            column.sortApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (request.sortRequest.key !== column.key),
                                                    "fa-sort-amount-asc": (request.sortRequest.key === column.key && request.sortRequest.direction),
                                                    "fa-sort-amount-desc": (request.sortRequest.key === column.key && !request.sortRequest.direction)
                                                })} aria-hidden="true" /> : null
                                        }

                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isLoadingData
                                ? <tr>
                                    <td colSpan={(store || []).length}><Spinner color={"primary"} /></td>
                                </tr>
                                : (response.records || []).map((row, rowIndex) =>
                                    <tr key={rowIndex} className={"align-middle"}>
                                        {(store || []).map((column, columnIndex) => {
                                            return (
                                                <td key={columnIndex} className={column.valueClassName}>
                                                    {column.render(row[column.key], row, rowIndex, templateData, this)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )}
                    </tbody>
                </Table>
            </div>
            <Row>
                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                    <div className={"text-left"} style={{ maxWidth: 200 }}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Show</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type={"select"}
                                name={"pageSize"}
                                value={request.pageRequest.pageSize}
                                disabled={isLoadingData}
                                onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                <option value={10}>10 Rows</option>
                                <option value={25}>25 Rows</option>
                                <option value={50}>50 Rows</option>
                                <option value={100}>100 Rows</option>
                                <option value={500}>500 Rows</option>
                            </Input>
                        </InputGroup>

                    </div>
                </Col>
                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                    <div className={"float-right"}>
                        <Pagination
                            activePage={request.pageRequest.currentPage}
                            itemsCountPerPage={request.pageRequest.pageSize}
                            totalItemsCount={templateData.response.totalRecords}
                            pageRangeDisplayed={3}
                            onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                            itemClass='page-item'
                            linkClass='page-link'
                            activeClass='active'
                            innerClass='pagination'
                            activeLinkClass='active'
                        />
                    </div>

                </Col>
            </Row>
            {
                isOpenDeleteConfirmationModal ?
                    <ConfirmModal
                        isOpen={isOpenDeleteConfirmationModal}
                        toggle={this.toggleDeleteConfirmModal}
                        handleSubmit={() => this.deleteTemplate(selectedCustomTemplateID)}
                        handleCancel={() => this.toggleDeleteConfirmModal(false)}
                        primaryMessage={"Are you sure you want to delete this template?"}
                        secondaryMessage={""}
                        submitColor={"danger"}
                        cancelColor={"secondary"}
                        icon={"fa fa-trash-o fa-2x"}
                        loading={isDeletingTemplate}
                        type="Deleting..."
                    />
                    : null
            }
        </div>)
    }
}
