import React, {Component} from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner, Table,Badge, ButtonGroup} from 'reactstrap';
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import {cloneDeep, isEmpty} from 'lodash';
import {Link as Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import {toast} from 'react-toastify';
import {
    changeFormatOfDateString,
    findIndex,
    getOrderColorName,
    handleErrorMessage,
    scrollTo, getShipmentStatusColorName,
    getDateString
} from '../../services/CommonService';
import dispatchOrderService from '../../services/DispatchOrderService';
import OrderService from '../../services/OrderService';
import SearchBannerDispatchOrder from './SearchBannerDispatchOrder';
import DispatchOrderItemsProductDetailModal from '../modal/DispatchOrderItemsProductDetailModal';
import ShippingModal from './ShippingModal';
import ShippingUpdateModal from './ShippingUpdateModal';
import UserService from "../../services/UserService";
import * as queryString from "query-string";
import ManageDispatchOrderPickupDateModal from '../modal/ManageDispatchOrderPickupDateModal';
import FileSaver from 'file-saver';
import CustomerCollectPickupModal from '../modal/CustomerCollectPickupModal';
import DispatchWindowareDeliveryDetailModal from '../modal/DispatchWindowareDeliveryDetailModal';
import DispatchPickupDetailModal from '../modal/DispatchPickupDetailModal';
import ShowShipmentStatusLogDetailModal from '../modal/ShowShipmentStatusLogDetailModal';
import ConfirmModal from "../../components/modal/ConfirmModal";
import ChangeCourierModal from '../modal/ChangeCourierModal';
import moment from 'moment'

export const maxDate = new Date();
export const minDate = moment().subtract(3, 'months').toDate();

export default class DispatchOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orders: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 100
                    },
                    sortRequest: {
                        key: '',
                        direction: false
                    },
                    filterRequest: {
                        ordNum: "",
                        accountID: "",
                        deliveryMethod: "",
                        isInvoiced: "",
                        isShipped: "",
                        startDate: minDate,
                        endDate: maxDate
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            isLoadingOrders: false,
            selectedOrdNum: null,
            isOpenProductGroupModal: false,
            isOpenShippingModal: false,
            isOpenShippingUpdateModal: false,
            hasDebtorReadPrivilege: false,
            hasOrderReadPrivilege: false,
            isOpenPickupDateModal: false,
            isLoadingDeliverySheet: false,
            isOpenCustomerCollectPickupModal: false,
            isOpenWindowareDeliveryModal: false,
            isOpenPickupInfoModal: false,
            isOpenShipmentStatusLogModal:false,
            showConfirmDeliver: false,
            isOpenCourierChangeModal: false,
            selectedOrdNumToChange: ''
        };

        this.orderService = new OrderService();
        this.userService = new UserService();
        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.printFile = this.printFile.bind(this);
        this.openShippingModal = this.openShippingModal.bind(this);
        this.toggleShippingModal = this.toggleShippingModal.bind(this);
        this.openShippingUpdateModal = this.openShippingUpdateModal.bind(this);
        this.toggleShippingUpdateModal = this.toggleShippingUpdateModal.bind(this);
        this.selectRecord = this.selectRecord.bind(this);
        this.selectAllRecords = this.selectAllRecords.bind(this);
        this.toggleProductGroupModal = this.toggleProductGroupModal.bind(this);
        this.updateDispatchOrder = this.updateDispatchOrder.bind(this);
        this.toggleUpdatePickupDateModal = this.toggleUpdatePickupDateModal.bind(this);
        this.openUpdatePickupDateModal = this.openUpdatePickupDateModal.bind(this);
        this.GenerateDeliverySheet = this.GenerateDeliverySheet.bind(this);
        this.openCustomerCollectPickupModal = this.openCustomerCollectPickupModal.bind(this);
        this.toggleCustomerCollectPickupModal = this.toggleCustomerCollectPickupModal.bind(this);
        this.toogleWindowareDeliverySheetModal = this.toogleWindowareDeliverySheetModal.bind(this);
        this.openPickupInfoModal = this.openPickupInfoModal.bind(this);
        this.togglePickupInfoModal = this.togglePickupInfoModal.bind(this);
        this.toggleShipmentStatusLogModal = this.toggleShipmentStatusLogModal.bind(this);
        this.openShipmentStatusLogModal = this.openShipmentStatusLogModal.bind(this);
        this.toggleDeliverModal = this.toggleDeliverModal.bind(this);
        this.handleDeliver = this.handleDeliver.bind(this);
        this.toggleCourierChangeModal = this.toggleCourierChangeModal.bind(this);
        this.getOrdNumToChange = this.getOrdNumToChange.bind(this);
    }

    componentDidMount() {
        let {orders, selectedOrdNum} = this.state;
        let {filterRequest, pageRequest, sortRequest} = orders.request;
        if (this.props.type) {
            switch (this.props.type) {
                case "overview":
                    orders.request.sortRequest.key = "productionCompletedDate";
                    break;
                case "windowareDelivery":
                    orders.request.sortRequest.key = "pickupDate";
                    orders.request.sortRequest.direction = true;
                    break;
                case "customerCollect":
                    orders.request.sortRequest.key = "delCompany";
                    break;
                case "archived":
                    orders.request.sortRequest.key = "shipDate";
                    break;
            }
        }
        let searchParams = queryString.parse(window.location.search);
        if (searchParams) {
            filterRequest.ordNum = searchParams.ordNum ? searchParams.ordNum : filterRequest.ordNum;
            filterRequest.accountID = searchParams.accountID ? searchParams.accountID : filterRequest.accountID;
            filterRequest.isInvoiced = searchParams.isInvoiced ? searchParams.isInvoiced : filterRequest.isInvoiced;
            filterRequest.isShipped = searchParams.isShipped ? searchParams.isShipped : filterRequest.isShipped;

            pageRequest.currentPage = searchParams.currentPage ? parseInt(searchParams.currentPage) : pageRequest.currentPage;
            pageRequest.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : pageRequest.pageSize;

            sortRequest.key = searchParams.sortRequestKey ? searchParams.sortRequestKey : sortRequest.key;
            sortRequest.direction = searchParams.sortRequestDirection ? searchParams.sortRequestDirection : sortRequest.direction;

            selectedOrdNum = searchParams.selectedOrderNum ? parseInt(searchParams.selectedOrderNum) : null;
        }

        let currentUser = this.userService.getLoggedInUser();
        let hasDebtorReadPrivilege = this.userService.hasPrivilege(currentUser, 'debtor-read');
        let hasOrderReadPrivilege = this.userService.hasPrivilege(currentUser, 'debtor-invoice-order-read');

        this.setState({orders, selectedOrdNum, hasDebtorReadPrivilege, hasOrderReadPrivilege}, () => {
            this.getOrders(this.state.orders);
        });
    }

    refreshAgain(updatedOrder) {
        let {orders} = this.state;
        let orderIndex = findIndex(this.state.orders.response.records, 'ordNum', updatedOrder.ordNum);
        orders.response.records[orderIndex].salesOrderJobStatusId = updatedOrder.salesOrderJobStatusId;
        orders.response.records[orderIndex].invPrinted = updatedOrder.invPrinted;
        orders.response.records[orderIndex].shipDate = updatedOrder.shipDate;
        if (updatedOrder.pickupDate) {
            orders.response.records[orderIndex].pickupDate = updatedOrder.pickupDate;
        }
        this.setState({orders});
    }

    toggleDeliverModal(change, orderNum) {
        this.setState({ showConfirmDeliver: change, selectedOrdNum: orderNum });
    }

    handleDeliver() {
        this.updateDispatchOrder(this.state.selectedOrdNum, "SHIPD");
        this.toggleDeliverModal(false, null);
    }

    getOrders(orders) {
        let {type} = this.props;
        this.setState({ isLoadingOrders: true, allRecordsSelected:false});
        let request = cloneDeep(orders.request);
        if (type === "archived") {
            request.filterRequest.isInvoiced = "";
            request.filterRequest.isShipped = "";
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        } else {
            request.filterRequest.startDate = "";
            request.filterRequest.endDate = "";
        }

        dispatchOrderService.getDispatchOrders(this.props.type, request).then(response => {
            orders.response = response.data;
            let {selectedOrdNum} = this.state;
            let canScroll = false;
            if (selectedOrdNum) {
                let orderIndex = findIndex(orders.response.records, 'ordNum', parseInt(selectedOrdNum));
                if (orderIndex > -1) {
                    canScroll = true;
                }
            }
            orders.response.records.forEach(ord => {
                ord.isCheckingCreditStatus = false;
            });
            this.setState({orders, isLoadingOrders: false}, () => {
                if (canScroll) {
                    setTimeout(() => {
                        scrollTo("Order" + selectedOrdNum)
                    }, 1000);
                }
            });
        }).catch(error => {
            this.setState({isLoadingOrders: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    onOrderClick(orderNum) {
        this.setState({selectedOrdNum: orderNum});
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("selectedOrderNum", orderNum);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }

    getColValue(value, storeItem, order, index) {
        let result = null;
        switch (storeItem.type) {
            case "openOrderDetailsModal":
                if (this.state.hasOrderReadPrivilege) {
                    return <ButtonGroup>
                        <Link
                            style={{ color: "white" }}
                            title={"Click here to view order in detail"}
                            onClick={() => this.onOrderClick(value)}
                            className={"btn btn-sm btn-" + getOrderColorName(order, this.state.selectedOrdNum) + ""}
                            to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}>
                            {value}
                        </Link>
                        {
                            order.isCustom ?
                                <Button size={"sm"} title={"Custom Order"}
                                    color={"warning"} disabled style={{ opacity: 1 }}>
                                    <i className="fa fa-wrench" />
                                </Button>
                                : null
                        }
                    </ButtonGroup>;
                }
                else {
                    return <Button
                        size="sm"
                        color={getOrderColorName(order, this.state.selectedOrdNum)}>
                        {value}
                    </Button>
                }

            case "date":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return <NumberFormat value={Math.floor(value)}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'} />;
            case "number":
                return <NumberFormat value={value}
                    displayType={'text'}
                    thousandSeparator={true} />;
            case "delCompany":
                return <div>{value}</div>;

            case "categoryByID":
                let className = "";
                switch (order.debtorCategoryID) {
                    case 1:
                        className = "btn btn-sm btn-warning";
                        break;
                    case 2:
                        className = "btn btn-sm btn-secondary";
                        break;
                    case 3:
                    default:
                        className = "btn btn-sm btn-primary";
                        break;
                }

                result = this.state.hasDebtorReadPrivilege
                    ?
                    <Link
                        className={className}
                        style={{ color: "white" }}
                        title={"Click here to see account details"}
                        to={"/customer/#" + value}
                    >{value}</Link>
                    :
                    <div className={className}>{value}</div>;

                return result;
            case "shipping":
                return <Button size={"sm"}
                    color={(order.salesOrderJobStatusId > 90 || order.invPrinted || order.shipDate || order.dispatchLogCount > 0) ? "warning" : "primary"}
                    onClick={() => this.openShippingModal(order)}
                    title={order.shipDate ? "Shipped on : " + order.shipDate : ""}>
                    {order.shipDate ? "Shipped" : "Ship"}</Button>;
            case "productDetails":
                return <small>
                    <a href={"javascript:;"}
                        style={{ color: (this.props.type === "overview" && order.salesOrderJobStatusId >= 95 && order.invPrinted && order.shipDate) ? 'red' : '#007bff' }}
                        onClick={() => this.toggleProductGroupModal(true, order.ordNum)}>
                        {this.getProductString(order.orderDetails)}
                    </a>
                </small>;
            case "consignLabelUrl":
                if (order.consignLabelUrl) {
                    return <div className={"text-center ml-2"}>
                        {
                            order.isLoadingConsignLabelDownload
                                ? <Spinner color={"primary"} size={"sm"} />
                                : <Button color={"link"} size={"sm"}
                                    onClick={() => this.downloadFile("Consign Label", order.ordNum)}>
                                    <i className="fa fa-download fa-lg" aria-hidden="true" />
                                </Button>
                        }
                        {
                            order.isLoadingConsignLabelPrint
                                ? <Spinner color={"primary"} size={"sm"} />
                                : <Button color={"link"} size={"sm"}
                                    onClick={() => this.printFile("Consign Label", order.ordNum)}>
                                    <i className="fa fa-print fa-lg" aria-hidden="true" />
                                </Button>
                        }
                    </div>;
                }
                return null;
            case "packingSlip":
                return <div className={"text-center ml-2"}>
                    {
                        order.isLoadingPackingSlipDownload
                            ? <Spinner color={"primary"} size={"sm"} />
                            : <Button color={"link"} size={"sm"}
                                onClick={() => this.downloadFile("Packing Slip", order.ordNum)}>
                                <i className="fa fa-download fa-lg" aria-hidden="true" />
                            </Button>
                    }
                    {
                        order.isLoadingPackingSlipPrint
                            ? <Spinner color={"primary"} size={"sm"} />
                            : <Button color={"link"} size={"sm"}
                                onClick={() => this.printFile("Packing Slip", order.ordNum)}>
                                <i className="fa fa-print fa-lg" aria-hidden="true" />
                            </Button>
                    }
                </div>;
            case "dispatchLabel":
                return <div className={"text-center ml-2"}>
                    {
                        order.isLoadingDispatchLabelDownload
                            ? <Spinner color={"primary"} size={"sm"} />
                            : <Button color={"link"} size={"sm"}
                                onClick={() => this.downloadFile("Dispatch Label", order.ordNum)}>
                                <i className="fa fa-download fa-lg" aria-hidden="true" />
                            </Button>
                    }
                    {
                        order.isLoadingDispatchLabelPrint
                            ? <Spinner color={"primary"} size={"sm"} />
                            : <Button color={"link"} size={"sm"}
                                onClick={() => this.printFile("Dispatch Label", order.ordNum)}>
                                <i className="fa fa-print fa-lg" aria-hidden="true" />
                            </Button>
                    }
                </div>;
            case "couriersPreference":
                return order.couriers ? order.couriers : order.couriersPreference;
            case "couriers":
                return order.couriers;
            case "shippingUpdateModal":
                return <Button size={"sm"}
                    color={"primary"}
                    onClick={() => this.openShippingUpdateModal(order)}
                    title={"Edit"}>
                    <i className="fa fa-pencil mr-2" aria-hidden="true" />
                    Edit</Button>;
            case "delivery":
                return <div className={"text-center ml-2"}>
                    {order.salesOrderJobStatusId !== 98 ?
                        <Button size={"sm"}
                            color={"primary"}
                            disabled={order.isLoadingDelivery}
                            onClick={() => this.toggleDeliverModal(true, order.ordNum)}
                            title={"Deliver"}>
                            {order.isLoadingDelivery ? <Spinner style={{ color: "white" }} className={"mr-2"} size={"sm"} /> : <i className="fa fa-truck mr-2" aria-hidden="true" />}
                            Deliver</Button>
                        : <Button size={"sm"}
                            color={"success"}
                            title={"Delivered"}>
                            <i className="fa fa-truck mr-2" aria-hidden="true" />
                            Delivered</Button>
                    }
                </div>;
            case "checkbox":
                return <div className={"text-center"}>
                    <a href={"javascript:void(0)"} onClick={() => this.selectRecord(value, order.ordNum)}>
                        <i className={classnames("fa", "fa-lg", {
                            "fa-check-square-o": value,
                            "fa-square-o": !value,
                        }
                        )} />

                    </a>
                </div>;
            case "pickupDate":
                return <div>
                    <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>
                    <Link size={"sm"} className={"ml-2"}
                        color={"primary"}
                        onClick={() => this.openUpdatePickupDateModal(order)}
                        title={"Edit"}>
                        <i className="fa fa-pencil mr-2" aria-hidden="true" />
                    </Link>
                </div>;
            case "pick":
                return <div className={"text-center ml-2"}>
                    {order.salesOrderJobStatusId !== 98 ?
                        <Button size={"sm"}
                            color={"primary"}
                            onClick={() => this.openCustomerCollectPickupModal(order)}
                            title={"Pick"}>
                            {order.isLoadingPick
                                ? <Spinner style={{ color: "white" }} className={"mr-2"} size={"sm"} />
                                :
                                <i className="fa fa-truck mr-2" aria-hidden="true" />}
                            Pick</Button>
                        : <Button size={"sm"}
                            color={"success"}
                            title={"Picked"}>
                            <i className="fa fa-truck mr-2" aria-hidden="true" />
                            Picked</Button>
                    }
                </div>;
            case "pickupInfoModal":
                if (order.couriers === 'Customer Collect' || order.couriersPreference === 'Customer Collect') {
                    return <Button size={"sm"}
                        color={"primary"}
                        onClick={() => this.openPickupInfoModal(order)}
                        title={"view"}>
                        <i className="fa fa-eye mr-2" aria-hidden="true" /> View
                    </Button>
                }
            case "shipmentStatusDescription":
                let url = order.consignLabelUrl;
                if (order.couriers == "TFM Express") {
                    if (url) {
                        return (<a
                            href={url}
                            target={"_blank"}>
                            <Badge color={getShipmentStatusColorName(order.shipmentStatusID)}>{value}</Badge>
                        </a>)
                    }
                    return <span><small>{value}</small></span>;
                } else {
                    return <Link size={"sm"} className={"ml-2"}
                        onClick={() => this.openShipmentStatusLogModal(order)}
                        title={"view to see tracking status log"}>
                        <Badge color={getShipmentStatusColorName(order.shipmentStatusID)}>{value}</Badge>
                    </Link>;
                } 
                break;
            case "changeCourier":
                return <Button color={"link"}
                           title={"Click here to change courier method."}
                           onClick={(e) => this.getOrdNumToChange(index)}>
                           <i className="fa fa-refresh"
                               aria-hidden="true" />
                       </Button>
                break;
            default:
                return <span>{value}</span>
        }
    }

    getOrdNumToChange(index) {
        let { orders } = this.state;
        let ordNum = orders.response.records[index].ordNum;
        this.setState({ selectedOrdNumToChange: ordNum }, () => this.toggleCourierChangeModal(true));
    }

    toggleCourierChangeModal(change) {
        this.setState({ isOpenCourierChangeModal: change });
    }

    downloadFile(type, ordNum) {
        let index, key;
        let {orders} = this.state;
        index = orders.response.records.findIndex(r => r.ordNum === ordNum);
        switch (type) {
            case "Consign Label":
                key = "isLoadingConsignLabelDownload";
                break;
            case "Packing Slip":
                key = "isLoadingPackingSlipDownload";
                break;
            case "Dispatch Label":
                key = "isLoadingDispatchLabelDownload";
                break;
        }
        if (index > -1) {
            orders.response.records[index][key] = true;
        }
        this.setState({orders});
        this.orderService.downloadFile(type, ordNum).then(response => {
            if (response.data) {
                window.open(URL.createObjectURL(response.data));
            }
            index = orders.response.records.findIndex(r => r.ordNum === ordNum);
            if (index > -1) {
                orders.response.records[index][key] = false;
            }
            this.setState({orders});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);

            index = orders.response.records.findIndex(r => r.ordNum === ordNum);
            if (index > -1) {
                orders.response.records[index][key] = false;
            }
            this.setState({orders});
        });
    }

    GenerateDeliverySheet(driverName) {
        let {orders} = this.state;
        let ordNums = [];
        (orders.response.records || []).forEach((order, index) => {
            if (order.selected) {
                ordNums.push(order.ordNum);
            }
        });
        this.setState({isLoadingDeliverySheet: true});
        this.orderService.downloadGenerateDeliverySheet(ordNums, driverName).then(response => {
            this.setState({isLoadingDeliverySheet: false});
            FileSaver.saveAs(response.data, "GenerateDeliverySheet.pdf");
        }).catch(error => {
            this.setState({isLoadingDeliverySheet: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    printFile(type, ordNum) {
        let index, key;
        let {orders} = this.state;
        index = orders.response.records.findIndex(r => r.ordNum === ordNum);
        switch (type) {
            case "Consign Label":
                key = "isLoadingConsignLabelPrint";
                break;
            case "Packing Slip":
                key = "isLoadingPackingSlipPrint";
                break;
            case "Dispatch Label":
                key = "isLoadingDispatchLabelPrint";
                break;
        }
        if (index > -1) {
            orders.response.records[index][key] = true;
        }
        this.setState({orders});
        this.orderService.printFile(type, ordNum).then(response => {
            index = orders.response.records.findIndex(r => r.ordNum === ordNum);
            if (index > -1) {
                orders.response.records[index][key] = false;
            }
            this.setState({orders});
            toast.success("Print job added!");
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);

            index = orders.response.records.findIndex(r => r.ordNum === ordNum);
            if (index > -1) {
                orders.response.records[index][key] = false;
            }
            this.setState({orders});
        });
    }

    openShippingModal(order) {
        this.setState({selectedOrdNum: order.ordNum, isOpenShippingModal: true});
    }

    toggleShippingModal(change) {
        this.setState({isOpenShippingModal: change});
    }

    openShippingUpdateModal(order) {
        this.setState({selectedOrdNum: order.ordNum, isOpenShippingUpdateModal: true});
    }

    toggleShippingUpdateModal(change) {
        this.setState({isOpenShippingUpdateModal: change});
    }

    toggleCustomerCollectPickupModal(change) {
        this.setState({ isOpenCustomerCollectPickupModal: change });
    }

    openCustomerCollectPickupModal(order) {
        this.setState({ selected: order, isOpenCustomerCollectPickupModal: true });
    }

    toggleProductGroupModal(change, ordNum, isRefreshRequested) {
        let {selectedOrdNum, isOpenProductGroupModal} = this.state;
        if (change) {
            if (ordNum) {
                selectedOrdNum = ordNum;
            }
        }
        isOpenProductGroupModal = change;
        this.setState({isOpenProductGroupModal, selectedOrdNum}, () => {
            if (isRefreshRequested) {
                this.getOrders(this.state.orders);
            }
        });
    }

    getProductString(orderDetails) {
        let productsString = [];
        let count = 0;
        (orderDetails || []).map((item) => {
            if (item.stockGroupShortName === "COMPONENTS") {
                count++;
            }
        });
        (orderDetails || []).map((item) => {
            if (item.stockGroupShortName !== "COMPONENTS") {
                productsString.push(item.stockGroupShortName + " (" + item.qty + ") ");
            }
        });
        if (count > 0) {
            productsString.push("COMPONENTS " + "(" + count + ")");
        }
        return productsString.toString();
    }

    handleChange(change, key) {
        let {orders} = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        switch (key) {
            case "sortKey":
                if (orders.request.sortRequest.key === change) {
                    orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                } else {
                    orders.request.sortRequest.key = change;
                    orders.request.sortRequest.direction = true;
                }
                searchParams.set("sortRequestKey", change);
                searchParams.set("sortRequestDirection", orders.request.sortRequest.direction);
                this.setState({orders}, () => {
                    this.getOrders(this.state.orders);
                });
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                searchParams.set("pageSize", change);
                this.setState({orders}, () => {
                    this.getOrders(this.state.orders);
                });
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                searchParams.set("currentPage", change);
                this.setState({orders}, () => {
                    this.getOrders(this.state.orders);
                });
                break;
            default:
                orders.request.filterRequest[key] = change;
                orders.request.pageRequest.currentPage = 1;
                if (key !== 'deliveryMethod') {
                    searchParams.set(key, change);
                }
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                this.setState({orders}, () => {
                    this.getOrders(this.state.orders);
                });
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }

    getStore() {
        let type = this.props.type;
        return [
            {
                key: "selected",
                label: <div className={"text-center"}><a href="javascript:void(0)" onClick={this.selectAllRecords}>
                    {this.state.allRecordsSelected ?
                        <i className="fa fa-lg fa-check-square-o" aria-hidden="true"/> :
                        <i className="fa fa-lg fa-square-o" aria-hidden="true"/>
                    }
                </a></div>,
                type: "checkbox",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: type === 'windowareDelivery',
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "ordNum",
                label: "Order No",
                type: "openOrderDetailsModal",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-left",
                labelClassName: "hoverableItem align-middle"
            },
            /*{
                key: "daysOutstanding",
                label: "Days OD",
                type: "number",
                colSpan: 1,
                minWidth: 40,
                sorterApplicable: false,
                showColumn: type === "overview",
                valueClassName: "text-right",
                labelClassName: "align-middle"
            },*/
            {
                key: "productionCompletedDate",
                label: "Completed Date",
                type: "date",
                colSpan: 1,
                minWidth: 160,
                sorterApplicable: true,
                showColumn: type !== "archived",
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "accountID",
                label: "Account",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "delCompany",
                label: "Company Name",
                type: "delCompany",
                colSpan: 1,
                minWidth: 250,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "orderDetails",
                label: "Stock Group(s)",
                type: "productDetails",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "couriersPreference",
                label: "Delivery Method(Pref.)",
                type: "couriersPreference",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: type === "overview",
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "couriers",
                label: "Delivery Method",
                type: "couriers",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: type === "archived",
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "shipping",
                label: "Ship",
                type: "shipping",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                showColumn: type === "overview",
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "shipDate",
                label: "Shipped On",
                type: "date",
                colSpan: 1,
                minWidth: 115,
                sorterApplicable: true,
                showColumn: type === "archived",
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "consignNum",
                label: "Connote No.",
                type: "consignNum",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-right",
                labelClassName: "align-middle"
            },
            {
                key: "shipmentID",
                label: "Shipment ID",
                type: "shipmentID",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-right",
                labelClassName: "align-middle"
            },
            {
                key: "shipmentStatusDescription",
                label: "Shipment Status",
                type: "shipmentStatusDescription",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: type === "archived",
                valueClassName: "text-right",
                labelClassName: "align-middle"
            },
            {
                key: "etaDate",
                label: "ETA",
                type: "date",
                colSpan: 1,
                minWidth: 160,
                sorterApplicable: true,
                showColumn: type === "archived",
                valueClassName: "text-right",
                labelClassName: "align-middle"
            },
            {
                key: "cartons",
                label: "Carton Qty",
                type: "totalCartonNumber",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-center",
                labelClassName: "align-middle"
            },
            {
                key: "consignLabelUrl",
                label: "Courier Label",
                type: "consignLabelUrl",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-center",
                labelClassName: "align-middle"
            },
            {
                key: "packingSlip",
                label: "Packing Slip",
                type: "packingSlip",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-center",
                labelClassName: "align-middle"
            },
            {
                key: "dispatchLabel",
                label: "Dispatch Label",
                type: "dispatchLabel",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-center",
                labelClassName: "align-middle"
            },
            {
                key: "shippingUpdateModal",
                label: "Override",
                type: "shippingUpdateModal",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-center",
                labelClassName: "align-middle"
            },
            {
                key: "delCity",
                label: "City",
                type: "default",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: type === "windowareDelivery",
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "pickupDate",
                label:"Scheduled Delivery",
                type: "pickupDate",
                colSpan: 1,
                minWidth: 180,
                sorterApplicable: true,
                showColumn: type === "windowareDelivery",
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "delivery",
                label: "Delivery",
                type: "delivery",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: type === "windowareDelivery",
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "pick",
                label: "Pick",
                type: "pick",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: type === "customerCollect",
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "pickupInfoModal",
                label: "Pickup Detail",
                type: "pickupInfoModal",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: false,
                showColumn: type === "archived",
                valueClassName: "text-center",
                labelClassName: "align-middle"
            },
            {
                key: "changeCourierMethod",
                label: "Change Delivery",
                type: "changeCourier",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                showColumn: type === "windowareDelivery" || type === "customerCollect",
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle"
            }
        ];
    }

    selectRecord(val, OrdNum) {
        let {orders} = this.state;
        const elementsIndex = orders.response.records.findIndex(x => x.ordNum === OrdNum);
        orders.response.records[elementsIndex].selected = !val;
        let counter = 0;
        for (let i in orders.response.records) {
            if (orders.response.records[i].selected) {
                counter++;
            }
        }
        if (counter === orders.response.records.length) {
            this.setState({allRecordsSelected: true, selectedCheckboxes_count: counter, orders});
        }
        else {
            this.setState({allRecordsSelected: false, selectedCheckboxes_count: counter, orders});
        }
    }

    selectAllRecords() {
        let {orders, allRecordsSelected} = this.state;
        orders.response.records.forEach(el => {
            el.selected = !allRecordsSelected;
        });
        let counter = 0;
        for (let i in orders.response.records) {
            if (orders.response.records[i].selected) {
                counter++;
            }
        }
        this.setState({orders, allRecordsSelected: !allRecordsSelected, selectedCheckboxes_count: counter});
    }

    updateDispatchOrder(ordNum, optionType) {
        let {orders} = this.state;
        let index = orders.response.records.findIndex(r => r.ordNum === ordNum);
        orders.response.records[index].isLoadingDelivery = true;
        this.setState({orders});
        dispatchOrderService.updateDispatchOrder(ordNum, optionType).then(response => {
            if (response.data) {
                orders.response.records[index].salesOrderJobStatusId = response.data.salesOrderJobStatusId;
            }
            orders.response.records[index].isLoadingDelivery = false;
            toast.success("Delivered!");
            this.getOrders(orders);
            this.setState({orders});

        }).catch(error => {
            orders.response.records[index].isLoadingDelivery = false;
            this.setState({orders});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    openUpdatePickupDateModal(order) {
        this.setState({isOpenPickupDateModal: true, selectedOrder: order});
    }

    toggleUpdatePickupDateModal(change) {
        this.setState({isOpenPickupDateModal: change});
    }

    toogleWindowareDeliverySheetModal(change) {
        this.setState({ isOpenWindowareDeliveryModal: change });
    }

    openPickupInfoModal(order) {
        this.setState({ selectedShipmentID: order.shipmentID,selectedOrdNum:order.ordNum, isOpenPickupInfoModal: true });
    }
    togglePickupInfoModal(change) {
        this.setState({ isOpenPickupInfoModal: change });
    }
    openShipmentStatusLogModal(order) {
        this.setState({ selectedShipment: order, isOpenShipmentStatusLogModal: true });
    }
    toggleShipmentStatusLogModal(change) {
        this.setState({ isOpenShipmentStatusLogModal: change });
    }


    render() {
        let {
            orders, isLoadingOrders, selectedOrdNum, isOpenPickupInfoModal,
            isOpenCustomerCollectPickupModal, isOpenShipmentStatusLogModal,
            isOpenWindowareDeliveryModal, isOpenShippingModal, isOpenShippingUpdateModal,
            isLoadingDeliverySheet, isOpenPickupDateModal, selectedCheckboxes_count,
            showConfirmDeliver, isOpenCourierChangeModal, selectedOrdNumToChange
        } = this.state;
        let {pageRequest, sortRequest, filterRequest} = this.state.orders.request;

        let {type} = this.props;
        let store = this.getStore(orders.request);

        return (
            <div>
                <div className="mb-2">
                    <SearchBannerDispatchOrder
                        type={type}
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                    />
                </div>

                <Row className={"align-items-center"}>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {isLoadingOrders ?
                                <span>
                                    <Spinner size={"sm"} color={"primary"}/>
                                </span>
                                :
                                <span>
                                    Showing{' '}
                                    {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {orders.response.totalRecords}
                                    {' '}entries
                                </span>
                            }

                        </div>
                    </Col>
                    {
                        selectedCheckboxes_count > 0 && type === 'windowareDelivery' ?
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"text-right"}>
                                <Button size={"sm"}
                                        color={"primary"}
                                        onClick={() => this.toogleWindowareDeliverySheetModal(true)}
                                        title={"Generate Sheet"}>
                                    {isLoadingDeliverySheet ?
                                        <Spinner style={{color: "white"}} className={"mr-2"} size={"sm"}/>
                                        : <i className="fa fa-truck-pickup mr-2" aria-hidden="true"/>}
                                    Generate Delivery Sheet</Button>
                            </Col>
                            : null
                    }
                </Row>
                <div className="mt-2">
                    <Table hover bordered size={"sm"} striped responsive>
                        <thead>
                        <tr>
                            {(store || []).filter(item => item.showColumn).map((item, index) => {
                                return (
                                    <th key={index}
                                        onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                        colSpan={item.colSpan}
                                        className={item.labelClassName}
                                        style={{minWidth: item.minWidth}}>
                                        {item.label}
                                        {
                                            item.sorterApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                )} aria-hidden="true"/> : null
                                        }
                                    </th>
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>

                        {(orders.response.records || []).map((order, i) => {

                            return (
                                <tr key={i}
                                    id={selectedOrdNum === order.ordNum ? "Order" + selectedOrdNum : null}>
                                    {(store || []).filter(item => item.showColumn).map((storeItem, index) => {
                                        return (
                                            <td key={index} className={storeItem.valueClassName}>
                                                {this.getColValue(order[storeItem.key], storeItem, order, i)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}

                        </tbody>
                    </Table>

                    {!isLoadingOrders ?
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={isLoadingOrders}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={orders.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                        :
                        null
                    }

                </div>

                {
                    this.state.isOpenProductGroupModal ?
                        <DispatchOrderItemsProductDetailModal
                            order={orders.response.records.find(x => x.ordNum === selectedOrdNum)}
                            isOpen={this.state.isOpenProductGroupModal}
                            toggle={this.toggleProductGroupModal}
                            refreshAgain={() => this.getOrders(orders)}/>
                        : null
                }

                {
                    isOpenShippingModal ?
                        <ShippingModal
                            order={orders.response.records.find(x => x.ordNum === selectedOrdNum)}
                            isOpen={isOpenShippingModal}
                            toggle={this.toggleShippingModal}
                            refreshAgain={() => this.getOrders(orders)}/>
                        : null
                }
                {
                    isOpenShippingUpdateModal ?
                        <ShippingUpdateModal
                            order={orders.response.records.find(x => x.ordNum === selectedOrdNum)}
                            isOpen={isOpenShippingUpdateModal}
                            toggle={this.toggleShippingUpdateModal}
                            refreshAgain={() => this.getOrders(orders)}/>
                        : null
                }
                {isOpenPickupDateModal ?
                    <ManageDispatchOrderPickupDateModal
                        isOpen={isOpenPickupDateModal}
                        toggle={this.toggleUpdatePickupDateModal}
                        selectedOrder={this.state.selectedOrder}
                        refreshAgain={() => this.getOrders(orders)}/>
                    : null
                }

                {isOpenCustomerCollectPickupModal ?
                    <CustomerCollectPickupModal
                        isOpen={isOpenCustomerCollectPickupModal}
                        toggle={this.toggleCustomerCollectPickupModal}
                        selectedOrder={this.state.selected}
                        refreshAgain={() => this.getOrders(orders)} />
                    : null
                }
                {isOpenWindowareDeliveryModal ?
                    <DispatchWindowareDeliveryDetailModal
                        isOpen={isOpenWindowareDeliveryModal}
                        toggle={this.toogleWindowareDeliverySheetModal}
                        downloadSheet={this.GenerateDeliverySheet} />
                   : null
                }
                {
                    isOpenPickupInfoModal ?
                        <DispatchPickupDetailModal
                            isOpen={isOpenPickupInfoModal}
                            toggle={this.togglePickupInfoModal}
                            selectedShipmentID={this.state.selectedShipmentID}
                            selectedOrdNum= {this.state.selectedOrdNum}
                            />
                        :null
                }
                {
                    isOpenShipmentStatusLogModal ?
                        <ShowShipmentStatusLogDetailModal
                            isOpen={isOpenShipmentStatusLogModal}
                            toggle={this.toggleShipmentStatusLogModal}
                            shipment={this.state.selectedShipment} />
                        :null
                }
                {
                    isOpenCourierChangeModal ?
                        <ChangeCourierModal
                            isOpen={isOpenCourierChangeModal}
                            toggle={this.toggleCourierChangeModal}
                            ordNum={selectedOrdNumToChange}
                            refreshAgain={() => this.getOrders(orders)}
                        />
                        : null
                    }
                <ConfirmModal
                    isOpen={showConfirmDeliver}
                    toggle={this.toggleDeliverModal}
                    handleSubmit={() => this.handleDeliver()}
                    handleCancel={() => this.toggleDeliverModal(false, null)}
                    primaryMessage={`Order Number: ${selectedOrdNum}<br />Company Name: ${orders?.response?.records?.find(x => x.ordNum === selectedOrdNum)?.delCompany}<br />Mark as delivered?`}
                    submitColor={"success"}
                    cancelColor={"secondary"}
                    icon={"fa fa-truck fa-2x"}
                    type="Updating..."
                />
            </div>
        );
    }
}