import React, {Component} from 'react';
import {Badge, Col, ListGroup, ListGroupItem, Row, Spinner, Button} from 'reactstrap';
import {Redirect} from "react-router-dom";
import {changeFormatOfDateString} from '../../../../services/CommonService';
import {isEmpty} from 'lodash';
import UploadFiles from "../../../../components/attachment/UploadFiles";
import OrderEnquiryOrderItem from "./OrderEnquiryOrderItem";
import OrderEnquiryDocumentDownload from "./OrderEnquiryDocumentDownload";
import OrderEnquiryCustomerDetail from "./OrderEnquiryCustomerDetail";
import ReactToPrint from "react-to-print";
import OrderEnquiryPrintMode from "./OrderEnquiryPrintMode";
import ReworkFormPageForCustomer from "../../../Rework/ReworkFormPageForCustomer";
import {toast, ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import {Link as Link} from "react-router-dom";
import ReworkOrderSummary from "../../../Rework/ReworkOrderSummary";
import CRMService from "../../../../services/crm/CRMService";

let visibleColumnsInOrderItems = ["expandIcon", "prodCode", "description", "productName", "location", "fabric",
    "width", "drop", "qty", "price", "qtyprice", "discVal", "orderedExtendedEx"];
const documentTypes = ["Invoice", "Quote", "Sales Order Summary"];

export default class OrderEnquiryReadOnlyMode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenWarrantyClaim: false,
            isOpenReworkOrderSummaryModal: false,
            paymentTermsId: null,
            isBulkOrder: props.isBulkOrder,
            isBlankOrder: props.orderItems === 0
        };
        this.crmService = new CRMService();
        this.toggleWarrantyClaim = this.toggleWarrantyClaim.bind(this);
        this.toggleReworkOrderSummaryModal = this.toggleReworkOrderSummaryModal.bind(this);
        this.getCustomerInformation = this.getCustomerInformation.bind(this);
    }

    componentDidMount() {
        let accountId = this.props.currentUser?.menu?.accountID;
        if (accountId) {
            this.getCustomerInformation(accountId);
        }
    }

    getCustomerInformation(accountId) {
        this.crmService.getCustomerInformation(accountId).then(response => {
            let paymentTermsId = response.data?.paymentTerm?.paymentTermsID;
            this.setState({ paymentTermsId });
        });
    }

    toggleWarrantyClaim(change) {
        this.setState({isOpenWarrantyClaim: change});
    }

    toggleReworkOrderSummaryModal() {
        this.setState((prevState) => ({isOpenReworkOrderSummaryModal: !prevState.isOpenReworkOrderSummaryModal}));
    }

    render() {
        let {
            order, isQuoteValid,
            toggleExpandRow, toggleAllRowExpanded,
            salesOrder, reworkOrder, getOrder,
            currentUser, toggleRetailCustomerModal
        } = this.props;

        let { isOpenWarrantyClaim, isOpenReworkOrderSummaryModal, paymentTermsId, isBulkOrder, isBlankOrder } = this.state;
        if (order.salesOrderJobStatusId >= 95)  //  if order is invoiced then we can show the qty supplied column..
        {
            let visibleColumns = visibleColumnsInOrderItems;
            let index = visibleColumns.indexOf("qty");
            visibleColumns.splice(index + 1, 0, "qtySupplied");
            visibleColumnsInOrderItems = visibleColumns;
        }
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color="warning"
                                               className={"ml-2"}>
                                            {order.salesOrderJobStatusDescription}
                                        </Badge>
                                        {salesOrder ? salesOrder.isRetail ?
                                            <Badge color="danger"
                                                   className={"ml-2"}>
                                                Retail Order
                                            </Badge>
                                            : null
                                            : null
                                        }
                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Button title={"Rework"} color={"warning"}
                                                        className={"ml-2"}
                                                        size={"sm"}
                                                        onClick={this.toggleReworkOrderSummaryModal}
                                                >
                                                    <strong>R</strong>
                                                </Button>
                                                : null
                                        }

                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Link
                                                    style={{color: "white"}}
                                                    title={"Original Order"}
                                                    className={"btn btn-sm btn-info ml-2"}
                                                    onClick={() => getOrder(reworkOrder.originalOrdNum, "current")}
                                                    to={"/sales/order/view?" + queryString.stringify({OrdNum: reworkOrder.originalOrdNum})}>
                                                    <strong>Original order:</strong> {reworkOrder.originalOrdNum}
                                                </Link>
                                                : null
                                        }

                                        {
                                            order && order.isCustom ?
                                                <Button size={"sm"} title={"Custom Order"}
                                                    color={"warning"} className={"ml-2"} disabled style={{ opacity: 1 }}>
                                                    <i className="fa fa-wrench" />
                                                </Button>
                                                : null
                                        }
                                    </small>

                                    &nbsp;
                                    {currentUser.isExternalUser && !isBlankOrder && ((order.salesOrderJobStatusId === 10 || order.documentTypeID === 3) && order.salesOrderJobStatusId !== 1 && isQuoteValid) ?
                                        <Button color={"primary"}
                                                size={"sm"} onClick={() => this.props.convertQuoteToOrder(order)}>
                                            {(this.props.convertingToOrder)
                                                ?
                                                <Spinner size={"sm"} color={"warning"}/> :
                                                <i className="fa fa-cart-arrow-down mr-2" aria-hidden="true"/>
                                            }
                                            {"Convert To Order"}
                                        </Button>
                                        : null}
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                    {(order.highPriority) ?
                                        <Badge color="danger" className={"m-1 p-1"} pill>MUSTGO</Badge>
                                        : null
                                    }
                                    {(order.excludeFuelSurcharge) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>No Fuel
                                            Surcharge</Badge>
                                        : null
                                    }
                                    {(order.isInstallation) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>>Installation</Badge>
                                        : null
                                    }
                                    {(order.isRemake) ?
                                        <Badge color="info" className={"m-1 p-1"}>Remake</Badge> : null
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"text-right"}>
                                <Row>

                                </Row>
                                <OrderEnquiryDocumentDownload
                                    orderCreatedFrom={salesOrder ? salesOrder.createdFrom : ""}
                                    documentTypes={order.salesOrderJobStatusId >= 95 ? documentTypes : (paymentTermsId === 2 ? documentTypes.filter(x => ["Invoice", "Sales Order Summary"].includes(x)) : documentTypes.filter(x => x === "Sales Order Summary"))}
                                    ordNum={order.ordNum}
                                    isExternalUser={currentUser.isExternalUser}
                                    isBulkOrder={isBulkOrder}
                                    isBlankOrder={isBlankOrder}
                                    refreshOrder={() => getOrder(order.ordNum, "current")}
                                    history={this.props.history}
                                />
                                <div className="mt-2">
                                    {!currentUser.isExternalUser ?
                                        <ReactToPrint
                                            trigger={() => {
                                                return <Button color={"primary"} size={"sm"}>
                                                    <i className="fa fa-print mr-2"
                                                       aria-hidden="true"/>Print
                                                </Button>;
                                            }}
                                            content={() => this.componentRef}
                                        /> : null}
                                    {
                                        (order.salesOrderJobStatusId >= 98 && !currentUser.isExternalUser && !isBulkOrder) ?
                                            <Button size={"sm"} color={"primary"} className={"ml-2"}
                                                    onClick={() => this.toggleWarrantyClaim(true)}><i
                                                className="fa fa-ticket mr-2" aria-hidden="true"/>Create Rework</Button>
                                            : null
                                    }
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <OrderEnquiryCustomerDetail order={order}
                                                        salesOrder={salesOrder}
                                                        editCustomer={toggleRetailCustomerModal}
                                                        currentUser={currentUser}/>
                        </div>
                        <hr/>
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    (order.custOrdNum || order.custOrdDate)
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {
                                                    order.custOrdNum ?
                                                        <div>
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order No.</strong>
                                                            </p>
                                                            <div>{order.custOrdNum}</div>
                                                        </div> : null
                                                }
                                                {
                                                    order.custOrdDate ?
                                                        <div className="mt-2">
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order Date</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.custOrdDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250}}>
                                        <div>
                                            <p className={"mb-1"}><strong>Required on</strong></p>
                                            <div>
                                                {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}

                                            </div>
                                        </div>
                                        {
                                            order.jobRef ?
                                                <div className="mt-2">
                                                    <p className={"mb-1"}>
                                                        <strong>Job Reference</strong>
                                                    </p>
                                                    <div>{order.jobRef}</div>
                                                </div> : null
                                        }
                                        {order.repArea ?
                                            <div className="mt-2">
                                                <p className={"mb-1"}><strong>Sales
                                                    Rep</strong>
                                                </p>
                                                <div>{order.repArea.name} ({order.repArea.code})</div>
                                            </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (order.couriers || order.consignNum || order.courierInstructions || order.shipDate)
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {order.couriers ?
                                                    <div>
                                                        <p className={"mb-1"}><strong>Shipment
                                                            by</strong></p>
                                                        <div>{order.couriers}</div>
                                                    </div> : null
                                                }
                                                {order.consignNum ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Consignment
                                                            no</strong>
                                                        </p>
                                                        <div>{order.consignNum}</div>
                                                    </div> : null
                                                }
                                                {order.courierInstructions ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Courier Instructions</strong></p>
                                                        <div>{order.courierInstructions}</div>
                                                    </div> : null
                                                }
                                                {
                                                    order.shipDate
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Shipped date</strong></p>
                                                            <div>{changeFormatOfDateString(order.shipDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }

                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250, overflowY: 'auto'}}>
                                        <p className={"mb-1"}><strong>Notes</strong></p>
                                        {order.notes}
                                    </div>

                                </ListGroupItem>
                                {
                                    (salesOrder && !isEmpty(salesOrder.attachmentIDs))
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                <UploadFiles
                                                    isReadOnly={true}
                                                    attachmentIDs={salesOrder ? salesOrder.attachmentIDs : ""}
                                                    multiple={false}
                                                    maxLength={1} />
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                            </ListGroup>
                        </div>
                        <hr/>
                        <div>
                            <OrderEnquiryOrderItem
                                toggleExpandRow={toggleExpandRow}
                                toggleAllRowExpanded={toggleAllRowExpanded}
                                order={order}
                                currentUser={currentUser}
                                visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                salesOrder={salesOrder}
                            />
                        </div>
                    </div>
                </div>
                <div style={{display: "none"}}>
                    <OrderEnquiryPrintMode order={order}
                                           visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                           currentUser={currentUser}
                                           toggleExpandRow={toggleExpandRow} toggleAllRowExpanded={toggleAllRowExpanded}
                                           ref={el => (this.componentRef = el)}
                    />
                </div>
                {isOpenWarrantyClaim ?
                    <ReworkFormPageForCustomer
                        isOpen={isOpenWarrantyClaim}
                        toggle={this.toggleWarrantyClaim}
                        toggleExpandRow={toggleExpandRow}
                        toggleAllRowExpanded={toggleAllRowExpanded}
                        order={order}
                        currentUser={currentUser}
                        visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                        salesOrder={salesOrder}
                        getOrder={getOrder}
                        history={this.props.history}
                    />
                    : null
                }
                {
                    isOpenReworkOrderSummaryModal ?
                        <ReworkOrderSummary
                            isOpen={isOpenReworkOrderSummaryModal}
                            toggle={this.toggleReworkOrderSummaryModal}
                            reworkOrder={reworkOrder}
                            getOrder={getOrder}
                            order={order}
                            currentUser={currentUser}
                        />
                        : null
                }
                <ToastContainer/>
            </div>
        );

    }
}
