import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import axiosFileUploader from './axiosFileUploader';

export default class BulkOrderService {

    generateBulkOrderImportFormat() {
        return axiosFileDownloader.get('api/bulkOrder/template/export');
    }

    importBulkOrderData(request) {
        return axiosFileUploader.post('api/bulkOrder/import', request, { timeout: 600000 });
    }

    checkImportedData(request) {
        return axios.post('api/bulkOrder/check/data/import', request);
    }

    getBulkOrderData(request) {
        return axios.post('api/bulkOrder/check/data/import', request);
    }

    createBulkOrder(request) {
        return axios.post('api/bulkOrder/save', request);
    }

    createBulkOrderItems(request, ordNum) {
        return axios.post('api/bulkOrder/create/items?ordNum=' + ordNum, request);
    }

    clearOrderItems(ordNum) {
        return axios.delete('api/bulkOrder/clear/items?ordNum=' + ordNum, { timeout: 1800000 });
    }

    getEDIOrders() {
        return axios.get('api/bulkOrder/list/ediOrders');
    }

    linkEDIOrder(request) {
        return axios.post('api/bulkOrder/link/ediOrder', request);
    }

    updateShippingAddress(request) {
        return axios.post('api/bulkOrder/update/shippingAddress', request);
    }

}