import React, {Component} from 'react';
import {
    Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody,
    CardFooter, Input, Button, InputGroup, InputGroupAddon, InputGroupText, Spinner, Badge
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import StockService from '../../services/StockService';
import KeywayStockService from '../../services/KeywayStockService';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep'
import {handleErrorMessage} from '../../services/CommonService';
import StockAttributeManageModal from '../../components/modal/StockAttributeManageModal';
import ConfirmModal from '../../components/modal/ConfirmModal'


export default class ManageStockAttributePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockAttributeData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        type: '',
                        value: '',
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            selectedStockAttribute: {},
            isOpenModal: false,
            loading: true,
            searchText: '',
            deleteItem: null,
            isOpenDeleteConfirmModal: false,
        };
        this.stockService = new StockService();
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.searchFunction = this.searchFunction.bind(this);
        this.toggleCompleteConfirmModal = this.toggleCompleteConfirmModal.bind(this);
        this.handleDeleteStockAttribute = this.handleDeleteStockAttribute.bind(this);
    }

    componentDidMount() {
        this.getStocksAttribute(this.state.stockAttributeData);
    }

    openModal(selectedStockAttribute) {
        this.setState({
            isOpenModal: true,
            selectedStockAttribute: selectedStockAttribute
        });
    }

    toggleModal(data) {
        this.setState({isOpenModal: data});
    }

    refreshAgain() {

        let {stockAttributeData} = this.state;
        this.setState({isOpenModal: false});
        this.getStocksAttribute(stockAttributeData);
    }

    getStocksAttribute(stockAttributeData) {
        this.setState({loading: true});

        KeywayStockService.getStockAttributeList(stockAttributeData.request).then(response => {
            stockAttributeData.response = response.data;
            this.setState({stockAttributeData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStockAttributeHeading({filterRequest}) {
        let stockAttributeHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "type",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="type" name="type"
                                        value={filterRequest.type}
                                        onChange={(e) => this.handleChange(e.target.value, "type")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "value",
                label: "Value",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="value" name="value"
                                        value={filterRequest.value}
                                        onChange={(e) => this.handleChange(e.target.value, "value")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center">
                    <Button color='primary'
                            size='sm'
                            onClick={() => this.openModal({type: '', value: ''})}>
                        <i className="fa fa-plus mr-2"
                           aria-hidden="true"/>
                        Add</Button>
                </div>
            }];

        return stockAttributeHeading;
    }

    handleChange(change, key) {
        let {stockAttributeData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (stockAttributeData.request.sortRequest.key === change) {
                    stockAttributeData.request.sortRequest.direction = !stockAttributeData.request.sortRequest.direction;
                } else {
                    stockAttributeData.request.sortRequest.key = change;
                    stockAttributeData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStocksAttribute(stockAttributeData);
                }
                break;
            case "pageSize":
                stockAttributeData.request.pageRequest[key] = change;
                stockAttributeData.request.pageRequest.currentPage = 1;
                this.getStocksAttribute(stockAttributeData);
                break;
            case "currentPage":
                stockAttributeData.request.pageRequest[key] = change;
                this.getStocksAttribute(stockAttributeData);
                break;
            default:
                stockAttributeData.request.filterRequest[key] = change;
                stockAttributeData.request.pageRequest.currentPage = 1;
                this.getStocksAttribute(stockAttributeData);
                this.setState({stockAttributeData});
        }

    }
    handleDeleteStockAttribute() {
        let { stockAttributeData, deleteItem } = this.state;
        KeywayStockService.deleteStockAttribute(deleteItem.type, deleteItem.value).then(response => {
            if ( response.status == 200) {
                this.setState({ canDeleteStockUnitModal: false });
                this.getStocksAttribute(stockAttributeData);
                toast.success("Deleted");
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            flag = false;
            searchText = searchText.toLowerCase();
            if (!flag && item.value) {
                flag = item.value.toLowerCase().includes(searchText);
            }
            if (!flag && item.type) {
                flag = item.type.toLowerCase().includes(searchText);
            }
        }
        return flag;
    }
    toggleCompleteConfirmModal(change, item) {
        this.setState({ isOpenDeleteConfirmModal: change, deleteItem: item });
    }
    handleDelete() {
        this.setState({ isOpenDeleteConfirmModal: false }, () =>
            this.handleDeleteStockAttribute());
    }

    render() {
        let {stockAttributeData, loading, searchText, isOpenModal, selectedStockAttribute} = this.state;
        let {pageRequest, sortRequest} = stockAttributeData.request;
        let stockHeading = this.getStockAttributeHeading(stockAttributeData.request);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Attribute</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Stocks Attribute</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockAttributeData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                   aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(stockHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(stockHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>
                                <tbody>
                                {(stockAttributeData.response.records || []).filter((stock) => this.searchFunction(stock, searchText)).map((stock, index) =>
                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td>{stock.type}</td>
                                        <td>{stock.value}</td>
                                        <td className="text-center">
                                            <Button color='btn btn-primary btn-sm'
                                                    size='sm'
                                                    onClick={() => this.openModal(stock)}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />Edit</Button>
                                            &nbsp;&nbsp; 
                                            <Button color='btn btn-danger btn-sm'
                                                onClick={() => this.toggleCompleteConfirmModal(true, stock)}>
                                                <i class="fa fa-trash-o mr-2" aria-hidden="true" />
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>

                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockAttributeData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                {
                    isOpenModal ?
                        <StockAttributeManageModal
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            selectedStockAttribute={selectedStockAttribute}
                            refreshAgain={this.refreshAgain}
                        />
                        : null
                }
                {
                    this.state.isOpenDeleteConfirmModal &&
                    <ConfirmModal
                        isOpen={this.state.isOpenDeleteConfirmModal}
                        toggle={this.toggleCompleteConfirmModal}
                        handleSubmit={() => this.handleDelete()}
                        handleCancel={() => this.toggleCompleteConfirmModal(false)}
                        primaryMessage={"Are you sure to delete this attribute?"}
                        submitColor={"danger"}
                        cancelColor={"secondary"}
                        icon={"fa fa-trash-o fa-2x"}
                        type="Deleting..."
                    />
                }
                <ToastContainer/>
            </div>
        );
    }
}