import React, {Component} from 'react';
import {Button, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner, Table} from "reactstrap";
import addressService from "../../services/AddressService";
import {getCommonAddressString, getSorted, handleErrorMessage} from "../../services/CommonService";
import {toast} from "react-toastify";
import {isEmpty} from 'lodash';

import ConfirmModal from "../modal/ConfirmModal";
import DeliveryAddressCreateModal from "../modal/DeliveryAddressCreateModal";

export default class DeliveryAddressCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAddress: {},
            accountId: "",
            addresses: [],
            isOpenDeliveryAddressCreateModal: false,
            deliveryAddressSearchText: "",
            loadingDeliveryAddresses: false,
            loadingSaveAddress: false,
            deleteAddressID: "",
            showConfirmDelete: false,
            deleting: false,
        };

        this.modalClearAddress = this.modalClearAddress.bind(this);
        this.getDebtorAddresses = this.getDebtorAddresses.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.toggleAddressFormModal = this.toggleAddressFormModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.accountId)) {
            this.setState({accountId: this.props.accountId}, () => {
                this.refresh();
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({accountId: nextProps.accountId}, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        this.getDebtorAddresses();
    }

    getDebtorAddresses() {
        if (this.state.accountId) {
            this.setState({loadingDeliveryAddresses: true});
            addressService.getDebtorAddresses(this.state.accountId).then(response => {
                let addresses = response.data;
                if (addresses) {
                    for (let i = 0; i < addresses.length; i++) {
                        addresses[i].fullAddress = getCommonAddressString(addresses[i]);
                    }
                    addresses = getSorted(addresses, 'addressID', false);
                }


                this.setState({addresses: addresses, loadingDeliveryAddresses: false});
            }).catch(error => {
                console.log(error);
                this.setState({loadingDeliveryAddresses: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.TOP_CENTER
                });
            });

        }
    }

    modalClearAddress() {
        return {
            debtorAccountID: '',
            contactName: '',
            company: '',
            phone: '',
            fax: '',
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            city: '',
            state: '',
            postCode: '',
            area_code: '',
            country: 'AUSTRALIA',

        }
    }


    toggleAddressFormModal(isOpen) {
        let {selectedAddress} = this.state;
        if (!isOpen) {
            selectedAddress = this.modalClearAddress()
        }
        this.setState({
            isOpenDeliveryAddressCreateModal: isOpen,
            selectedAddress
        });
    }


    handleSearchTextChange(change, key) {
        this.setState({[key]: change});
    }

    searchDeliveryAddressFunction(item, searchText) {
        let flag = true;
        if (item.contactName && searchText) {
            flag = item.contactName.toLowerCase().includes(searchText.toLowerCase())
        }
        if (item.email && searchText) {
            flag = item.email.toLowerCase().includes(searchText.toLowerCase())
        }
        if (item.phone && searchText) {
            flag = item.phone.toLowerCase().includes(searchText.toLowerCase())
        }
        if (item.fullAddress && searchText) {
            flag = item.fullAddress.toLowerCase().includes(searchText.toLowerCase())
        }
        return flag;
    }

    deleteAddress() {
        this.setState({ deleting: true });
        let request = [];
        request.push(this.state.deleteAddressID);
        addressService.deleteDebtorAddress(this.state.deleteAddressID).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.refresh(this.state.accountId);
                this.setState({deleteAddressID: "", showConfirmDelete: false, deleting: false});
                toast.success("Deleted!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    toggleDeleteModal(change) {
        this.setState({showConfirmDelete: change, deleteAddressID: ""})
    }


    handleAction(key, change) {
        switch (key) {
            case "deliveryAddress-delete":
                this.setState({deleteAddressID: change.addressID, showConfirmDelete: true});
                break;
            case "deliveryAddress-add":
                change = this.modalClearAddress();
                change.debtorAccountID = this.state.accountId;
                this.setState({isOpenDeliveryAddressCreateModal: true, selectedAddress: change});
                break;
            case "deliveryAddress-edit":
                let selectedAddress = {
                    addressID: change.addressID,
                    debtorAccountID: change.debtorAccountID,
                    contactName: change.contactName,
                    phone: change.phone,
                    fax: change.fax,
                    email: change.email,
                    company: change.company,
                    address1: change.address1,
                    address2: change.address2,
                    address3: change.address3,
                    address4: change.address4,
                    city: change.city,
                    state: change.state,
                    postCode: change.postCode,
                    country: change.country,
                    area_code: change.area_code
                };
                this.setState({isOpenDeliveryAddressCreateModal: true, selectedAddress});
                break;
            case "deliveryAddress-created":
                this.setState({isOpenDeliveryAddressCreateModal: false}, () => {
                    this.getDebtorAddresses();
                });
                break;
        }
    }


    render() {
        let {
            addresses, selectedAddress, deliveryAddressSearchText, loadingDeliveryAddresses,
            showConfirmDelete, deleting
        } = this.state;

        return (
            <div>
                <div>
                    {loadingDeliveryAddresses
                        ? <Spinner color={"primary"}/>
                        : <div>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-search"
                                           aria-hidden="true"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    size={"md"}
                                    onChange={(e) => this.handleSearchTextChange(e.target.value, e.target.name)}
                                    type="search"
                                    name="deliveryAddressSearchText"
                                    id="searchText"
                                    placeholder="Search..."/>
                            </InputGroup>
                            <Table size={"sm"} striped={true} hover={true} bordered responsive>
                                <thead>
                                <tr>
                                    <th>
                                        Contact Person
                                    </th>
                                    <th style={{minWidth: 250}}>
                                        Contact Details
                                    </th>
                                    <th>
                                        Company
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th className={"text-center align-middle"} style={{minWidth: 115}}>
                                        <Button size="sm" color="primary"
                                                title={"Add delivery address"}
                                                className={"mt-1"}
                                                onClick={() => this.handleAction("deliveryAddress-add", {})}>
                                            <i className="fa fa-plus mr-2"
                                               aria-hidden="true"/>
                                            Add
                                        </Button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (addresses || []).filter((item) => this.searchDeliveryAddressFunction(item, deliveryAddressSearchText)).map((address, index) =>
                                        <tr key={index}>
                                            <td className={"align-middle"}>
                                                <div>{address.contactName}</div>
                                            </td>
                                            <td className={"align-middle"}>
                                                {address.phone
                                                    ? <div><a href={"tel:" + address.phone}>
                                                        <i className="text-muted fa fa-fw fa-phone mr-2"
                                                           aria-hidden="true"/>
                                                        {address.phone}
                                                    </a></div>
                                                    : null
                                                }
                                                {address.email
                                                    ? <div><a href={"mailto:" + address.email}>
                                                        <i className="text-muted fa fa-fw fa-envelope-o mr-2"
                                                           aria-hidden="true"/>
                                                        {address.email}
                                                    </a></div>
                                                    : null
                                                }
                                            </td>
                                            <td className={"align-middle"}>
                                                <div>{address.company}</div>
                                            </td>
                                            <td className={"align-middle"}>
                                                {address.fullAddress
                                                    ? <a
                                                        href={"http://maps.google.com/?q=" + address.fullAddress}
                                                        target="_blank">
                                                        <i className="fa fa-map-marker fa-fw mr-2"
                                                           aria-hidden="true"/>{address.fullAddress}
                                                        <i className="fa fa-external-link fa-fw ml-3"
                                                           aria-hidden="true"/>
                                                    </a>
                                                    : null
                                                }
                                            </td>
                                            <td className={"align-middle"}>
                                                <div className={"text-center"}>
                                                    <Button color={"primary"}
                                                            className={"mr-2"}
                                                            size={"sm"}
                                                            onClick={() => this.handleAction("deliveryAddress-edit", address)}>
                                                        <i className={"fa fa-pencil mr-2"}/>Edit
                                                    </Button>
                                                    <Button color={"danger"}
                                                            size={"sm"}
                                                            onClick={() => this.handleAction("deliveryAddress-delete", address)}>
                                                        <i className="fa fa-trash"/>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    }

                </div>
                {this.state.isOpenDeliveryAddressCreateModal
                    ? <DeliveryAddressCreateModal
                        isOpen={this.state.isOpenDeliveryAddressCreateModal}
                        toggle={this.toggleAddressFormModal}
                        address={selectedAddress}
                        onCreation={(createdAddress) => this.handleAction("deliveryAddress-created", createdAddress)}
                        usage={"External User Page"}
                    />
                    : null
                }


                <ConfirmModal
                    isOpen={showConfirmDelete}
                    toggle={this.toggleDeleteModal}
                    handleSubmit={() => this.deleteAddress()}
                    handleCancel={() => this.toggleDeleteModal(false)}
                    primaryMessage={"Are you sure you want to delete this address?"}
                    secondaryMessage={""}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    loading={deleting}
                    type="Deleting..."
                />
            </div>
        );
    }
}