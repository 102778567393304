import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import classnames from "classnames";
import { Link as Link } from "react-router-dom";
import dispatchOrderService from '../../services/DispatchOrderService';
import courierService from '../../services/shipment/CourierService';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import SearchUser from '../search/SearchUser';
import { getDateObj, getDateString, handleErrorMessage, } from '../../services/CommonService';
import { EventBus } from '../../../../ClientApp/src/components/events/event';

import { v4 as uuidv4 } from 'uuid';
import UpdateOrderDeliveryAddressModal from '../modal/UpdateOrderDeliveryAddressModal';
import { camelCase, cloneDeep, isEmpty } from 'lodash';
import {
    SHIPMENT_COURIER_BORDER_EXPRESS_NAME,
    SHIPMENT_COURIER_BORDER_EXPRESS_SERVICE_CODE,
    SHIPMENT_COURIER_TFM_EXPRESS_NAME,
    SHIPMENT_METHOD_ConsignNumNotMandatory,
    SHIPMENT_SENDER_DEFAULT_ID
} from '../../store/AppConstants';
import SearchDespatchSender from "../search/SearchDespatchSender";
import SearchDespatchReceiver from "../search/SearchDespatchReceiver";
import * as FileSaver from 'file-saver';
import {
    shipmentItemTypes,
    shipmentLinekeys
} from '../../store/EstimatedProductWeightDetail';
import ConfirmModal from "../modal/ConfirmModal";

export default class ShippingModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            couriers: [],
            isLoadingShippingProcess: false,
            isLoadingShipmentPreferences: false,
            isLoadingDebtorInvoiceItems: false,
            shipment: {
                courier: "",
                courierCarrierCode: "",
                courierServiceCode: "",
                pickUpDate: new Date(),
                shipmentLines: [],
                shipmentSenderID: SHIPMENT_SENDER_DEFAULT_ID,//default
                shipmentReceiverID: 0,
                shipmentSenderAddress: {},
                shipmentReceiverAddress: {},
                shipmentSenderAddressFullAddressDom: null,
                shipmentReceiverAddressFullAddressDom: null,
            },
            consignment: {
                consignNum: "",
                autoGenerate: false
            },
            packedBy: "",
            isOpenUpdateOrderDeliveryAddressModal: false,
            isShipmentReceiverAddressValid: true,
            isShipmentReceiverAddressValidLoading: false,
            isShipmentReceiverAddressSaveLoading: false,
            printDispatchLabel: false,	// this should be by default == false (client requirement) (this field linked with Print Dispatch Label)
            printInvoice: false,
            printPackSlip: false,
            dispatchProcessProgressResponse: {
                logs: [],
                actionType: "Start",
                actionProcessName: "Initiate",
                printJobs: []
            },
            error: {
                hasError: false,
                shipmentLines: []
            },
            isOpenConfirmModal: false,
            form: {
                invoiceOptionsDisabled: false,
                shipmentConsignmentDisabled: false,
                shippingDisabled: false,
            },
            isCourierChanged: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.getCouriers = this.getCouriers.bind(this);
        this.getShippingPreferences = this.getShippingPreferences.bind(this);
        this.getShipmentLines = this.getShipmentLines.bind(this);
        this.isDispatchDataLoading = this.isDispatchDataLoading.bind(this);
        this.toggleUpdateOrderDeliveryAddressModal = this.toggleUpdateOrderDeliveryAddressModal.bind(this);
        this.handleOrderDeliveryAddressUpdate = this.handleOrderDeliveryAddressUpdate.bind(this);
        this.validateShipmentReceiver = this.validateShipmentReceiver.bind(this);
        this.saveShipmentReceiverAddress = this.saveShipmentReceiverAddress.bind(this);
        this.getDispatchLogs = this.getDispatchLogs.bind(this);
        this.processDebtorInvoiceInitiate = this.processDebtorInvoiceInitiate.bind(this);
        this.processDebtorInvoiceInvoicing = this.processDebtorInvoiceInvoicing.bind(this);
        this.processDebtorInvoiceShipmentConsignmentCreate = this.processDebtorInvoiceShipmentConsignmentCreate.bind(this);
        this.processDebtorInvoiceShipping = this.processDebtorInvoiceShipping.bind(this);
        this.processDebtorInvoiceOrderStatusUpdate = this.processDebtorInvoiceOrderStatusUpdate.bind(this);
        this.processDebtorInvoiceDispatchCommunication = this.processDebtorInvoiceDispatchCommunication.bind(this);
        this.processDebtorInvoiceDispatchWrapper = this.processDebtorInvoiceDispatchWrapper.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.processDebtorInvoiceDispatchProcessProgress = this.processDebtorInvoiceDispatchProcessProgress.bind(this);
        this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
        this.submitConfirmModal = this.submitConfirmModal.bind(this);
        this.updateDispatchFormDisable = this.updateDispatchFormDisable.bind(this);
    }

    componentDidMount() {
        let { order } = this.props;
        this.getCouriers();
        this.getShippingPreferences(order.ordNum);
        this.getShipmentLines();
        this.getDispatchLogs();
    }

    getCouriers() {
        courierService.getCouriers().then(response => {
            let couriers = response.data;
            this.setState({ couriers });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    validateShipmentReceiver(address) {
        let isValid = this.isReceiverAddressValid(address);
        if (!isValid) {
            this.setState({
                isShipmentReceiverAddressValid: isValid
            });
            return;
        }

        if (address.addressCountry != 'AUSTRALIA') {
            return;
        }
        this.setState({ isShipmentReceiverAddressValidLoading: true });
        dispatchOrderService.validateShipmentReceiverAddress(address).then(response => {
            isValid = !!response.data;
            this.setState({
                isShipmentReceiverAddressValidLoading: false,
                isShipmentReceiverAddressValid: isValid
            }, () => {
                if (isValid) {
                    this.saveShipmentReceiverAddress(address);
                }
            });
        }).catch(error => {
            this.setState({ isShipmentReceiverAddressValidLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    saveShipmentReceiverAddress(address) {
        this.setState({ isShipmentReceiverAddressSaveLoading: true });
        dispatchOrderService.saveShipmentReceiverAddress(address).then(response => {
            this.setState({ isShipmentReceiverAddressSaveLoading: false });
            if (response.data) {
                this.handleChange('shipmentReceiverID', response.data);
            }
        }).catch(error => {
            this.setState({ isShipmentReceiverAddressSaveLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    toggleUpdateOrderDeliveryAddressModal(value) {
        this.setState({ isOpenUpdateOrderDeliveryAddressModal: value });
    }

    handleOrderDeliveryAddressUpdate(updatedAddress) {
        this.toggleUpdateOrderDeliveryAddressModal(false);
        this.handleChange('shipmentReceiverID', updatedAddress);
        this.setState({ isShipmentReceiverAddressValid: true });
    }

    getShipmentLines() {
        let { shipment } = this.state;
        this.setState({ isLoadingDebtorInvoiceItems: true });
        dispatchOrderService.getDebtorInvoiceItemsDetails(this.props.order.ordNum).then(response => {
            if (response.data.shipmentLines) {
                shipment.shipmentLines = response.data.shipmentLines;
            }
            else {
                shipment.shipmentLines = dispatchOrderService.constructShipmentLines(response.data);
                let isBorderExpress = shipment.courier === SHIPMENT_COURIER_BORDER_EXPRESS_NAME;
                let hasLengthyItem = (shipment.shipmentLines || []).some(sl => (sl.lengthCm > 400));
                if (isBorderExpress && hasLengthyItem) {
                    this.switchCourierToTFM(shipment);
                }
            }


            this.setState({ shipment, isLoadingDebtorInvoiceItems: false }, () => {
                let error = this.validateForm();
                this.setState({ error });
            });
        }).catch(error => {
            this.setState({ isLoadingDebtorInvoiceItems: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    updateShipmentCourierOptions(shipment, consignment) {
        consignment.autoGenerate = false;
        switch (shipment.courier) {
            case SHIPMENT_COURIER_BORDER_EXPRESS_NAME:
                shipment.courierServiceCode = SHIPMENT_COURIER_BORDER_EXPRESS_SERVICE_CODE;
                consignment.autoGenerate = true;
                break;
            case SHIPMENT_COURIER_TFM_EXPRESS_NAME:
            default:
                shipment.courierServiceCode = "";
                break;
        }
        return { shipment, consignment };
    }

    getShippingPreferences(ordNum) {
        let { shipment, consignment, packedBy, printDispatchLabel, printInvoice, printPackSlip } = this.state;
        this.setState({ isLoadingShipmentPreferences: true });
        dispatchOrderService.getDebtorInvoiceShippingPreferences(ordNum).then(response => {
            printDispatchLabel = response.data.printDispatchLabel;
            printInvoice = response.data.printInvoice;
            printPackSlip = response.data.printPackSlip;
            shipment.courier = response.data.courier;
            shipment.pickUpDate = getDateObj(response.data.shipDate, "DD/MM/YYYY");
            packedBy = response.data.packedBy;

            consignment.consignNum = response.data.consignNum;

            let updatedShipment = this.updateShipmentCourierOptions(shipment, consignment);
            shipment = updatedShipment.shipment;
            consignment = updatedShipment.consignment;

            let address = {
                id: response.data.receiverAddress.id,
                courierReceiverID: response.data.receiverAddress.courierReceiverID,
                addressDescription: response.data.receiverAddress.addressDescription,
                addressContactName: response.data.receiverAddress.addressContactName,
                addressCountryCd: response.data.receiverAddress.addressCountryCd,
                addressCountry: response.data.receiverAddress.addressCountry,
                addressState: response.data.receiverAddress.addressState,
                addressPostcode: response.data.receiverAddress.addressPostcode,
                addressSuburb: response.data.receiverAddress.addressSuburb,
                addressEmail: response.data.receiverAddress.addressEmail,
                addressPhone: response.data.receiverAddress.addressPhone,
                addressLine1: response.data.receiverAddress.addressLine1,
                addressLine2: response.data.receiverAddress.addressLine2,
            };


            this.setState({
                isLoadingShipmentPreferences: false,
                consignment,
                shipment,
                packedBy,
                printDispatchLabel, printInvoice, printPackSlip
            }, () => {
                this.handleChange('shipmentReceiverID', address);
                this.validateShipmentReceiver(address);
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ isLoadingShipmentPreferences: false, });
        });
    }

    renderShipmentAddress(item) {
        let result = null;
        if (item) {
            result = (<div>

                {item.addressContactName ? <p className="mb-0">{item.addressContactName}</p> : null}
                {item.addressPhone ? <p className="mb-0">{item.addressPhone}</p> : null}
                {item.addressEmail ? <p className="mb-0">{item.addressEmail}</p> : null}
                {item.addressDescription ? <p className="mb-0">{item.addressDescription}</p> : null}
                {item.addressLine1 ? <p className="mb-0">{item.addressLine1}</p> : null}
                {item.addressLine2 ? <p className="mb-0">{item.addressLine2}</p> : null}
                <p className="mb-0">{item.addressSuburb ? item.addressSuburb + ", " : null}
                    {item.addressState ? item.addressState + ", " : null}
                    {item.addressPostcode ? item.addressPostcode + ", " : null}
                    {item.addressCountryCd ? item.addressCountryCd : null}</p>

            </div>);
        }
        return result;
    }

    handleChange(key, value) {
        let { shipment, consignment, isShipmentReceiverAddressValid, error } = this.state;
        switch (key) {
            case "shipmentSenderID":
                if (value) {
                    shipment.shipmentSenderID = value.id;
                    shipment.shipmentSenderAddress = value;
                    shipment.shipmentSenderAddressFullAddressDom = this.renderShipmentAddress(value);
                } else {
                    shipment.shipmentSenderID = null;
                    shipment.shipmentSenderAddress = null;
                    shipment.shipmentSenderAddressFullAddressDom = null;
                }
                error = this.validateForm();
                this.setState({ shipment, error });
                break;
            case "shipmentReceiverID":
                if (value) {
                    shipment.shipmentReceiverID = value.id ? value.id : null;
                    shipment.shipmentReceiverAddress = value;
                    shipment.shipmentReceiverAddressFullAddressDom = this.renderShipmentAddress(value);
                    isShipmentReceiverAddressValid = this.isReceiverAddressValid(shipment.shipmentReceiverAddress);
                } else {
                    shipment.shipmentReceiverID = null;
                    shipment.shipmentReceiverAddress = null;
                    shipment.shipmentReceiverAddressFullAddressDom = null;
                    isShipmentReceiverAddressValid = false;
                }

                error = this.validateForm();
                this.setState({ shipment, isShipmentReceiverAddressValid, error });
                break;
            case "autoGenerate":
            case "consignNum":
                consignment[key] = value;
                error = this.validateForm();
                this.setState({ consignment, error });
                break;
            case "courier":
                shipment[key] = value;

                let updatedShipment = this.updateShipmentCourierOptions(shipment, consignment);
                shipment = updatedShipment.shipment;
                consignment = updatedShipment.consignment;

                error = this.validateForm();
                this.setState({ shipment, consignment, error }, () => {
                    let isBorderExpress = shipment.courier === SHIPMENT_COURIER_BORDER_EXPRESS_NAME;
                    let hasLengthyItem = (shipment.shipmentLines || []).some(sl => (sl.lengthCm > 400));
                    if (isBorderExpress && hasLengthyItem) {
                        this.switchCourierToTFM(shipment);
                    }
                    else {
                        if (![SHIPMENT_COURIER_BORDER_EXPRESS_NAME, SHIPMENT_COURIER_TFM_EXPRESS_NAME].includes(shipment.courier)) {
                            this.setState({ isCourierChanged: false });
                        }
                    }
                });
                break;
            case "courierCarrierCode":
            case "courierServiceCode":
            case "pickUpDate":
            case "packedBy":
                shipment[key] = value;
                error = this.validateForm();
                this.setState({ shipment, error });
                break;
            case "printDispatchLabel":
            case "printInvoice":
            case "printPackSlip":
                this.setState({ [key]: value });
                break;
            case "shipmentLine":
                switch (value.key) {
                    case "clone":
                        let copiedOption = cloneDeep(shipment.shipmentLines[value.index]);
                        copiedOption.uuid = uuidv4();
                        copiedOption.id = 0;
                        shipment.shipmentLines.push(copiedOption);
                        break;
                    case "new":
                        let item = dispatchOrderService.getShipmentLineInstance();
                        shipment.shipmentLines.push(item);
                        break;
                    case "delete":
                        shipment.shipmentLines.splice(value.index, 1);
                        break;
                    case "lengthCm":
                        shipment.shipmentLines[value.index]["lengthCm"] = value.value;
                        let isBorderExpress = shipment.courier === SHIPMENT_COURIER_BORDER_EXPRESS_NAME;
                        let hasLengthyItem = (shipment.shipmentLines || []).some(sl => (sl.lengthCm > 400));
                        if (isBorderExpress && hasLengthyItem) {
                            this.switchCourierToTFM(shipment);
                        }
                        else {
                            this.setState({ isCourierChanged: false });
                        }
                        break;
                    case "itemType":
                        if (value.value === 'Tube') {
                            shipment.shipmentLines[value.index]["widthCm"] = 10;
                            shipment.shipmentLines[value.index]["heightCm"] = 10;
                        }
                        shipment.shipmentLines[value.index][value.key] = value.value;
                        break;
                    case "widthCm":
                    case "heightCm":
                        if (shipment.shipmentLines[value.index]["itemType"] === 'Tube') {
                            shipment.shipmentLines[value.index]["widthCm"] = value.value;
                            shipment.shipmentLines[value.index]["heightCm"] = value.value;
                        }
                        shipment.shipmentLines[value.index][value.key] = value.value;
                        break;
                    default:
                        shipment.shipmentLines[value.index][value.key] = value.value;

                }

                error = this.validateForm();
                this.setState({ shipment, error });
        }
    }

    switchCourierToTFM(shipment) {
        let { order } = this.props;
        //creating the message to update in logs.
        if (order) {
            var message = "Courier service is changed to TFM Express for order: " + order.ordNum;
        } else {
            var message = "Courier service is changed to TFM Express";
        }
        //toast to let the user know of the courier being changed
        toast.info("Courier service is changed to TFM Express.", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 4000
        });
        this.setState({ isCourierChanged: true });
        //calling generalised api to create a log
        this.updateLogs(message, "info");
        this.handleChange("courier", SHIPMENT_COURIER_TFM_EXPRESS_NAME);
    }

    updateLogs(message, type) {
        let request = {
            message: message,
            type: type
        }
        dispatchOrderService.updateLogs(request).then(response => {
        }).catch(error => {
        });
    }

    isReceiverAddressValid(address) {
        let isValid = true;
        let addressKeys = ["addressDescription",
            "addressContactName",
            "addressCountryCd",
            "addressCountry",
            "addressState",
            "addressPostcode",
            "addressSuburb",
            "addressEmail",
            "addressPhone",
            "addressLine1"];
        addressKeys.forEach(key => {
            if (!address[key]) {
                isValid = false;
            }
        });
        return isValid;
    }

    validateForm() {
        let { shipment, error, consignment, isShipmentReceiverAddressValid } = this.state;
        error = {
            hasError: false,
            courier: "",
            shipmentReceiverID: "",
            shipmentSenderID: "",
            consignNum: "",
            shipmentLines: {
                hasError: false,
                message: "",
                items: []
            },
            messages: []
        };
        if (!shipment.courier) {
            error.hasError = true;
            error.courier = "Please select delivery method!";
            error.messages.push(error.courier);
        }
        if (!shipment.shipmentReceiverID || !isShipmentReceiverAddressValid) {
            error.hasError = true;
            error.shipmentReceiverID = "Please select receiver!";
            error.messages.push(error.shipmentReceiverID);
        }
        if (!isShipmentReceiverAddressValid) {
            error.hasError = true;
            error.shipmentReceiverID = "Receiver address is not valid!";
            error.messages.push(error.shipmentReceiverID);
        }
        if (!shipment.shipmentSenderID) {
            error.hasError = true;
            error.shipmentSenderID = "Please select sender!";
            error.messages.push(error.shipmentSenderID);
        }
        if (!consignment.autoGenerate && !consignment.consignNum && !(SHIPMENT_METHOD_ConsignNumNotMandatory.includes(shipment.courier))) {
            error.hasError = true;
            error.consignNum = "Please enter connote no!";
            error.messages.push(error.consignNum);
        }
        if (!shipment.shipmentLines || (isEmpty(shipment.shipmentLines))) {
            error.hasError = true;
            error.shipmentLines.hasError = true;
            error.shipmentLines.message = "Please add at least 1 shipment line item!";
            error.messages.push(error.shipmentLines.message);
        }

        shipment.shipmentLines.forEach((shipmentLine, shipmentLineIndex) => {
            error.shipmentLines.items[shipmentLineIndex] = {
                error: {},
                hasError: false,
                message: ""
            };
            shipmentLinekeys.forEach(key => {
                error.shipmentLines.items[shipmentLineIndex].error[key] = "";
                if (!shipment.shipmentLines[shipmentLineIndex][key] || parseInt(shipment.shipmentLines[shipmentLineIndex][key]) <= 0) {
                    error.hasError = true;
                    error.shipmentLines.hasError = true;
                    error.shipmentLines.items[shipmentLineIndex].error[key] = "Should be greater than 0!";
                    error.messages.push("Shipment line item " + (shipmentLineIndex + 1) + " - " + key + " " + error.shipmentLines.items[shipmentLineIndex].error[key]);
                }
                switch (key) {
                    case "qty":
                        if (parseInt(shipment.shipmentLines[shipmentLineIndex][key]) > 10) {
                            error.hasWarningError = true;
                        }
                        break;
                    case "lengthCm":
                        if (parseInt(shipment.shipmentLines[shipmentLineIndex][key]) > 650 && parseInt(shipment.shipmentLines[shipmentLineIndex][key]) < 5) {
                            error.hasWarningError = true;
                        }
                        break;
                    case "widthCm":
                        if (parseInt(shipment.shipmentLines[shipmentLineIndex][key]) > 200 && parseInt(shipment.shipmentLines[shipmentLineIndex][key]) < 5) {
                            error.hasWarningError = true;
                        }
                        break;
                    case "heightCm":
                        if (parseInt(shipment.shipmentLines[shipmentLineIndex][key]) > 200 && parseInt(shipment.shipmentLines[shipmentLineIndex][key]) < 5) {
                            error.hasWarningError = true;
                        }
                        break;
                }
            });
        });

        return error;
    }

    processDebtorInvoiceDispatchWrapper() {
        let { error } = this.state;

        error = this.validateForm();
        if (error.hasError) {

            error.messages.forEach(msg => {
                toast.info(msg);
            });
            this.setState({ error });
            return;
        }
        if (error.hasWarningError) {
            this.setState({ isOpenConfirmModal: true });
            return false;
        }
        this.processDebtorInvoiceDispatchProcessProgress();

    }

    processDebtorInvoiceDispatchProcessProgress() {
        let { dispatchProcessProgressResponse } = this.state;

        this.setState({ isLoadingShippingProcess: true });

        switch (dispatchProcessProgressResponse.actionType) {
            case "Start":
                this.processDebtorInvoiceInitiate();
                break;
            case "Resume":
                switch (dispatchProcessProgressResponse.actionProcessName) {
                    case "Invoicing":
                        this.processDebtorInvoiceInvoicing();
                        break;
                    case "Consignment":
                        this.processDebtorInvoiceShipmentConsignmentCreate();
                        break;
                    case "Shipping":
                        this.processDebtorInvoiceShipping();
                        break;
                    case "Order_Status_Update":
                        this.processDebtorInvoiceOrderStatusUpdate();
                        break;
                    case "Dispatch_Email":
                        this.processDebtorInvoiceDispatchCommunication();
                        break;
                    default:
                        toast.info("Coudn't find right action");
                }
                break;
            case "Completed":
                toast.info("Shipment Completed");
                break;
        }
    }

    updateDispatchFormDisable() {
        let { form, dispatchProcessProgressResponse } = this.state;

        form.invoiceOptionsDisabled = false;
        form.shipmentConsignmentDisabled = false;


        dispatchProcessProgressResponse.logs.forEach(log => {
            switch (log.processName) {
                case "Invoicing":
                    switch (log.status) {
                        case "InProgress":
                        case "Pending":
                        case "Failed":
                            form.invoiceOptionsDisabled = false;
                            break;
                        case "Completed":
                            form.invoiceOptionsDisabled = true;
                            break;
                    }
                    break;
                case "Consignment":
                    switch (log.status) {
                        case "InProgress":
                        case "Pending":
                        case "Failed":
                            form.shipmentConsignmentDisabled = false;
                            break;
                        case "Completed":
                            form.shipmentConsignmentDisabled = true;
                            break;
                    }
                    break;
                case "Shipping":
                    switch (log.status) {
                        case "InProgress":
                        case "Pending":
                        case "Failed":
                            form.shippingDisabled = false;
                            break;
                        case "Completed":
                            form.shippingDisabled = true;
                            break;
                    }
                    break;
                case "Order_Status_Update":
                    break;
                case "Dispatch_Email":
                    break;
            }
        });

        this.setState({ form });
    }

    processDebtorInvoiceInitiate() {
        let { shipment } = this.state;

        this.setState({ isLoadingShippingProcess: true });
        dispatchOrderService.processDebtorInvoiceInitiate(this.props.order.ordNum, shipment.courier).then(response => {
            if (response.data) {
                this.setState({ dispatchProcessProgressResponse: response.data }, () => {
                    this.updateDispatchFormDisable();
                });
            }
            this.processDebtorInvoiceInvoicing();

        }).catch(error => {
            this.getDispatchLogs();
            this.setState({ isLoadingShippingProcess: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    processDebtorInvoiceInvoicing() {
        let { printDispatchLabel, printInvoice, printPackSlip, packedBy, shipment } = this.state;
        console.log(shipment)
        let request = {
            ordNum: this.props.order.ordNum,
            invoicedBy: packedBy,
            printInvoice: printInvoice,
            printPackSlip: printPackSlip,
            printDispatchLabel: printDispatchLabel,
            copies: 1,//by default,
            totalCartons: shipment.shipmentLines.reduce((acc, line) => acc + parseInt(line.qty), 0) || 1
        };
        this.setState({ isLoadingShippingProcess: true });
        dispatchOrderService.processDebtorInvoiceInvoicing(request).then(response => {
            if (response.data) {
                this.setState({ dispatchProcessProgressResponse: response.data }, () => {
                    this.updateDispatchFormDisable();
                });
            }
            this.processDebtorInvoiceShipmentConsignmentCreate();
        }).catch(error => {
            this.getDispatchLogs();
            this.setState({ isLoadingShippingProcess: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    processDebtorInvoiceShipmentConsignmentCreate() {
        let { consignment, shipment } = this.state;

        let request = {
            ordNum: this.props.order.ordNum,
            courier: shipment.courier,
            courierCarrierCode: shipment.courierCarrierCode,
            courierServiceCode: shipment.courierServiceCode,
            pickUpDate: getDateString(shipment.pickUpDate, "DD/MM/YYYY HH:mm:ss"),
            shipmentSenderID: shipment.shipmentSenderID,
            shipmentReceiverID: shipment.shipmentReceiverID,
            shipmentLines: shipment.shipmentLines,
            consignNum: consignment.consignNum,
            autoGenerateConsignment: consignment.autoGenerate
        };

        this.setState({ isLoadingShippingProcess: true });
        dispatchOrderService.processDebtorInvoiceShipmentConsignmentCreate(request).then(response => {
            if (response.data) {
                consignment.consignNum = response.data.consignNum;
                this.setState({ dispatchProcessProgressResponse: response.data, consignment }, () => {
                    this.updateDispatchFormDisable();
                });
                this.processDebtorInvoiceShipping();
            }
        }).catch(error => {
            this.getDispatchLogs();
            this.setState({ isLoadingShippingProcess: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    processDebtorInvoiceShipping() {
        let { packedBy } = this.state;
        let request = {
            ordNum: this.props.order.ordNum,
            packedBy: packedBy
        };
        this.setState({ isLoadingShippingProcess: true });
        dispatchOrderService.processDebtorInvoiceShipping(request).then(response => {
            if (response.data) {
                this.setState({ dispatchProcessProgressResponse: response.data }, () => {
                    this.updateDispatchFormDisable();
                });
                this.processDebtorInvoiceOrderStatusUpdate();
            }
        }).catch(error => {
            this.getDispatchLogs();
            this.setState({ isLoadingShippingProcess: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    processDebtorInvoiceOrderStatusUpdate() {
        this.setState({ isLoadingShippingProcess: true });
        dispatchOrderService.processDebtorInvoiceOrderStatusUpdate(this.props.order.ordNum).then(response => {
            if (response.data) {
                this.setState({ dispatchProcessProgressResponse: response.data }, () => {
                    this.updateDispatchFormDisable();
                });
            }
            this.processDebtorInvoiceDispatchCommunication();
        }).catch(error => {
            this.getDispatchLogs();
            this.setState({ isLoadingShippingProcess: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    processDebtorInvoiceDispatchCommunication() {
        this.setState({ isLoadingShippingProcess: true });
        dispatchOrderService.processDebtorInvoiceDispatchCommunication(this.props.order.ordNum).then(response => {
            if (response.data) {
                this.setState({ dispatchProcessProgressResponse: response.data }, () => {
                    this.updateDispatchFormDisable();
                });
            }
            this.setState({ isLoadingShippingProcess: false });
            toast.success("Shipment processed successfully!");
        }).catch(error => {
            this.getDispatchLogs();
            this.setState({ isLoadingShippingProcess: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    getDispatchLogs() {
        dispatchOrderService.getDispatchLogs(this.props.order.ordNum).then(response => {
            this.setState({ dispatchProcessProgressResponse: response.data }, () => {
                this.updateDispatchFormDisable();
            });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    isDispatchDataLoading() {
        let {
            isLoadingShipmentPreferences
            , isLoadingDebtorInvoiceItems
            , isShipmentReceiverAddressValidLoading
            , isShipmentReceiverAddressSaveLoading
        } = this.state;

        return (isLoadingShipmentPreferences
            || isLoadingDebtorInvoiceItems
            || isShipmentReceiverAddressValidLoading
            || isShipmentReceiverAddressSaveLoading);
    }

    closeModal() {
        this.props.refreshAgain();
        EventBus.publish("order-shipped", {});
        this.props.toggle(false);
    }

    toggleConfirmModal(change) {
        this.setState({ isOpenConfirmModal: change });
    }

    submitConfirmModal() {
        this.processDebtorInvoiceDispatchProcessProgress();
    }

    render() {

        let { isOpen, toggle, order } = this.props;
        let {
            isLoadingShippingProcess,
            isLoadingShipmentPreferences,
            couriers, shipment,
            isOpenUpdateOrderDeliveryAddressModal,
            isLoadingDebtorInvoiceItems,
            consignment,
            isShipmentReceiverAddressValidLoading,
            isShipmentReceiverAddressSaveLoading,
            isShipmentReceiverAddressValid,
            printDispatchLabel,
            printInvoice,
            printPackSlip, dispatchProcessProgressResponse,
            packedBy, isCourierChanged,
            error, isOpenConfirmModal, form
        } = this.state;

        let isDispatchDataLoading = this.isDispatchDataLoading();
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)} backdrop={"static"}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Shipping for: #{order.ordNum}
                </ModalHeader>

                <ModalBody>
                    <div>
                        <div>
                            <Link
                                className="btn btn-primary btn-sm mr-2"
                                style={{ color: "white" }}
                                title={"Click here to see account details"}
                                to={"/customer/#" + order.accountID}
                            >{order.accountID}</Link>
                            {order.delCompany}
                        </div>

                        <h6 className="mt-4">Invoice Options</h6>
                        {
                            isLoadingShipmentPreferences
                                ? <Spinner color={"primary"} />
                                : <Row>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Label>
                                            <Button color={"link"}
                                                size={"sm"}
                                                disabled={form.invoiceOptionsDisabled}
                                                onClick={() => this.handleChange("printInvoice", !printInvoice)}>
                                                <i className={classnames("fa", "fa-lg", "fa-fw", "mr-2", {
                                                    "fa-check-square-o": printInvoice,
                                                    "fa-square-o": !printInvoice,
                                                }
                                                )} aria-hidden="true" />
                                            </Button>
                                            Print invoice
                                        </Label>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Label>
                                            <Button color={"link"}
                                                size={"sm"}
                                                disabled={form.invoiceOptionsDisabled}
                                                onClick={() => this.handleChange("printPackSlip", !printPackSlip)}>
                                                <i className={classnames("fa", "fa-lg", "fa-fw", "mr-2", {
                                                    "fa-check-square-o": printPackSlip,
                                                    "fa-square-o": !printPackSlip,
                                                }
                                                )} aria-hidden="true" />
                                            </Button>
                                            Print packing slip
                                        </Label>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Label>
                                            <Button color={"link"}
                                                size={"sm"}
                                                disabled={form.invoiceOptionsDisabled}
                                                onClick={() => this.handleChange("printDispatchLabel", !printDispatchLabel)}>
                                                <i className={classnames("fa", "fa-lg", "fa-fw", "mr-2", {
                                                    "fa-check-square-o": printDispatchLabel,
                                                    "fa-square-o": !printDispatchLabel,
                                                }
                                                )} aria-hidden="true" />
                                            </Button>
                                            Print dispatch label
                                        </Label>
                                    </Col>
                                </Row>
                        }


                        <h6 className="mt-2">Courier Information</h6>
                        {
                            isLoadingShipmentPreferences
                                ? <Spinner color={'primary'} />
                                : <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Delivery method</Label>
                                            <Input type="select"
                                                name="courier"
                                                value={shipment.courier}
                                                invalid={!!error.courier}
                                                disabled={form.shipmentConsignmentDisabled}
                                                onChange={(e) => this.handleChange("courier", e.target.value)}>
                                                <option value="" disabled>Select courier</option>
                                                {(couriers || []).map((data, index) =>
                                                    <option key={index} value={data.courier}> {data.courier}</option>
                                                )}
                                            </Input>
                                            <FormText color="danger">{error.courier}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Courier Carrier Code</Label>
                                            <Input type="text" name="courierCarrierCode"
                                                value={shipment.courierCarrierCode || ''}
                                                disabled={form.shipmentConsignmentDisabled}
                                                onChange={(e) => this.handleChange("courierCarrierCode", e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label> Courier Service Code</Label>
                                            <Input type="text" name="courierServiceCode"
                                                value={shipment.courierServiceCode || ''}
                                                disabled={form.shipmentConsignmentDisabled}
                                                onChange={(e) => this.handleChange("courierServiceCode", e.target.value)} />

                                        </FormGroup>
                                    </Col>
                                </Row>

                        }
                        <h6 className="mt-2">Sender & Receiver Information</h6>
                        {
                            isLoadingShipmentPreferences
                                ? <Spinner color={'primary'} />
                                : <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Sender</Label>
                                            <SearchDespatchSender
                                                disabled={form.shipmentConsignmentDisabled}
                                                selectedSenderID={shipment.shipmentSenderID}
                                                handleSelection={(selection) => this.handleChange("shipmentSenderID", selection)}
                                            />
                                            {
                                                shipment.shipmentSenderAddressFullAddressDom
                                                    ? <Alert color={classnames(
                                                        {
                                                            "primary": !error.shipmentSenderID,
                                                            "danger": error.shipmentSenderID,
                                                        }
                                                    )} className={"mt-1"} style={{ minHeight: 170 }}>
                                                        {shipment.shipmentSenderAddressFullAddressDom}
                                                    </Alert>
                                                    : null
                                            }
                                            <FormText color="danger">{error.shipmentSenderID}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label> Receiver</Label>
                                            <SearchDespatchReceiver
                                                disabled={form.shipmentConsignmentDisabled}
                                                selectedReceiverID={shipment.shipmentReceiverID}
                                                handleSelection={(selection) => this.handleChange("shipmentReceiverID", selection)}
                                            />
                                            <Alert color={classnames(
                                                {
                                                    "secondary": isShipmentReceiverAddressValidLoading || isShipmentReceiverAddressSaveLoading,
                                                    "primary": ((!isShipmentReceiverAddressValidLoading) && (!isShipmentReceiverAddressSaveLoading) && isShipmentReceiverAddressValid),
                                                    "danger": ((!isShipmentReceiverAddressValidLoading) && (!isShipmentReceiverAddressSaveLoading) && (!isShipmentReceiverAddressValid)),
                                                }
                                            )}
                                                className={"mt-1 mb-0"}
                                                style={{ minHeight: 170 }}>
                                                {
                                                    isShipmentReceiverAddressValidLoading ? "Validating..." : ""
                                                }
                                                {
                                                    isShipmentReceiverAddressSaveLoading ? "Preparing..." : ""
                                                }

                                                <Row className={"align-items-end"}>
                                                    <Col className={"col-auto mx-auto"}>
                                                        {
                                                            ((!isShipmentReceiverAddressValidLoading) && (!isShipmentReceiverAddressSaveLoading))
                                                                ? shipment.shipmentReceiverAddressFullAddressDom
                                                                : null
                                                        }
                                                    </Col>

                                                    {
                                                        (!isShipmentReceiverAddressValidLoading && !isShipmentReceiverAddressSaveLoading && shipment.shipmentReceiverAddress)
                                                            ? <Col className={"text-right"}>
                                                                {
                                                                    isShipmentReceiverAddressValid
                                                                        ? null
                                                                        : <div>
                                                                            <p className="mb-0">
                                                                                <i className="fa fa-lg fa-exclamation-circle mr-2"
                                                                                    aria-hidden="true" />
                                                                                Invalid delivery address
                                                                            </p>
                                                                            <hr />
                                                                        </div>
                                                                }
                                                                <div>
                                                                    <Button color={"primary"}
                                                                        size={"sm"}
                                                                        disabled={form.shipmentConsignmentDisabled}
                                                                        onClick={(e) => this.toggleUpdateOrderDeliveryAddressModal(true)}>
                                                                        <i className="fa fa-pencil mr-2" aria-hidden="true" />Modify
                                                                        address
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>
                                            </Alert>
                                            <FormText color="danger">{error.shipmentReceiverID}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                        }

                        <h6 className="mt-2">Shipping Items</h6>
                        {isLoadingDebtorInvoiceItems
                            ? <Spinner color={'primary'} />
                            : <Table responsive={true} striped={true} bordered={true} hover={true}>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: 140 }}>Type</th>
                                        <th>
                                            Qty
                                        </th>
                                        <th>
                                            Length(cm)
                                        </th>
                                        <th>
                                            Width(cm)
                                        </th>
                                        <th>Height(cm)</th>
                                        <th>
                                            Weight(kg)
                                        </th>
                                        <th style={{ minWidth: 165 }} className="text-center">
                                            <Button color={"primary"}
                                                size="sm"
                                                className={"mr-2"}
                                                disabled={form.shipmentConsignmentDisabled}
                                                onClick={() => this.handleChange("shipmentLine", {
                                                    key: "new",
                                                    value: '',
                                                    index: -1
                                                })}>
                                                <i className="fa fa-plus mr-2" aria-hidden="true" />
                                                Add New Item
                                            </Button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(shipment.shipmentLines || []).map((shipmentLine, index) =>
                                        <tr key={index}
                                            className={classnames({ "table-danger": error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].hasError : false })}>
                                            <td>
                                                <Input type="select" name="itemType" value={shipmentLine.itemType}
                                                    invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.itemType : false}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onChange={(e) => this.handleChange("shipmentLine", {
                                                        key: e.target.name,
                                                        value: e.target.value,
                                                        index: index
                                                    })}>
                                                    {(shipmentItemTypes || []).map((item, itemIndex) =>
                                                        <option value={item.value} key={itemIndex}>{item.label}</option>
                                                    )}
                                                </Input>
                                                <FormText
                                                    color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.itemType : ""}</FormText>
                                            </td>
                                            <td>
                                                <Input type="number" name="qty"
                                                    value={shipmentLine.qty}
                                                    invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.qty : false}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onChange={(e) => this.handleChange("shipmentLine", {
                                                        key: e.target.name,
                                                        value: e.target.value,
                                                        index: index
                                                    })} />
                                                <FormText
                                                    color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.qty : ""}</FormText>
                                            </td>
                                            <td>
                                                <Input type="number" name="lengthCm"
                                                    invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.lengthCm : false}
                                                    value={shipmentLine.lengthCm}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onChange={(e) => this.handleChange("shipmentLine", {
                                                        key: e.target.name,
                                                        value: e.target.value,
                                                        index: index
                                                    })} />
                                                <FormText
                                                    color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.lengthCm : ""}</FormText>
                                            </td>
                                            <td>
                                                <Input type="number" name="widthCm"
                                                    invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.widthCm : false}
                                                    value={shipmentLine.widthCm}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onChange={(e) => this.handleChange("shipmentLine", {
                                                        key: e.target.name,
                                                        value: e.target.value,
                                                        index: index
                                                    })} />
                                                <FormText
                                                    color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.widthCm : ""}</FormText>
                                            </td>
                                            <td>
                                                <Input type="number" name="heightCm"
                                                    invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.heightCm : false}
                                                    value={shipmentLine.heightCm}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onChange={(e) => this.handleChange("shipmentLine", {
                                                        key: e.target.name,
                                                        value: e.target.value,
                                                        index: index
                                                    })} />
                                                <FormText
                                                    color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.heightCm : ""}</FormText>
                                            </td>
                                            <td>
                                                <Input type="number" name="weightKg"
                                                    invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.weightKg : false}
                                                    value={shipmentLine.weightKg}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onChange={(e) => this.handleChange("shipmentLine", {
                                                        key: e.target.name,
                                                        value: e.target.value,
                                                        index: index
                                                    })} />
                                                <FormText
                                                    color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.weightKg : ""}</FormText>
                                            </td>
                                            <td className="text-center">
                                                <Button color={"primary"}
                                                    className={"mr-2"}
                                                    size={"sm"}
                                                    title={"click here to copy item"}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onClick={() => this.handleChange("shipmentLine", {
                                                        key: "clone",
                                                        value: "",
                                                        index: index
                                                    })}>
                                                    <i className="fa fa-clone fa-lg"
                                                        aria-hidden="true" />
                                                </Button>
                                                <Button color='danger'
                                                    size={"sm"}
                                                    disabled={form.shipmentConsignmentDisabled}
                                                    onClick={() => this.handleChange("shipmentLine", {
                                                        key: "delete",
                                                        value: "",
                                                        index: index
                                                    })}>
                                                    <i className='fa fa-trash fa-lg' aria-hidden="true" />
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                    {
                                        error.shipmentLines.hasError
                                            ? <tr>
                                                <td colSpan={7}>
                                                    <FormText
                                                        color="danger">{error.shipmentLines.message}</FormText>
                                                </td>
                                            </tr>
                                            : null
                                    }
                                </tbody>
                            </Table>
                        }
                        {isCourierChanged ?
                            <Alert color="secondary">
                                <p className="text-danger align-middle">
                                    <i>Note: The courier has been changed to TFM Express due to the existence of shipping items of
                                        Length greater than 4m, which Border Express will NOT accept. </i>
                                </p>
                            </Alert>
                            : null}
                        {
                            this.props.order.courierInstructions
                                ? <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p>Courier Instructions: <span className="font-italic" style={{ color: 'red', fontWeight: 'bold' }}>
                                        {this.props.order.courierInstructions}
                                    </span></p>
                                </Col>
                                : null
                        }
                        <h6 className="mt-2">PickUp Date</h6>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <DatePicker
                                        className={"form-control"}
                                        selected={shipment.pickUpDate}
                                        onChange={date => this.handleChange("pickUpDate", date)}
                                        selectsStart
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        withPortal
                                        disabled={form.shipmentConsignmentDisabled}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                        <h6 className="mt-2">Consignment Information</h6>
                        <Row>
                            {
                                shipment.courier === SHIPMENT_COURIER_BORDER_EXPRESS_NAME
                                    ? <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <div>
                                            <Button
                                                size={"sm"}
                                                color={"link"}
                                                disabled={form.shipmentConsignmentDisabled}
                                                onClick={() => this.handleChange("autoGenerate", !consignment.autoGenerate)}>
                                                <i className={classnames("fa", "fa-lg", {
                                                    "fa-check-square-o": consignment.autoGenerate,
                                                    "fa-square-o": !consignment.autoGenerate,
                                                }
                                                )} />
                                            </Button>
                                            Auto Generate Consignment?
                                        </div>
                                    </Col>
                                    : null
                            }
                            {
                                consignment.autoGenerate
                                    ? null
                                    : <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label> Connote No.</Label>
                                            <Input type="text" name="consignNum"
                                                invalid={!!error.consignNum}
                                                value={consignment.consignNum || ''}
                                                disabled={form.shipmentConsignmentDisabled}
                                                onChange={(e) => this.handleChange("consignNum", e.target.value)} />
                                            <FormText color="danger">{error.consignNum}</FormText>
                                        </FormGroup>
                                    </Col>
                            }
                        </Row>
                        <h6 className="mt-2">Prepared by</h6>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <SearchUser
                                        disabled={form.shippingDisabled}
                                        role={""}
                                        onChange={(change) => this.handleChange("packedBy", change)}
                                        defaultValue={packedBy} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>

                    {
                        ((dispatchProcessProgressResponse.logs || []).length > 0)
                            ? <div>
                                <hr />
                                <Progress multi>
                                    {(dispatchProcessProgressResponse.logs || [])
                                        //.filter(log => !["Pending"].includes(log.status))
                                        .map((log, logIndex) => {
                                            return <Progress
                                                key={logIndex}
                                                bar color={log.colour}
                                                animated={isLoadingShippingProcess && log.status !== "Completed"}
                                                value={100 / dispatchProcessProgressResponse.logs.length}>{log.processName.replace(/_/g, ' ')}</Progress>
                                        })}
                                </Progress>
                            </div>
                            : null
                    }

                    {
                        (dispatchProcessProgressResponse.logs || [])
                            .filter(log => !["Pending"].includes(log.status))
                            .map(log => {
                                return <Alert color={log.colour} className={"mt-2"}>
                                    <div>
                                        <p className="mb-1">Process
                                            <strong className="ml-2">{log.processName.replace(/_/g, ' ')}</strong>
                                            <Badge className="ml-2" color={log.colour}>{log.status} <i
                                                className={log.icon + " ml-2"} aria-hidden="true" /></Badge>
                                            {
                                                log.isLoading
                                                    ? <Spinner color={"primary"} size={"sm"} className="ml-2" />
                                                    : null
                                            }
                                        </p>
                                    </div>
                                    {
                                        log.attempts > 1
                                            ? <div><strong>{log.attempts}</strong> Attempts | Last attempted
                                                on {log.lastAttemptedOn}
                                            </div>
                                            : null
                                    }
                                    {
                                        (log.processName === "Invoicing" && dispatchProcessProgressResponse.printJobs.length > 0)
                                            ? <div>
                                                <hr />
                                                <h6>Prints</h6>
                                                <div>
                                                    {
                                                        (dispatchProcessProgressResponse.printJobs || []).map((printerJob, pjIndex) => {
                                                            return <div className="mb-1">
                                                                <a href={printerJob.blobUrl} target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    download
                                                                    className="mr-2"
                                                                >
                                                                    <i className="fa fa-print" aria-hidden="true" />
                                                                </a>
                                                                <span className="mr-2"> {printerJob.printJobType}
                                                                    ({printerJob.printCopies} {printerJob.printCopies > 1 ? "copies" : "copy"})</span>
                                                                <Badge
                                                                    color={printerJob.printingStatus === "PRINTED" ? "success" : "warning"}>
                                                                    {printerJob.printingStatus}
                                                                </Badge>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }

                                    {
                                        (log.status === "Failed" && log.errorMessage) ? <div>
                                            <hr />

                                            <div className={"text-" + log.colour}>
                                                <strong>{log.errorMessage}</strong>
                                            </div>
                                            <div className={"text-" + log.colour}>{log.exception}</div>

                                        </div>
                                            : null
                                    }

                                </Alert>
                            })
                    }


                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button
                            color={dispatchProcessProgressResponse.actionType === "Completed" ? "success" : "primary"}
                            size="sm"
                            className={"mr-2"}
                            onClick={this.processDebtorInvoiceDispatchWrapper}
                            disabled={isDispatchDataLoading || isLoadingShippingProcess || dispatchProcessProgressResponse.actionType === "Completed"}>
                            {
                                isLoadingShippingProcess
                                    ? <Spinner size='sm' color={"light"} className={"mr-2"} />
                                    : <i className={classnames("mr-2", "fa", {
                                        "fa-floppy-o": dispatchProcessProgressResponse.actionType === "Start",
                                        "fa-repeat": dispatchProcessProgressResponse.actionType === "Resume",
                                        "fa-check-circle-o": dispatchProcessProgressResponse.actionType === "Completed",
                                    })} aria-hidden="true" />
                            }


                            {
                                dispatchOrderService.getDispatchActionType(isLoadingShippingProcess, dispatchProcessProgressResponse.actionType)
                            }
                        </Button>

                        {
                            isLoadingShippingProcess
                                ? null
                                : <Button color={"secondary"} size="sm" onClick={this.closeModal}>
                                    <i className="fa fa-times mr-2" aria-hidden="true" />Close
                                </Button>
                        }

                    </div>
                </ModalFooter>
                {
                    isOpenUpdateOrderDeliveryAddressModal
                        ? <UpdateOrderDeliveryAddressModal
                            isOpen={isOpenUpdateOrderDeliveryAddressModal}
                            toggle={this.toggleUpdateOrderDeliveryAddressModal}
                            handleOrderDeliveryAddressUpdate={this.handleOrderDeliveryAddressUpdate}
                            originalAddress={shipment.shipmentReceiverAddress}
                            ordNum={order.ordNum}
                        />
                        : null
                }
                {
                    isOpenConfirmModal ?
                        <ConfirmModal
                            isOpen={isOpenConfirmModal}
                            toggle={this.toggleConfirmModal}
                            handleSubmit={() => this.submitConfirmModal()}
                            handleCancel={() => this.toggleConfirmModal(false)}
                            primaryMessage={"There is abnormal qty or dimension of package(s)"}
                            secondaryMessage={"Are you sure you want to save shipment?"}
                            submitColor={"danger"}
                            cancelColor={"secondary"}
                            icon={"fa fa-2x fa-exclamation-triangle"}
                            loading={isLoadingShippingProcess}
                            type="Saving..."
                        /> : null
                }
            </Modal>
        )
    }
}