import React, { Component } from 'react';
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { Link as Link, Redirect } from "react-router-dom";
import OrderService from "../../../../services/OrderService";
import BulkOrderService from '../../../../services/BulkOrderService';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import { handleErrorMessage } from "../../../../services/CommonService";
import classnames from 'classnames';
import FileSaver from 'file-saver';

export default class OrderEnquiryDocumentDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingDownload: false,
            isLoadingPrint: false,
            isLoadingDelete: false,
            allDownloadDocumentTypes: ["Job Sheet", "Pick Slip", "Production Label", "Invoice", "Dispatch Label", "Packing Slip", "PowderCoat Label", "Sales Order Summary"],
            statusID: null,
            isDescheduled: false,
            isOpenDeleteConfirmModal: false
        };
        this.orderService = new OrderService();
        this.bulkOrderService = new BulkOrderService();
        this.downloadFile = this.downloadFile.bind(this);
        this.clearOrderItems = this.clearOrderItems.bind(this);
        this.importOrderItems = this.importOrderItems.bind(this);
        this.toggleClearItemsConfirmModal = this.toggleClearItemsConfirmModal.bind(this);
    }

    componentDidMount() {
        let { ordNum } = this.props;
        this.getOrderStatus(ordNum);
        this.CheckIsPowderCoatOrder(ordNum);
        this.isOrderDescheduled(ordNum);
    }

    toggleClearItemsConfirmModal(change) {
        this.setState({ isOpenDeleteConfirmModal: change });
    }

    getOrderStatus(ordNum) {
        let { allDownloadDocumentTypes } = this.state;
        this.orderService.getOrderStatus(ordNum).then(response => {
            let data = response.data;
            if (data != null) {
                this.setState({ statusID: data.id });
                if (data.statusCode === "QUOTE") {
                    allDownloadDocumentTypes = allDownloadDocumentTypes.map(function (x) {
                        return x.replace("Invoice", "Quote");
                    });
                    this.setState({ allDownloadDocumentTypes });
                }
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    CheckIsPowderCoatOrder(ordNum) {
        let { allDownloadDocumentTypes } = this.state;
        this.orderService.checkIsPowderCoatOrder(ordNum).then(response => {
            let data = response.data;
            if (data === false || data === 0) {
                const index = allDownloadDocumentTypes.indexOf("PowderCoat Label");
                if (index > -1) {
                    allDownloadDocumentTypes.splice(index, 1);
                }
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    isOrderDescheduled(ordNum) {
        this.orderService.isOrderDescheduled(ordNum).then(response => {
            let data = response.data;
            if (data === true || data === 1) {
                this.setState({ isDescheduled: true });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    downloadFile(type, ordNum) {
        this.setState({ isLoadingDownload: true });
        this.orderService.downloadFile(type, ordNum).then(response => {
            let fileName = ordNum + " " + type + (type === "Export Quote" ? ".xlsx" : ".pdf");
            FileSaver.saveAs(response.data, fileName);
            this.setState({ isLoadingDownload: false });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
            this.setState({ isLoadingDownload: false });
        });
    }

    printFile(type, ordNum) {
        this.setState({ isLoadingPrint: true });
        this.orderService.printFile(type, ordNum).then(response => {
            this.setState({ isLoadingPrint: false });
            toast.success("Print job added!");
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
            this.setState({ isLoadingPrint: false });
        });
    }

    clearOrderItems() {
        this.setState({ isLoadingDelete: true });
        this.bulkOrderService.clearOrderItems(this.props.ordNum).then(response => {
            this.setState({ isLoadingDelete: false, isOpenDeleteConfirmModal: false });
            this.props.refreshOrder();
            toast.success("Order Items Cleared Successfully");
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
            this.setState({ isLoadingDelete: false, isOpenDeleteConfirmModal: false });
        });
    }

    importOrderItems(ordNum) {
        this.props.history.push({
            pathname: '/bulk-order/import',
            state: { ordNum }
        });
    }

    render() {
        let { ordNum, documentTypes, orderCreatedFrom, isExternalUser, isBulkOrder, isBlankOrder } = this.props;
        let { isLoadingDownload, isLoadingPrint, isLoadingDelete, allDownloadDocumentTypes, statusID, isDescheduled, isOpenDeleteConfirmModal } = this.state;
        let finalDocumentTypes = (allDownloadDocumentTypes || []).filter(x => documentTypes.includes(x));
        if (isBulkOrder) {
            finalDocumentTypes.push("Export Quote");
        }
        return (
            <div>
                <div>
                    <ButtonGroup>
                        {
                            (isBlankOrder && statusID === 10) ?
                                <Button color="primary" size="sm" onClick={() => this.importOrderItems(ordNum)}>
                                    Add Bulk Order Items
                                </Button>
                                :
                                <>
                                    {(!isBulkOrder && !isBlankOrder && !isExternalUser && ((statusID > 1 && statusID < 45) || isDescheduled)) ?
                                        <Link
                                            className={classnames("btn", "btn-sm", {
                                                "btn-warning": orderCreatedFrom !== "KEYWAY_PRODUCT_BUILDER_V1",
                                                "btn-primary": orderCreatedFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                                            }
                                            )}
                                            to={"/sales/order/create/product-builder/v1?orderNum=" + ordNum}
                                        >
                                            <i className="fa fa-external-link mr-2" aria-hidden="true" />
                                            Open in product builder
                                        </Link>
                                        : null}
                                    {
                                        (isBulkOrder && !isBlankOrder && statusID === 10)
                                            ? <Button color="danger"
                                                size="sm"
                                                title="Click here to delete all items of this order"
                                                disabled={isLoadingDelete}
                                                onClick={() => this.toggleClearItemsConfirmModal(true)}>
                                                {
                                                    isLoadingDelete
                                                        ? <Spinner size="sm" className="mr-1" />
                                                        : <i className="fa fa-trash mr-1" aria-hidden="true" />
                                                }
                                                Clear Order Items
                                            </Button>
                                            : null
                                    }
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color={"secondary"}
                                            size={"sm"}
                                            disabled={(isLoadingDownload || isLoadingPrint)}>
                                            {
                                                (isLoadingDownload || isLoadingPrint)
                                                    ? <Spinner size={"sm"}
                                                        className={"mr-2"} />
                                                    : <i className="fa fa-file-pdf-o mr-2" aria-hidden="true" />
                                            }
                                            Documents
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                (finalDocumentTypes || []).map((documentType, documentTypeIndex) => {
                                                    if (isExternalUser) {
                                                        return <DropdownItem
                                                            key={documentTypeIndex}
                                                            onClick={() => this.downloadFile(documentType, ordNum)}>
                                                            <i className="fa fa-file-pdf-o mr-2" aria-hidden="true" /> {documentType}
                                                        </DropdownItem>
                                                    } else {
                                                        return <DropdownItem key={documentTypeIndex}>
                                                            {documentType}
                                                            <a href="javascript:void(0)"
                                                                className="ml-2"
                                                                onClick={() => this.downloadFile(documentType, ordNum)}>
                                                                <i className="fa fa-download " aria-hidden="true" />
                                                            </a>
                                                            {
                                                                (isBulkOrder && documentType === "Export Quote")
                                                                    ? null
                                                                    : <a href="javascript:void(0)"
                                                                        className="ml-2"
                                                                        onClick={() => this.printFile(documentType, ordNum)}>
                                                                        <i className="fa fa-print" aria-hidden="true" />
                                                                    </a>
                                                            }
                                                        </DropdownItem>
                                                    }
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </>
                        }
                    </ButtonGroup>
                </div>
                {
                    isOpenDeleteConfirmModal &&
                    <ConfirmModal
                        isOpen={isOpenDeleteConfirmModal}
                        toggle={this.toggleClearItemsConfirmModal}
                        handleSubmit={() => this.clearOrderItems()}
                        handleCancel={() => this.toggleClearItemsConfirmModal(false)}
                        primaryMessage={"Are you sure to delete all items of this order?"}
                        secondaryMessage={""}
                        submitColor={"danger"}
                        cancelColor={"secondary"}
                        icon={"fa fa-trash-o fa-2x"}
                        loading={isLoadingDelete}
                        type="Deleting..."
                    />
                }
                <ToastContainer />
            </div>
        )
    }
}