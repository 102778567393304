import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Modal, ModalBody, Button, Row, Col, Spinner } from 'reactstrap';

export default class ConfirmModal extends Component {
    render() {
        let { isOpen, toggle, handleCancel, handleSubmit, loading, primaryMessage, secondaryMessage, cancelColor, submitColor, icon, type } = this.props;
        return (
            <Modal isOpen={isOpen} size="sm" scrollable={true} toggle={() => toggle(!isOpen)} backdrop="static">
                <ModalBody>
                    <div className={"p-4 text-center"}>
                        {
                            loading ?
                                <>
                                    <Spinner color={"primary"} />
                                    {type && <div>{ReactHtmlParser(type)}</div>}
                                </>
                                :
                                <>
                                    {icon && <i className={icon} aria-hidden="true" />}
                                    {primaryMessage && <div>{ReactHtmlParser(primaryMessage)}</div>}
                                    {secondaryMessage && <div>{ReactHtmlParser(secondaryMessage)}</div>}
                                </>
                        }
                    </div>
                    <Row>
                        <Col>
                            <div className={"text-center"}>
                                <Button block color={cancelColor} disabled={loading}
                                    onClick={() => handleCancel()}>
                                    No
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className={"text-center"}>
                                <Button block color={submitColor} disabled={loading}
                                    onClick={() => handleSubmit()}>
                                    Yes
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}