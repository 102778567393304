import React, { Component } from "react";
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import { toast } from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';
import warehouseLocationTypeService from '../../services/WarehouseLocationTypeService';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmModal from "./ConfirmModal";


const warehouseLocationTypeOptions = [
    { locationTypeID: 1, locationTypeName: "Pick" },
    { locationTypeID: 2, locationTypeName: "Bulk" },
    { locationTypeID: 3, locationTypeName: "GI" }
];

export default class ManageWarehouseLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWarehouseLocation: cloneDeep(this.props.selectedData),
            formErrors: {},
            isLoadingSave: false,
            isOpenDeleteModal: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.saveWarehouseLocation = this.saveWarehouseLocation.bind(this);
        this.handleDeleteLocation = this.handleDeleteLocation.bind(this);
        this.openConfirmationDeleteModal = this.openConfirmationDeleteModal.bind(this);
    }

    handleChange(key, change) {
        let { selectedWarehouseLocation } = this.state;
        selectedWarehouseLocation[key] = change;
        this.setState({ selectedWarehouseLocation });
    }

    validateForm(item) {
        let formErrors = {}, isValid= true;
        if (!item.name) {
            formErrors.name = "Enter Location Name";
            isValid= false;
        }
        if (!item.warehouseID) {
            formErrors.warehouseID = "Select Warehouse Name";
            isValid= false;
        }
        if (!item.locationTypeID) {
            formErrors.locationTypeID = "Select Location Type";
            isValid= false;
        }
        // if (!item.checkDigit) {
        //     formErrors.checkDigit = "Enter Check Digit";
        //     isValid= false;
        // }
        return ({
            isValid: isValid,
            formErrors: formErrors
        })
    }

    addDefaultDataForNewLocations(location) {
        if (location && location.locationID) {
            return location;
        }
        location.warehouseID = '1';
        location.disabled = false;
        location.needsReplenishment = false;
        return location;
    }

    saveWarehouseLocation() {
        let { selectedWarehouseLocation } = this.state;
        let request = {
            "checkDigit": selectedWarehouseLocation.checkDigit,
            "disabled": selectedWarehouseLocation.disabled,
            "locationID": selectedWarehouseLocation.locationID,
            "locationTypeID": selectedWarehouseLocation.locationTypeID,
            "name": selectedWarehouseLocation.name,
            "needsReplenishment": selectedWarehouseLocation.needsReplenishment,
            "parentId": selectedWarehouseLocation.parentId,
            "warehouseID": selectedWarehouseLocation.warehouseID
        };
        let validateResult = this.validateForm(request);
        if (!validateResult.isValid) {
            this.setState({ formErrors: validateResult.formErrors });
            return;
        }
        request = this.addDefaultDataForNewLocations(request);
        this.setState({ isLoadingSave: true, formErrors: {} })
        warehouseLocationTypeService.saveWarehouseLocation(request).then(response => {
            if (response.status === 200 || response.status === "200" || response.status === 204 || response.status === "204") {
                this.setState({ isLoadingSave: false })
                toast.success("Saved!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.refreshAgain();
            }
        }).catch(error => {
            this.setState({ isLoadingSave: false })
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        })
    }

    openConfirmationDeleteModal(data) {
        this.setState({ isOpenDeleteModal: data });
    }

    handleDeleteLocation() {
        let { selectedWarehouseLocation } = this.state;
        warehouseLocationTypeService.deleteWarehouseLocation(selectedWarehouseLocation).then(response => {
            if (response.status === '200' || response.status === 200) {
                toast.success("Deleted", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({ isOpenDeleteModal: false });
                this.props.refreshAgain();
            }
        }).catch(error => {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { isOpen, toggle, warehouseOptions, hasStock } = this.props;
        let { isLoadingSave, formErrors, selectedWarehouseLocation, isOpenDeleteModal } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {selectedWarehouseLocation && selectedWarehouseLocation.locationID ? 'Edit' : 'Add'} Locations
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Location Name*</Label>
                                <Input type="text" name="name"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedWarehouseLocation.name}
                                    placeholder="Enter Location Name" />
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Warehouse *</Label>
                                <Input type={"text"} name={"warehouseID"}
                                    value={"Berrinba"}
                                    className={"mb-1"}
                                    disabled={true}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                </Input>
                                <FormText color="danger">{formErrors.warehouseID}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Location Type *</Label>
                                <Input type={"select"} name={"locationTypeID"}
                                    value={selectedWarehouseLocation ? selectedWarehouseLocation.locationTypeID : null}
                                    className={"mb-1"}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                    <option value={0}>Select Location Type</option>
                                    {warehouseLocationTypeOptions.map((locationTypeName, locationTypeIndex) =>
                                        <option value={locationTypeName.locationTypeID}>{locationTypeName.locationTypeName}</option>
                                    )}
                                </Input>
                                <FormText color="danger">{formErrors.locationTypeID}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Check Digit*</Label>
                                <Input type="number" name="checkDigit"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedWarehouseLocation.checkDigit}
                                    disabled={true}
                                    placeholder="Enter Check Digit Value" />
                                <FormText color="danger">{formErrors.checkDigit}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    {selectedWarehouseLocation && selectedWarehouseLocation.locationID ?
                            <Row>
                                <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("disabled", !selectedWarehouseLocation.disabled)}>
                                            {
                                                selectedWarehouseLocation && selectedWarehouseLocation.disabled
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Disabled
                                    </Label>

                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("needsReplenishment", !selectedWarehouseLocation.needsReplenishment)}>
                                            {
                                                selectedWarehouseLocation && selectedWarehouseLocation.needsReplenishment
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Needs Replenishment
                                    </Label>
                                </Col>
                            </Row> 
                            :
                        null
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        {selectedWarehouseLocation && selectedWarehouseLocation.locationID ?
                            <Button color='btn btn-danger btn-sm'
                                className="mr-2"
                                size='sm'
                                disabled={hasStock}
                                onClick={() => this.openConfirmationDeleteModal(true)}>
                                <i className="fa fa-trash mr-2"
                                    aria-hidden="true" />Delete
                            </Button>
                            : null
                        }
                        <Button color={"primary"} size="sm" onClick={() => this.saveWarehouseLocation()} disabled={isLoadingSave}>
                            {isLoadingSave
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            {selectedWarehouseLocation && selectedWarehouseLocation.locationID ? "Update" : "Save"}</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button> 
                    </div>
                </ModalFooter>
                {
                    isOpenDeleteModal ?
                        <ConfirmModal
                            isOpen={isOpenDeleteModal}
                            toggle={this.openConfirmationDeleteModal}
                            handleSubmit={this.handleDeleteLocation}
                            handleCancel={() => this.openConfirmationDeleteModal(false)}
                            primaryMessage={"Are you sure you want to delete this Location?"}
                            secondaryMessage={""}
                            submitColor={"danger"}
                            cancelColor={"secondary"}
                            icon={"fa fa-trash-o fa-2x"}
                            loading={false}
                            type="Deleting..."
                        />
                        : null
                }
            </Modal>
        );
    }
}